import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CptCode } from 'src/app/Models/cptcode.model';
import { NeurologyVisit } from 'src/app/Models/neurologyvisit.model';
import { Patient } from 'src/app/Models/patient.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { NeurologyVisitService } from 'src/app/services/neurology-visit.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-neurology-visit',
  templateUrl: './view-neurology-visit.component.html',
  styleUrls: ['./view-neurology-visit.component.css'],
})
export class ViewNeurologyVisitComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  patient: Patient;
  patientVisitList: NeurologyVisit[] = [];
  isAdmitted = false;

  pvId = 0;
  patientVisitId: NeurologyVisit[];
  showItem = '';
  CurrentDoctorId: number;
  filteredList;
  cptCodeLlist: CptCode[];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: NeurologyVisitService,
    private cptCodeService: CptcodeService
  ) {}

  ngOnInit(): void {
    this.getCptCode();

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getNeurologyVisits(this.id).subscribe((res) => {
      this.patient = res;
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.CurrentDoctorId = parseInt(decodedToken.nameid);
      this.filteredList = res.NeurologyVisits.sort(function (x, y) {
        return y.NeurologyVisitId - x.NeurologyVisitId;
      });
      this.patientVisitList = res.NeurologyVisits.sort((a, b) => {
        return b.NeurologyVisitId - a.NeurologyVisitId;
      });
    });
  }
  printPage() {
    window.print();
  }

  search(searchValue) {
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deleteNeurologyVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.NeurologyVisitId != id
        );
      });
    }
  }

  getCptCode() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeLlist = res;
    });
  }
}
