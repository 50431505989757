import { NursereportService } from './../../services/nursereport.service';
import { Component, OnInit } from '@angular/core';
import { NurseReport } from '../../Models/nursereport.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseService } from 'src/app/services/nurse.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-nursereport',
  templateUrl: './add-nursereport.component.html',
  styleUrls: ['./add-nursereport.component.css'],
})
export class AddNursereportComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  nurse: Nurse;
  options: string[] = ['Morning', 'Afternoon', 'Evening', 'Night'];
  isSuccess = false;
  nurseReports: NurseReport[];
  nurseId = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private nurseReportService: NursereportService,
    private router: Router,
    private route: ActivatedRoute,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);

    this.nurseId = parseInt(decodedToken.nameid);
    this.getNurse(this.nurseId);
  }

  medFrm = this.fb.group({
    NurseName: ['', Validators.required],
    // Date: [''],
    Time: [''],
    Respiration: [''],
    Comments: ['', Validators.required],
    Temparature: [''],
    HeartBeat: [''],
    BloodPressure: [''],
    Signature: ['', Validators.required],
  });

  get Respiration() {
    return this.medFrm.get('Respiration');
  }
  get Temparature() {
    return this.medFrm.get('Temparature');
  }
  get HeartBeat() {
    return this.medFrm.get('HeartBeat');
  }
  get BloodPressure() {
    return this.medFrm.get('BloodPressure');
  }
  get NurseName() {
    return this.medFrm.get('NurseName');
  }

  // get Date() {
  //   return this.medFrm.get('Date');
  // }
  get Time() {
    return this.medFrm.get('Time');
  }
  get Comments() {
    return this.medFrm.get('Comments');
  }
  get Signature() {
    return this.medFrm.get('Signature');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  formSubmit() {
    let partofDay = this.medFrm.value['PartofDay'];
    let nurseName = this.medFrm.value['NurseName'];
    let drugName = this.medFrm.value['DrugName'];
    let dosage = this.medFrm.value['Dosage'];
    let brandName = this.medFrm.value['BrandName'];
    //    let date = this.medFrm.value['Date']
    // date=  moment(date).format('MM/DD/YYYY');

    let time = this.medFrm.value['Time'];

    let respiration = this.medFrm.value['Respiration'];
    let temparature = this.medFrm.value['Temparature'];
    let heartBeat = this.medFrm.value['HeartBeat'];
    let bloodPressure = this.medFrm.value['BloodPressure'];
    let comments = this.medFrm.value['Comments'];
    let signature = this.medFrm.value['Signature'];
    let nurseId = this.medFrm.value['NurseId'];
    // let idoctorName = this.nurseList.filter(it => it.NurseId == nurseId)[0].NurseName;

    let body = {
      PatientId: this.id,
      NurseId: this.nurseId,
      Comments: comments,
      NurseName: this.nurse.NurseName,
      Signature: signature,
      Respiration: respiration,
      Temparature: temparature,
      HeartBeat: heartBeat,
      BloodPressure: bloodPressure,
      Time: time,
    };
    this.nurseReportService.registerNurseReport(body).subscribe((res) => {
      this.isSuccess = true;
      this.medFrm.reset();
    });
  }
}
