<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Daily Medication</h1>
      <form [formGroup]="medFrm" (ngSubmit)="formSubmit()" class="example-form">


        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Part Of the Day</mat-label>
              <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
              <mat-select formControlName="PartofDay">

                <mat-option *ngFor="let sp of options" [value]="sp">{{sp}}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="" formArrayName="Drugs">
          <div class="row" *ngFor="let item of Drugs.controls; let i=index">
            <ng-container [formGroup]="item">
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <mat-select formControlName="DrugName">

                    <mat-option *ngFor="let ml of medicineList" [value]="ml.DrugName">{{ml.DrugName}}</mat-option>

                  </mat-select>
                  <mat-error>Please Select Drug Name</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Strength</mat-label>
                  <mat-select formControlName="Dosage">

                    <mat-option *ngFor="let ds of medicineList" [value]="ds.Dosage">{{ds.Dosage}}</mat-option>

                  </mat-select>
                 
                  <mat-error>Please Select Dosage</mat-error>

                </mat-form-field>
              </div> -->
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label>Number Of Tablets</mat-label>
                  <input type="text" formControlName="NumberOfDrugs" matInput>
                  <mat-error>Please enter Number Of Tablets</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>BrandName</mat-label>
                  <mat-select formControlName="BrandName">

                    <mat-option *ngFor="let br of medicineList" [value]="br.BrandName">{{br.BrandName}}</mat-option>

                  </mat-select>

                </mat-form-field>
              </div> -->
              <div class="col-lg-2">
                <button type="button" mat-mini-fab (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
              </div>
            </ng-container>
          </div>

          <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>


        </div>

        <!-- <div class="row">
               
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <mat-select formControlName="DrugName">

                    <mat-option *ngFor="let ml of medicineList" [value]="ml.DrugName">{{ml.DrugName}}</mat-option>
        
                  </mat-select>
                  <mat-error >Please Select Drug Name</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>BrandName</mat-label>
                  <mat-select formControlName="BrandName">

                    <mat-option *ngFor="let br of medicineList" [value]="br.BrandName">{{br.BrandName}}</mat-option>
        
                  </mat-select>
                  
                </mat-form-field>
       
              </div>
              <div class="col-lg-4">
                
                <mat-form-field class="example-full-width">
                  <mat-label>Strength</mat-label>
                  <mat-select formControlName="Dosage">

                    <mat-option *ngFor="let ds of medicineList" [value]="ds.Dosage">{{ds.Dosage}}</mat-option>
        
                  </mat-select>
            
                  <mat-error >Please Select Dosage</mat-error>

                </mat-form-field>
              </div>
             </div> -->
        <div class="row">

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Date</mat-label>
              <input type="date" formControlName="Date" matInput>
              <mat-error>Please enter Date</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Time</mat-label>
              <input [ngxTimepicker]="picker" matInput formControlName="Time">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error>Please enter Time</mat-error>
              <!-- <input type="text" matInput formControlName="Time" > -->
            </mat-form-field>
          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>NurseName</mat-label>
              <input type="text" matInput formControlName="NurseName">
              <mat-error>Please enter Nurses Name</mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Nurse Notes/Comments </mat-label>
              <!-- <mat-icon matPrefix>event</mat-icon> -->
              <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
              <textarea matInput formControlName="Comments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


              <mat-error *ngIf="Comments.invalid && Comments.touched">Please enter Comment's</mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
                  <mat-label>Comments</mat-label>
                  <input type="text" matInput formControlName="Comments" >
                </mat-form-field> -->
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Signature/Initial</mat-label>
                <input type="text" matInput formControlName="Signature">
              </mat-form-field>
            </div>

          </div>




        </div>













        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Medication
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>