

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Hester Davies FallRisk Assessment</h1>
            <form [formGroup]="hdFrm" (ngSubmit)="formSubmit()" class="example-form">
           

              <div class="row">
              

                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select LastKnownFall</mat-label>
                    
                        <mat-select formControlName="LastKnownFall">
        
                          <mat-option *ngFor="let ls of LastKnownFalls" [value]="ls">{{ls}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Last KnownFall Score</mat-label>
                  <input type="number"  formControlName="LastKnownFallScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter LastKnown Fall Score</mat-error>
                </mat-form-field>
              </div>

            </div>   
              <div class="row">
              

                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Mobility</mat-label>
                    
                        <mat-select formControlName="Mobility">
        
                          <mat-option *ngFor="let mob of Mobilities" [value]="mob">{{mob}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Mobility Score</mat-label>
                  <input type="number"  formControlName="MobilityScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Mobility Score</mat-error>
                </mat-form-field>
              </div>

            </div>   

            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Medication</mat-label>
                    
                        <mat-select formControlName="Medication">
        
                          <mat-option *ngFor="let mds of Medications" [value]="mds">{{mds}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Medication Score</mat-label>
                  <input type="number"  formControlName="MedicationScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Medication Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select MentalStatuse</mat-label>
                    
                        <mat-select formControlName="MentalStatus">
        
                          <mat-option *ngFor="let mts of MentalStatuses" [value]="mts">{{mts}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label> MentalStatus Score</mat-label>
                  <input type="number"  formControlName="MentalStatusScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter  MentalStatus Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Toileting Needs</mat-label>
                    
                        <mat-select formControlName="ToiletingNeeds">
        
                          <mat-option *ngFor="let tns of ToiletingNeed" [value]="tns">{{tns}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Toileting Needs Score</mat-label>
                  <input type="number"  formControlName="ToiletingNeedsScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Toileting Needs Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Communication</mat-label>
                    
                        <mat-select formControlName="Communication">
        
                          <mat-option *ngFor="let cms of Communications" [value]="cms">{{cms}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Communication Score</mat-label>
                  <input type="number"  formControlName="CommunicationScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Communication Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select  Behaviour</mat-label>
                    
                        <mat-select formControlName="Behaviour">
        
                          <mat-option *ngFor="let bhv of  Behaviours" [value]="bhv">{{bhv}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Behaviour Score</mat-label>
                  <input type="number"  formControlName="BehaviourScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Behaviour Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Volume Electrolyte Status</mat-label>
                    
                        <mat-select formControlName="VolumeElectrolyteStatus">
        
                          <mat-option *ngFor="let ves of VolumeElectorlightStatuses" [value]="ves">{{ves}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Volume Electrolyte Status Score</mat-label>
                  <input type="number"  formControlName="VolumeElectrolyteStatusScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Volume Electrolyte Status Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            
            <div class="row">
                <div class="col-lg-6"> 
                   
                        <mat-label>Hester Davies Fall Risk Total</mat-label>
              
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Final Score</mat-label>
                  <input type="number" value="{{score}}"  formControlName="HesterDaviesFallRiskTotal" (keyup)="sum($event)" matInput >
                
                </mat-form-field>
              </div>
            </div>
            <div class="row">
            
              <div class="col-lg-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Fall Risk Precaution</mat-label>
                  <textarea matInput
                    formControlName="FallRiskPrecaution"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="40"></textarea>
                
                </mat-form-field>
              </div>
            </div>
            
            

                 
                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>
                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
              </form>
        
        </div>
    </div>

</div>

