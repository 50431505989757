import { Component } from '@angular/core';

@Component({
  selector: 'app-update-nerve-conduction-study',
  templateUrl: './update-nerve-conduction-study.component.html',
  styleUrls: ['./update-nerve-conduction-study.component.css']
})
export class UpdateNerveConductionStudyComponent {

}
