

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Bed</h1>
            <form [formGroup]="bedFrm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Select Room</mat-label>
                    <mat-select formControlName="RoomId">
          
                      <mat-option *ngFor="let room of roomList" [value]="room.RoomId">{{room.RoomNo}}</mat-option>
          
                    </mat-select>
          
                    <!-- <mat-error *ngIf="WardId.hasError('required')">You must make a selection</mat-error>  -->
          
                  </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Bed Number </mat-label>
                    <input type="text" matInput formControlName="BedNumber" >
                    <mat-error >Please enter Bed Number</mat-error>
                  </mat-form-field>
                

              
                <mat-form-field class="example-full-width">
                  <mat-label>Bed Charge</mat-label>
                  <input type="number" matInput formControlName="BedCharge" >
                  <mat-error >Please enter BedCharge</mat-error>
                </mat-form-field>

                <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                    <div class="row">
                      <mat-radio-button class="mx-3" value="true">Occupied</mat-radio-button>
                      <mat-radio-button class="mx-3 " value="false"> Empty</mat-radio-button>

                    </div>

                    </mat-radio-group>

                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>

                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
              </form>
             
        </div>
    </div>
</div>
