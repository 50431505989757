import { Component, OnInit, ViewChild } from '@angular/core';
import { AllergyService } from '../../services/allergy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Allergy } from '../../Models/allergy.models';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from '../../Models/patient.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-allergy',
  templateUrl: './view-allergy.component.html',
  styleUrls: ['./view-allergy.component.css'],
})
export class ViewAllergyComponent implements OnInit {
  id = 0;
  alergyList: Allergy[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'AllergicTo',
    'Reaction',
    'Description',
    'Delete',
  ];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private allergyService: AllergyService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getAllergy(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAllergy(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Allergies.sort(function (x, y) {
          return y.AllergyId - x.AllergyId;
        })
      );
      this.dataSource.paginator = this.paginator;

      this.alergyList = res.Allergies;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.allergyService.deleteAllergy(id).subscribe((res) => {
      alert('deleted');
      this.getAllergy(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getAllergy(this.id);
    }
  }
}
