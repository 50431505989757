import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  isUserExists = false;
  throwError = '';
  successMessage = '';
  constructor(
    private fb: UntypedFormBuilder,
    private userProfileService: UserService
  ) {}

  ngOnInit(): void {}

  resetpassForm = this.fb.group({
    Email: ['', [Validators.required, Validators.email]],
  });

  get email() {
    return this.resetpassForm.get('Email');
  }

  formSubmit() {
    let email = this.resetpassForm.value['Email'];
    let body = {
      Email: email,
    };
    this.userProfileService.forgotPassword(body).subscribe(
      (res) => {
        this.successMessage = res['Message'];
      },
      (err) => {
        this.throwError = Object.keys(err)
          .map((item) => err[item])
          .toString();
        this.isUserExists = true;
      }
    );
  }
}
