import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PurposefullRoundService } from 'src/app/services/purposefull-round.service';

@Component({
  selector: 'app-add-purposefull-round',
  templateUrl: './add-purposefull-round.component.html',
  styleUrls: ['./add-purposefull-round.component.css']
})
export class AddPurposefullRoundComponent implements OnInit {

  helper = new JwtHelperService()

  myToken = window.localStorage.getItem('token');

  id=0;

nurseId=0;
  isSuccess= false;
  Frm:FormGroup

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private purfullRoundService:PurposefullRoundService,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id'];
  })
  let decodedToken = this.helper.decodeToken(this.myToken);
  this.nurseId = parseInt(decodedToken.nameid);


  this.Frm = this.fb.group({
    PatientId: this.id,
    NurseId:this.nurseId,
    PatientPosition:[false],
    PatientSleeping:[false],
    PatientUnAvailable:[false],
    RnNotified:[false],
    Other:[''],
    Agitated:[false],
    EyesClosed:[false],
    Quiet:[false],
    Awake:[false],
    InBed:[false],
    PatientNotinRoom:[false],
    Confused:[false],
    InChair:[false],
    OtherComment:[''],
  })
  
  }



get PatientId(){
  return this.Frm.get('PatientId')
}
get NurseId(){
  return this.Frm.get('NurseId')
}
get PatientPosition(){
  return this.Frm.get('PatientPosition')
}
get PatientSleeping(){
  return this.Frm.get('PatientSleeping')
}
get PatientUnAvailable(){
  return this.Frm.get('PatientUnAvailable')
}
get RnNotified(){
  return this.Frm.get('RnNotified')
}
get Other(){
  return this.Frm.get('Other')
}
get Agitated(){
  return this.Frm.get('Agitated')
}
get EyesClosed(){
  return this.Frm.get('EyesClosed')
}
get Quiet(){
  return this.Frm.get('Quiet')
}
get Awake(){
  return this.Frm.get('Awake')
}
get InBed(){
  return this.Frm.get('InBed')
}
get PatientNotinRoom(){
  return this.Frm.get('PatientNotinRoom')
}
get Confused(){
  return this.Frm.get('Confused')
}
get InChair(){
  return this.Frm.get('InChair')
}
get OtherComment(){
  return this.Frm.get('OtherComment')
}

formSubmit(){

  this.purfullRoundService.registerPurposefullRound(this.Frm.value).subscribe(res=>{
    this.isSuccess=true;
    this.Frm.reset();
    // this.router.navigate(['nurseDash'])
  })

}


}
