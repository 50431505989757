<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-6 p-info-cols mb-5" *ngFor="let emContacts of EmergencyContactList">
            <mat-card >
              <mat-card-content>
                <h2>Emergency Contact Details</h2>
                <table class="w-100" >
                  <tr >
                    <td>Contact Name:</td>
                    <td>{{ emContacts?.EmergencyContactName }}</td>
                  </tr>
                  <tr>
                    <td>Contact Phone#</td>
                    <td>{{ emContacts?.EmergencyContactPhone }}</td>
                  </tr>
                  <tr>
                    <td>Relationship:</td>
                    <td>{{ emContacts?.EmergencyContactRelation }}</td>
                  </tr>
        
                </table>
              </mat-card-content>
            </mat-card>
            <button class="float-right" mat-mini-fab (click)="delete(emContacts.EmergencyContactId)"><mat-icon>delete</mat-icon></button>

        </div>
    </div>
</div>