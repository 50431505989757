<div id="dashboard">
  <div class="content">
    <div class="sidebar mat-elevation">
      <div class="header">
        <!-- <img src="../../../assets/admin.png" class="image" alt=""> -->

        <h4 class="text-white ml-2">Department Dashboard</h4>
      </div>
      <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>
          dashboard
        </mat-icon>
        <div class="mx-2"> Dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Company</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <!-- <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addPatient/',this.id]"> Add Patient</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
   
          -->



    </div>

    <div class="main">
      <div class="toolbar mat-elevation-z6">
        <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="mute"
          mat-raised-button>
          <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
          <span>Logout</span>
        </button>

      </div>




      <div class="container mt-5">
        <span [ngSwitch]="showItem">
          <p *ngSwitchCase="'1'">
            <app-add-company></app-add-company>
          </p>


          <p *ngSwitchDefault>
            <app-company></app-company>
          </p>
        </span>
      </div>

    </div>



  </div>
</div>