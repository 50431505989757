<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>Add Hospital</h2>
      <form [formGroup]="hsFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Hospital Name</mat-label>
              <input type="text" formControlName="HospitalName" matInput>
              <mat-error>Please enter Hospital Name</mat-error>
            </mat-form-field>

          </div>

          <div class="col-lg-4">
            <input type="file" (change)="image($event)">
            <!-- <mat-form-field class="example-full-width">
            <mat-label>Up Load Hospital Logo</mat-label> 
            <input type="text" formControlName="HospitalLogo"  matInput>
            <mat-error >Please enter HospitalLogo</mat-error>
          </mat-form-field> -->
            <mat-error>Please upload image</mat-error>
          </div>

        </div>
        <div class="row">
          <div class="col-2">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Hospital Phone</mat-label>
              <input type="text" formControlName="HospitalPhone" matInput>
              <mat-error>Please enter Hospital Phone</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Hospital Email</mat-label>
              <input type="text" formControlName="HospitalEmail" matInput>
              <mat-error>Please enter Hospital Email</mat-error>
            </mat-form-field>

          </div>

        </div>
        <div class="row">
          <div class="col-lg">

            <mat-form-field class="example-full-width">
              <mat-label>Hospital Address</mat-label>
              <input type="text" formControlName="HospitalAddress" matInput>
              <mat-error>Please enter Hospital Address</mat-error>
            </mat-form-field>

          </div>


        </div>

        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>


    </div>