import { Component } from '@angular/core';

@Component({
  selector: 'app-update-blood-sugar',
  templateUrl: './update-blood-sugar.component.html',
  styleUrls: ['./update-blood-sugar.component.css']
})
export class UpdateBloodSugarComponent {

}
