import { Component, OnInit } from '@angular/core';
import { PharmacyMember } from 'src/app/Models/pharmacymember.model';
import { PharmacyMemberService } from '../../services/pharmacy-member.service';
import { UserService } from '../../services/user.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-pharmacy-member',
  templateUrl: './view-pharmacy-member.component.html',
  styleUrls: ['./view-pharmacy-member.component.css'],
})
export class ViewPharmacyMemberComponent implements OnInit {
  phList: PharmacyMember[];

  constructor(
    private pharmacyService: PharmacyMemberService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getPharmacyMmembers();
  }

  getPharmacyMmembers() {
    this.pharmacyService.getPharmacyMembers().subscribe((res) => {
      this.phList = res.sort(function (x, y) {
        return y.PharmacyMemberId - x.PharmacyMemberId;
      });
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPharmacyMmembers();
    }
  }

  delete(id) {
    this.pharmacyService.deletePharmacyMember(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      this.getPharmacyMmembers();
    });
  }
}
