import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Labreport } from 'src/app/Models/labreport.model';
import { LabTest } from 'src/app/Models/labtest.model';
import { Medication } from 'src/app/Models/medication.model';
import { Patient } from 'src/app/Models/patient.model';
import { LabreportService } from 'src/app/services/labreport.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-printlabreport',
  templateUrl: './printlabreport.component.html',
  styleUrls: ['./printlabreport.component.css'],
})
export class PrintlabreportComponent implements OnInit {
  id = 0;

  patient: Patient;

  labR: LabTest[] = [];
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private labReportService: LabreportService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getLabReport(this.id);
    this.getPatient(this.id);
  }

  getLabReport(id) {
    this.labReportService.getLabTest(id).subscribe((res) => {
      this.labR = res.LabTests;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  print() {
    window.print();
  }
}
