import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-patient-pharmacy',
  templateUrl: './view-patient-pharmacy.component.html',
  styleUrls: ['./view-patient-pharmacy.component.css'],
})
export class ViewPatientPharmacyComponent implements OnInit {
  phList: PatientsPharmacy[];

  constructor(private pharmacyService: PharmacyService) {}

  ngOnInit(): void {
    this.getPharmacys();
  }

  getPharmacys() {
    this.pharmacyService.getPatientsPharmacys().subscribe((res) => {
      this.phList = res.sort(function (x, y) {
        return y.PatientsPharmacyId - x.PatientsPharmacyId;
      });
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPharmacys();
    }
  }

  delete(id) {
    this.pharmacyService.deletePatientsPharmacy(id).subscribe((res) => {
      this.getPharmacys();
    });
  }
}
