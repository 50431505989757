<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>New Procedure Order</h2>
            <form [formGroup]="labFrm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Procedure Name</mat-label>
                            <input type="text" formControlName="ProcedureName" matInput>
                            <mat-error>Please enter Procedure Name</mat-error>
                        </mat-form-field>



                    </div>




                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Ordered By Doctor</mat-label>


                            <mat-select formControlName="DoctorName">

                                <mat-option *ngFor="let doctor of doctorList"
                                    [value]="doctor.DoctorName">{{doctor.DoctorName}}</mat-option>

                            </mat-select>
                            <mat-error>select an option</mat-error>
                        </mat-form-field>

                    </div>


                </div>



                <div class="row">
                    <div class="col-lg-12">


                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Descriptive reason for Order</mat-label>

                            <textarea matInput (input)="onSubjectiveChange($event)" formControlName="Description"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="140" [value]="service.message1" matInput
                                (blur)="onSubjectiveBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startService()"></button>

                        </mat-form-field>


                    </div>
                    <button type="button" color="accent" (click)="stopService()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>

                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Order
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>


        </div>