<div class="container ">
    <h2>Safety Management</h2>

    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <h4>General Safety</h4>
        <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="CallBellWithinReach">
                Call Bell Within Reach
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="PatientIdentificationBandOn">
                Patient Identification Band On
            </mat-checkbox>
        </div>
      
        <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Head Of Bed Elevation</mat-label>
              <input type="text" formControlName="HeadOfBedElevation" matInput >
            </mat-form-field>
          </div>
<!-- AlamSet -->
         <h4>Alarm Set and Audible
         </h4>
         

         <div class="row" [formGroup]="Frm">
            <div class="col-md-4">
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="IVpump">
                        IV Pump
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="FeedingPump">
                        Feeding Pump
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="PcaPump">
                        PCA Pump
                    </mat-checkbox>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="EpiduralPump">
                        Epidural Pump
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="BedsideMonitor">
                        Bedside Monitor
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="HypoThermiaBlanket">
                        Hypothermia Blanket
                    </mat-checkbox>
                </div>
            </div>
        
             <div class="col-md-4">
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="UrinaryCatheterTempratureProbe">
                        Urinary Catheter Temprature Probe
                    </mat-checkbox>
                </div>
               
                <div class="col-lg-8">
                    <mat-form-field class="example-full-width">
                      <mat-label>Other Alarms Set and Audible</mat-label>
                      <input type="text" formControlName="OtherAlarmsSetandAudible" matInput >
                    </mat-form-field>
                  </div>
            </div>
        
        </div>


<!-- precautions -->


<h4>Precautions</h4>

<div class="row" [formGroup]="Frm">
    
   <div class="col-md-4">
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="Standard">
            Standard
           </mat-checkbox>
       </div>
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="Contact">
            Contact
           </mat-checkbox>
       </div>
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="Airborne">
            Airborne
           </mat-checkbox>
       </div>
   </div>
   <div class="col-md-4">
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="Droplet">
            Droplet
           </mat-checkbox>
       </div>
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="Aspiration">
            Aspiration
           </mat-checkbox>
       </div>
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="FallPrevention">
            FallPrevention
           </mat-checkbox>
       </div>
   </div>

    <div class="col-md-4">
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="SuicideRisk">
            Suicide Risk
           </mat-checkbox>
       </div>
      
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="AssualtRisk">
            Assualt Risk
           </mat-checkbox>
       </div>
      
       <div class="col-md-2">
           <mat-checkbox color="primary" formControlName="SelfHarmRisk">
            Suicide Risk
           </mat-checkbox>
       </div>
      
 
   </div>

</div>
<div class="row" [formGroup]="Frm">
    <div class="col-md-4">
<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="SelfHarmRisk">
     Self Harm Risk
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="ElopmentRisk">
     Elopment Risk
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="OsaRisk">
     OSA Risk
    </mat-checkbox>
</div>
    </div>
    <div class="col-md-4">
<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="Letex">
        Letex
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="Seizure">
        Seizure
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="Bleeding">
        Bleeding
    </mat-checkbox>
</div>
    </div>
    <div class="col-md-4">
<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="Chemotherapy">
        Chemotherapy
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="Neutropenic">
        Neutropenic
    </mat-checkbox>
</div>

<div class="col-md-2">
    <mat-checkbox color="primary" formControlName="NoBpOrIvonRightArm">
        No BP/IV on the Right Arm
    </mat-checkbox>
</div>
    </div>
</div>

<div class="row" [formGroup]="Frm">

    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="NoBpOrIvonLeftArm">
                No BP/IV on the Left Arm
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="WeightBearing">
                Weight Bearing
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="SpinalPrecautions">
                Spinal Precautions
            </mat-checkbox>
        </div>
            </div>
            <div class="col-md-4">
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="SternalPrecautions">
                        Sternal Precautions
                    </mat-checkbox>
                </div>
                
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="AnteriorHipPrecautions">
                        Anterior Hip Precautions
                    </mat-checkbox>
                </div>
                
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="PosteriorHipPrecautions">
                        Posterior Hip Precautions
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="RemoveHarmfulObjectsFromImmedietArea">
                        Remove Harmful Objects FromImmediet Area
                    </mat-checkbox>
                </div>
                    </div>
                
</div>
<h4>Dietary Considerations</h4>

<div class="row" [formGroup]="Frm">
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="NonSpecific">
                Non Specific
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="SafteyTray">
                Saftey Tray
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="FluidResrtiction">
                Fluid Resrtiction
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="CalerieCount">
                Calerie Count
            </mat-checkbox>
        </div>
            </div>
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="LiquidOrSolidConsistency">
                Liquid/Solid Consistency
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="SwallowingStrategies">
                Swallowing Strategies
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="CaffeineRestriction">
                Caffeine Restriction
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="Suppliments">
                Suppliments
            </mat-checkbox>
        </div>
            </div>
        
</div>
<h4>Liquid/Solid Consistencies</h4>

<div class="row" [formGroup]="Frm">
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="Normal">
                Normal
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L3LquidizedDiet">
                L3 Lquidized Diet
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L0ThinLiquids">
                L0 Thin Liquids
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L1SlightlyThickLiquids">
                L1 Slightly ThickLiquids
            </mat-checkbox>
        </div>
            </div>
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L2MildlyThickLiquids">
                L2 Mildly Thick Liquids
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L3ModeratelyThickLiquids">
                L3 Moderately Thick Liquids
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L4Pureed">
                L4 Pureed
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L5MincedandMoist">
                L5 Minced and Moist
            </mat-checkbox>
        </div>
            </div>
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L6SoftandBiteSizePiece">
                L6 Soft and Bite Size Piece
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="L7EasyToChew">
                L7 Easy To Chew
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="NoSwallowingStrategyNecessary">
                No Swallowing Strategy Necessary
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="AlternateLiquidsandSolids">
                Alternate Liquids and Solids
            </mat-checkbox>
        </div>
            </div>
    <div class="col-md-4">
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="ChinTuckWitLiquids">
                Chin Tuck Wit Liquids
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="FrequesntCoughReswallow">
                Frequent Cough/Re-swallow
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="NoStrawsSmallSipsbyCup">
                No Straws Small Sips by Cup
            </mat-checkbox>
        </div>
      
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="SmallSipsbySpoon">
                Small Sips by Spoon
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="NoLiquid">
                NoLiquid
            </mat-checkbox>
        </div>
            </div>
        
</div>


<div class="row" [formGroup]="Frm">
    <div class="col-md-4">
<h4>Med-Surge Observation</h4>

        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="FrequentMedSurgeObservation">
                Frequent Med-Surge Observation
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="MedSurgeObservation">
                Med-Surge Observation
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="VideoMonitoringMedSurgeObservation">
                Video Monitoring Med-Surge Observation
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="OnetoOneMedSurgeObservation">
                One to One Med-Surge Observation
            </mat-checkbox>
        </div>
            </div>
    
        



    <div class="col-md-4 ml-5">
<h4>Psych/Detox Unit Observation Status</h4>

        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="Every5MinutesPsychObservation">
                Every 5 Minutes Psych Observation
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="Every15MinutesPsychObservation">
                Every 15 Minutes Psych Observation
            </mat-checkbox>
        </div>
        
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="Every30MinutesPsychObservation">
                Every 30 Minutes Psych Observation
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="OnetoOnePsychObservation">
                One to One Psych Observation
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="ContinuousPsychObservation">
                Continuous Psych Observation
            </mat-checkbox>
        </div>
        <div class="col-md-2">
            <mat-checkbox color="primary" formControlName="SupportivePsychObservation">
                Supportive Psych Observation
            </mat-checkbox>
        </div>
            </div>
    
        </div>



         <button  class="float-right lgBtCl" mat-button >
            <!-- <mat-icon>save</mat-icon> -->
            Save</button>
            <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
              Successfully Created Prescription
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      </form>
        </div>
    </div>
</div>
