import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from '../../services/doctor.service';
import { LabOrderService } from '../../services/lab-order.service';
import * as moment from 'moment';
import { LabOrderSubject } from 'src/app/Models/labordersubject.model';
import { LabOrderSubjectService } from 'src/app/services/lab-order-subject.service';
import { PatientService } from 'src/app/services/patient.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { Observable } from 'rxjs';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-lab-order',
  templateUrl: './add-lab-order.component.html',
  styleUrls: ['./add-lab-order.component.css'],
})
export class AddLabOrderComponent implements OnInit {
  id = 0;
  isSuccess = false;
  doctorList: Doctor[];
  subjectList: LabOrderSubject[] = [];
  hospitalId: number;
  flteredsubjectList: Observable<any>;
  listening = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private labOrderService: LabOrderService,
    private laborderSubjectService: LabOrderSubjectService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    public service: VoiceRecognitionService
  ) {
    this.service.init();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getDoctors();

    this.getSubjects();
  }

  labFrm = this.fb.group({
    OrderedBy: ['', Validators.required],
    Subject: ['', Validators.required],
    Description: ['', Validators.required],
    Date: ['', Validators.required],
  });

  get OrderedBy() {
    return this.labFrm.get('OrderedBy');
  }
  get Subject() {
    return this.labFrm.get('Subject');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }
  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  getSubjects() {
    this.laborderSubjectService.getLabOrderSubjects().subscribe((res) => {
      this.subjectList = res;
    });
  }

  formSubmit() {
    let orderedBy = this.labFrm.value['OrderedBy'];
    let subject = this.labFrm.value['Subject'];
    let description = this.labFrm.value['Description'];
    let date = this.labFrm.value['Date'];
    date = moment(date).format('MM/DD/YYYY');

    let body = {
      PatientId: this.id,
      OrderedBy: orderedBy,
      Subject: subject,
      Description: description,
      Date: date,
    };

    this.labOrderService.registerLabOrder(body).subscribe((res) => {
      this.isSuccess = true;
      this.labFrm.reset();
    });
  }
}
