<button mat-raised-button color="primary" [routerLink]="['/adminDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Update Room</h1>
            <form [formGroup]="roomFrm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Select Ward</mat-label>
                    <mat-select formControlName="WardId">

                        <mat-option *ngFor="let wrd of wardList" [value]="wrd.WardId">{{wrd.WardName}}</mat-option>

                    </mat-select>

                    <!-- <mat-error *ngIf="WardId.hasError('required')">You must make a selection</mat-error>  -->

                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>RoomNo </mat-label>
                    <input type="text" matInput formControlName="RoomNo">
                    <mat-error>Please enter RoomNo</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Room Charge </mat-label>
                    <input type="text" matInput formControlName="RoomCharge">
                    <mat-error>Please enter Room Charge</mat-error>
                </mat-form-field>



                <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input type="text" matInput formControlName="Description">
                    <mat-error>Please enter Description</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>ExpectedAvailability</mat-label>
                    <input type="date" matInput formControlName="ExpectedAvailability">
                    <mat-error>Please enter ExpectedAvailability</mat-error>
                </mat-form-field>


                <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                    <div class="row">
                        <mat-radio-button class="mx-3" value="true">Occupied</mat-radio-button>
                        <mat-radio-button class="mx-3 " value="false"> Empty</mat-radio-button>
                    </div>
                </mat-radio-group>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Room
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>