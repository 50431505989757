import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoice.service';
import { InvoiceItemService } from 'src/app/services/invoice-item.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-update-invoice',
  templateUrl: './update-invoice.component.html',
  styleUrls: ['./update-invoice.component.css'],
})
export class UpdateInvoiceComponent implements OnInit {
  id = 0;
  StatusList: string[] = ['Paid', 'Unpaid', 'Pending', 'Balance'];

  // patientName;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private invoiceService: InvoiceService,
    private invoiceItemService: InvoiceItemService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.invoiceService.getInvoiceItems(this.id).subscribe((res) => {
      this.PatientName.setValue(res.PatientName);
      this.Title.setValue(res.Title);
      // this.Amount.setValue(res.Amount)
      // this.Description.setValue(res.Description)

      this.CoPay.setValue(res.CoPay);
      this.DoctorName.setValue(res.DoctorName);
      this.NpiNumber.setValue(res.NpiNumber);
      this.InsuaranceName.setValue(res.InsuaranceName);
      this.InsuaranceNo.setValue(res.InsuaranceNo);
      this.GroupNo.setValue(res.GroupNo);
      this.NpiNumber.setValue(res.NpiNumber);
      this.SubTotal.setValue(res.SubTotal);
      this.InsurancePay.setValue(res.InsurancePay);
      this.AdjustmentAmount.setValue(res.AdjustmentAmount);
      this.BalanceAmount.setValue(res.BalanceAmount);
      this.Total.setValue(res.Total);
      this.Date.setValue(res.Date);
      this.Status.setValue(res.Status);
      res.InvoiceItems.forEach((items, index) => {
        this.addItem();
        this.InvoiceItems.controls[index].patchValue(items);
      });
    });
  }
  invForm = this.fb.group({
    PatientName: ['', Validators.required],
    Title: ['', Validators.required],
    DoctorName: [''],
    NpiNumber: [''],
    GroupNo: [''],
    InsuaranceNo: [''],
    InsuaranceName: [''],
    Status: ['', Validators.required],
    Date: ['', Validators.required],
    CoPay: [0, Validators.required],
    InsurancePay: [0],
    AdjustmentAmount: [0],
    BalanceAmount: [0],
    SubTotal: [0],
    Total: [0],
    InvoiceItems: this.fb.array([]),
  });
  get GroupNo() {
    return this.invForm.get('GroupNo');
  }
  get InsuaranceNo() {
    return this.invForm.get('InsuaranceNo');
  }
  get InsuaranceName() {
    return this.invForm.get('InsuaranceName');
  }
  get NpiNumber() {
    return this.invForm.get('NpiNumber');
  }
  get DoctorName() {
    return this.invForm.get('DoctorName');
  }
  get BalanceAmount() {
    return this.invForm.get('BalanceAmount');
  }
  get Date() {
    return this.invForm.get('Date');
  }
  get Title() {
    return this.invForm.get('Title');
  }
  get Amount() {
    return this.invForm.get('Amount');
  }
  get Description() {
    return this.invForm.get('Description');
  }
  get ProcedureCode() {
    return this.invForm.get('ProcedureCode');
  }
  get Status() {
    return this.invForm.get('Status');
  }
  get PatientName() {
    return this.invForm.get('PatientName');
  }

  get CoPay() {
    return this.invForm.get('CoPay');
  }
  get SubTotal() {
    return this.invForm.get('SubTotal');
  }

  get InsurancePay() {
    return this.invForm.get('InsurancePay');
  }

  get AdjustmentAmount() {
    return this.invForm.get('AdjustmentAmount');
  }
  get Total() {
    return this.invForm.get('Total');
  }

  get InvoiceItems(): UntypedFormArray {
    return this.invForm.get('InvoiceItems') as UntypedFormArray;
  }

  public addItem(): void {
    const item = this.fb.group({
      InvoiceItemId: [''],
      Amount: [''],
      Description: [''],
      ProcedureCode: [''],
      Duration: [''],
      Units: [''],
    });
    this.InvoiceItems.push(item);
  }

  public removeItem(i, InvoiceItemId): void {
    this.invoiceItemService.deleteInvoiceItem(InvoiceItemId).subscribe();
    this.InvoiceItems.removeAt(i);
    this.getGrandTotal();
  }

  public getGrandTotal(): void {
    let total: number = 0;
    let balance = 0;
    const invoiceItems = this.InvoiceItems.value;
    const coPay = +this.invForm.value['CoPay'];
    const adjusment = +this.invForm.value['AdjustmentAmount'];
    const insurancePay = +this.invForm.value['InsurancePay'];

    invoiceItems.forEach((invoiceItem) => {
      if (invoiceItem.Amount) {
        total = total + invoiceItem.Amount;
      }
    });

    total = total - coPay;
    balance = total - adjusment - insurancePay;
    this.Total.setValue(total);
    this.SubTotal.setValue(total);
    this.BalanceAmount.setValue(balance);
  }

  public updateInvoiceItems(i) {
    const body = this.InvoiceItems.controls[i].value;
    const id = this.InvoiceItems.controls[i].value['InvoiceItemId'];
    delete body['InvoiceItemId'];
    body['InvoiceId'] = this.id;
    this.invoiceItemService.updateInvoiceItem(id, body).subscribe();
  }

  formSubmit() {
    delete this.invForm.value['InvoiceItems'];
    let body = {
      ...this.invForm.value,
    };
    this.invoiceService.updateInvoice(this.id, body).subscribe((res) => {
      this.router.navigate(['adminDash']);
    });
  }
}
