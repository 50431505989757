<div class="container w-100 mt-5" id="printable">
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <apx-chart [series]="heartRateChart.series" [chart]="heartRateChart.chart"
                    [xaxis]="heartRateChart.xAxis" [title]="heartRateChart.title"></apx-chart>
            </div>

        </div>

    </div>


</div>