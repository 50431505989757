import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RadiologyMember } from 'src/app/Models/radiologymember.model';
import { RadiologyMemberService } from 'src/app/services/radiology-member.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-radiology-member',
  templateUrl: './view-radiology-member.component.html',
  styleUrls: ['./view-radiology-member.component.css'],
})
export class ViewRadiologyMemberComponent implements OnInit {
  radiologyMemberList: RadiologyMember[];

  constructor(
    private radiologyMemberService: RadiologyMemberService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getRadiologyMembers();
  }

  getRadiologyMembers() {
    this.radiologyMemberService.getRadiologyMembers().subscribe((res) => {
      this.radiologyMemberList = res.sort(function (x, y) {
        return y.RadiologyMemberId - x.RadiologyMemberId;
      });
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getRadiologyMembers();
    }
  }
  delete(id) {
    this.radiologyMemberService.deleteRadiologyMember(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      // this.radiologyMemberList =   this.radiologyMemberList.filter(x=>x.RadiologyMemberId !=id);
      this.getRadiologyMembers();
    });
  }
}
