<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-4" *ngFor="let item of socialHistoryList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Social History</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>


                    <tr>
                        <th> Do You Drink Alcohol : </th>

                        <td>{{item?.DoYouDrinkAlcohol ?'Yes':'No'}}</td>
                    </tr>
                    <tr>
                        <th> How often do you drink Alcohol : </th>
                        <td>{{item?.AlcoholDrinkingFrequency }}</td>

                    </tr>
                    <tr>
                        <th> Do You Smoke: </th>
                        <td>{{item?.DoYouSmoke ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> How Do You Smoke : </th>
                        <td>{{item?.HowDoYouSmoke}}</td>

                    </tr>

                    <tr>
                        <th> Do You Drink Caffeine : </th>
                        <td>{{item?.DoYouDrinkCaffeine ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Caffeine Drinking Frequency : </th>
                        <td>{{item?.CaffeineDrinkFrequency}}</td>

                    </tr>
                    <tr>
                        <th> Are You Sexually Active : </th>
                        <td>{{item?.AreYouSexuallyActive ?'Yes':'No'}}</td>

                    </tr>

                    <tr>
                        <th> Do You Wish To Be Checked For STDs : </th>
                        <td>{{item?.DoYouWishToBeCheckedForSTDs ?'Yes':'No'}}</td>

                    </tr>

                </mat-card-content>


            </mat-card>
        </div>
    </div>
</div>