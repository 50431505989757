<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
              </mat-form-field>
        </div>
    </div>
  </div>
  <div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
  
  
  
  
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef > Date </th>
        <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
      </ng-container>
      <ng-container matColumnDef="Subject">
        <th mat-header-cell *matHeaderCellDef > Subject </th>
        <td mat-cell *matCellDef="let row"> {{row.Subject}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Description">
        <th class="message"  mat-header-cell *matHeaderCellDef > Description </th>
        <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
      </ng-container>
      <ng-container matColumnDef="OrderedBy">
        <th mat-header-cell *matHeaderCellDef > Ordered By </th>
        <td  mat-cell *matCellDef="let row"> {{row.OrderedBy}} </td>
      </ng-container>
  
  
      <ng-container matColumnDef="Delete">
        <th  mat-header-cell *matHeaderCellDef> Delete</th>
        <td mat-cell *matCellDef="let element"> 
            <button  (click)="delete(element.LabOrderId)"
            mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[ 10, 25, 100]"></mat-paginator>
  </div>
  