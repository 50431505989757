<div class="row d-flex justify-content-center">
  </div>
  <div class="row my-2 mx-1 justify-content-center">
    <table class="table table-striped table-borderless">
      <thead id="invoice-header">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Drug Name</th>
          <th scope="col">Dosage</th>
          <th scope="col">Brand Name</th>
          <th scope="col">Quanity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of drugList.Drugs; let i=index ">
          <td scope="col">#{{i+1}}</td>
          <td>{{item.DrugName}}</td>
          <td>{{item.Dosage}}</td>
          <td>{{item.BrandName}}</td>
          <td>{{item.NumberOfDrugs}}</td>
        </tr>
        <!-- <tr>
            <th scope="row">2</th>
            <td>Web hosting</td>
            <td>1</td>
            <td>$10</td>
            <td>$10</td>
          </tr> -->
        <!-- <tr>
            <th scope="row">3</th>
            <td>Consulting</td>
            <td>1 year</td>
            <td>$300</td>
            <td>$300</td>
          </tr> -->
      </tbody>

    </table>
  </div>
