import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-test-results',
  templateUrl: './update-test-results.component.html',
  styleUrls: ['./update-test-results.component.css'],
})
export class UpdateTestResultsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
