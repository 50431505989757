import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Department } from 'src/app/Models/department.model';
import { Patient } from 'src/app/Models/patient.model';
import { PhysicalTherapyBooking } from 'src/app/Models/physicaltherapybooking.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';

@Component({
  selector: 'app-physical-therapy-dashboard',
  templateUrl: './physical-therapy-dashboard.component.html',
  styleUrls: ['./physical-therapy-dashboard.component.css']
})
export class PhysicalTherapyDashboardComponent implements OnInit {
  displayedColumns: string[] = ['FirstName', 'LastName', 'Gender', 'PatientNationalId', 'Email', 'PhoneNumber', 'PrimaryDoctor', 'WardName', 'RoomNo', 'Detail'];
  dataSource: MatTableDataSource<any>;
  userList: Patient[] = [];
  id = 0;
  color = new UntypedFormControl('primary');

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  public todayAppointments: PhysicalTherapyBooking[];
  public showAllPatient = false;
  isUnderConsulation: any = {};
  DoctorName: string = '';
  showItem = '';
  DoctorId: string = '';
  doctorImage:string;
  drId=0;
  constructor(private patientService: PatientService, private doctorService: PhysicalTherapyDoctorService, private appointmentService: PhysicaltherapyBookingService, private router: Router) { }

  ngOnInit(): void {
    const hasColor = localStorage.getItem("defaultThemeColor");
    if (hasColor?.length) {
      this.color.setValue(hasColor);
    }

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.DoctorId = this.id.toString()
    this.getAllPatients();
    this.doctorService.getPhysicalTherapyDoctorById(this.DoctorId).subscribe({
      next: (res) =>{
        this.DoctorName = res.DoctorName;
        this.doctorImage=res.DoctorImage
        this.drId=res.PhysicalTherapyDoctorId
      }
    })

    setInterval(() => {
    }, 300e3);
    this.getDoctorBookings(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  getAllPatients() {
    this.patientService.getPatients().subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    }, err => {
      console.log(err)
    })
  }

  getDoctorBookings(id) {
    this.doctorService.getPTDoctorBooking(id).subscribe({
      next: (res) => {

        this.todayAppointments = res.PhysicalTherapyBookings.filter((booking) => moment(booking.VisitDate).isSame(moment(), 'day')
          && (booking.Status === "Confirmed" || booking.Status === "Observing")).sort((a, b) => a['Tocken'] - b["Tocken"]);
        this.todayAppointments.forEach((appointment, id) => {
          if (appointment.Status === 'Observing') this.isUnderConsulation = appointment;
          this.patientService.getPatientById(appointment['PatientId']).subscribe({
            next: (res) => {
              appointment['PatientName'] = `${res.PatientFirstName} ${res.PatientLastName}`;
            }
          })
        })
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/'])
  }

  getValue() {
    localStorage.setItem('defaultThemeColor', this.color.value)
  }


  occupied(id, appointment) {
    this.appointmentService.updatePhysicalTherapyBooking(id, { ...appointment, Status: "Observing" }).subscribe(res => {
      this.getDoctorBookings(this.id);
      this.isUnderConsulation = appointment;
    });
  }

  complete(id, appointment) {
    this.appointmentService.updatePhysicalTherapyBooking(id, { ...appointment, Status: "Completed" }).subscribe(res => {
      this.getDoctorBookings(this.id);
      this.isUnderConsulation = {};
    });
  }
}

