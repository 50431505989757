import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrescriptionService } from 'src/app/services/prescription.service';

@Component({
  selector: 'app-prescriptin-given',
  templateUrl: './prescriptin-given.component.html',
  styleUrls: ['./prescriptin-given.component.css']
})
export class PrescriptinGivenComponent  implements OnInit {
id=0;
  constructor(private route:ActivatedRoute,private presscriptionService:PrescriptionService){

  }
  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id']
    })

  }


}
