import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-pharmacydashboard',
  templateUrl: './pharmacydashboard.component.html',
  styleUrls: ['./pharmacydashboard.component.css']
})
export class PharmacydashboardComponent implements OnInit {
  displayedColumns: string[] = ['FirstName', 'LastName', 'Gender','PatientNationalId', 'Email','PhoneNumber','PrimaryDoctor','WardName','RoomNo','Detail'];
  dataSource: MatTableDataSource<any>;
  userList:Patient[]=[];
  id=0;

  helper = new JwtHelperService();
  
  myToken = window.localStorage.getItem('token')

  showItem='';
  
  constructor(private userService:PatientService,private router:Router) { }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id  = parseInt(decodedToken.nameid)
    this.getAllPatients();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  
  getAllPatients(){
    this.userService.getPatients().subscribe(res=>{
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator=this.paginator;

    },err=>{
      console.log(err)
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['/'])
  }
}

