import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BradenscaleService } from '../../services/bradenscale.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-add-bradenscale',
  templateUrl: './add-bradenscale.component.html',
  styleUrls: ['./add-bradenscale.component.css']
})
export class AddBradenscaleComponent implements OnInit {
  id=0;
score=0;
isSuccess= false;
Moistures:string[]=['Constantly Moist 1','Very Moist 2','Occasionally Moist 3','Rarely Moist 4']
  Activities:string[]=['Bedfast 1','Chairfast 2','Walks Occasionally 3','Walks Frequently 4']
  Mobilities:string[]=['Completely Immobile 1','Very Limited 2','Slightly Limited 3','No Limitation 4']
  Nutritions:string[]=['Very Poor 1','Probably Inadequate 2','Adequate 3','Ecellent 4']
  FrictionAndShears:string[]=['Problem 1','Potential Problem 2','No Apparent Problems 3']
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  nurseId=0;

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private bradenScaleService:BradenscaleService) { }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe(res=>{
      this.id = res['id'];
    })


  }

  bradForm = this.fb.group({
    Moisture:['',Validators.required],
    MoistureScore:['',Validators.required],
    Activity:['',Validators.required],
    ActivityScore:['',Validators.required],
    Mobility:['',Validators.required],
    MobilityScore:['',Validators.required],
    Nutrition:['',Validators.required],
    NutritionScore:['',Validators.required],
    FrictionAndShear:['',Validators.required],
    FrictionAndShearScore:['',Validators.required],
    BradenScaleScore:[this.score],
  })

  get Moisture(){
    return this.bradForm.get('Moisture')
  }
  get MoistureScore(){
    return this.bradForm.get('MoistureScore')
  }
  get BradenScaleScore(){
    return this.bradForm.get('BradenScaleScore')
  }
  get Activity(){
    return this.bradForm.get('Activity')
  }
  get ActivityScore(){
    return this.bradForm.get('ActivityScore')
  }
  get Mobility(){
    return this.bradForm.get('Mobility')
  }
  get MobilityScore(){
    return this.bradForm.get('MobilityScore')
  }
  get Nutrition(){
    return this.bradForm.get('Nutrition')
  }
  get NutritionScore(){
    return this.bradForm.get('NutritionScore')
  }
  get FrictionAndShear(){
    return this.bradForm.get('FrictionAndShear')
  }
  get FrictionAndShearScore(){
    return this.bradForm.get('FrictionAndShearScore')
  }

  sum(e){
    let mstScore = this.bradForm.value['MoistureScore']
    let actScore = this.bradForm.value['ActivityScore']
    let mbtScore = this.bradForm.value['MobilityScore']
    let ntScore = this.bradForm.value['NutritionScore']
    let fsScore = this.bradForm.value['FrictionAndShearScore']

    this.score = mstScore+actScore+mbtScore+ntScore+fsScore
  }
  

  formSubmit(){

let moisture = this.bradForm.value['Moisture']
let activity = this.bradForm.value['Activity']
let mobility = this.bradForm.value['Mobility']
let nutrition = this.bradForm.value['Nutrition']
let frictionAndShear = this.bradForm.value['FrictionAndShear']


    let mstScore = this.bradForm.value['MoistureScore']
    let actScore = this.bradForm.value['ActivityScore']
    let mbtScore = this.bradForm.value['MobilityScore']
    let ntScore = this.bradForm.value['NutritionScore']
    let fsScore = this.bradForm.value['FrictionAndShearScore']
    this.score = mstScore+actScore+mbtScore+ntScore+fsScore


    let body={
    "PatientId":this.id,
    "NurseId":this.nurseId,
    "Moisture":moisture,
    "MoistureScore":mstScore,
    "Activity":activity,
    "ActivityScore":actScore,
    "Mobility":mobility,
    "MobilityScore":mbtScore,
    "Nutrition":nutrition,
    "NutritionScore":ntScore,
    "FrictionAndShear":frictionAndShear,
    "FrictionAndShearScore":fsScore,
    "BradenScaleScore":this.score,

    }

    this.bradenScaleService.registerBradenScale(body).subscribe(res=>{
      this.isSuccess=true
      this.bradForm.reset();
    })
  }

}
