import { Component } from '@angular/core';

@Component({
  selector: 'app-update-doctor-report',
  templateUrl: './update-doctor-report.component.html',
  styleUrls: ['./update-doctor-report.component.css']
})
export class UpdateDoctorReportComponent {

}
