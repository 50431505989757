<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab id="printable">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Ticket To Ride
        </ng-template>
        <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
            data-target=".bd-example-modal-lg"> Print </button>
        <div class="row">
            <div class="col-md-4" *ngFor="let item of ticketToRideList">

                <mat-card class="mat-elevation-z8 mt-3 ">
                    <h2 class="clr">Ticket To Ride</h2>

                    <p class="float-right">Date : {{item.createdDate | date: 'medium' }}</p>

                    <hr>
                    <mat-card-content>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        S = Situation
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> Primary Diagnosis : </th>

                                    <td>{{item.PrimaryDiagnosis}}</td>
                                </tr>
                                <tr>
                                    <th> Test To B Performed : </th>
                                    <td>{{item.TestToBePerformed}}</td>

                                </tr>
                                <tr>
                                    <th> Date : </th>
                                    <td>{{item.Date}}</td>

                                </tr>
                                <hr>
                                <tr>
                                    <th> Time : </th>
                                    <td>{{item.Time}}</td>

                                </tr>
                                <tr>
                                    <th> NurseConfirmedTest : </th>
                                    <td>{{item.NurseConfirmedTest}}</td>

                                </tr>
                                <tr>
                                    <th> Nurse Signature : </th>
                                    <td>{{item.NurseSignature}}</td>

                                </tr>
                                <tr>
                                    <th> Transporter Initials : </th>
                                    <td>{{item.TransporterInitials}}</td>

                                </tr>

                            </mat-expansion-panel>
                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        B = Background
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> DNR : </th>
                                    <td>{{item.DNR ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Restraints : </th>
                                    <td>{{item.Restraints ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Type : </th>
                                    <td>{{item.Type}}</td>

                                </tr>
                                <tr>
                                    <th> High Risk Falls : </th>
                                    <td>{{item.HighRiskFalls ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Isolation : </th>
                                    <td>{{item.Isolation}}</td>

                                </tr>
                                <tr>
                                    <th> Latext Allergy : </th>
                                    <td>{{item.LatextAllergy ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Impairment : </th>
                                    <td>{{item.Impairment}}</td>

                                </tr>
                                <tr>
                                    <th> Other: </th>
                                    <td>{{item.ImpairmentOther}}</td>

                                </tr>
                                <tr>
                                    <th> Patient Language : </th>
                                    <td>{{item.PatientLanguage}}</td>

                                </tr>
                                <tr>
                                    <th> Other: </th>
                                    <td>{{item.PatientLanguageOther}}</td>

                                </tr>
                                <tr>
                                    <th> Mobility : </th>
                                    <td>{{item.Mobility}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>

                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        A=Assessment
                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <tr>
                                    <th> Sedated and/ or Pain Medication : </th>
                                    <td>{{item.SedatedandorPainMedication ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Can Patient Stand For Test : </th>
                                    <td>{{item.CanPatientStandForTest ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Mental Status : </th>
                                    <td>{{item.MentalStatus}}</td>

                                </tr>
                                <tr>
                                    <th> Oxygen : </th>
                                    <td>{{item.Oxygen ?'Yes':'No'}}</td>

                                </tr>


                                <tr>
                                    <th> Rate : </th>
                                    <td>{{item.Rate}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        R=Recomendations

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> IsthereanyAnticipatedProblems : </th>
                                    <td>{{item.IsthereanyAnticipatedProblems ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Describe : </th>
                                    <td>{{item.Describe}}</td>

                                </tr>
                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Post Test / Procedure Transport

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> Test Procedure Completed : </th>
                                    <td>{{item.TestProcedureCompleted ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Completed Time: </th>
                                    <td>{{item.CompletedTime}}</td>

                                </tr>
                                <tr>
                                    <th> Change Patient Status : </th>
                                    <td>{{item.ChangePatientStatus ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Describe Change In Patient Status : </th>
                                    <td>{{item.DescribeChangeInPatientStatus}}</td>

                                </tr>
                                <tr>
                                    <th> TransporterSign : </th>
                                    <td>{{item.TransporterSign}}</td>

                                </tr>
                                <tr>
                                    <th> Is Nurse Notified Upon PatientReturning To Room : </th>
                                    <td>{{item.NurseNotifiedUponPatientReturningToRoom ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Is Bed AlarmActivated : </th>
                                    <td>{{item.BedAlarmActivated ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Is Side Rails Up : </th>
                                    <td>{{item.SideRailsUp ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Is Patient Recieved Back To Unit : </th>
                                    <td>{{item.RecievedBackToUnit ?'Yes':'No'}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        IV Lines

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <tr>
                                    <th>InfusaPort : </th>
                                    <td>{{item.InfusaPort}}</td>

                                </tr>

                                <tr>
                                    <th>Periferal : </th>
                                    <td>{{item.Periferal}}</td>

                                </tr>

                                <tr>
                                    <th>Hickman : </th>
                                    <td>{{item.Hickman}}</td>

                                </tr>

                                <tr>
                                    <th>Picc : </th>
                                    <td>{{item.Picc}}</td>

                                </tr>

                                <tr>
                                    <th>Central : </th>
                                    <td>{{item.Central}}</td>

                                </tr>

                                <tr>
                                    <th>Epidural : </th>
                                    <td>{{item.Epidural}}</td>

                                </tr>

                                <tr>
                                    <th>PCA : </th>
                                    <td>{{item.PCA}}</td>

                                </tr>

                                <tr>
                                    <th>Fistula : </th>
                                    <td>{{item.Fistula}}</td>

                                </tr>

                                <tr>


                            </mat-expansion-panel>
                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Other Lines

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <tr>
                                    <th>GastricTube : </th>
                                    <td>{{item.GastricTube ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>Dob Hoff Feeding Tube : </th>
                                    <td>{{item.DobHoffFeedingTube ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>Tink Off PD Tube : </th>
                                    <td>{{item.TinkOffPdTube ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>NG Tube : </th>
                                    <td>{{item.NgTube ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>Dialysis Access : </th>
                                    <td>{{item.DialysisAccess ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>ArterialLine : </th>
                                    <td>{{item.ArterialLine ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>Bile Bag : </th>
                                    <td>{{item.BileBag ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Rectal Tube : </th>
                                    <td>{{item.RectalTube ?'Yes':'No'}}</td>

                                </tr>


                                <tr>


                            </mat-expansion-panel>
                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        R=Recomendations

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> ET Tube : </th>
                                    <td>{{item.EtTube ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Trach: </th>
                                    <td>{{item.Trach ?'Yes':'No'}}</td>

                                </tr>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </mat-card-content>
                    <button class="float-right" [routerLink]="['/updateTicketToRide/',item.TicketToRideId]"
                        color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
                    <button class="float-right" (click)="delete(item.TicketToRideId)" color="accent"
                        mat-mini-fab><mat-icon>delete</mat-icon> </button>

                </mat-card>
            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Ticket to Ride
        </ng-template>

        <app-add-ticket-to-ride></app-add-ticket-to-ride>
    </mat-tab>


</mat-tab-group>