import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyOrder } from 'src/app/Models/radilogyorder.model';
import { PatientService } from 'src/app/services/patient.service';
import { RadiologyOrderService } from 'src/app/services/radiology-order.service';

@Component({
  selector: 'app-view-new-radiology-order',
  templateUrl: './view-new-radiology-order.component.html',
  styleUrls: ['./view-new-radiology-order.component.css'],
})
export class ViewNewRadiologyOrderComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'OrderedBy',
    'Subject',
    'Description',
    'View',
    'Delete',
  ];

  id = 0;
  radiologyOrder: RadiologyOrder[] = [];
  dataSource: MatTableDataSource<any>;
  radioDocument: string = '';
  patient: Patient;
  date: string;

  constructor(
    private patientService: PatientService,
    private radiologyOrderservice: RadiologyOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetRadiologyOrder(this.id);
    this.getPatient(this.id);
    this.date = this.datePipe.transform(new Date(), 'MM/dd/YYYY');
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetRadiologyOrder(id) {
    this.patientService.getRadiologyOrder(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.RadiologyOrders.filter((x) => x.Date == this.date)
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.radiologyOrderservice.deleteRadiologyOrder(id).subscribe((res) => {
      alert('deleted');
      this.GetRadiologyOrder(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewLabReport(report: RadiologyOrder): void {
    // console.log('report: ', report);
    this.radioDocument = report.Description;
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.GetRadiologyOrder(this.id);
    }
  }
}
