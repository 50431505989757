<div class="nav-container">
    <div class="nav">
        <div class="action-btns">
            <button #undo disabled>
                <i class="fas fa-undo"></i>
            </button>
            <button #redo disabled>
                <i class="fas fa-redo"></i>
            </button>
        </div>
        <div class="action-btns">
            <button #pen [disabled]="isPenActive">
                <i class="fas fa-pen"></i>
            </button>
            <button #eraser [disabled]="isEraserActive">
                <i class="fas fa-eraser"></i>
            </button>
        </div>
    </div>
</div>
<div class="editor-container">
    <div id="editor" #editor touch-action="none"></div>
</div>

<button mat-strocked-button color="primary" (click)="getExportedText()">getExportedText</button>