// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  Url: 'https://binsera.net/api/',
  imgurl: 'https://binsera.net/Images/',
  mriurl: 'https://binsera.net/Uploads/MRI/',

  // imgurl: '/mages/',
  // mriurl: '/Uploads/MRI/',
  Url2: 'https://binsera.net/api/',

  // Url: 'http://localhost:46740/api/',
  // imgurl: 'http://localhost:46740/Images/',
  // mriurl: 'http://localhost:46740/Uploads/MRI/',
};

/*
 * For easier debugging in development mode, you can import the following file
 *const ; to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
// Url: 'http://emrapp-dev.eba-2wrwf4te.us-east-1.elasticbeanstalk.com/api/',

// imgurl:
//   'http://emrapp-dev.eba-2wrwf4te.us-east-1.elasticbeanstalk.com/Images/',
// mriurl:
//   'http://emrapp-dev.eba-2wrwf4te.us-east-1.elasticbeanstalk.com/Uploads/MRI/',
// Url: 'https://medicalapp.binsion.org/api/',
// imgurl: 'https://medicalapp.binsion.org/Images/',
// mriurl: 'https://medicalapp.binsion.org/Uploads/MRI/',
