<div class="container mt-5">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Intake - Assessment</h2>
        <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

            <div class="row" >
                <div class="col-md-4">
           
                    <mat-form-field class="example-full-width">
                        <mat-label>Intake</mat-label> 
                        <input type="number" formControlName="Intake" matInput >
                        <mat-card-subtitle matSuffix>ml</mat-card-subtitle>
                        
                        <mat-error >Please enter Intake</mat-error>
                      </mat-form-field> 
                </div>
                <div class="col-md-4">
           
                    <mat-form-field class="example-full-width">
                        <mat-label>IV fluid</mat-label>
                        <input type="number" formControlName="IVfluid" matInput >
                        <mat-card-subtitle matSuffix>ml</mat-card-subtitle>

                        <mat-error >Please enter IV fluid</mat-error>
                      </mat-form-field>
                </div>
                <div class="col-md-4">
           
                    <mat-form-field class="example-full-width">
                        <mat-label>Oral Intake</mat-label>
                        <input type="number" formControlName="OralIntake" matInput >
                        
                        <mat-error >Please enter Oral Intake</mat-error>
                      </mat-form-field>
                </div>
            </div>
            
  <div class="row">
   
   
    <div class="col-md-4">
       
        <mat-form-field class="example-full-width">
            <mat-label>IntakeTotal</mat-label>
            <input type="number" matInput formControlName="IntakeTotal" (input)="getGrandTotal()">
            <mat-card-subtitle matSuffix>ml</mat-card-subtitle>

            <mat-error >Please enter IntakeTotal</mat-error>
          </mat-form-field>
    </div>
   
    <div class="col-md-4">
       
        <mat-form-field class="example-full-width">
            <mat-label>Output</mat-label>
            <input type="number" formControlName="Output" matInput >
            <mat-card-subtitle matSuffix>ml</mat-card-subtitle>

            <mat-error >Please enter Output</mat-error>
          </mat-form-field>
    </div>
   
    <div class="col-md-4">
       
        <mat-form-field class="example-full-width">
            <mat-label>Output Total</mat-label>
            <input type="number" formControlName="OutputTotal" matInput >
            <mat-card-subtitle matSuffix>ml</mat-card-subtitle>

            <mat-error >Please enter Output Total</mat-error>
          </mat-form-field>
    </div>
   
  </div>
   <div class="row">
    <div class="col-md-3">
           
        <mat-form-field class="example-full-width">
            <mat-label>Previous Weight</mat-label>
            <input type="number" formControlName="PreviousWeight" matInput >
            <mat-error >Please enter Previous Weight</mat-error>
          </mat-form-field>
    </div>
   
    <div class="col-md-3">
       
        <mat-form-field class="example-full-width">
            <mat-label>Current Weight</mat-label>
            <input type="number" formControlName="CurrentWeight" matInput >
            <mat-card-subtitle matSuffix>kg/lbs</mat-card-subtitle>

            <mat-error >Please enter Current Weight</mat-error>
          </mat-form-field>
    </div>
   
    <div class="col-md-3">
       
        <mat-form-field class="example-full-width">
            <mat-label>Date Of Last Bowel Movement</mat-label>
            <input type="date" formControlName="DateOfLastBowelMovement" matInput >
            <mat-error >Please enter DateOfLastBowelMovement</mat-error>
          </mat-form-field>
    </div>
    
    <div class="col-md-3">
       
        <mat-form-field class="example-full-width">
            <mat-label>Weight Gain or Loss</mat-label>
            <input type="text" formControlName="WeightGainorLoss" matInput >
            <mat-card-subtitle matSuffix>kg/lbs</mat-card-subtitle>

            <mat-error >Please enter Weight Gain or Loss</mat-error>
          </mat-form-field>
    </div>
   </div>
        
  
         <button  class="float-right lgBtCl" mat-button >
            <!-- <mat-icon>save</mat-icon> -->
            Save</button>
            <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
              Successfully Created Prescription
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
      </form>
        </div>
    </div>
</div>
