import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HesterDaviesService } from '../../services/hester-davies.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-add-hester-davies',
  templateUrl: './add-hester-davies.component.html',
  styleUrls: ['./add-hester-davies.component.css']
})
export class AddHesterDaviesComponent implements OnInit {

id=0;
score=0;
isSuccess= false;
LastKnownFalls:string[]=['No Falls 1','Within the Last Year 2','Within the Llast six months 3','Within the last month 4','During the current Hhospitalization 5']

Mobilities:string[]=['No Limitations 1','Dizziness/Generallised weakness 2','immovalised required assist of one person 3','Use of assistive device/requires assistance of 2 people 4','Hemiplegic,paraplegia or quadriplegia 5']

Medications:string[]=['No Meds 1','Cardio Vascular or central nervous system meds 2','Duretics 3','Chemotherapy in the last month 4']

  MentalStatuses:string[]=['Awake,alert and oriented to date,place and person 1','Oriented to person and place 2','Lethargic/priented to person only 2','Memmory Loss/Confusion 3','Unresponsive/Non compliance with instruction 4']

  ToiletingNeed:string[]=['No Needs 1','Use of catheters or diversion devices 2','Use of assistive device(beside commode,bedpan,urinal) 3','Incontinence 4','Diarrheafrequency/urgency 5']

  VolumeElectorlightStatuses:string[]=['No Problems 0','NPO Greater than 24 hours 1','Use of IV fluids/tube feeds 2','Nausea/vomiting 3','Low blood sugar/electrolite imbalances 4']

  Communications:string[]=['No defects 0','Visual(Glasses)/hearing defect 1','Non-English patient/unable to speak/slurred speech 2','Neuropathy 3','Blindness/recent visual change 4']

  Behaviours:string[]=['Appropriate behaviour 1','Depression/anxiety 2','Behavioral non compliance with instruction 3','Impulsiveness 4','Ethanolsubstance abuse 5']
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  nurseId=0;
  constructor(private route:ActivatedRoute,private hesterDavieseService:HesterDaviesService,private fb:UntypedFormBuilder) { }

  ngOnInit(): void {

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);


    this.route.params.subscribe(res=>{
      this.id = res['id'];
    })

  }



  hdFrm = this.fb.group({
    
    LastKnownFall:['',Validators.required],
    Mobility:['',Validators.required],
    Medication:['',Validators.required],
    MentalStatus:['',Validators.required],
    ToiletingNeeds:['',Validators.required],
    VolumeElectrolyteStatus:['',Validators.required],
    Communication:['',Validators.required],
    Behaviour:['',Validators.required],
    HesterDaviesFallRiskTotal:[''],
    FallRiskPrecaution:[''],
    LastKnownFallScore:['',Validators.required],
    MobilityScore:['',Validators.required],
    MedicationScore:['',Validators.required],
    MentalStatusScore:['',Validators.required],
    ToiletingNeedsScore:['',Validators.required],
    CommunicationScore:['',Validators.required],
    BehaviourScore:['',Validators.required],
    VolumeElectrolyteStatusScore:['',Validators.required],

  })


get LastKnownFall(){
  return this.hdFrm.get('LastKnownFall');
}
get Mobility(){
  return this.hdFrm.get('Mobility');
}

get Medication(){
  return this.hdFrm.get('Medication');
}

get MentalStatus(){
  return this.hdFrm.get('MentalStatus');
}

get ToiletingNeeds(){
  return this.hdFrm.get('ToiletingNeeds');
}

get VolumeElectrolyteStatus(){
  return this.hdFrm.get('VolumeElectrolyteStatus');
}

get Communication(){
  return this.hdFrm.get('Communication');
}
get Behaviour(){
  return this.hdFrm.get('Behaviour');
}
get HesterDaviesFallRiskTotal(){
  return this.hdFrm.get('HesterDaviesFallRiskTotal');
}
get FallRiskPrecaution(){
  return this.hdFrm.get('FallRiskPrecaution');
}
get LastKnownFallScore(){
  return this.hdFrm.get('LastKnownFallScore');
}
get MobilityScore(){
  return this.hdFrm.get('MobilityScore');
}
get MedicationScore(){
  return this.hdFrm.get('MedicationScore');
}
get MentalStatusScore(){
  return this.hdFrm.get('MentalStatusScore');
}
get ToiletingNeedsScore(){
  return this.hdFrm.get('ToiletingNeedsScore');
}
get CommunicationScore(){
  return this.hdFrm.get('CommunicationScore');
}
get BehaviourScore(){
  return this.hdFrm.get('BehaviourScore');
}
get VolumeElectrolyteStatusScore(){
  return this.hdFrm.get('VolumeElectrolyteStatusScore');
}

sum(e){
let lastKnownFallScore = this.hdFrm.value['LastKnownFallScore']
let mobilityScore= this.hdFrm.value['MobilityScore']        
let medicationScore= this.hdFrm.value['MedicationScore']
let mentalStatusScore= this.hdFrm.value['MentalStatusScore']
let toiletingNeedsScore= this.hdFrm.value['ToiletingNeedsScore']
let communicationScore= this.hdFrm.value['CommunicationScore']
let behaviourScore= this.hdFrm.value['BehaviourScore']
let volumeElectrolyteStatusScore= this.hdFrm.value['VolumeElectrolyteStatusScore']

this.score =   
lastKnownFallScore+
mobilityScore+
medicationScore+
mentalStatusScore+
toiletingNeedsScore+
communicationScore+
behaviourScore+
volumeElectrolyteStatusScore
}

formSubmit(){
let lastKnownFall = this.hdFrm.value['LastKnownFall']
let mobility= this.hdFrm.value['Mobility']        
let medication= this.hdFrm.value['Medication']
let mentalStatus= this.hdFrm.value['MentalStatus']
let toiletingNeeds= this.hdFrm.value['ToiletingNeeds']
let volumeElectrolyteStatus= this.hdFrm.value['VolumeElectrolyteStatus']
let communication= this.hdFrm.value['Communication']
let behaviour= this.hdFrm.value['Behaviour']
let fallRiskPrecaution= this.hdFrm.value['FallRiskPrecaution']

let lastKnownFallScore = this.hdFrm.value['LastKnownFallScore']
let mobilityScore= this.hdFrm.value['MobilityScore']        
let medicationScore= this.hdFrm.value['MedicationScore']
let mentalStatusScore= this.hdFrm.value['MentalStatusScore']
let toiletingNeedsScore= this.hdFrm.value['ToiletingNeedsScore']
let communicationScore= this.hdFrm.value['CommunicationScore']
let behaviourScore= this.hdFrm.value['BehaviourScore']
let volumeElectrolyteStatusScore= this.hdFrm.value['VolumeElectrolyteStatusScore']
let HesterDaviesFallRiskTotal= this.hdFrm.value['HesterDaviesFallRiskTotal']


let body={
  "PatientId":this.id,
  "NurseId":this.nurseId,
  "LastKnownFall":lastKnownFall,
  "Mobility":mobility,
  "Medication":medication,
  "MentalStatus":mentalStatus,
  "ToiletingNeeds":toiletingNeeds,
  "VolumeElectrolyteStatus":volumeElectrolyteStatus,
  "Communication":communication,
  "Behaviour":behaviour,
  "LastKnownFallScore":lastKnownFallScore,
  "MobilityScore":mobilityScore,
  "MedicationScore":medicationScore,
  "MentalStatusScore":mentalStatusScore,
  "ToiletingNeedsScore":toiletingNeedsScore,
  "CommunicationScore":communicationScore,
  "BehaviourScore":behaviourScore,
  "VolumeElectrolyteStatusScore":volumeElectrolyteStatusScore,
  "HesterDaviesFallRiskTotal":this.score,
  "FallRiskPrecaution":fallRiskPrecaution,

}

this.hesterDavieseService.registerHesterDavies(body).subscribe(res=>{
  this.isSuccess=true
  this.hdFrm.reset();
})

}


}
