<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <form [formGroup]="mriFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-md-6 col-12">
            <mat-form-field class="w-100">
              <mat-label>Study Name</mat-label>
              <input type="text" matInput formControlName="StudyName">
            </mat-form-field>
          </div>
          <div class="col-md-6 col-12">
            <mat-form-field class="w-100">
              <mat-label>Study Date</mat-label>
              <input type="text" matInput formControlName="StudyDate">
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <h2>Upload MRI Images</h2>
            <input type="file" (change)="image($event)" accept=".DCM, .dcm">
          </div>
          <button class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
          </button>
          <div class="row">
          </div>
          <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            You have Successfully submitted
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </form>
      <div class="my-3">
        <!-- <mat-progress-bar mode="determinate"></mat-progress-bar> -->
        <mat-progress-spinner class="example-margin" [value]="uploadProgress">
        </mat-progress-spinner>
        <p>Once the upload progress is complete, press the save button.</p>
      </div>
    </div>