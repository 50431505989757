import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AllergyService } from '../../services/allergy.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-allergy',
  templateUrl: './add-allergy.component.html',
  styleUrls: ['./add-allergy.component.css']
})
export class AddAllergyComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  id=0;
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private allergyService:AllergyService,private route:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{

      this.id= res['id'];

    })
  }


  addAllergyFrm = this.fb.group({
    AllergicTo : ['',Validators.required],
    Reaction:['',Validators.required],
    Description:['',Validators.required]
  })

get AllergicTo(){
  return this.addAllergyFrm.get('AllergicTo')
}
get Reaction(){
  return this.addAllergyFrm.get('Reaction')
}
get Description(){
  return this.addAllergyFrm.get('Description')
}






  formSubmit(){

   let allergicTo = this.addAllergyFrm.value['AllergicTo']
   let reaction = this.addAllergyFrm.value['Reaction']
   let description = this.addAllergyFrm.value['Description']


   let body={
    "PatientId":this.id,
    "AllergicTo":allergicTo,
    "Reaction" :reaction,
    "Description":description
   }

this.allergyService.registerAllergy(body).subscribe(res=>{
  this.isSuccess=true
  this.addAllergyFrm.reset();
  this.submitAction.emit("")
  // this.router.navigate(['/patientDetails/',this.id])
})
  }
}
