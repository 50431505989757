<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Shedule Duty Shifts (Weekly)</h2>
            <form [formGroup]="hsFrm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Select Ward</mat-label>
                            <mat-select formControlName="WardId">

                                <mat-option *ngFor="let wrd of wardList"
                                    [value]="wrd.WardId">{{wrd.WardName}}</mat-option>

                            </mat-select>


                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Week Commencing</mat-label>
                            <input type="text" formControlName="WeekCommencing" matInput>
                            <mat-error>Please enter Week Commencing</mat-error>
                        </mat-form-field>

                    </div>



                </div>
                <div class="" formArrayName="DutyRosterItems">
                    <div class="row" *ngFor="let item of DutyScheduleItems.controls; let i=index">
                        <ng-container [formGroup]="item">

                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Staff Name</mat-label>
                                    <input type="text" formControlName="FullName" matInput>
                                    <mat-error>Please enter Staff Name</mat-error>
                                </mat-form-field>
                            </div>



                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Date </mat-label>
                                    <input type="date" formControlName="DateOfDuty" matInput>
                                    <mat-error>Please enter Date Of Duty</mat-error>
                                </mat-form-field>

                            </div>

                            <div class="col-lg-2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Select Day </mat-label>
                                    <mat-select formControlName="DayOfDuty">
                                        <mat-option *ngFor="let day of autoFillItems"
                                            [value]="day.value">{{day.value}}</mat-option>
                                    </mat-select>
                                    <!-- <input type="text" formControlName="LionLatStretch" matInput> -->
                                    <mat-error>Please enter Day Of Duty</mat-error>
                                </mat-form-field>
                            </div>


                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Shift</mat-label>
                                    <input type="text" placeholder="ED/MD/LD/NIGHT/EV" formControlName="Shift" matInput>
                                    <mat-error>Please enter Shift</mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-lg-3">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Comments</mat-label>
                                    <textarea matInput formControlName="Comments" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="140"></textarea>

                                    <mat-error>Please enter Comments</mat-error>
                                </mat-form-field>

                            </div>
                            <!-- <button type="button" color="warn" (click)="stopService()" mat-raised-button>stop</button> -->

                            <div class="col-lg-1">
                                <button type="button" mat-mini-fab
                                    (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
                            </div>
                        </ng-container>
                    </div>

                    <button type="button" mat-mini-fab color="primary"
                        (click)="addItem()"><mat-icon>add</mat-icon></button>


                </div>

                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>


        </div>