import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Room } from 'src/app/Models/room.model';
import { BedService } from 'src/app/services/bed.service';
import { RoomService } from 'src/app/services/room.service';

@Component({
  selector: 'app-update-bed',
  templateUrl: './update-bed.component.html',
  styleUrls: ['./update-bed.component.css'],
})
export class UpdateBedComponent implements OnInit {
  roomList: Room[];
  isSuccess = false;
  id = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private bedService: BedService,
    private route: ActivatedRoute,
    private roomService: RoomService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.bedService.getBedById(this.id).subscribe((res) => {
      this.bedFrm.patchValue(res);
    });
    this.getRooms();
  }

  bedFrm = this.fb.group({
    RoomId: [],
    BedNumber: ['', Validators.required],
    RoomNumber: ['', Validators.required],
    BedCharge: ['', Validators.required],
    Status: ['', Validators.required],
  });

  get RoomId() {
    return this.bedFrm.get('RoomId');
  }
  get BedNumber() {
    return this.bedFrm.get('BedNumber');
  }
  get RoomNumber() {
    return this.bedFrm.get('RoomNumber');
  }
  get BedCharge() {
    return this.bedFrm.get('BedCharge');
  }
  get Status() {
    return this.bedFrm.get('Status');
  }

  getRooms() {
    this.roomService.getRooms().subscribe((res) => {
      this.roomList = res;
    });
  }

  formSubmit() {
    let bedNumber = this.bedFrm.value['BedNumber'];
    let bedCharge = this.bedFrm.value['BedCharge'];
    let status = this.bedFrm.value['Status'];
    let roomId = this.bedFrm.value['RoomId'];
    let roomNo = this.roomList.filter((it) => it.RoomId == roomId)[0].RoomNo;

    let body = {
      BedNumber: bedNumber,
      BedCharge: bedCharge,
      Status: status,
      RoomId: roomId,
      RoomNo: roomNo,
    };

    this.bedService.updateBed(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.bedFrm.reset();
      this.router.navigate(['/adminDash']);
    });
  }
}
