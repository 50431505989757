<div class=" mt-5" id="printable">
    <button class="float-right" (click)="print()" color="primary" mat-mini-fab><mat-icon>print</mat-icon></button>
    <table class="table table-striped">
        <thead>

            <th>#</th>
            <th>Test Name</th>
            <th>Test Result</th>
            <th>Referance Range</th>
            <th>Units</th>

        </thead>
        <tbody>
            <tr *ngFor="let lb of labR; let i=index">
                <td scope="col">#{{i+1}}</td>
                <td>{{lb.TestName}}</td>
                <td>{{lb.TestResultValue}}</td>
                <td>{{lb.ReferanceRange}}</td>
                <td>{{lb.Units}}</td>
            </tr>
        </tbody>
    </table>
</div>