import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { Hospital } from '../../Models/hospital.model';
import { HospitalService } from '../../services/hospital.service';

@Component({
  selector: 'app-hospital-dashboard',
  templateUrl: './hospital-dashboard.component.html',
  styleUrls: ['./hospital-dashboard.component.css']
})
export class HospitalDashboardComponent implements OnInit {
id=0;
showItem='';
hospital:Hospital

  constructor(private route:ActivatedRoute,private hospitalService:HospitalService) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{
      this.id = res['id'];
    })

    this.getHospitalById(this.id);
    // this.getDpt(this.id);
  }


  getHospitalById(id){
    this.hospitalService.getHospitalById(id).subscribe(res=>{
      this.hospital = res;
    })
  }
  

  // getDpt(id){
  //   this.hospitalService.getDepartment(id).subscribe(res=>{
  //         this.dptList = res.Departments;
  //   })
  // }
}
