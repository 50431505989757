import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralConsentForTreatment } from 'src/app/Models/generalconsentfortreatment.model';
import { HippaCompliance } from 'src/app/Models/hippacompliance.model';
import { Hospital } from 'src/app/Models/hospital.model';
import { GeneralConsentForTreatmentService } from 'src/app/services/general-consent-for-treatment.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-general-consent-for-treatment',
  templateUrl: './view-general-consent-for-treatment.component.html',
  styleUrls: ['./view-general-consent-for-treatment.component.css'],
})
export class ViewGeneralConsentForTreatmentComponent implements OnInit {
  id = 0;
  GeneralConsentForTreatmentList: GeneralConsentForTreatment[] = [];
  hospital: Hospital;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private generalConsentForTreatmentService: GeneralConsentForTreatmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });

    this.generalConsent(this.id);
  }

  generalConsent(id) {
    this.patientService.GetGeneralConsentForTreatment(id).subscribe((res) => {
      this.GeneralConsentForTreatmentList =
        res.GeneralConsentForTreatments.sort(function (x, y) {
          return (
            y.GeneralConsentForTreatmentId - x.GeneralConsentForTreatmentId
          );
        });
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.generalConsentForTreatmentService
      .deleteGeneralConsentForTreatment(id)
      .subscribe((res) => {
        this.generalConsent(this.id);
      });
  }
}
