import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PharmacylistService } from 'src/app/services/pharmacylist.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-pharmacylist',
  templateUrl: './update-pharmacylist.component.html',
  styleUrls: ['./update-pharmacylist.component.css'],
})
export class UpdatePharmacylistComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  id = 0;
  isSuccess = false;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private phService: PharmacylistService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.phService.getPharmacyById(this.id).subscribe((res) => {
      this.phFrm.patchValue(res);
    });
    this.countryCodes();
    this.filteredOptions = this.phFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  phFrm = this.fb.group({
    // DepartmentId:[''],
    PharmacyName: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    PharmacyAddress: ['', Validators.required],
    Status: [''],
  });

  get PharmacyName() {
    return this.phFrm.get('PharmacyName');
  }
  get Email() {
    return this.phFrm.get('Email');
  }
  get DepartmentId() {
    return this.phFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.phFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.phFrm.get('NationalId');
  }

  get PharmacyAddress() {
    return this.phFrm.get('PharmacyAddress');
  }

  get Status() {
    return this.phFrm.get('Status');
  }

  get CountryCode() {
    return this.phFrm.get('CountryCode');
  }

  formSubmit() {
    let pharmacyName = this.phFrm.value['PharmacyName'];

    let email = this.phFrm.value['Email'];
    let phoneNumber =
      this.phFrm.value['CountryCode'] + this.phFrm.value['PhoneNumber'];
    let nationalId = this.phFrm.value['NationalId'];
    let pharmacyAddress = this.phFrm.value['PharmacyAddress'];
    let status = this.phFrm.value['Status'];

    let body = {
      PatientId: this.id,
      PharmacyName: pharmacyName,
      Email: email,
      PhoneNumber: phoneNumber,
      NationalId: nationalId,
      PharmacyAddress: pharmacyAddress,
      Status: status,
    };

    this.phService.updatePharmacy(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.phFrm.reset();
      // this.submitAction.emit('');
      this.router.navigate(['/hospitalDash/']);
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
