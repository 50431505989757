<div class="ml-3">
  <button class="float-right mr-4 mt-4" color="primary" (click)="print()" mat-mini-fab>
    <mat-icon>print</mat-icon>
  </button>
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8" id="printable">
  <table mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="createdDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Date </th>
      <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
    </ng-container>


    <!-- <ng-container matColumnDef="Time">
      <th mat-header-cell *matHeaderCellDef > Time </th>
      <td mat-cell *matCellDef="let row"> {{row.Time}} </td>
    </ng-container> -->
    <ng-container matColumnDef="Respiration">
      <th mat-header-cell *matHeaderCellDef> Respiration </th>
      <td mat-cell *matCellDef="let row"> {{row.Respiration}} </td>
    </ng-container>
    <ng-container matColumnDef="Temparature">
      <th mat-header-cell *matHeaderCellDef> Temparature </th>
      <td mat-cell *matCellDef="let row"> {{row.Temparature}} </td>
    </ng-container>
    <ng-container matColumnDef="HeartBeat">
      <th mat-header-cell *matHeaderCellDef> Heart Beat </th>
      <td mat-cell *matCellDef="let row"> {{row.HeartBeat}} </td>
    </ng-container>
    <ng-container matColumnDef="Comments">
      <th mat-header-cell *matHeaderCellDef> Comments </th>
      <td mat-cell *matCellDef="let row"> {{row.Comments}} </td>
    </ng-container>

    <ng-container matColumnDef="NurseName">
      <th mat-header-cell *matHeaderCellDef> NurseName </th>
      <td mat-cell *matCellDef="let row"> {{row.NurseName}} </td>
    </ng-container>
    <ng-container matColumnDef="Signature">
      <th mat-header-cell *matHeaderCellDef> Signature </th>
      <td mat-cell *matCellDef="let row"> {{row.Signature}} </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View Report </th>
      <td mat-cell *matCellDef="let row">


        <button type="button" (click)="PrintReport(row)" class="btn btn-primary" data-toggle="modal"
          data-target=".bd-example-modal-lg">View Report</button>

        <div class="modal w-100 fade bd-example-modal-lg" tabindex="-1" role="dialog"
          aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Print Report</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body " id="printable" #nursereportList>
                <app-view-hospital></app-view-hospital>
                <div>
                  <div class="areaStyle mb-1">
                    <P>Patient ID: {{patient?.PatientId}} </P>

                    <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                    <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                    <P>Patient Address: {{patient?.PatientAddress}} </P>
                  </div>
                  <div class="areaStyle mb-1 ">
                    <P>Respiration: {{row.Respiration}}</P>
                    <P>Temparature: {{row.Temparature}}</P>
                    <P>HeartBeat: {{row.HeartBeat}}</P>
                    <P>BloodPressure: {{row.BloodPressure}}</P>
                    <P>Comments: {{row.Comments}}</P>
                  </div>
                  <div class="areaStyle">
                    <P>Nurse Name: {{row.NurseName}}</P>
                    <P> Signature: {{row.Signature}}</P>
                    <P> Date: {{row.createdDate | date:'short'}}</P>


                  </div>
                </div>

              </div>
              <div class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">



                <div>
                  <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
                  <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                </div>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
              </div>
            </div>
          </div>
        </div>

      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="Update">
      <th  mat-header-cell *matHeaderCellDef> Update</th>
      <td mat-cell *matCellDef="let row"> 
        <button mat-mini-fab [routerLink]="['/updateNurseReport/',row.NurseReportId]" color="primary"><mat-icon mat-list-icon>edit</mat-icon></button>
      </td>
    </ng-container>-->
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button mat-mini-fab color="accent" (click)="delete(row.NurseReportId)"><mat-icon
            mat-list-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>