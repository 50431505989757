import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css'],
})
export class SetpasswordComponent implements OnInit {
  email = '';
  token = '';
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userProfileService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.email = res['email'];
      this.token = res['token'];
    });
  }

  setpassForm = this.fb.group({
    Password: ['', Validators.required],
    ConfirmPassword: ['', Validators.required],
  });

  get Password() {
    return this.setpassForm.get('Password');
  }
  get ConfirmPassword() {
    return this.setpassForm.get('ConfirmPassword');
  }

  formSubmit() {
    let password = this.Password.value;
    let confirmPwd = this.ConfirmPassword.value;
    if (password != confirmPwd) {
      alert('password do not match');
    } else {
      let body = {
        Email: this.email,
        Password: password,
        ConfirmPassword: confirmPwd,
        Token: this.token,
      };

      this.userProfileService.ResetPassword(body).subscribe(
        (res) => {
          this.router.navigate(['/']);
        },
        (err) => console.log(err)
      );
    }
  }
}
