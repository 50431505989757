import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DutyScheduleItem } from 'src/app/Models/dutyschduleitem.model';
import { DutyschduleitemService } from 'src/app/services/dutyschduleitem.service';

@Component({
  selector: 'app-view-dutyschedule-details',
  templateUrl: './view-dutyschedule-details.component.html',
  styleUrls: ['./view-dutyschedule-details.component.css'],
})
export class ViewDutyscheduleDetailsComponent implements OnInit {
  id = 0;
  myList: DutyScheduleItem;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dutySchduleItemService: DutyschduleitemService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getItem(this.id);
  }

  getItem(id) {
    this.dutySchduleItemService.getDutyScheduleItemById(id).subscribe((res) => {
      this.myList = res;
    });
  }
}
