<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of PhotographicConsentList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Photographic Consent</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <strong>I AUTHORIZE TO THE USE AND DISCLOSURE LISTED BELOW:</strong>
                    <p>I authorize {{hospital.HospitalName}} the use and disclosure of photographic, video images,
                        and/or testimonials (i.e.
                        picture with the physician, thank you cards, google reviews, etc…) for marketing purposes by the
                        practice and or the physicians of our practice</p>
                    <p> I understand that the image(s) or testimonials I’ve authorized for disclosure may be seen by
                        members of general public.</p>
                    <p>I authorize {{hospital.HospitalName}} to take photographs and/or videos, or to
                        allow third parties to take photographs and/ or videos.</p>
                    <p> I authorize {{hospital.HospitalName}} the use and disclosure of photographic, video images,
                        and/or testimonials or the
                        following uses:</p>
                    <h2>For Public/Marketing Relations Purposes</h2>


                    <tr>
                        <th> On {{hospital.HospitalName}},physician’s website or internet sites
                            Including but not limited to social media platforms such as
                            Facebook and Instagram. </th>

                        <td>{{item?.Website ?'Yes':'No'}}</td>
                    </tr>

                    <tr>
                        <th>In {{hospital.HospitalName}} publications, brochures, office or marketing
                            Materials. </th>

                        <td>{{item?.Publications ?'Yes':'No'}}</td>
                    </tr>

                    <tr>
                        <th> In the public media, such as newspapers, magazines, on the
                            Internet, and on television </th>

                        <td>{{item?.Media ?'Yes':'No'}}</td>
                    </tr>

                    <tr>
                        <th> In presentations, publications, brochures, advertisements, or
                            Articles by agencies hired and contracted by the practice </th>

                        <td>{{item?.Presentations ?'Yes':'No'}}</td>
                    </tr>
                    <tr>
                        <th> I consent {{hospital.HospitalName}} to the use of my name. I understand that I may be
                            identified by name in printed,
                            internet, or broadcast information
                            that might accompany the photo or video image of me. </th>

                        <td>{{item?.ConsentToUseMyName ?'Yes':'No'}}</td>
                    </tr>


                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>
                <button (click)="delete(item.PhotographicConsentId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    -->

            </mat-card>
        </div>
    </div>
</div>