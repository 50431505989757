<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-12" *ngFor="let item of BloodTransfusionConsentList">

            <mat-card class="mat-elevation-z8 mt-3 ">

                <mat-card-content id="printable">
                    <h2 class="clr">Blood Transfusion Consent</h2>

                    <p class="float-right">Date : {{item.CreatedDate | date: 'short' }}</p>

                    <hr>
                    <p>You are about to recive a blood product transfusion as recommended by
                        <tr>
                            <th> </th>

                            <td class="clr">{{item?.DoctorName}}</td>
                        </tr>
                        .The Benefits of blood transfusions many times outweigh the risks.Blood products are carefully
                        matched to your
                        blood type to lessen those risks.Still with any transfusion, there is a small chance of
                        short-term
                        or long-term delayed reaction.
                    </p>

                    <h4>Risks involved when receiving a blood product transfusion</h4>
                    <ul>
                        <li>Unexepected Fever.</li>
                        <li>Transfusion reaction which may include kidney failure or anemia.</li>
                        <li>Bleeding from the needle site where the transfusion was geiven.</li>
                        <li>Pain in your back or chest.</li>
                        <li>Weakness.</li>
                        <li>Weakness.</li>
                        <li>Consusion.</li>
                        <li>Rashes/itching.</li>
                        <li>Other infections.</li>
                    </ul>
                    <h4>Symptoms of long-term delayed reaction:(These symptoms can happen 2 weeks to 2 months after the
                        transfusion)</h4>
                    <ul>
                        <li>Usual Tiredness.</li>
                        <li>Change in appetite.</li>
                        <li>Unexpalined weight loss.</li>
                        <li>Dark Urine.</li>
                        <li>Yellow eyes or skin (jaundice).</li>
                        <li>Swollen Glands.</li>
                        <li>Heavy Sweeting at night.</li>

                    </ul>
                    <h4>What do I do if I have any of these symptoms</h4>
                    <ul>
                        <li>Notify the nurse, or if any iof thes symptoms happen at home call your doctor immedietely.
                        </li>
                        <li>Always contact the blood bank if you have of the short-termor long-term delayed blood
                            transfusion reaction symptoms.</li>
                    </ul>
                    <p>I have given the opportunity to ask questions about my condition,the blood bank products I will
                        recieve and the potential risks
                        involved.This form has been fully explained to me.I have read the form or it has been read to me
                        and
                        I understand its contents.
                        I am satisfied with the expnantions and voluntarily give my consent to recieve blood products.
                    </p>

                    <tr>
                        <th> Consent By : </th>

                        <td>{{item?.ConsentBy}}</td>
                    </tr>

                    <tr>
                        <th> Witness By : </th>
                        <td>{{item?.WitnessBy}}</td>

                    </tr>


                    <tr>
                        <th> Language Line Assistance Confirmation Number : </th>
                        <td>{{item?.LanguageLineAssistanceConfirmationNumber}}</td>

                    </tr>


                </mat-card-content>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    <button  (click)="delete(item.FamilyHistoryId)"
    mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button> -->

            </mat-card>
        </div>
    </div>
</div>