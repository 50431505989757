<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully added
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<button mat-raised-button color="primary" (click)="selectedPharmacy()">Select and Click here to add Pharmacy</button>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
            <td mat-cell *matCellDef="let row"> <mat-checkbox class="example-margin"
                    (change)="selectParmacy($event, row)"></mat-checkbox> </td>
        </ng-container>


        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyId}} </td>
        </ng-container>


        <ng-container matColumnDef="PharmacyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PharmacyName </th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyName}} </td>
        </ng-container>
        <ng-container matColumnDef="PharmacyAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PharmacyAddress</th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyAddress}} </td>
        </ng-container>
        <ng-container matColumnDef="PhoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PhoneNumber </th>
            <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>