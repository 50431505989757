<!-- <mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Purposefull Rounds
        </ng-template> -->
<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-4" *ngFor="let item of roundsList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Purposefull Rounds</h2>

                <p class="float-right">Date : {{item.CreatedDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>


                    <tr>
                        <th> Pain, Position, Possession, Patty, Pump : </th>

                        <td>{{item.PatientPosition ?'Yes':'No'}}</td>
                    </tr>
                    <tr>
                        <th> Patient Sleeping : </th>
                        <td>{{item.PatientSleeping ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Patient Not in Room : </th>
                        <td>{{item.PatientNotinRoom ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Rn Notified ? : </th>
                        <td>{{item.RnNotified ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Other : </th>
                        <td>{{item.Other }}</td>

                    </tr>
                    <tr>
                        <th> Agitated : </th>
                        <td>{{item.Agitated ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Eyes Closed : </th>
                        <td>{{item.EyesClosed ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Quiet : </th>
                        <td>{{item.Quiet ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Awake : </th>
                        <td>{{item.Awake ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Patient Not in Room : </th>
                        <td>{{item.PatientNotinRoom ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> In Bed : </th>
                        <td>{{item.InBed ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Confused : </th>
                        <td>{{item.Confused ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> In Chair : </th>
                        <td>{{item.InChair ?'Yes':'No'}}</td>

                    </tr>
                    <tr>
                        <th> Other Comments : </th>
                        <td>{{item.OtherComment}}</td>

                    </tr>
                </mat-card-content>
                <button class="float-right" [routerLink]="['/editPurposefullRound/',item.PurposefullRoundId]"
                    color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>

            </mat-card>
        </div>
    </div>
</div>
<!-- </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            New Purposeful-Rounds
        </ng-template>
        <app-add-purposefull-round></app-add-purposefull-round>
    </mat-tab>
</mat-tab-group> -->