<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Disclosure And Consent Medical and Surgical procedures
        </ng-template>
        <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
            data-target=".bd-example-modal-lg"> Print </button>
        <div class="row">
            <div class="col-md-6" *ngFor="let item of diclosureandconsentList">

                <mat-card class="mat-elevation-z8 mt-3 ">
                    <h2 class="clr"> Disclosure And Consent Medical and Surgical procedures</h2>


                    <hr>
                    <mat-card-content>
                        <span class="float-right">Date : {{item.createdDate | date: 'medium' }}</span>

                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h4>Nurse Name: {{item.NurseName}} </h4> &nbsp;&nbsp;&nbsp;

                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Page-1
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> Care Provider : </th>

                                    <td>{{item.CareProvider}}</td>
                                </tr>
                                <tr>
                                    <th> Health Condition : </th>
                                    <td>{{item.HealthCondition}}</td>

                                </tr>
                                <tr>
                                    <th> Planned Procedure : </th>
                                    <td>{{item.PlannedProcedure}}</td>

                                </tr>
                                <hr>
                                <tr>
                                    <th> Use Of Blood : </th>
                                    <td>{{item.UseOfBlood}}</td>

                                </tr>
                                <tr>
                                    <th> Use Of Blood Patient Initial : </th>
                                    <td>{{item.UseOfBloodPatientInitial}}</td>

                                </tr>
                                <tr>
                                    <th> Risk Of Procedure : </th>
                                    <td>{{item.RiskOfProcedure}}</td>

                                </tr>
                                <tr>
                                    <th> Authorised Person Name: </th>
                                    <td>{{item.AuthorisedPersonName}}</td>

                                </tr>
                                <tr>
                                    <th> Authorised Person Signatire: </th>
                                    <td>{{item.AuthorisedPersonSignatire}}</td>

                                </tr>
                                <tr>
                                    <th> Authorised Person Relationto Patient: </th>
                                    <td>{{item.AuthorisedPersonRelationtoPatient}}</td>

                                </tr>
                                <tr>
                                    <th> Sign Date : </th>
                                    <td>{{item.SignDate}}</td>

                                </tr>
                                <tr>
                                    <th> Witness : </th>
                                    <td>{{item.Witness}}</td>

                                </tr>
                                <tr>
                                    <th> Witness Signature : </th>
                                    <td>{{item.WitnessSignature}}</td>

                                </tr>
                                <tr>
                                    <th> Witness Signature Date : </th>
                                    <td>{{item.WitnessSignatureDate}}</td>

                                </tr>
                                <tr>
                                    <th> Witness Signature Time : </th>
                                    <td>{{item.WitnessSignatureTime}}</td>

                                </tr>
                                <tr>
                                    <th> Addresss : </th>
                                    <td>{{item.Addresss}}</td>

                                </tr>
                                <tr>
                                    <th> City,State,Zip : </th>
                                    <td>{{item.CityStateZip}}</td>

                                </tr>
                                <tr>
                                    <th> Interpreter Name : </th>
                                    <td>{{item.InterpreterName}}</td>

                                </tr>
                                <tr>
                                    <th> Doctor Signature : </th>
                                    <td>{{item.DoctorSignature}}</td>

                                </tr>
                                <tr>
                                    <th> Doctor Signature Date : </th>
                                    <td>{{item.DoctorSignatureDate}}</td>

                                </tr>
                                <tr>
                                    <th> Doctor Signature Time : </th>
                                    <td>{{item.DoctorSignatureTime}}</td>

                                </tr>
                            </mat-expansion-panel>
                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Page-2
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> Anesthesia Consent: </th>
                                    <td>{{item.Anesthesia ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Anesthesia Inital : </th>
                                    <td>{{item.AnesthesiaInital}}</td>

                                </tr>
                                <tr>
                                    <th> Cardio Vascular System Consent: </th>
                                    <td>{{item.CardioVascularSystem ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Cardio Vascular System Initials : </th>
                                    <td>{{item.CardioVascularSystemInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Surgical Consent: </th>
                                    <td>{{item.Surgical ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Surgical Initials : </th>
                                    <td>{{item.SurgicalInitials}}</td>

                                </tr>
                                <tr>
                                    <th> NonSurgical Coronary Angioplasty Consent: </th>
                                    <td>{{item.NonSurgicalCoronaryAngioplasty ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> NonSurgical Coronary Angioplasty Initials : </th>
                                    <td>{{item.NonSurgicalCoronaryAngioplastyInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Dignostic Consent: </th>
                                    <td>{{item.Dignostic ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Dignostic Initials : </th>
                                    <td>{{item.DignosticInitials}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>

                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Page-3
                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <tr>
                                    <th> Vascular Consent: </th>
                                    <td>{{item.Vascular ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Vascular Initials : </th>
                                    <td>{{item.VascularInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Angiography Consent: </th>
                                    <td>{{item.Angiography ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Angiography Initials : </th>
                                    <td>{{item.AngiographyInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Angioplasty Consent: </th>
                                    <td>{{item.Angioplasty ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Angioplasty Initials : </th>
                                    <td>{{item.AngioplastyInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Endo Vascular Stending Consent: </th>
                                    <td>{{item.EndoVascularStending ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Endo Vascular Stending Initials : </th>
                                    <td>{{item.EndoVascularStendingInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Vascular Thrombolysis Consent: </th>
                                    <td>{{item.VascularThrombolysis ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Vascular Thrombolysis Initials : </th>
                                    <td>{{item.VascularThrombolysisInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Aniography With Occation Technique Consent: </th>
                                    <td>{{item.AniographyWithOccationTechnique ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Aniography With Occation Technique Initials : </th>
                                    <td>{{item.AniographyWithOccationTechniqueInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Mensenteric Angiography With Infusional Therapy Consent: </th>
                                    <td>{{item.MensentericAngiographyWithInfusionalTherapy ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Mensenteric Angiography With Infusional Therapy Initials : </th>
                                    <td>{{item.MensentericAngiographyWithInfusionalTherapyInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Inferior Vena Caval Filter Insertion And Removal Consent: </th>
                                    <td>{{item.InferiorVenaCavalFilterInsertionAndRemoval ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Inferior Vena Caval Filter Insertion And Removal Consent Initials : </th>
                                    <td>{{item.InferiorVenaCavalFilterInsertionAndRemovalInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Pulmonary Angiograpgy Consent: </th>
                                    <td>{{item.PulmonaryAngiograpgy ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Pulmonary Angiograpgy Initials : </th>
                                    <td>{{item.PulmonaryAngiograpgyInitials}}</td>

                                </tr>

                                <tr>
                                    <th> Percutneous Treatment Of Pseudcaneurysm Consent: </th>
                                    <td>{{item.PercutneousTreatmentOfPseudcaneurysm ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Percutneous Treatment Of PseudcaneurysmInitials : </th>
                                    <td>{{item.PercutneousTreatmentOfPseudcaneurysmInitials}}</td>

                                </tr>

                                <tr>
                                    <th> VascularAccess Consent: </th>
                                    <td>{{item.VascularAccess ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Vascular Access Initials : </th>
                                    <td>{{item.VascularAccessInitials}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Page-4

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> Varicose Vein Treatment Consent: </th>
                                    <td>{{item.VaricoseVeinTreatment ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Varicose Vein Treatment Initials : </th>
                                    <td>{{item.VaricoseVeinTreatmentInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Digestive System Treatment And Procedures Consent: </th>
                                    <td>{{item.DigestiveSystemTreatmentAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> DigestiveSystemTreatmentAndProceduresInitials : </th>
                                    <td>{{item.DigestiveSystemTreatmentAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Open Consent: </th>
                                    <td>{{item.Open ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Open Initials : </th>
                                    <td>{{item.OpenInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Ear Treatments And Procedures Consent: </th>
                                    <td>{{item.EarTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Ear Treatments And Procedures Initials : </th>
                                    <td>{{item.EarTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Endocrine System Treatments And Procedures Consent: </th>
                                    <td>{{item.EndocrineSystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Endocrine System Treatments And Procedures Initials : </th>
                                    <td>{{item.EndocrineSystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Eye Treatments And Procedures Consent: </th>
                                    <td>{{item.EyeTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Eye Treatments And Procedures Initials : </th>
                                    <td>{{item.EyeTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Female Genital System Treatments And Procedures Consent: </th>
                                    <td>{{item.FemaleGenitalSystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Female Genital System Treatments And Procedures Initials : </th>
                                    <td>{{item.FemaleGenitalSystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Male Genital System Treatments And Procedures Consent: </th>
                                    <td>{{item.MaleGenitalSystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Male Genital System Treatments And Procedures Initials : </th>
                                    <td>{{item.MaleGenitalSystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Hemmatic And Lymphatic System Consent: </th>
                                    <td>{{item.HemmaticAndLymphaticSystem ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Hemmatic And Lymphatic System Initials : </th>
                                    <td>{{item.HemmaticAndLymphaticSystemInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Breat Surgery Consent: </th>
                                    <td>{{item.BreatSurgery ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Breat Surgery Initials : </th>
                                    <td>{{item.BreatSurgeryInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Maternity And Related Cases Consent : </th>
                                    <td>{{item.MaternityAndRelatedCases ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Maternity And Related Cases Initials : </th>
                                    <td>{{item.MaternityAndRelatedCasesInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Muskuloskeletal System Treatments And Procedures Consent: </th>
                                    <td>{{item.MuskuloskeletalSystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th> Muskuloskeletal System Treatments And Procedures Initials : </th>
                                    <td>{{item.MuskuloskeletalSystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Page-5

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> Nervous System Treatments And Procedures Consent: </th>
                                    <td>{{item.NervousSystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Nervous System Treatments And Procedures Initials: </th>
                                    <td>{{item.NervousSystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Radiology Consent: </th>
                                    <td>{{item.Radiology ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Radiology Initials: </th>
                                    <td>{{item.RadiologyInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Respiratory System Treatments And Procedures Consent: </th>
                                    <td>{{item.RespiratorySystemTreatmentsAndProcedures ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Respiratory System Treatments And Procedures Initials: </th>
                                    <td>{{item.RespiratorySystemTreatmentsAndProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Urenary System Consent: </th>
                                    <td>{{item.UrenarySystem ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Urenary System Initials: </th>
                                    <td>{{item.UrenarySystemInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Dialysis Consent: </th>
                                    <td>{{item.Dialysis ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Dialysis Initials: </th>
                                    <td>{{item.DialysisInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Psychatric Procedures Consent: </th>
                                    <td>{{item.PsychatricProcedures ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Psychatric Procedures Initials: </th>
                                    <td>{{item.PsychatricProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Radiation Therapy Consent: </th>
                                    <td>{{item.RadiationTherapy ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Radiation Therapy Initials: </th>
                                    <td>{{item.RadiationTherapyInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Laproscopic Or Thoracoscopic Surgery Consent: </th>
                                    <td>{{item.LaproscopicOrThoracoscopicSurgery ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Laproscopic Or Thoracoscopic Surgery Initials: </th>
                                    <td>{{item.LaproscopicOrThoracoscopicSurgeryInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Pain Management Procedures Consent: </th>
                                    <td>{{item.PainManagementProcedures ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Pain Management Procedures Initials: </th>
                                    <td>{{item.PainManagementProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Dental Surgery Procedures Consent: </th>
                                    <td>{{item.DentalSurgeryProcedures ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Dental Surgery Procedures Initials: </th>
                                    <td>{{item.DentalSurgeryProceduresInitials}}</td>

                                </tr>
                                <tr>
                                    <th> Plastic Surgery Consent: </th>
                                    <td>{{item.PlasticSurgery ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Plastic Surgery Initials: </th>
                                    <td>{{item.PlasticSurgeryInitials}}</td>

                                </tr>

                            </mat-expansion-panel>


                        </mat-accordion>

                    </mat-card-content>
                    <button class="float-right"
                        [routerLink]="['/updateDisclosureAndConsent/',item.DisclosureAndConsentId]" color="primary"
                        mat-mini-fab><mat-icon>edit</mat-icon> </button>
                    <button class="float-right" (click)="delete(item.DisclosureAndConsentId)" color="accent"
                        mat-mini-fab><mat-icon>delete</mat-icon> </button>

                </mat-card>
            </div>
        </div>
    </mat-tab>


    <!-- <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Disclosure
        </ng-template>

        <app-add-ticket-to-ride></app-add-ticket-to-ride>
    </mat-tab> -->


</mat-tab-group>