<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Blood Transfusion Consent Form</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <hr>
                <p>You are about to recive a blood product transfusion as recommended by
                    <mat-form-field class="example-full-width">
                        <mat-label>Doctor Name:</mat-label>
                        <input type="text" formControlName="DoctorName" matInput>
                        <!-- <mat-error >Please enter Description</mat-error> -->
                    </mat-form-field>
                    .The Benefits of blood transfusions many times outweigh the risks.Blood products are carefully
                    matched to your
                    blood type to lessen those risks.Still with any transfusion, there is a small chance of short-term
                    or long-term delayed reaction.
                </p>

                <h4>Risks involved when receiving a blood product transfusion</h4>
                <ul>
                    <li>Unexepected Fever.</li>
                    <li>Transfusion reaction which may include kidney failure or anemia.</li>
                    <li>Bleeding from the needle site where the transfusion was geiven.</li>
                    <li>Pain in your back or chest.</li>
                    <li>Weakness.</li>
                    <li>Weakness.</li>
                    <li>Consusion.</li>
                    <li>Rashes/itching.</li>
                    <li>Other infections.</li>
                </ul>
                <h4>Symptoms of long-term delayed reaction:(These symptoms can happen 2 weeks to 2 months after the
                    transfusion)</h4>
                <ul>
                    <li>Usual Tiredness.</li>
                    <li>Change in appetite.</li>
                    <li>Unexpalined weight loss.</li>
                    <li>Dark Urine.</li>
                    <li>Yellow eyes or skin (jaundice).</li>
                    <li>Swollen Glands.</li>
                    <li>Heavy Sweeting at night.</li>

                </ul>
                <h4>What do I do if I have any of these symptoms</h4>
                <ul>
                    <li>Notify the nurse, or if any iof thes symptoms happen at home call your doctor immedietely.</li>
                    <li>Always contact the blood bank if you have of the short-termor long-term delayed blood
                        transfusion reaction symptoms.</li>
                </ul>
                <p>I have given the opportunity to ask questions about my condition,the blood bank products I will
                    recieve and the potential risks
                    involved.This form has been fully explained to me.I have read the form or it has been read to me and
                    I understand its contents.
                    I am satisfied with the expnantions and voluntarily give my consent to recieve blood products.
                </p>
                <div class="row">

                    <div class="col-md-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>ConsentBy:</mat-label>
                            <input type="text" formControlName="ConsentBy"
                                placeholder="Signature of Patient oir Legally responsible party" matInput>
                            <!-- <mat-error >Please enter Description</mat-error> -->
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>WitnessBy:</mat-label>
                            <input type="text" formControlName="WitnessBy" placeholder="Signature of witness" matInput>
                            <!-- <mat-error >Please enter Description</mat-error> -->
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Language LineAssistance Confirmation Number:</mat-label>
                            <input type="text" formControlName="LanguageLineAssistanceConfirmationNumber" matInput>
                            <!-- <mat-error >Please enter Description</mat-error> -->
                        </mat-form-field>


                    </div>





                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>