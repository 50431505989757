<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Doctors</h2>
      <form [formGroup]="doctorFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Select Department</mat-label>
              <mat-select formControlName="DepartmentId">

                <mat-option *ngFor="let dpt of dptList" [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

              </mat-select>

              <mat-error *ngIf="DepartmentId.hasError('required')">You must make a selection</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-4">
            Upload Picture <input type="file" (change)="image($event)">


          </div>

        </div>
        <div class="row">

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Name of Doctor</mat-label>
              <input type="text" formControlName="DoctorName" matInput>
              <mat-error>Please enter Doctors Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctors Qualification</mat-label>
              <input type="text" formControlName="DoctorQualification" matInput>
              <mat-error>Please enter Doctors Qualification</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>DoctorSpecialization</mat-label>
              <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
              <mat-select formControlName="DoctorSpecialization">

                <mat-option *ngFor="let sp of doctorSpeciality" [value]="sp">{{sp}}</mat-option>

              </mat-select>
            </mat-form-field>

          </div>

        </div>
        <div class="row">
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Licence Number</mat-label>
              <input type="text" formControlName="LicenceNumber" matInput>
              <mat-error>Please enter Licence Number</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput>
              <mat-error>Please enter NPI Number</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Doctors D.O.B</mat-label>
              <input type="date" formControlName="DoctorDOB" matInput>
              <mat-error>Please enter Doctors D.O.B</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label> Doctor Email</mat-label>
              <input type="email" formControlName="Email" matInput>
              <mat-error>Please enter Doctor Email</mat-error>
            </mat-form-field>

          </div>
          <div class="col-1">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Doctors Phone</mat-label>
              <input type="number" formControlName="PhoneNumber" matInput>
              <mat-error>Please enter Doctor Phone</mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-2">

            <mat-form-field class="example-full-width">
              <mat-label> Start Date</mat-label>
              <input type="date" formControlName="StartDate" matInput>
              <mat-error>Please enter StartDate</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-2">

            <mat-form-field class="example-full-width">
              <mat-label> Password</mat-label>
              <input type="text" formControlName="Password" matInput>
              <mat-error>Please enter Password</mat-error>
            </mat-form-field>
            <span *ngIf="doctorFrm.get('Password')?.touched && doctorFrm.get('Password')?.invalid">
              <mat-error *ngIf="doctorFrm.get('Password')?.errors.required">Please enter Password</mat-error>
              <mat-error *ngIf="doctorFrm.get('Password')?.errors.pattern"> Password must contain more than 7
                characters, 1 numeric, 1 upper case letter, and 1 special character($@$!%*?&).</mat-error>
            </span>
          </div>

          <div class="col-lg-8">

            <mat-form-field class="example-full-width">
              <mat-label> Doctors Address</mat-label>
              <input type="text" formControlName="DoctorAddress" matInput>
              <mat-error>Please enter Doctors Address</mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4">

            <p>Status</p>
            <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
              <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
              <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <div class="row mt-5">
          <h2>Capture Image from Camera</h2>
          <div class="col-md-12">
            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180" [width]="180">
            </webcam>
          </div>
          <div class="col-md-12">
            <button class="btn btn-danger" (click)="getSnapshot()" type="button">
              Capture Image
            </button>
          </div>
          <div class="col-12">
            <div id="results">Your taken image manifests here...</div>
            <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
          </div>
        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Doctor
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>