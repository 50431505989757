import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-prescription-display',
  templateUrl: './prescription-display.component.html',
  styleUrls: ['./prescription-display.component.css'],
})
export class PrescriptionDisplayComponent implements OnInit {
  currentPrintedPrescriptionDate = '';
  panelOpenState = false;

  displayedColumns: string[] = [
    'DatePrescribed',
    // 'DrugName',
    // 'BrandName',
    // 'Strength',
    // 'Quantity',
    // 'MorningTime',
    // 'AfternoonTime',
    // 'EveningTime',
    // 'NightTime',
    // 'QTime',
    // 'Status',
    // 'Instruction',
    //'View',
    // 'Update',
  ];
  id = 0;
  prescriptionList: Prescription[] = [];
  groupedPrescriptions: string[] = [];

  dataSource: MatTableDataSource<any>;
  email = new FormControl('');
  fax = new FormControl('');
  patient: Patient;
  isSuccess: boolean;
  Insurance: PatientInsurance[];

  pharmacy: any;
  display: string;
  FaxImage: FormData;
  errorMessage: string;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    datePipe: DatePipe,
    private prescriptionService: PrescriptionService,
    private http: HttpClient,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPrescription(this.id);

    this.getPatient(this.id);
    this.getInsurance(this.id);
    this.getPharmacy(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('prescriptionList') prescription!: ElementRef<any>;

  ngAfterViewInit() {}

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPrescription(this.id);
    }
  }

  getPrescription(id) {
    this.patientService.getPatientPrescription(id).subscribe((res) => {
      //grouped
      this.prescriptionList = res.Prescriptions.sort(function (x, y) {
        return y.PrescriptionId - x.PrescriptionId;
      });
      for (var i = 0; i < res.Prescriptions.length; i++) {
        var item = res.Prescriptions[i];
        var d = new Date(item.CreatedDate);
        d.setHours(0, 0, 0, 0);
        this.groupedPrescriptions.push(d.toDateString());
      }

      this.groupedPrescriptions = this.groupedPrescriptions.filter(function (
        elem,
        index,
        self
      ) {
        return index === self.indexOf(elem);
      });

      this.dataSource = new MatTableDataSource(this.groupedPrescriptions);
      this.dataSource.paginator = this.paginator;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  getInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurance = res.PatientInsurances;
    });
  }

  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
      this.email.setValue(res.PatientsPharmacys[0].Email);
      this.fax.setValue(res.PatientsPharmacys[0].FaxNumber);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  PrintPrescription(date: string) {
    this.currentPrintedPrescriptionDate = date;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      // console.log('pres', this.prescription.nativeElement.innerText);
    }, 0);
  }

  print() {
    window.print();
  }

  sendPresciption() {
    let body = {
      Email: this.email.value,
      Subject:
        this.patient.PatientFirstName +
        ' ' +
        this.patient.PatientLastName +
        "'s" +
        'Prescription',
      Template: `${this.prescription.nativeElement.innerHTML}`,
    };
    this.patientService.sendPrescription(body).subscribe((res) => {
      this.isSuccess = true;
    });
  }
  // faxPresciption() {
  //   let body = {
  //     FaxNumber: this.fax.value,
  //     Subject:
  //       this.patient.PatientFirstName +
  //       ' ' +
  //       this.patient.PatientLastName +
  //       "'s" +
  //       'Prescription',
  //     Template: `${this.prescription.nativeElement.innerHTML}`,
  //   };
  //   this.patientService.sendPrescriptionFax(body).subscribe((res) => {
  //     this.isSuccess = true;
  //   });
  // }

  getPrescriptionOfOneDay(date: string) {
    let x = this.prescriptionList.filter((e) => {
      var d = new Date(e.CreatedDate);
      d.setHours(0, 0, 0, 0);
      return date == d.toDateString();
    });
    for (let index = 0; index < x.length; index++) {
      const element = x[index];
      if (index > 0) {
        (element.DoctorName = null), (element.NurseName = null);
      }
    }
    return x;
  }
  // getPrescriptionOfOneDay(date: string) {
  //   let x = this.prescriptionList.filter((e) => {
  //     var d = new Date(e.CreatedDate);
  //     d.setHours(0, 0, 0, 0);

  //     return date == d.toLocaleDateString();
  //   });
  //   let list = this.prescriptionList;
  //   for (let index = 0; index < list.length; index++) {
  //     const element = list[index];
  //     if (index > 0) {
  //       (element.DoctorName = null), (element.NurseName = null);
  //     }
  //   }
  //   return list;
  // }
  delete(id) {
    this.prescriptionService.deletePrescription(id).subscribe((res) => {
      this.getPrescription(this.id);
    });
  }

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }
  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }
  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }
  formSubmit() {}
  faxPresciption() {
    // const { FaxNumber } = this.Frm.value;
    const faxNumber = this.fax.value!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    container.innerHTML = `${this.prescription.nativeElement.innerHTML}`;
    document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }

  faxes() {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: `${this.prescription.nativeElement.innerHTML}`,
          from: '+12523900048',
          to: this.fax.value,
        },
        { headers }
      )
      .subscribe((res) => {
        console.log(res);
      });
  }
}
// for (let index = 0; index < list.length; index++) {
//   const element = list[index];
//   if (index > 0) {
//     element.DoctorName = null;
//   }
// }
// if (list?.length > 0) {
//   list.forEach((percep, index) => {
//     if (index > 0) {
//     percep.NurseName = null;
//       percep.DoctorName = null;
//     }
//   });
// }
