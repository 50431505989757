import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CptCode } from 'src/app/Models/cptcode.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { PtInitialEvaluationService } from 'src/app/services/pt-initial-evaluation.service';

@Component({
  selector: 'app-add-pt-initial-evaluation',
  templateUrl: './add-pt-initial-evaluation.component.html',
  styleUrls: ['./add-pt-initial-evaluation.component.css'],
})
export class AddPtInitialEvaluationComponent implements OnInit {
  id = 0;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  cptCodeList: CptCode[];
  filteredOptions: Observable<CptCode[]>;

  Frm: FormGroup;
  isSuccess = false;
  autoFillItems = [
    { value: '1x' },
    { value: '2x' },
    { value: '3x' },
    { value: '4x' },
    { value: '5x' },
    { value: '6x' },
    { value: '7x' },
    { value: '8x' },
    { value: '9x' },
    { value: '10x' },
    { value: '11x' },
    { value: '12x' },
    { value: '13x' },
    { value: '14x' },
    { value: '15x' },
    { value: '16x' },
    { value: '17x' },
    { value: '18x' },
    { value: '19x' },
    { value: '20x' },
  ];
  allRom = [
    { value: 'WNL' },
    { value: '5% Limited' },
    { value: '10% Limited' },
    { value: '15% Limited' },
    { value: '20% Limited' },
    { value: '25% Limited' },
    { value: '30% Limited' },
    { value: '35% Limited' },
    { value: '40% Limited' },
    { value: '45% Limited' },
    { value: '50% Limited' },
    { value: '55% Limited' },
    { value: '60% Limited' },
    { value: '65% Limited' },
    { value: '70% Limited' },
    { value: '75% Limited' },
    { value: '80% Limited' },
    { value: '85% Limited' },
    { value: '90% Limited' },
    { value: '95% Limited' },
    { value: '100% Limited' },
  ];
  painScale = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '10' },
  ];
  autoRange = [
    { value: 'Increased' },
    { value: 'Normal' },
    { value: 'Stable' },
    { value: 'Increased' },
  ];
  autoPain = [
    { value: 'Severe' },
    { value: 'Moderate' },
    { value: 'Mild' },
    { value: 'Extremely Severe' },
  ];
  STPtestVal = [{ value: '+' }, { value: '-' }];
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private ptInitalEvaluationService: PtInitialEvaluationService,
    private physicaltherpyDoctorService: PhysicalTherapyDoctorService,
    private cptCodeService: CptcodeService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getCptCode();
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      DoctorId: this.DoctorId,
      DoctorName: [''],
      InitialEvaluationDiagnosisCodes: this.fb.array([]),
      PatientId: this.id,
      InjuryDate: ['', Validators.required],
      MechanismOfInjury: ['', Validators.required],
      PainIncreasesWith: ['', Validators.required],
      PainDecreasesWith: ['', Validators.required],
      MedicalHistory: ['', Validators.required],
      LivingEnvironment: ['', Validators.required],
      Occupation: ['', Validators.required],
      DiagnosisTesting: ['', Validators.required],
      PatientGoal: ['', Validators.required],
      PainWorstLevel: ['', Validators.required],
      PainCurrentLevel: ['', Validators.required],
      PainBestLevel: ['', Validators.required],
      TreatmentArea: ['', Validators.required],
      Palpitation: ['', Validators.required],
      Posture: ['', Validators.required],
      FlexionCervical: ['', Validators.required],
      ExtensionCervical: ['', Validators.required],
      LeftSideFlexionCervical: ['', Validators.required],
      RightSideFlexionCervical: ['', Validators.required],
      LeftRotationCervical: ['', Validators.required],
      RightRotationCervical: ['', Validators.required],
      FlexionLumbar: ['', Validators.required],
      ExtensionLumbar: ['', Validators.required],
      LeftSideFlexionLumbar: ['', Validators.required],
      RightSideFlexionLumbar: ['', Validators.required],
      LeftRotationLumbar: ['', Validators.required],
      RightRotationLumbar: ['', Validators.required],
      LowerAbdominals: ['', Validators.required],
      HipFlexors: ['', Validators.required],
      HipExtensors: ['', Validators.required],
      SpineSpecialTests: ['', Validators.required],
      ThomasText: ['', Validators.required],
      SlumpTest: ['', Validators.required],
      PhoneInstabilityTest: ['', Validators.required],
      Comments: [''],
      //HipRom
      AbductionArromLeft: ['', Validators.required],
      AbductionArromRight: ['', Validators.required],
      AbductionFromLeft: ['', Validators.required],
      AbductionFromRight: ['', Validators.required],

      FlexionWithKneeFlexedArromLeft: ['', Validators.required],
      FlexionWithKneeFlexedArromRight: ['', Validators.required],
      FlexionWithKneeFlexedFromLeft: ['', Validators.required],
      FlexionWithKneeFromRight: ['', Validators.required],

      FlexionWithKneeExtendedArromLeft: ['', Validators.required],
      FlexionWithKneeExtendedArromRight: ['', Validators.required],
      FlexionWithKneeExtendedFromLeft: ['', Validators.required],
      FlexionWithKneeExtendedFromRight: ['', Validators.required],

      HipExtensionArromLeft: ['', Validators.required],
      HipExtensionArromRight: ['', Validators.required],
      HipExtensionFromLeft: ['', Validators.required],
      HipExtensionFromRight: ['', Validators.required],

      HipExternalRotationArromLeft: ['', Validators.required],
      HipExternalRotationArromRight: ['', Validators.required],
      HipExternalRotationFromLeft: ['', Validators.required],
      HipExternalRotationFromRight: ['', Validators.required],

      //KneeRom
      KneeFlexionArromLeft: ['', Validators.required],
      KneeFlexionArromRight: ['', Validators.required],
      KneeFlexionFromLeft: ['', Validators.required],
      KneeFlexionFromRight: ['', Validators.required],

      KneeExtensionArromLeft: ['', Validators.required],
      KneeExtensionArromRight: ['', Validators.required],
      KneeExtensionFromLeft: ['', Validators.required],
      KneeExtensionFromRight: ['', Validators.required],

      //Assessment
      //problem List
      Pain: ['', Validators.required],
      Weekness: ['', Validators.required],
      Mobility: ['', Validators.required],
      Ambulatiion: ['', Validators.required],
      RangeOfMotion: ['', Validators.required],
      Problem1: ['', Validators.required],
      Problem2: ['', Validators.required],
      Prognosis: ['', Validators.required],

      //Goals

      GoalTime: ['', Validators.required],
      AbletoSitFor: ['', Validators.required],
      ReturnToWork: ['', Validators.required],
      BeIndependentandSelfGrooming: ['', Validators.required],
      FunctionalGoal1: ['', Validators.required],
      FunctionalGoal2: ['', Validators.required],
      FunctionalGoal3: ['', Validators.required],

      //Plan
      //TreatmentPlan

      FrequencyandDuration: ['', Validators.required],
      TreatmentPlan: ['', Validators.required],

      //TreatmentFlowSheet
      //Manual Therapy

      JointMobilization: ['', Validators.required],
      SoftTissueMobilization: ['', Validators.required],
      Stretching: ['', Validators.required],

      //Lwer Extermity Exercise Flowsheet
      //Other
      PirformisStr: ['', Validators.required],
      LungeStr: ['', Validators.required],

      //Spine Execise Flowsheet
      //Thoracic Extension
      FoamRoller: ['', Validators.required],
      LionLatStretch: ['', Validators.required],
      CatandCamelStretch: ['', Validators.required],
      LowerTrunkRotation: ['', Validators.required],

      TimeIn: ['', Validators.required],
      TimeOut: ['', Validators.required],
      Signature: ['', Validators.required],
    });
  }
  public addItem(): void {
    const item = this.fb.group({
      Code: [''],
      // Description: [''],
    });
    this.InitialEvaluationDiagnosisCodes.push(item);
  }

  public removeItem(i: number): void {
    this.InitialEvaluationDiagnosisCodes.removeAt(i);
  }
  get InitialEvaluationDiagnosisCodes(): UntypedFormArray {
    return this.Frm.get('InitialEvaluationDiagnosisCodes') as UntypedFormArray;
  }
  get DiagnosisCodes() {
    return this.Frm.get('DiagnosisCodes');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }

  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get LungeStr() {
    return this.Frm.get('LungeStr');
  }

  get PirformisStr() {
    return this.Frm.get('PirformisStr');
  }

  get Stretching() {
    return this.Frm.get('Stretching');
  }

  get SoftTissueMobilization() {
    return this.Frm.get('SoftTissueMobilization');
  }

  get JointMobilization() {
    return this.Frm.get('JointMobilization');
  }

  get TreatmentPlan() {
    return this.Frm.get('TreatmentPlan');
  }

  get FrequencyandDuration() {
    return this.Frm.get('FrequencyandDuration');
  }

  get FunctionalGoal3() {
    return this.Frm.get('FunctionalGoal3');
  }

  get FunctionalGoal2() {
    return this.Frm.get('FunctionalGoal2');
  }

  get FunctionalGoal1() {
    return this.Frm.get('FunctionalGoal1');
  }

  get BeIndependentandSelfGrooming() {
    return this.Frm.get('BeIndependentandSelfGrooming');
  }

  get ReturnToWork() {
    return this.Frm.get('ReturnToWork');
  }

  get AbletoSitFor() {
    return this.Frm.get('AbletoSitFor');
  }

  get GoalTime() {
    return this.Frm.get('GoalTime');
  }

  get Prognosis() {
    return this.Frm.get('Prognosis');
  }

  get Problem2() {
    return this.Frm.get('Problem2');
  }

  get Problem1() {
    return this.Frm.get('Problem1');
  }

  get RangeOfMotion() {
    return this.Frm.get('RangeOfMotion');
  }

  get Ambulatiion() {
    return this.Frm.get('Ambulatiion');
  }

  get Mobility() {
    return this.Frm.get('Mobility');
  }

  get Weekness() {
    return this.Frm.get('Weekness');
  }

  get Pain() {
    return this.Frm.get('Pain');
  }

  get KneeExtensionFromRight() {
    return this.Frm.get('KneeExtensionFromRight');
  }

  get KneeExtensionFromLeft() {
    return this.Frm.get('KneeExtensionFromLeft');
  }

  get KneeExtensionArromRight() {
    return this.Frm.get('KneeExtensionArromRight');
  }

  get KneeExtensionArromLeft() {
    return this.Frm.get('KneeExtensionArromLeft');
  }

  get KneeFlexionFromRight() {
    return this.Frm.get('KneeFlexionFromRight');
  }

  get KneeFlexionFromLeft() {
    return this.Frm.get('KneeFlexionFromLeft');
  }

  get KneeFlexionArromRight() {
    return this.Frm.get('KneeFlexionArromRight');
  }

  get KneeFlexionArromLeft() {
    return this.Frm.get('KneeFlexionArromLeft');
  }

  get HipExternalRotationFromRight() {
    return this.Frm.get('HipExternalRotationFromRight');
  }

  get HipExternalRotationFromLeft() {
    return this.Frm.get('HipExternalRotationFromLeft');
  }

  get HipExternalRotationArromRight() {
    return this.Frm.get('HipExternalRotationArromRight');
  }

  get HipExternalRotationArromLeft() {
    return this.Frm.get('HipExternalRotationArromLeft');
  }

  get HipExtensionFromRight() {
    return this.Frm.get('HipExtensionFromRight');
  }

  get HipExtensionFromLeft() {
    return this.Frm.get('HipExtensionFromLeft');
  }

  get HipExtensionArromRight() {
    return this.Frm.get('HipExtensionArromRight');
  }

  get HipExtensionArromLeft() {
    return this.Frm.get('HipExtensionArromLeft');
  }

  get FlexionWithKneeFromRight() {
    return this.Frm.get('FlexionWithKneeFromRight');
  }

  get FlexionWithKneeExtendedArromLeft() {
    return this.Frm.get('FlexionWithKneeExtendedArromLeft');
  }

  get FlexionWithKneeExtendedArromRight() {
    return this.Frm.get('FlexionWithKneeExtendedArromRight');
  }

  get FlexionWithKneeExtendedFromLeft() {
    return this.Frm.get('FlexionWithKneeExtendedFromLeft');
  }

  get FlexionWithKneeExtendedFromRight() {
    return this.Frm.get('FlexionWithKneeExtendedFromRight');
  }

  get FlexionWithKneeFlexedFromLeft() {
    return this.Frm.get('FlexionWithKneeFlexedFromLeft');
  }

  get FlexionWithKneeFlexedArromRight() {
    return this.Frm.get('FlexionWithKneeFlexedArromRight');
  }

  get FlexionWithKneeFlexedArromLeft() {
    return this.Frm.get('FlexionWithKneeFlexedArromLeft');
  }

  get AbductionFromRight() {
    return this.Frm.get('AbductionFromRight');
  }

  get AbductionFromLeft() {
    return this.Frm.get('AbductionFromLeft');
  }

  get AbductionArromRight() {
    return this.Frm.get('AbductionArromRight');
  }

  get AbductionArromLeft() {
    return this.Frm.get('AbductionArromLeft');
  }

  get Comments() {
    return this.Frm.get('Comments');
  }

  get PhoneInstabilityTest() {
    return this.Frm.get('PhoneInstabilityTest');
  }

  get SlumpTest() {
    return this.Frm.get('SlumpTest');
  }

  get ThomasText() {
    return this.Frm.get('ThomasText');
  }

  get SpineSpecialTests() {
    return this.Frm.get('SpineSpecialTests');
  }

  get HipExtensors() {
    return this.Frm.get('HipExtensors');
  }

  get HipFlexors() {
    return this.Frm.get('HipFlexors');
  }

  get LowerAbdominals() {
    return this.Frm.get('LowerAbdominals');
  }

  get RightRotationLumbar() {
    return this.Frm.get('RightRotationLumbar');
  }

  get LeftRotationLumbar() {
    return this.Frm.get('LeftRotationLumbar');
  }

  get RightSideFlexionLumbar() {
    return this.Frm.get('RightSideFlexionLumbar');
  }

  get LeftSideFlexionLumbar() {
    return this.Frm.get('LeftSideFlexionLumbar');
  }

  get ExtensionLumbar() {
    return this.Frm.get('ExtensionLumbar');
  }

  get FlexionLumbar() {
    return this.Frm.get('FlexionLumbar');
  }

  get RightRotationCervical() {
    return this.Frm.get('RightRotationCervical');
  }

  get LeftRotationCervical() {
    return this.Frm.get('LeftRotationCervical');
  }

  get RightSideFlexionCervical() {
    return this.Frm.get('RightSideFlexionCervical');
  }

  get LeftSideFlexionCervical() {
    return this.Frm.get('LeftSideFlexionCervical');
  }

  get ExtensionCervical() {
    return this.Frm.get('ExtensionCervical');
  }

  get FlexionCervical() {
    return this.Frm.get('FlexionCervical');
  }

  get Posture() {
    return this.Frm.get('Posture');
  }

  get Palpitation() {
    return this.Frm.get('Palpitation');
  }

  get TreatmentArea() {
    return this.Frm.get('TreatmentArea');
  }

  get PainBestLevel() {
    return this.Frm.get('PainBestLevel');
  }

  get PainCurrentLevel() {
    return this.Frm.get('PainCurrentLevel');
  }

  get PainWorstLevel() {
    return this.Frm.get('PainWorstLevel');
  }

  get PatientGoal() {
    return this.Frm.get('PatientGoal');
  }

  get DiagnosisTesting() {
    return this.Frm.get('DiagnosisTesting');
  }

  get Occupation() {
    return this.Frm.get('Occupation');
  }

  get LivingEnvironment() {
    return this.Frm.get('LivingEnvironment');
  }

  get MedicalHistory() {
    return this.Frm.get('MedicalHistory');
  }

  get PainDecreasesWith() {
    return this.Frm.get('PainDecreasesWith');
  }
  get PainIncreasesWith() {
    return this.Frm.get('PainIncreasesWith');
  }

  get MechanismOfInjury() {
    return this.Frm.get('MechanismOfInjury');
  }
  get InjuryDate() {
    return this.Frm.get('InjuryDate');
  }
  getCptCode() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  formSubmit() {
    this.ptInitalEvaluationService
      .registerPtInitalEvaluation(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
