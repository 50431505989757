import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { CommunicationAssessmentService } from 'src/app/services/communication-assessment.service';
import { NurseService } from 'src/app/services/nurse.service';

@Component({
  selector: 'app-update-communication-assessment',
  templateUrl: './update-communication-assessment.component.html',
  styleUrls: ['./update-communication-assessment.component.css'],
})
export class UpdateCommunicationAssessmentComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  nurse: Nurse;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private communicationAssessmentService: CommunicationAssessmentService,
    private router: Router,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
    this.getNurse(this.nurseId);

    this.communicationAssessmentService
      .getCommunicationAssessmentById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });

    this.Frm = this.fb.group({
      NurseId: this.nurseId,
      NurseName: [''],
      PrefferedLanguage: ['', Validators.required],
      PrimaryLanguage: ['', Validators.required],
      InterpreterRequired: [''],
      AbleToRead: [false],
      None: [false],
      //Language Barriers

      AgeRelated: [false],
      Auditory: [false],
      Cognitive: [false],
      Cultural: [false],
      Emotional: [false],
      EnergyLevel: [false],
      Financial: [false],
      Motivation: [false],
      Physical: [false],
      ReadingSkills: [false],
      Religion: [false],
      Visual: [false],
      InstructionsRefused: [false],

      //communiction tools
      FacialExpression: [false],
      LetterBoard: [false],
      PictireBoard: [false],
      PhysicalGestures: [false],
      WritingTablet: [false],
      SignLanguage: [false],
      LipReading: [false],
      LargePrintLiterature: [false],
      Braille: [false],
      VoiceProsthesis: [false],
      SpeakingValve: [false],
      ElectroLarinx: [false],
      Amplifier: [false],
      ElectronicEquipment: [false],

      //CommunicationBarriers
      NoBarriers: [false],
      Blind: [false],
      Deaf: [false],
      VisualBarriers: [false],
      HealthLiteracy: [false],
      CognitiveBarriers: [false],
      CulturalBarriers: [false],
      MotivationBarriers: [false],
      CommunicationComments: [''],
    });
  }

  get NurseName() {
    return this.Frm.get('NurseName');
  }

  get None() {
    return this.Frm.get('None');
  }
  get AbleToRead() {
    return this.Frm.get('AbleToRead');
  }

  get CommunicationComments() {
    return this.Frm.get('CommunicationComments');
  }
  get MotivationBarriers() {
    return this.Frm.get('MotivationBarriers');
  }

  get CulturalBarriers() {
    return this.Frm.get('CulturalBarriers');
  }

  get CognitiveBarriers() {
    return this.Frm.get('CognitiveBarriers');
  }

  get HealthLiteracy() {
    return this.Frm.get('HealthLiteracy');
  }

  get VisualBarriers() {
    return this.Frm.get('VisualBarriers');
  }

  get Deaf() {
    return this.Frm.get('Deaf');
  }

  get Blind() {
    return this.Frm.get('Blind');
  }

  get ElectronicEquipment() {
    return this.Frm.get('ElectronicEquipment');
  }

  get Amplifier() {
    return this.Frm.get('Amplifier');
  }

  get ElectroLarinx() {
    return this.Frm.get('ElectroLarinx');
  }

  get SpeakingValve() {
    return this.Frm.get('SpeakingValve');
  }

  get VoiceProsthesis() {
    return this.Frm.get('VoiceProsthesis');
  }

  get Braille() {
    return this.Frm.get('Braille');
  }

  get LargePrintLiterature() {
    return this.Frm.get('LargePrintLiterature');
  }

  get LipReading() {
    return this.Frm.get('LipReading');
  }
  get SignLanguage() {
    return this.Frm.get('SignLanguage');
  }

  get WritingTablet() {
    return this.Frm.get('WritingTablet');
  }
  get PhysicalGestures() {
    return this.Frm.get('PhysicalGestures');
  }

  get PictireBoard() {
    return this.Frm.get('PictireBoard');
  }

  get LetterBoard() {
    return this.Frm.get('LetterBoard');
  }

  get FacialExpression() {
    return this.Frm.get('FacialExpression');
  }
  get InstructionsRefused() {
    return this.Frm.get('InstructionsRefused');
  }

  get Visual() {
    return this.Frm.get('Visual');
  }
  get Religion() {
    return this.Frm.get('Religion');
  }
  get ReadingSkills() {
    return this.Frm.get('ReadingSkills');
  }
  get Physical() {
    return this.Frm.get('Physical');
  }
  get Motivation() {
    return this.Frm.get('Motivation');
  }

  get Financial() {
    return this.Frm.get('Financial');
  }

  get EnergyLevel() {
    return this.Frm.get('EnergyLevel');
  }

  get Emotional() {
    return this.Frm.get('Emotional');
  }

  get Cultural() {
    return this.Frm.get('Cultural');
  }

  get Cognitive() {
    return this.Frm.get('Cognitive');
  }

  get Auditory() {
    return this.Frm.get('Auditory');
  }
  get AgeRelated() {
    return this.Frm.get('AgeRelated');
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get PrefferedLanguage() {
    return this.Frm.get('PrefferedLanguage');
  }
  get InterpreterRequired() {
    return this.Frm.get('InterpreterRequired');
  }
  get PrimaryLanguage() {
    return this.Frm.get('PrimaryLanguage');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  formSubmit() {
    let body = {
      ...this.Frm.value,
      NurseName: this.nurse.NurseName,
    };
    this.communicationAssessmentService
      .updateCommunicationAssessment(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.router.navigate(['nurseDash']);
      });
  }
}
