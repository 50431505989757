import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-fax',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.css'],
})
export class FaxComponent implements OnInit {
  isSuccess = false;
  errorMessage = '';
  FaxImage: any;

  constructor(private fb: FormBuilder, private http: HttpClient) {}
  ngOnInit(): void {}

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }

  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }

  formSubmit() {
    const { FaxNumber } = this.Frm.value;
    const faxNumber = FaxNumber!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    container.innerHTML = template;
    document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }
}
