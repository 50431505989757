

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Medicine</h1>
            <form [formGroup]="prescriptionFrm" (ngSubmit)="formSubmit()" class="example-form">
             <div class="row">
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <input type="text" formControlName="DrugName" matInput >
                  <mat-error>Please enter drug name</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>BrandName</mat-label>
                  <input type="text" formControlName="BrandName" matInput >
                  <mat-error>Please enter brand name</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Category</mat-label>
                  <input type="text" formControlName="Category" matInput >
                  <mat-error>Please enter category</mat-error>

                </mat-form-field>

              </div>
             </div>
             <div class="row">
              <div class="col-lg-4">
                
                <mat-form-field class="example-full-width">
                  <mat-label>Strength</mat-label>
                  <input type="text" matInput formControlName="Dosage" >
                  <mat-error>Please enter Strength</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Price</mat-label>
                  <input type="text" matInput formControlName="Price" >
                </mat-form-field>
              </div>
              <div class="col-lg-4">

                <p>Status</p>
                <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                <div class="row">
                  <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                  <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
                </div>
                </mat-radio-group>
               
            </div>
             </div>
             <div class="row">
              <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Drug Code</mat-label>
                <input type="text" matInput formControlName="DrugCode" >
                <mat-error>Please enter Drug Code</mat-error>

              </mat-form-field>
            </div>
              <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Instruction</mat-label>
                <input type="text" matInput formControlName="Instruction" >
                <mat-error>Please enter Instruction</mat-error>

              </mat-form-field>
            </div>
             </div>
             
  
             <div class="row">
              <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Description</mat-label>
                <input type="text" matInput formControlName="Description" >
                <mat-error>Please enter Description</mat-error>

              </mat-form-field>
            </div>
             </div>
             
  
                

                
               
                
                

              
              
              

                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>

                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
              </form>
             
        </div>
    </div>

</div>

