<div class="container mt-3">
  <div
            class="d-flex py-4 flex-wrap"
            *ngIf="resultsArray.length; else WriteSomething"
          >
            <div *ngFor="let item of resultsArray; let itemIndex = index">
              <h4
                class="mb-0"
                style="cursor: pointer"
                [matMenuTriggerFor]="menu"
              >
                {{ item[0] }}&nbsp;
              </h4>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let suggestion of item; let i = index"
                  (click)="getSuggestion(item, i)"
                >
                  {{ suggestion }}
                </button>
                <button
                  mat-mini-fab
                  color="primary"
                  matTooltip="Remove text"
                  (click)="removeSuggestion(itemIndex)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="w-100">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="40"
                  name="export"
                  [(ngModel)]="ExportedData"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="main_container">
            <div class="sidebar">
              <div class="colours" matTooltip="Click to select any color">
                <div
                  class="colour"
                  colour="brown"
                  style="background-color: brown"
                ></div>
                <div
                  class="colour"
                  colour="teal"
                  style="background-color: teal"
                ></div>
                <div
                  class="colour"
                  colour="orange"
                  style="background-color: orange"
                ></div>
                <div
                  class="colour"
                  colour="blue"
                  style="background-color: blue"
                ></div>
                <div
                  class="colour"
                  colour="yellow"
                  style="background-color: yellow"
                ></div>
                <div
                  class="colour"
                  colour="green"
                  style="background-color: green"
                ></div>
                <div
                  class="colour"
                  colour="red"
                  style="background-color: red"
                ></div>
                <div
                  class="colour"
                  colour="black"
                  style="background-color: black"
                ></div>
              </div>
              <div class="tools">
                <button
                  mat-mini-fab
                  color="primary"
                  class="tool"
                  [disabled]="!strokes.length"
                  (click)="undo()"
                  matTooltip="Click to extract text"
                >
                  <mat-icon>undo</mat-icon>
                </button>
                <button
                  mat-mini-fab
                  class="tool"
                  tool="pencil"
                  color="primary"
                  matTooltip="Click to start drawing"
                >
                  <mat-icon>brush</mat-icon>
                </button>
                <button
                  mat-mini-fab
                  class="tool"
                  tool="eraser"
                  color="secondary"
                  matTooltip="Click to start erasing"
                >
                  <mat-icon>phonelink_erase</mat-icon>
                </button>
                <button
                  mat-mini-fab
                  class="tool"
                  tool="clear"
                  color="warn"
                  matTooltip="Clear canvas"
                >
                  <mat-icon>delete</mat-icon>
                </button>

                <button
                  mat-mini-fab
                  color="primary"
                  class="tool"
                  [disabled]="!strokes?.length"
                  (click)="recognizeText()"
                  matTooltip="Click to extract text"
                >
                  <mat-icon>change_circle</mat-icon>
                </button>
                <button
                  mat-mini-fab
                  class="tool"
                  (click)="getText()"
                  color="warn"
                  matTooltip="Clear canvas"
                >
                  <mat-icon>save</mat-icon>
                </button>
              </div>
            </div>
            <div class="canvas-container">
              <canvas #canvas id="canvas"></canvas>
            </div>
            <ng-template #WriteSomething>
              <h4 class="text-muted mb-0 py-4">
                Start Writing...
              </h4>
            </ng-template>
          </div>
      
</div>