import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { BradenScale } from '../../Models/bradenscale.model';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-bradenscale',
  templateUrl: './bradenscale.component.html',
  styleUrls: ['./bradenscale.component.css'],
})
export class BradenscaleComponent implements OnInit {
  displayedColumns: string[] = [
    'createdDate',
    'Moisture',
    'Activity',
    'Mobility',
    'Nutrition',
    'FrictionAndShear',
    'BradenScaleScore',
    'View',
  ];
  id = 0;
  bradenScaleList: BradenScale[] = [];
  dataSource: MatTableDataSource<any>;

  Moisture: string = '';
  MoistureScore: any;
  Activity: string = '';
  ActivityScore: any;
  Mobility: string = '';
  MobilityScore: any;
  Nutrition: string = '';
  NutritionScore: any;
  FrictionAndShear: string = '';
  FrictionAndShearScore: any;
  BradenScaleScore: any;

  constructor(
    private ptService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getBradenScale(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getBradenScale(id) {
    this.ptService.getBradenScale(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.BradenScales.sort(function (x, y) {
          return y.BradenScaleId - x.BradenScaleId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: BradenScale): void {
    this.Moisture = report.Moisture;
    this.MoistureScore = report.MoistureScore;
    this.Activity = report.Activity;
    this.ActivityScore = report.ActivityScore;
    this.Mobility = report.Mobility;
    this.MobilityScore = report.MobilityScore;
    this.Nutrition = report.Nutrition;
    this.NutritionScore = report.NutritionScore;
    this.FrictionAndShear = report.FrictionAndShear;
    this.FrictionAndShearScore = report.FrictionAndShearScore;
    this.BradenScaleScore = report.BradenScaleScore;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getBradenScale(this.id);
    }
  }
}
