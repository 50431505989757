import { Component } from '@angular/core';

@Component({
  selector: 'app-nurse-patient-reports',
  templateUrl: './nurse-patient-reports.component.html',
  styleUrls: ['./nurse-patient-reports.component.css']
})
export class NursePatientReportsComponent {

}
