<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table class="w-100" mat-table [dataSource]="dataSource" matSort>




    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.createdDate | date: 'short'}} </td>
    </ng-container>
    <ng-container matColumnDef="TaskName">
      <th class="mx-3" mat-header-cell *matHeaderCellDef> TaskName </th>
      <td class="mx-3" mat-cell *matCellDef="let row"> {{row.TaskName}} </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
      <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
    </ng-container>
    <ng-container matColumnDef="StartDate">
      <th mat-header-cell *matHeaderCellDef> StartDate </th>
      <td mat-cell *matCellDef="let row"> {{row.StartDate}} </td>
    </ng-container>
    <ng-container matColumnDef="StartTime">
      <th mat-header-cell *matHeaderCellDef> StartTime </th>
      <td mat-cell *matCellDef="let row"> {{row.StartTime}} </td>
    </ng-container>
    <ng-container matColumnDef="EndTime">
      <th mat-header-cell *matHeaderCellDef> EndTime </th>
      <td mat-cell *matCellDef="let row"> {{row.EndTime}} </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef> Status</th>
      <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
    </ng-container>
    <ng-container matColumnDef="Update">
      <th mat-header-cell *matHeaderCellDef> Edit</th>
      <td mat-cell *matCellDef="let row">
        <button type="button" [routerLink]="['/updateOtherTask/',row.OtherTaskId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button type="button" (click)="delete(row.OtherTaskId)" mat-mini-fab color="accent">
          <mat-icon matPrefix>delete</mat-icon>
        </button>
      </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>