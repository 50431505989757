import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { NurseService } from 'src/app/services/nurse.service';
import { NursereportService } from 'src/app/services/nursereport.service';

@Component({
  selector: 'app-update-nursereport',
  templateUrl: './update-nursereport.component.html',
  styleUrls: ['./update-nursereport.component.css'],
})
export class UpdateNursereportComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  nurse: Nurse;
  options: string[] = ['Morning', 'Afternoon', 'Evening', 'Night'];
  NurseId: number = 0;
  CurrentNurseId: number;
  nurseReports: NurseReport[];
  constructor(
    private fb: UntypedFormBuilder,
    private nurseReportService: NursereportService,
    private router: Router,
    private route: ActivatedRoute,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);

    this.CurrentNurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.nurseReportService.getNurseReportById(this.id).subscribe((res) => {
      this.NurseId = res.NurseId;
      this.NurseName.setValue(res.NurseName);
      this.Date.setValue(res.Date);
      this.Time.setValue(res.Time);
      this.Comments.setValue(res.Comments);
      this.Signature.setValue(res.Signature);
      this.Respiration.setValue(res.Respiration);
      this.HeartBeat.setValue(res.HeartBeat);
      this.Temparature.setValue(res.Temparature);
    });
  }

  medFrm = this.fb.group({
    NurseName: [''],
    Date: [''],
    Time: [''],
    Comments: [''],
    Signature: [''],
    Respiration: [''],
    Temparature: [''],
    HeartBeat: [''],
    BloodPressure: [''],
  });

  get Respiration() {
    return this.medFrm.get('Respiration');
  }
  get Temparature() {
    return this.medFrm.get('Temparature');
  }
  get HeartBeat() {
    return this.medFrm.get('HeartBeat');
  }
  get BloodPressure() {
    return this.medFrm.get('BloodPressure');
  }
  get NurseName() {
    return this.medFrm.get('NurseName');
  }

  get Date() {
    return this.medFrm.get('Date');
  }
  get Time() {
    return this.medFrm.get('Time');
  }
  get Comments() {
    return this.medFrm.get('Comments');
  }
  get Signature() {
    return this.medFrm.get('Signature');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  formSubmit() {
    let nurseName = this.medFrm.value['NurseName'];

    let date = this.medFrm.value['Date'];

    let time = this.medFrm.value['Time'];

    let respiration = this.medFrm.value['Respiration'];
    let temparature = this.medFrm.value['Temparature'];
    let heartBeat = this.medFrm.value['HeartBeat'];
    let bloodPressure = this.medFrm.value['BloodPressure'];
    let comments = this.medFrm.value['Comments'];
    let signature = this.medFrm.value['Signature'];
    let nurseId = this.medFrm.value['NurseId'];
    // let idoctorName = this.nurseList.filter(it => it.NurseId == nurseId)[0].NurseName;

    let body = {
      Comments: comments,
      Date: date,
      Time: time,
      NurseName: nurseName,
      Signature: signature,
      Respiration: respiration,
      Temparature: temparature,
      HeartBeat: heartBeat,
      BloodPressure: bloodPressure,
    };

    this.nurseReportService
      .updateNurseReport(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['/nurseDash/']);
      });
  }
}
