import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';

@Component({
  selector: 'app-add-medical-history',
  templateUrl: './add-medical-history.component.html',
  styleUrls: ['./add-medical-history.component.css'],
})
export class AddMedicalHistoryComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  id = 0;
  isSuccess = false;
  Frm: FormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private medicalHistoryService: MedicalHistoryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      PatientId: this.id,
      NoneOfOtherProblemsListed: [false],
      Chestpain: [false],
      Hypertension: [false],
      Osteoporosis: [false],
      Allergies: [false],
      Congestiveheartfailure: [false],
      Hypogonadismmale: [false],
      Pulmonaryembolism: [false],
      Anemia: [false],
      Chronicfatiguesyndrome: [false],
      Hypothyroidism: [false],
      Seizuredisorders: [false],
      Arthritisconditions: [false],
      Depression: [false],
      Infectionproblems: [false],
      ShortnessOfBreath: [false],
      Asthma: [false],
      Diabetes: [false],
      Insomnia: [false],
      SinusConditions: [false],
      ArterialFibrillation: [false],
      BleedingProblems: [false],
      BPH: [false],
      CADcoronaryArteryDisease: [false],
      Cancer: [false],
      CardiacArrest: [false],
      CeliacDisease: [false],
      DrugOrAlcoholAbuse: [false],
      ErectileDysfunction: [false],
      Fibromyalgia: [false],
      Gerd: [false],
      HeartDisease: [false],
      Hyperinsulinemia: [false],
      Hyperlipidemia: [false],
      IrritableBowelSyndrome: [false],
      KidneyProblems: [false],
      Menopause: [false],
      MigrainesOrHeadaches: [false],
      Neuropathy: [false],
      Onychomycosis: [false],
    });
  }

  get Onychomycosis() {
    return this.Frm.get('Onychomycosis');
  }

  get Neuropathy() {
    return this.Frm.get('Neuropathy');
  }

  get MigrainesOrHeadaches() {
    return this.Frm.get('MigrainesOrHeadaches');
  }

  get Menopause() {
    return this.Frm.get('Menopause');
  }

  get KidneyProblems() {
    return this.Frm.get('KidneyProblems');
  }

  get IrritableBowelSyndrome() {
    return this.Frm.get('IrritableBowelSyndrome');
  }

  get Hyperlipidemia() {
    return this.Frm.get('Hyperlipidemia');
  }

  get Hyperinsulinemia() {
    return this.Frm.get('Hyperinsulinemia');
  }

  get HeartDisease() {
    return this.Frm.get('HeartDisease');
  }

  get Gerd() {
    return this.Frm.get('Gerd');
  }

  get Fibromyalgia() {
    return this.Frm.get('Fibromyalgia');
  }

  get ErectileDysfunction() {
    return this.Frm.get('ErectileDysfunction');
  }

  get DrugOrAlcoholAbuse() {
    return this.Frm.get('DrugOrAlcoholAbuse');
  }

  get CeliacDisease() {
    return this.Frm.get('CeliacDisease');
  }

  get CardiacArrest() {
    return this.Frm.get('CardiacArrest');
  }

  get Cancer() {
    return this.Frm.get('Cancer');
  }

  get CADcoronaryArteryDisease() {
    return this.Frm.get('CADcoronaryArteryDisease');
  }

  get BPH() {
    return this.Frm.get('BPH');
  }

  get BleedingProblems() {
    return this.Frm.get('BleedingProblems');
  }

  get ArterialFibrillation() {
    return this.Frm.get('ArterialFibrillation');
  }

  get SinusConditions() {
    return this.Frm.get('SinusConditions');
  }

  get Insomnia() {
    return this.Frm.get('Insomnia');
  }

  get Diabetes() {
    return this.Frm.get('Diabetes');
  }

  get Asthma() {
    return this.Frm.get('Asthma');
  }

  get ShortnessOfBreath() {
    return this.Frm.get('ShortnessOfBreath');
  }

  get Infectionproblems() {
    return this.Frm.get('Infectionproblems');
  }

  get Depression() {
    return this.Frm.get('Depression');
  }

  get Arthritisconditions() {
    return this.Frm.get('Arthritisconditions');
  }

  get Seizuredisorders() {
    return this.Frm.get('Seizuredisorders');
  }

  get Hypothyroidisms() {
    return this.Frm.get('Hypothyroidisms');
  }

  get Chronicfatiguesyndrome() {
    return this.Frm.get('Chronicfatiguesyndrome');
  }

  get Anemia() {
    return this.Frm.get('Anemia');
  }

  get Pulmonaryembolism() {
    return this.Frm.get('Pulmonaryembolism');
  }

  get Hypogonadismmale() {
    return this.Frm.get('Hypogonadismmale');
  }

  get Congestiveheartfailure() {
    return this.Frm.get('Congestiveheartfailure');
  }

  get Allergies() {
    return this.Frm.get('Allergies');
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }

  get NoneOfOtherProblemsListed() {
    return this.Frm.get('NoneOfOtherProblemsListed');
  }

  get Chestpain() {
    return this.Frm.get('Chestpain');
  }

  get Hypertension() {
    return this.Frm.get('Hypertension');
  }
  get Osteoporosis() {
    return this.Frm.get('Osteoporosis');
  }

  formSubmit() {
    this.medicalHistoryService
      .registerMedicalHistory(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.submitAction.emit('');
        this.Frm.reset();
      });
  }
}
