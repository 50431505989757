import { Component } from '@angular/core';

@Component({
  selector: 'app-view-nerve-study-subtype',
  templateUrl: './view-nerve-study-subtype.component.html',
  styleUrls: ['./view-nerve-study-subtype.component.css']
})
export class ViewNerveStudySubtypeComponent {

}
