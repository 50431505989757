import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OtherTaskService } from 'src/app/services/other-task.service';

@Component({
  selector: 'app-update-other-task',
  templateUrl: './update-other-task.component.html',
  styleUrls: ['./update-other-task.component.css']
})
export class UpdateOtherTaskComponent implements OnInit {

  id=0;
  isSuccess= false;
  statustList:string[]=['Progress','Completed']

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private otherTaskService:OtherTaskService,private router:Router) { }

  ngOnInit(): void {
this.route.params.subscribe(res=>{
  this.id = res['id'];
})

this.otherTaskService.getOtherTaskById(this.id).subscribe(res=>{
  this.Frm.patchValue(res);
})

  }


  Frm = this.fb.group({
    TaskName:['',Validators.required],
    Description:['',Validators.required],
    StartDate:['',Validators.required],
    StartTime:['',Validators.required],
    EndTime:['',Validators.required],
    Status:['',Validators.required],
  })



get Status(){
  return this.Frm.get('Status');
}
get TaskName(){
  return this.Frm.get('TaskName');
}
get Description(){
  return this.Frm.get('Description');
}
get StartDate(){
  return this.Frm.get('StartDate');
}
get StartTime(){
  return this.Frm.get('StartTime');
}
get EndTime(){
  return this.Frm.get('EndTime');
}




  formSubmit(){

    let taskName = this.Frm.value['TaskName']
    let description = this.Frm.value['Description']
    let startDate = this.Frm.value['StartDate']
    startDate = moment(startDate).format('MM/DD/YYYY');

    let startTime = this.Frm.value['StartTime']
    let endTime = this.Frm.value['EndTime']
    let status = this.Frm.value['Status']


    let body={
      "PatientId":this.id,
      "TaskName":taskName,
      "Description":description,
      "StartDate":startDate,
      "StartTime":startTime,
      "EndTime":endTime,
      "Status":status,
    }


this.otherTaskService.updateOtherTask(this.id,body).subscribe(res=>{
  this.isSuccess=true
  // this.Frm.reset();
  this.router.navigate(['nurseDash'])
})


  }


}
