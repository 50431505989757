import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from '../../services/patientvisit.service';

@Component({
  selector: 'app-view-patient-visit',
  templateUrl: './view-patient-visit.component.html',
  styleUrls: ['./view-patient-visit.component.css']
})
export class ViewPatientVisitComponent implements OnInit {

  id=0;

  constructor() { }

  ngOnInit(): void {
    
  }


  getPatientVisit(){

  }
}
