<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Surgical History</h1>
      <form [formGroup]="srgFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg">
            <mat-form-field class="example-full-width">
              <mat-label>Type Of Surgery </mat-label>
              <input type="text" formControlName="SurgeyText" matInput>
              <mat-error>Please enter Type of Surgery</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Date Of Surgery</mat-label>
              <input type="date" formControlName="DateOfSurgery" matInput>
              <mat-error>Please enter Date Of Surgery</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput>
              <mat-error>Please enter Description</mat-error>
            </mat-form-field>



          </div>
        </div>

        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>
</div>