import { Component } from '@angular/core';

@Component({
  selector: 'app-view-all-physical-therapy-appointment',
  templateUrl: './view-all-physical-therapy-appointment.component.html',
  styleUrls: ['./view-all-physical-therapy-appointment.component.css']
})
export class ViewAllPhysicalTherapyAppointmentComponent {

}
