import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { HesterDavies } from '../../Models/hesterdavies.model';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-hester-davies',
  templateUrl: './hester-davies.component.html',
  styleUrls: ['./hester-davies.component.css'],
})
export class HesterDaviesComponent implements OnInit {
  displayedColumns: string[] = [
    'createdDate',
    'LastKnownFall',
    'Mobility',
    'Medication',
    'MentalStatus',
    'ToiletingNeeds',
    'HesterDaviesFallRiskTotal',
    'View',
  ];
  id = 0;
  hesterDaviesList: HesterDavies[] = [];
  dataSource: MatTableDataSource<any>;

  LastKnownFall;
  Mobility;
  Medication;
  MentalStatus;
  ToiletingNeeds;
  VolumeElectrolyteStatus;
  Communication;
  Behaviour;
  HesterDaviesFallRiskTotal;
  FallRiskPrecaution;

  LastKnownFallScore;
  MobilityScore;
  MedicationScore;
  MentalStatusScore;
  ToiletingNeedsScore;
  CommunicationScore;
  BehaviourScore;
  VolumeElectrolyteStatusScore;

  constructor(
    private ptService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getHesterDavies(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getHesterDavies(id) {
    this.ptService.getHesterDavies(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.HesterDaviesFallRiskAssessments.sort(function (x, y) {
          return (
            y.HesterDaviesFallRiskAssessmentId -
            x.HesterDaviesFallRiskAssessmentId
          );
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: HesterDavies): void {
    this.LastKnownFall = report.LastKnownFall;
    this.Mobility = report.Mobility;
    this.Mobility = report.Mobility;
    this.MentalStatus = report.MentalStatus;
    this.ToiletingNeeds = report.ToiletingNeeds;
    this.VolumeElectrolyteStatus = report.VolumeElectrolyteStatus;
    this.Communication = report.Communication;
    this.Behaviour = report.Behaviour;
    this.HesterDaviesFallRiskTotal = report.HesterDaviesFallRiskTotal;
    this.FallRiskPrecaution = report.FallRiskPrecaution;

    this.LastKnownFallScore = report.LastKnownFallScore;
    this.MobilityScore = report.MobilityScore;
    this.MedicationScore = report.MedicationScore;
    this.MentalStatusScore = report.MentalStatusScore;
    this.ToiletingNeedsScore = report.ToiletingNeedsScore;
    this.CommunicationScore = report.CommunicationScore;
    this.BehaviourScore = report.BehaviourScore;
    this.VolumeElectrolyteStatusScore = report.VolumeElectrolyteStatusScore;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getHesterDavies(this.id);
    }
  }
}
