import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { OtherTask } from 'src/app/Models/othertask.model';
import { OtherTaskService } from 'src/app/services/other-task.service';

@Component({
  selector: 'app-add-other-task',
  templateUrl: './add-other-task.component.html',
  styleUrls: ['./add-other-task.component.css']
})
export class AddOtherTaskComponent implements OnInit {

  id=0;
  isSuccess= false;

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private otherTaskService:OtherTaskService) { }

  ngOnInit(): void {
this.route.params.subscribe(res=>{
  this.id = res['id'];
})


  }


  Frm = this.fb.group({
    TaskName:['',Validators.required],
    Description:['',Validators.required],
    StartDate:['',Validators.required],
    StartTime:['',Validators.required],
    EndTime:['',Validators.required],
  })



get TaskName(){
  return this.Frm.get('TaskName');
}
get Description(){
  return this.Frm.get('Description');
}
get StartDate(){
  return this.Frm.get('StartDate');
}
get StartTime(){
  return this.Frm.get('StartTime');
}
get EndTime(){
  return this.Frm.get('EndTime');
}




  formSubmit(){

    let taskName = this.Frm.value['TaskName']
    let description = this.Frm.value['Description']
    let startDate = this.Frm.value['StartDate']
    startDate = moment(startDate).format('MM/DD/YYYY');

    let startTime = this.Frm.value['StartTime']
    let endTime = this.Frm.value['EndTime']


    let body={
      "PatientId":this.id,
      "TaskName":taskName,
      "Description":description,
      "StartDate":startDate,
      "StartTime":startTime,
      "EndTime":endTime,
    }


this.otherTaskService.registerOtherTask(body).subscribe(res=>{
  this.isSuccess=true
  this.Frm.reset();
})


  }


}
