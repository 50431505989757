import { Component } from '@angular/core';

@Component({
  selector: 'app-update-nurse-note',
  templateUrl: './update-nurse-note.component.html',
  styleUrls: ['./update-nurse-note.component.css']
})
export class UpdateNurseNoteComponent {

}
