<mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        Purposeful Rounds
      </ng-template>
      <app-view-purposefull-round></app-view-purposefull-round>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        New Purposeful Rounds
      </ng-template>
      <app-add-purposefull-round></app-add-purposefull-round>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        Nursing Assessment Plan
      </ng-template>
      <app-view-nursing-assessment-plan></app-view-nursing-assessment-plan>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        New Nursing Assessment Plan
      </ng-template>
      <app-add-nursing-assesment></app-add-nursing-assesment>

    </mat-tab>
   
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
       Braden Scale Report
      </ng-template>
      <app-bradenscale></app-bradenscale>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        New Braden Scale
      </ng-template>
      <app-add-bradenscale></app-add-bradenscale>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
       Hester Davies  Report
      </ng-template>
      <app-hester-davies></app-hester-davies>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        New Hester Davies
      </ng-template>
      <app-add-hester-davies></app-add-hester-davies>

    </mat-tab>

</mat-tab-group>
