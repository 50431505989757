import { Injectable } from '@angular/core';
declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionPlanService {
  recognition = new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  public PatientNote = '';
  tempWords;

  constructor() {}

  init() {
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      // console.log(transcript);
    });
  }

  startPatientNote() {
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    console.log('Speech recognition started');
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
        // console.log('End speech recognition');
      } else {
        this.wordConcatPatientNote();
        this.recognition.start();
      }
    });
  }
  stopPatientNote() {
    this.isStoppedSpeechRecog = true;
    this.wordConcatPatientNote();
    this.recognition.stop();
    // console.log('End speech recognition');
  }

  wordConcatPatientNote() {
    this.PatientNote = this.PatientNote + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }
}
