import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from 'src/app/Models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-twofa',
  templateUrl: './twofa.component.html',
  styleUrls: ['./twofa.component.css']
})
export class TwofaComponent implements OnInit {
otp :any;
inputDigit :string ="Verify Code";
btnStaus:string = "btn-light"
  decodedToken: any;
  Email:any;
id=0;
helper = new JwtHelperService();
  
myToken = window.localStorage.getItem('token')
  constructor(private authService:AuthService,private router:Router,private route:ActivatedRoute) { }

  public configurations ={
    length:5
  }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
     this.Email = res['unique_name']
    this.decodedToken=res;
   
    })
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id  = parseInt(decodedToken.nameid)
  }



  onOtpChange(event:any){
    this.otp = event;
if(this.otp.length< this.configurations.length ){
 this.inputDigit = this.configurations.length - this.otp.length + " digits left"
}
if(this.otp.length === this.configurations.length){
  this.inputDigit ="Go"
  this.btnStaus = 'btn-primary'
  
}
  }

  veryFy(e){
    let body={
      "TwoFactorCode":this.otp.toString(),
      "Email":this.Email
    }
    
    this.authService.TwoFa(body).subscribe(res=>{
   

     if(res === true && this.decodedToken.role == "Admin"){
        this.router.navigate(['/adminDash'])

       }else if(res === true && this.decodedToken.role == "Doctor"){
        this.router.navigate(['/doctorDash'])

       }
       else if(res === true && this.decodedToken.role == "Nurse"){
        this.router.navigate(['/nurseDash/'])

       }
       else if(res === true && this.decodedToken.role == "LabManager"){
        this.router.navigate(['/labDash/'])

       }
       else if(res === true && this.decodedToken.role == "PharmacyMember"){
        this.router.navigate(['/pharmacyDash/'])

       }
       else if(res === true && this.decodedToken.role == "Patient") {
        this.router.navigate(['/patientDash/',this.id])
       }
       else if(res === true && this.decodedToken.role == "PhysicalTherapyDoctor") {
        this.router.navigate(['/physicalTherapyDash/'])
       }
       else if(res === true && this.decodedToken.role == "RadiologyMember") {
        this.router.navigate(['/radiologyDash/'])
         
       }else{
        alert('not user found')
       }
        
      })
  }

}
