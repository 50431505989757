import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ward } from 'src/app/Models/ward.model';
import { NurseHandOffNoteService } from 'src/app/services/nurse-hand-off-note.service';
import { PatientService } from 'src/app/services/patient.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-add-nurse-hand-off-note',
  templateUrl: './add-nurse-hand-off-note.component.html',
  styleUrls: ['./add-nurse-hand-off-note.component.css'],
})
export class AddNurseHandOffNoteComponent implements OnInit {
  id = 0;
  isSuccess = false;
  wardList: Ward[];
  listening: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private nurseHandOffNoteService: NurseHandOffNoteService,
    private route: ActivatedRoute,
    private router: Router,
    private wardService: WardService,
    private patientService: PatientService,
    public service: VoiceRecognitionService
  ) {
    this.service.init();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getWards();
    this.getPatient(this.id);
  }

  Frm = this.fb.group({
    PatientName: ['', Validators.required],
    PatientDOB: ['', Validators.required],
    HandOffNote: ['', Validators.required],
    WardId: [],
  });

  get WardId() {
    return this.Frm.get('WardId');
  }
  get PatientName() {
    return this.Frm.get('PatientName');
  }
  get PatientDOB() {
    return this.Frm.get('PatientDOB');
  }
  get HandOffNote() {
    return this.Frm.get('HandOffNote');
  }

  getWards() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.PatientName.setValue(
        res.PatientFirstName + ' ' + res.PatientLastName
      );
      this.PatientDOB.setValue(res.PatientDOB);
    });
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }

  formSubmit() {
    let patientName = this.Frm.value['PatientName'];
    let patientDOB = this.Frm.value['PatientDOB'];
    let handOffNote = this.Frm.value['HandOffNote'];
    let wardId = this.Frm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;

    let body = {
      PatientId: this.id,
      WardId: wardId,
      WardName: wardName,
      PatientName: patientName,
      PatientDOB: patientDOB,
      HandOffNote: handOffNote,
    };

    this.nurseHandOffNoteService
      .registerNurseHandOffNote(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();

        // this.router.navigate(['/patientDetails/',this.id])
      });
  }
}
