<div id="">


  <div class=" toolbar mat-elevation-z6">

    <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">
      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>search</mat-icon>
        <div class="mx-2">Scan Patient Data</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS">
        <mat-icon mat-list-icon>search</mat-icon>
        <a routerLink="/pharmacyPatientScanner">Scan Patient</a>
      </div>
      <button type="button" (click)="logout()" class="btn float-right mx-3 my-3 logout " color="accent"
        mat-raised-button>
        <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>

  <span [ngSwitch]="showItem">

    <p *ngSwitchCase="'1'">
      <app-pharmacy-patient-scanner></app-pharmacy-patient-scanner>
    </p>



    <div *ngSwitchDefault>

      <div class=" ml-3">
        <mat-form-field class="example-full-width mt-5">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>


            <ng-container matColumnDef="Id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
            </ng-container>


            <ng-container matColumnDef="FirstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
              <td mat-cell *matCellDef="let row"> {{row.PatientFirstName}} </td>
            </ng-container>
            <ng-container matColumnDef="LastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
              <td mat-cell *matCellDef="let row"> {{row.PatientLastName}} </td>
            </ng-container>
            <ng-container matColumnDef="Gender">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
              <td mat-cell *matCellDef="let row"> {{row.PatientGender}} </td>
            </ng-container>
            <ng-container matColumnDef="PatientNationalId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> National Id </th>
              <td mat-cell *matCellDef="let row"> {{row.PatientNationalId}} </td>
            </ng-container>
            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let row"> {{row.Email}} </td>
            </ng-container>
            <ng-container matColumnDef="PhoneNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> PhoneNumber </th>
              <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="PrimaryDoctor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Doctor </th>
              <td mat-cell *matCellDef="let row"> {{row.PrimaryDoctorsName}} </td>
            </ng-container>

            <ng-container matColumnDef="WardName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ward </th>
              <td mat-cell *matCellDef="let row"> {{row.WardName}} </td>
            </ng-container>
            <ng-container matColumnDef="RoomNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Room No </th>
              <td mat-cell *matCellDef="let row"> {{row.RoomNo}} </td>
            </ng-container>
            <ng-container matColumnDef="Detail">
              <th mat-header-cell *matHeaderCellDef> Patient Details</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" [routerLink]="['/pharmacyPatientPrescription/',element.PatientId]" mat-mini-fab
                  color="primary">
                  <mat-icon matPrefix>remove_red_eye</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>



      </div>

    </div>







  </span>

</div>