<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of officePolicyList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">OFFICE POLICIES</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <h2>PAYMENT POLICY</h2>
                    <p>We are not contracted with all insurance companies and/or discounted networks. Your insurance
                        benefits will be verified prior to service and you will be notified in advance if we are
                        out-of-network. We accept many forms of payments including cashier’s checks, wire transfers,
                        AMEX,
                        Master Card, Visa, and Discover. We do not accept any personal or business checks. Below is a
                        summary of payment expectations:</p>

                    <ul>
                        <li> Office Visits copays, co-insurance, deductibles or any other patient liability is due at
                            the
                            time
                            of service.</li>
                        <li> Surgery Deposits will be calculated according to your benefits, this is an estimate of your
                            liability for surgery and must be paid prior to surgery. A billing representative will meet
                            with
                            you
                            prior to surgery to review your surgical out of pocket costs.</li>
                        <li> International/Global Insurance is not accepted by the practice.</li>
                        <li> Uninsured & International Patients (any patient residing outside of the USA) are considered
                            self-pay. Payment for services is due in full at the time of service, all surgical payments
                            must
                            be
                            paid prior to surgery.</li>
                        <li> Motor Vehicle Accidents/Work Related Injuries/Legal Cases are not accepted by the practice.
                        </li>
                    </ul>

                    <tr>
                        <th> Do you have a lawyer representing you for this medical condition?? </th>

                        <td>{{item?.IslawyerrepresentingyouForThisMedicalCondition ?'Yes':'No'}}</td>
                    </tr>



                    <h2>SURGERY CANCELLATIONS</h2>
                    <p>If you must cancel a scheduled surgery, please call our office by 3:00pm (3) business days
                        (Monday –
                        Friday) prior to your surgery appointment to notify us of your cancellation or reschedule.
                        Messages
                        left over the weekend are not considered sufficient notice.
                        Failure to cancel surgery appointments without proper notice will result in a $150.00 no
                        show/cancellation fee. You are responsible for this fee and it is not billable to your insurance
                        carrier.</p>
                    <h2>FORMS AND MEDICAL RECORDS</h2>
                    <p>If you require our office to complete any disability, FMLA, personal reimbursement, or work forms
                        there is a charge of $25 for the first form and $15 for each additional form. Payment must be
                        made
                        before your forms can be processed. Your forms will be completed within 10 business days.</p>
                    <p>If you require a copy of your medical records you must sign a Medical Records Release of
                        Information
                        form and a payment of $25 will be due upon receipt of your request. Your request will be
                        completed
                        within 10 business days.</p>

                    <h2>RETURN OF IMAGING CDS/FILMS</h2>
                    <p>Imaging CDs/Films are an important factor used in determining your diagnosis and treatment.
                        However,
                        after surgery, {{hospital.HospitalName}}. does not need your CDs/Films and does not have
                        space to store them. We will store your CDs/Films for 90 days following your surgery date. After
                        90
                        days we will dispose of the CDs/Films in accordance of standards established by the HIPAA
                        Privacy
                        and Security Rules to preserve the confidentiality of your protected health information. Please
                        note
                        you can always get a copy of your CDs/Films from the imaging provider that performed the study
                        as
                        they are required to retain those images for 7 years. We are not responsible for loss, damage or
                        misplacement of your CDS/Films, or for non-receipt of CDS/Films that were mailed or returned via
                        U.S. Mail or other delivery services.</p>

                    <p>If you request your CDs/Films to be returned within 90 days from your surgery date, the following
                        explains our policy about returning your films for you:</p>


                    <strong>By signing below, I acknowledge that I have read, understand, and agree to the terms and
                        conditions of this form and that I am authorized as the patient or the Patient’s Legal
                        Representative to sign this document.</strong>

                    <ul>
                        <li> Office Pick Up CD/Films can be picked up for a {{hospital.HospitalName}} office for no
                            charge.</li>
                        <li> U.S. Shipment CDS/Films returned to the patient within the United States will be charged a
                            $50
                            handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).</li>
                        <li> International Shipment CDS/Films returned to the patient outside of the United States will
                            be
                            charged a $200 handling fee plus actual postage determined by carrier (USPS/FedEx, DHL).
                        </li>
                    </ul>



                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>
                <button (click)="delete(item.OfficePolicyId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
                <!-- <button class="float-right" [routerLink]="['/editFamilyHistory/',item.FamilyHistoryId]" color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
    -->

            </mat-card>
        </div>
    </div>
</div>