import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PastMedicationService } from 'src/app/services/past-medication.service';

@Component({
  selector: 'app-add-pastmedication',
  templateUrl: './add-pastmedication.component.html',
  styleUrls: ['./add-pastmedication.component.css'],
})
export class AddPastmedicationComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  isSuccess = false;

  id = 0;
  states;
  constructor(
    private fb: UntypedFormBuilder,
    private pastMedicationService: PastMedicationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  Frm = this.fb.group({
    DrugName: ['', Validators.required],
    Dosage: ['', Validators.required],
    StartDate: ['', Validators.required],
    EndDate: ['', Validators.required],
    // Status: [''],
  });

  get EndDate() {
    return this.Frm.get('EndDate');
  }

  get DrugName() {
    return this.Frm.get('DrugName');
  }

  get Dosage() {
    return this.Frm.get('Dosage');
  }
  get StartDate() {
    return this.Frm.get('StartDate');
  }

  // get Status() {
  //   return this.Frm.get('Status');
  // }

  formSubmit() {
    let endDate = this.Frm.value['EndDate'];
    let drugName = this.Frm.value['DrugName'];
    let dosage = this.Frm.value['Dosage'];

    let startDate = this.Frm.value['StartDate'];

    // let status = this.Frm.value['Status'];

    let body = {
      PatientId: this.id,
      DrugName: drugName,
      Dosage: dosage,
      StartDate: startDate,
      EndDate: endDate,
      // Status: status,
    };
    this.pastMedicationService.registerPastMedication(body).subscribe((res) => {
      this.isSuccess = true;
      this.submitAction.emit('');

      this.Frm.reset();
    });
  }
}
