import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientAdmission } from 'src/app/Models/patientadmission.mode';
import { PatientAdmissionService } from 'src/app/services/patient-admission.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-patient-admission',
  templateUrl: './view-patient-admission.component.html',
  styleUrls: ['./view-patient-admission.component.css'],
})
export class ViewPatientAdmissionComponent implements OnInit {
  id = 0;
  patientAdmissionList: PatientAdmission[] = [];
  patient: Patient;
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private patientAdmissionService: PatientAdmissionService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.getPatientAdmisson(this.id);
  }

  getPatientAdmisson(id) {
    this.patientService.getPatientAdmission(id).subscribe((res) => {
      this.patientAdmissionList = res.PatientAdmissions.sort(function (x, y) {
        return y.PatientAdmissionId - x.PatientAdmissionId;
      });
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.patientAdmissionService.deletePatientAdmission(id).subscribe((res) => {
      this.getPatientAdmisson(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPatientAdmisson(this.id);
    }
  }
}
