import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { BloodSugarService } from 'src/app/services/blood-sugar.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-blood-sugar',
  templateUrl: './add-blood-sugar.component.html',
  styleUrls: ['./add-blood-sugar.component.css'],
})
export class AddBloodSugarComponent implements OnInit {
  id = 0;
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  UserId: number = 0;
  doctor: Doctor;
  listening = false;
  listeningReportName: boolean;
  listeningSugarLevel: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private bloodSugarService: BloodSugarService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    public serviceObjective: VoiceRecognitionObjectiveService
  ) {
    this.service.init();
    this.serviceObjective.init();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  Frm = this.fb.group({
    TestDate: ['', Validators.required],
    SugarLevel: ['', Validators.required],
  });

  getSugarLevel() {
    return this.Frm.get('SugarLevel');
  }
  getTestDate() {
    return this.Frm.get('TestDate');
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getSugarLevel();
    }
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }

  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningSugarLevel = true;
  }

  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningSugarLevel = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  onObjectiveBlur() {
    this.stopServiceObjective();
    this.stopService();
  }
  stopAll() {
    this.stopServiceObjective();
    this.stopService();
  }

  formSubmit() {
    let testDate = this.Frm.value['TestDate'];
    let sugarLevel = this.Frm.value['SugarLevel'];

    let body = {
      PatientId: this.id,
      TestDate: testDate,
      SugarLevel: sugarLevel,
    };
    this.bloodSugarService.registerBloodSugar(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
    });
  }
}
