import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PhysicalTherapyBooking } from 'src/app/Models/physicaltherapybooking.model';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';

@Component({
  selector: 'app-view-todays-physical-therapy-appointment',
  templateUrl: './view-todays-physical-therapy-appointment.component.html',
  styleUrls: ['./view-todays-physical-therapy-appointment.component.css'],
})
export class ViewTodaysPhysicalTherapyAppointmentComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'Detail',
    'Delete',
  ];

  id = 0;
  Appointments: PhysicalTherapyBooking[] = [];

  filteredList;
  dataSource: MatTableDataSource<any>;
  date;

  constructor(
    private appointmentService: PhysicaltherapyBookingService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAppt();
    // this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    let date = new Date();
    this.date = moment(date).format('MM/DD/YYYY');
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt() {
    this.appointmentService.getPhysicalTherapyBookings().subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.filter((x) => x.VisitDate == this.date)
      );
      this.dataSource.paginator = this.paginator;

      this.filteredList = res.filter((x) => x.VisitDate == this.date);

      this.Appointments = res;
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.Appointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.appointmentService
      .deletePhysicalTherapyBooking(id)
      .subscribe((res) => {
        // this.filteredList = this.Appointments.filter(item=>item.PatientBookingId !=id)

        this.getAppt();
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
