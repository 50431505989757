<div class="container mt-5">
    <h2>Import Patients</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFile()">
                        <input type="file" class="formControl" (change)="onFileSelected($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Patients</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Doctors</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFileDoctors()">
                        <input type="file" class="formControl" (change)="selectedDoctorFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Doctors</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Physcal Therapy Doctors</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFilePhyscalTherapist()">
                        <input type="file" class="formControl" (change)="selectedPhysicalTherapistFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Physical Therapy
                            Doctors</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Pharmacist</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFilePharmacist()">
                        <input type="file" class="formControl" (change)=" selectedPharmacistFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Pharmacist</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Lab Manager</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFileLabManagers()">
                        <input type="file" class="formControl" (change)=" selectedLabManagerFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Lab Managers</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Radiology Members</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFileRadilogyMembers()">
                        <input type="file" class="formControl" (change)=" selectedRadiologyMemberFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Radiology Members</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import PatientVisits</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadPatientVisit()">
                        <input type="file" class="formControl" (change)=" selectedPatientVisitFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            PatientVisits</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Neurology Visits</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadNeurologyVisit()">
                        <input type="file" class="formControl" (change)=" selectedNeurologyVisitFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Neurology Visits</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>