<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Blood Sugar Result</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Test</mat-label>
                            <input type="date" formControlName="TestDate" matInput>
                            <mat-error>Please enter Test Date</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Sugar Level</mat-label>
                            <textarea matInput (input)="onObjectiveChange($event)" formControlName="SugarLevel"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10" [value]="serviceObjective.Objective" matInput
                                (blur)="onObjectiveBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listeningReportName"
                                (click)="startServiceObjective()"></button>
                            <mat-error>Please enter Sugar Level</mat-error>
                        </mat-form-field>

                    </div>
                    <button type="button" color="accent" (click)="stopService()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>




                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>