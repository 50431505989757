import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Procedure } from '../Models/procedure.model';
import { EmailPayload } from '../Models/email.model';

@Injectable({
  providedIn: 'root',
})
export class ProcedureService {
  url = environment.Url + 'Procedures';
  emailRefferal = environment.Url + 'Emails/refferalEmail';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  sendRadiologyOroder(body) {
    return this.http
      .post<EmailPayload>(this.emailRefferal, body)
      .pipe(catchError(this.handleError));
  }
  registerProcedure(body) {
    return this.http
      .post<Procedure>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getProcedures() {
    return this.http
      .get<Procedure[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getProcedureById(id) {
    return this.http.get<Procedure>(this.url + '/' + id);
  }

  updateProcedure(id, body) {
    return this.http
      .put<Procedure>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteProcedure(id) {
    return this.http
      .delete<Procedure>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
