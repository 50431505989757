<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
              </mat-form-field>
        </div>
    </div>
  </div>
  <div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
  
  
  
  
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef > Date </th>
        <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
      </ng-container>
      <ng-container matColumnDef="ReportName">
        <th mat-header-cell *matHeaderCellDef > Exam / Report </th>
        <td mat-cell *matCellDef="let row"> {{row.ReportName}} </td>
      </ng-container>

      <ng-container matColumnDef="DoctorName">
        <th mat-header-cell *matHeaderCellDef > Doctor Name </th>
        <td  mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
      </ng-container>

      <ng-container matColumnDef="Signature">
        <th mat-header-cell *matHeaderCellDef > Signed </th>
        <td  mat-cell *matCellDef="let row"> Yes </td>
      </ng-container>


      <ng-container matColumnDef="View">
        <th  mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let row"> 
          <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg"> View </button>
  
                  <div class="modal fade bd-example-modal-lg"  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">Exam / Report</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body " id="printable" >
                          <!-- <app-view-hospital></app-view-hospital> -->
                        
                           <!-- <div class="areaStyle mb-1"> -->
                             <!-- <P>Patient ID: {{patient?.PatientId}} </P>
         
                              <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                             <P>Patient D.O.B: {{patient?.PatientDOB}} </P> -->
                             <!-- <P>Patient Address: {{patient?.PatientAddress}} </P> -->
                       
                             <!-- </div> -->
                         <!-- <p>Doctor Name: {{row.DoctorName}}</p> -->

                         <!-- <p>Subject: {{row.ReportName}}</p> -->
                   
                         <p> {{row.ReportDescription}}</p>
                         <p class="mt-5">Reported By: {{row.DoctorName}}</p>
                         <p>Date: {{row.createdDate  | date:'short'}}</p>

                        </div>
                        <div class="modal-footer">
                          <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
  
                          <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  
        </td>
      </ng-container>
  <!-- modal  -->
         
        
  
      <ng-container matColumnDef="Delete">
        <th  mat-header-cell *matHeaderCellDef> Delete</th>
        <td mat-cell *matCellDef="let row"> 
            <button  (click)="delete(row.DoctorReportId)"
            mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  