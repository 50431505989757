<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">code_file</mat-icon>
      Hester Davies Risk Fall Assessment
    </ng-template>
    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Date </th>
          <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
        </ng-container>

        <ng-container matColumnDef="LastKnownFall">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Known Fall </th>
          <td mat-cell *matCellDef="let row"> {{row.LastKnownFall}} </td>
        </ng-container>


        <ng-container matColumnDef="Mobility">
          <th mat-header-cell *matHeaderCellDef> Mobility </th>
          <td mat-cell *matCellDef="let row"> {{row.Mobility}} </td>
        </ng-container>
        <ng-container matColumnDef="Medication">
          <th mat-header-cell *matHeaderCellDef> Medication </th>
          <td mat-cell *matCellDef="let row"> {{row.Medication}} </td>
        </ng-container>

        <ng-container matColumnDef="MentalStatus">
          <th mat-header-cell *matHeaderCellDef> Mental Status </th>
          <td mat-cell *matCellDef="let row"> {{row.MentalStatus}} </td>
        </ng-container>
        <ng-container matColumnDef="ToiletingNeeds">
          <th mat-header-cell *matHeaderCellDef> Toileting Needs </th>
          <td mat-cell *matCellDef="let row"> {{row.ToiletingNeeds}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Communication">
        <th mat-header-cell *matHeaderCellDef > Communication </th>
        <td mat-cell *matCellDef="let row"> {{row.Communication}} </td>
      </ng-container>
      <ng-container matColumnDef="Behaviour">
        <th mat-header-cell *matHeaderCellDef > Behaviour </th>
        <td mat-cell *matCellDef="let row"> {{row.Behaviour}} </td>
      </ng-container>
      <ng-container matColumnDef="VolumeElectrolyteStatus">
        <th mat-header-cell *matHeaderCellDef > VolumeElectrolyteStatus </th>
        <td mat-cell *matCellDef="let row"> {{row.VolumeElectrolyteStatus}} </td>
      </ng-container> -->
        <ng-container matColumnDef="HesterDaviesFallRiskTotal">
          <th mat-header-cell *matHeaderCellDef>Final Score </th>
          <td mat-cell *matCellDef="let row"> {{row.HesterDaviesFallRiskTotal}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="FallRiskPrecaution">
        <th mat-header-cell *matHeaderCellDef > FallRiskPrecaution </th>
        <td mat-cell *matCellDef="let row"> {{row.FallRiskPrecaution}} </td>
      </ng-container> -->

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef> View Report </th>
          <td mat-cell *matCellDef="let row">


            <!-- modal  -->
            <button type="button" (click)="viewReport(row)" class="btn btn-primary" data-toggle="modal"
              data-target=".bd-example-modal-lg"> View Report</button>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content" id="printable">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Hester Davies Fall Risk Assessment Report</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Last Known Fall:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="LastKnownFall"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="LastKnownFallScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Mobility:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Mobility"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MobilityScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Medication:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Medication"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MedicationScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Mental Status:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MentalStatus"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MentalStatusScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Toileting Needs:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="ToiletingNeeds"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="ToiletingNeedsScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Volume Electrolyte Status:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="VolumeElectrolyteStatus"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="VolumeElectrolyteStatusScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Communication:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Communication"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="CommunicationScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Behaviour:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Behaviour"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="BehaviourScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Hester Davies Fall Risk Total:</h4>

                      </div>
                      <div class="col-md-4">

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="HesterDaviesFallRiskTotal"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Fall Risk Precaution:</h4>

                      </div>
                      <div class="col-md-8">
                        <div [innerHTML]="FallRiskPrecaution"></div>

                      </div>

                    </div>
                    <div class="row mt-5">
                      <ul>
                        <li> <strong>Sever Risk</strong>: Total Score < 9 </li>
                        <li> <strong>High Risk</strong>: Total Score 10 - 12 </li>
                        <li> <strong>Moderate Risk</strong>: Total Score 13 - 14 </li>
                        <li> <strong>Mild Risk</strong>: Total Score 15 - 18 </li>



                      </ul>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                    <button type="button" class="btn btn-secondary mx-4" data-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                  </div>
                </div>
              </div>
            </div>


            <!-- modal  -->



          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      New Hester Davies Fall Risk Assessment
    </ng-template>

    <app-add-hester-davies></app-add-hester-davies>
  </mat-tab>


</mat-tab-group>