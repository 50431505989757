<!DOCTYPE html>
<html>

<head>
    <title>Binsera EHR Privacy Policy</title>

</head>

<body class="mx-5 my-5">
    <div class="mb-5">

        <button mat-raised-button color="primary" [routerLink]="['/']"><mat-icon mat-list-icon>undo</mat-icon>
            Back</button>
    </div>
    <p><strong>BINSERA's Privacy Policy</strong></p>
    <p>BINSERA recognizes that your privacy is very important, and it is our goal to protect the privacy and security of
        your personal information. To achieve this goal, this Privacy Policy (&ldquo;Privacy Policy&rdquo;) describes
        how Medical Information Technology, Inc. (&ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;BINSERA&rdquo;) may
        collect, use and share information about you (&ldquo;you&rdquo; or &ldquo;user&rdquo;), and how you can control
        BINSERA&rsquo;s use of your personal information.</p>
    <p>By using the BINSERA Services (including, but not limited to, the BINSERA Website and various BINSERA offerings),
        you consent to this Privacy Policy and the terms herein. BINSERA may change this Privacy Policy from time to
        time. It is your responsibility to check this webpage periodically to see if any terms have been changed or
        modified. Your continued use of the BINSERA Services constitutes your acceptance of any updates to this Privacy
        Policy.</p>
    <p><strong>How does BINSERA collect your information?</strong></p>
    <p>BINSERA collects information from you in three ways: (1) directly from you, (2) from BINSERA web server logs, and
        (3) cookies.</p>
    <ol>
        <li><strong>Information That You Give Us</strong><br /> We obtain information about you when you use the BINSERA
            Services, when you contact us about products, services, or employment, when you send us a message, or when
            you choose (opt-in) to subscribe to our blog, newsletter, or event notification emails. In these cases, you
            decide what and how much information you want to share.</li>
        <li><strong>BINSERA Web Server Logs</strong><br /> An IP address is a number automatically assigned to your
            computer whenever you access the Internet. IP addresses allow computers and servers to recognize and
            communicate with each other. BINSERA collects IP addresses in order to conduct system administration and
            site analysis, to improve functionality and usage of the BINSERA Website.</li>
        <li><strong>Cookies</strong><br /> For information about cookies, see&nbsp;<a
                href="https://binsera.com/cookie-policy.html">BINSERA&rsquo;s Cookie Policy</a>.</li>
    </ol>
    <p><strong>What information is BINSERA collecting?</strong></p>
    <p>The information we collect might include:</p>
    <ul>
        <li>First and last name</li>
        <li>Physical address</li>
        <li>Email address</li>
        <li>IP address</li>
        <li>Geolocation data</li>
        <li>Professional or employment-related information</li>
        <li>Demographic data</li>
        <li>Education Information</li>
        <li>Browser type and version</li>
        <li>Data about the equipment used to visit the BINSERA Website</li>
        <li>Patterns of searching and browsing on the BINSERA Website</li>
        <li>Additional information provided by you</li>
    </ul>
    <p><strong>How does BINSERA use your information?</strong></p>
    <p>We may use your information:</p>
    <ul>
        <li>to improve the functionality and usage of the BINSERA Services</li>
        <li>to facilitate BINSERA&rsquo;s own marketing, promotional, and informational purposes</li>
        <li>to track the popularity of features of the BINSERA Services and to guide the development of new ones</li>
        <li>to identify the types of devices our visitors use so that we can improve and optimize our systems</li>
        <li>to evaluate your candidacy for a position</li>
        <li>to meet certain legal, regulatory, or contractual obligations</li>
    </ul>
    <p><strong>Will BINSERA share your information?</strong></p>
    <p>BINSERA will never sell, trade, or rent your personal identification information to others.</p>
    <p>BINSERA engages certain service providers, such as <a href="https://www.google.com/analytics">Google
            Analytics</a>, for purposes of tracking and associating Internet search and browsing behavior with our
        advertisements, and to provide functionality on the BINSERA Website. We enable these service providers to use
        tracking technologies on, or in conjunction with, the website. The use and collection of information by these
        service providers is governed by their respective privacy statements, and thus is not covered by this Policy. To
        read more about their respective policies, check out&nbsp;<a
            href="https://www.google.com/analytics/terms/us.html">Google Analytics Terms of Service</a>,&nbsp;<a
            href="https://policies.google.com/privacy">Google Privacy Policy</a>.</p>
    <p>In addition, we may share BINSERA Website usage information with these service providers, who are responsible for
        hosting the data, to identify trends and determine market focus. Information obtained through these processes
        may be combined with Personally Identifiable Information (such as name, address and phone number) in order to
        analyse our marketing efforts. We will only share Personally Identifiable Information with third party vendors,
        consultants, agents, partners, and other service providers with whom we contract to help us provide or improve
        the BINSERA Services.</p>
    <p>Job candidate information may be shared with third parties after an offer has been extended in order to verify
        credentials and to conduct a background check.</p>
    <p><strong>Choice/Opt-In</strong></p>
    <p>Automated communication is restricted to only information you have requested, such as reminders for an upcoming
        event or webinar, or delivery of a white paper or case study. Should you desire more regular communication from
        BINSERA, you can elect to subscribe to our blog, newsletter, or event notification emails. All of these features
        also include an opt-out function, so you can unsubscribe at any time.</p>
    <p><strong>How does BINSERA protect your information?</strong></p>
    <p>The security of your information and data while using the BINSERA Services is very important to us. We implement
        appropriate data collection, storage, and processing practices and security measures to protect against
        unauthorized access, alteration, disclosure, or destruction of your personal information, username, password,
        transaction information and data stored in connection with your use of the BINSERA Services.</p>
    <p>Sensitive and private data exchange between the BINSERA Website and its users is encrypted and protected through
        an SSL secured communication channel. BINSERA keeps up to date with the latest encryption protocols, and does
        not support outdated and vulnerable protocols.</p>
    <p><strong>Third-party links</strong></p>
    <p>The BINSERA Website may contain links to third-party websites. These websites are governed by their own
        respective privacy policies. We encourage you to review the privacy policies of third-party websites to
        understand their data and information collection, as well as use practices.</p>
    <p>Authorized vendors and third parties of BINSERA may have limited, incidental access to your data , as such
        vendors and subcontractors provide services and products integral to the BINSERA Software and services.</p>
    <p><strong>Your Rights</strong></p>
    <p>Consumer data rights will vary depending on your state, province, or country of residence. For example, you may
        have the right to:</p>
    <ul>
        <li>Access</li>
        <li>Correct</li>
        <li>Portability</li>
        <li>Delete</li>
        <li>Opt out of certain processing including automated decision making</li>
        <li>Opt out of sales</li>
        <li>Opt in for sensitive data processing</li>
    </ul>
    <p><strong>General Data Protection Regulation (GDPR)</strong></p>
    <p>Learn more about the&nbsp;GDPR and International Data Transefer</p>
    <p><strong>Notice for residents of Canada</strong></p>
    <p>If you are a Canadian resident, view our&nbsp;Canadian Privacy notice page to learn more about your rights.</p>
    <p><strong>Notice for&nbsp;residents of California</strong></p>
    <p>If you are a California resident, view our<u> <a
                href="https://binsera.com/CaliforniaConsumerPrivacyAct.html">California Privacy Act page </a></u>to
        learn more about your rights.</p>
    <p><strong>Contact information</strong></p>
    <p>To submit a request to exercise your rights, please submit a&nbsp;Data Request form, or call BINSERA's Contact
        number.</p>
    <p>To protect your privacy, we will take reasonable steps to help verify your identity before granting access or
        making any corrections or modifications to your information. All data rights requests related to your Personal
        Data processed in our software will be funnelled to your healthcare provider for response.</p>
    <p>If you have any questions about this Privacy Policy, our treatment of your information, our handling of your data
        rights request, or wish to appeal a denial of request, please write to us through&nbsp;<a
            href="https://binsera.com/contact.html">our contact page</a>&nbsp;or contact the BINSERA representative
        listed below.</p>

</body>

</html>