
<div class="container">
    <table class="table">
        <thead>
            <th>User Name</th>
            <th>Company Name</th>
            <th>ContactPerson </th>
            <th>Email</th>
            <th>Phone </th>
            <th>Address </th>
            <th>View No.Of Users </th>
        </thead>
        <tbody >
            
            <tr *ngFor="let item of userList" >
                <div *ngIf="item.CompanyName;then content"></div>
<ng-template #content>  <td >{{item.UserName}}</td>
    <td >{{item.CompanyName}}</td>
    <td >{{item.ContactPerson}}</td>
    <td >{{item.Email}}</td>
    <td >{{item.PhoneNumber}}</td>
    <td >{{item.Address}}</td>
    <td ><button color="primary" mat-mini-fab><mat-icon>visibility</mat-icon></button></td>

</ng-template>

              
               
            
            
            </tr>
        </tbody>
    </table>
</div>
