<div class="pb-5"> 
  
    <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
           <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
           <div class="toolbar mat-elevation-z6" >

           <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

           <mat-menu  class="ItemS" #crt="matMenu">
            <div class="ItemS" >
              <mat-icon mat-list-icon>undo</mat-icon>
              <div class="mx-2" [routerLink]="['/pharmacyDash/']"> PH Dashboard</div>
              <!-- <mat-icon>navigate_next</mat-icon> -->
            </div>
           
           <div class="ItemS "  (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">Presciptions</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
      
          <div class="ItemS "  (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">Create Invoice</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
      
          <div class="ItemS "  (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">View Invoice</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        </mat-menu>
    </div> 
            
          <!-- <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">New Appointment</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
          <div class="ItemS  " >
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">Medical Records</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
           -->
        
         

<!-- 
        </div> -->
        <!-- <div class="main">
    <div class="toolbar mat-elevation-z6" ></div> -->

    <span [ngSwitch]="showItem">
        
      <p *ngSwitchCase="'1'">
        <app-prescription-display></app-prescription-display>
      </p>
    
      <p *ngSwitchCase="'2'">
        <app-add-invoice></app-add-invoice>
      </p>
    
    
      <p *ngSwitchCase="'3'">
        <app-view-invoice></app-view-invoice>
      </p>
    



      <p  *ngSwitchDefault>

        <app-prescription-display></app-prescription-display>

    </p>





     

    </span>

        <!-- </div>
    </div> -->
</div>
