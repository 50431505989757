<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Emergency Contact</h1>
            <form [formGroup]="emgencyFrm" (ngSubmit)="formSubmit()" class="example-form">
              <div class="row">
                <div class="col-lg">
                  <mat-form-field class="example-full-width">
                    <mat-label>Emergency Contact Name</mat-label>
                    <input type="text" formControlName="EmergencyContactName" matInput >
                    <mat-error >Please enter Substance which Emergency Contact Name</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Emergency Contact Phone</mat-label>
                    <input type="text" formControlName="EmergencyContactPhone" matInput >
                    <mat-error >Please enter Emergency Contact Phone</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Emergency Contact Relationship</mat-label>
                    <input type="text" formControlName="EmergencyContactRelation" matInput >
                    <mat-error >Please enter Emergency Contact Relationship</mat-error>
                  </mat-form-field>



                 </div>
                  </div>
                  <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    </button>
                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully Created Emergency Contact
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
            </form>
           
        </div>
    </div>
</div>
