<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Safety Management
        </ng-template>
        <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
            data-target=".bd-example-modal-lg"> Print </button>
        <div class="row">
            <div class="col-md-12" *ngFor="let item of safetyList">

                <mat-card class="mat-elevation-z8 mt-3 " id="printable">
                    <h2 class="clr">Safety Management</h2>
                    <p>Nurse Name : {{item.NurseName}}</p>
                    <p class="float-right">Date : {{item.createdDate | date: 'medium' }}</p>

                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <h2> General Safety</h2>
                            <tr>
                                <th> Call Bell With in Reach : </th>

                                <td>{{item.CallBellWithinReach ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Patient Identification Band On : </th>
                                <td>{{item.PatientIdentificationBandOn ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Head Of Bed Elevation : </th>
                                <td>{{item.HeadOfBedElevation ?'Yes':'No'}}</td>

                            </tr>
                            <hr>
                            <h4 class="clr">Alarm Set and Audible</h4>
                            <tr>
                                <th> Rn Notified ? : </th>
                                <td>{{item.RnNotified ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> IV pump : </th>
                                <td>{{item.IVpump }}</td>

                            </tr>
                            <tr>
                                <th> FeedingPump : </th>
                                <td>{{item.FeedingPump ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> PCA Pump : </th>
                                <td>{{item.PcaPump ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Epidural Pump : </th>
                                <td>{{item.EpiduralPump ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Bedside Monitor : </th>
                                <td>{{item.BedsideMonitor ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Hypothermia Blanket : </th>
                                <td>{{item.HypoThermiaBlanket ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Urinary Catheter Temprature Probe : </th>
                                <td>{{item.UrinaryCatheterTempratureProbe ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Other Alarms Set and Audible : </th>
                                <td>{{item.OtherAlarmsSetandAudible ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2> Precautions</h2>
                            <tr>
                                <th> Standard : </th>
                                <td>{{item.Standard ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Contact : </th>
                                <td>{{item.Contact ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Airborne : </th>
                                <td>{{item.Airborne ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Droplet : </th>
                                <td>{{item.Droplet ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Special Contact : </th>
                                <td>{{item.SpecialContact ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Aspiration : </th>
                                <td>{{item.Aspiration ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fall Prevention : </th>
                                <td>{{item.FallPrevention ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Suicide Risk : </th>
                                <td>{{item.SuicideRisk ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Assualt Risk : </th>
                                <td>{{item.AssualtRisk ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Self Harm Risk : </th>
                                <td>{{item.SelfHarmRisk ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Elopment Risk : </th>
                                <td>{{item.ElopmentRisk ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Osa Risk : </th>
                                <td>{{item.OsaRisk ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Letex : </th>
                                <td>{{item.Letex ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Seizure : </th>
                                <td>{{item.Seizure ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Neutropenic : </th>
                                <td>{{item.Neutropenic ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> No BP/IV on Right Arm : </th>
                                <td>{{item.NoBpOrIvonRightArm ?'Correct':'Not specified'}}</td>

                            </tr>
                            <tr>
                                <th> No BP/IV on Left Arm : </th>
                                <td>{{item.NoBpOrIvonLeftArm ?'Correct':'Not specified'}}</td>

                            </tr>
                            <tr>
                                <th> Weight Bearing : </th>
                                <td>{{item.WeightBearing ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Spinal Precautions : </th>
                                <td>{{item.SpinalPrecautions ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Sternal Precautions : </th>
                                <td>{{item.SternalPrecautions ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Anterior Hip Precautions : </th>
                                <td>{{item.AnteriorHipPrecautions ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Posterior Hip Precautions : </th>
                                <td>{{item.PosteriorHipPrecautions ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Remove Harmful Objects From Immediet Area : </th>
                                <td>{{item.Bleeding ?'Removed':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2> Dietary </h2>
                            <h4>Dietary Considerations</h4>
                            <tr>
                                <th> Non Specific : </th>
                                <td>{{item.NonSpecific ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Saftey Tray : </th>
                                <td>{{item.SafteyTray ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fluid Resrtiction : </th>
                                <td>{{item.FluidResrtiction ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Calerie Count : </th>
                                <td>{{item.CalerieCount ?'Yes':'No'}}</td>

                            </tr>


                            <tr>
                                <th> Liquid / Solid Consistency : </th>
                                <td>{{item.LiquidOrSolidConsistency ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Swallowing Strategies : </th>
                                <td>{{item.SwallowingStrategies ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Caffeine Restriction : </th>
                                <td>{{item.CaffeineRestriction ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Suppliments : </th>
                                <td>{{item.Suppliments ?'Yes':'No'}}</td>

                            </tr>
                        </div>

                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <h2> Liquid/Solid Consistencies
                            </h2>
                            <tr>
                                <th> Normal : </th>
                                <td>{{item.Normal ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L3 Lquidized Diet : </th>
                                <td>{{item.L3LquidizedDiet ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L0 Thin Liquids : </th>
                                <td>{{item.L0ThinLiquids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L1 Slightly Thick Liquids : </th>
                                <td>{{item.L1SlightlyThickLiquids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L2 Mildly Thick Liquids : </th>
                                <td>{{item.L2MildlyThickLiquids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L3 Moderately Thick Liquids : </th>
                                <td>{{item.L3ModeratelyThickLiquids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L4 Pureed : </th>
                                <td>{{item.L4Pureed ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L5 Minced and Moist : </th>
                                <td>{{item.L5MincedandMoist ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L6 Softand Bite Size Piece : </th>
                                <td>{{item.L6SoftandBiteSizePiece ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> L7 Easy To Chew : </th>
                                <td>{{item.L7EasyToChew ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> No Swallowing Strategy Necessary : </th>
                                <td>{{item.NoSwallowingStrategyNecessary ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Alternate Liquids and Solids : </th>
                                <td>{{item.AlternateLiquidsandSolids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Chin Tuck Wit Liquids : </th>
                                <td>{{item.ChinTuckWitLiquids ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Frequesnt Cough Reswallow : </th>
                                <td>{{item.FrequesntCoughReswallow ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> No Straws Small SipsbyCup : </th>
                                <td>{{item.NoStrawsSmallSipsbyCup ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Small Sips by Spoon : </th>
                                <td>{{item.SmallSipsbySpoon ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> No Liquid : </th>
                                <td>{{item.NoLiquid ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2> Observations</h2>
                            <h4> Med-Surge Observation </h4>
                            <tr>
                                <th> Frequent Med-Surge Observation : </th>
                                <td>{{item.FrequentMedSurgeObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Med-Surge Observation : </th>
                                <td>{{item.MedSurgeObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Video Monitoring MedSurge Observation : </th>
                                <td>{{item.VideoMonitoringMedSurgeObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> One to One Med-Surge Observation : </th>
                                <td>{{item.OnetoOneMedSurgeObservation ?'Yes':'No'}}</td>

                            </tr>


                            <hr>

                            <h4>Psych/Detox Unit Observation Status</h4>



                            <tr>
                                <th> Every 5 Minutes Psych Observation : </th>
                                <td>{{item.Every5MinutesPsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Every 15 Minutes Psych Observation : </th>
                                <td>{{item.Every15MinutesPsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Every 30 Minutes Psych Observation : </th>
                                <td>{{item.Every30MinutesPsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Every Hour Psych Observation : </th>
                                <td>{{item.EveryHourPsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> One to One Psych Observation : </th>
                                <td>{{item.OnetoOnePsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Continuous Psych Observation : </th>
                                <td>{{item.ContinuousPsychObservation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Supportive Psych Observation : </th>
                                <td>{{item.SupportivePsychObservation ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Nurse Name : </th>
                                <td>{{item?.NurseName}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2> </h2>
                        </div>

                    </div>
                    <mat-card-content>


                    </mat-card-content>
                    <button class="float-right" [routerLink]="['/editSafetyManagement/',item.GeneralSafteyId]"
                        color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>

                </mat-card>
            </div>
        </div>
        <hr>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Safety Management
        </ng-template>

        <app-add-safteymanagement></app-add-safteymanagement>
    </mat-tab>


</mat-tab-group>

<!-- <mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                General Safety
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Precautions
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>

    <hr>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Dietary
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>



    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Liquid/Solid Consistencies

            </mat-panel-title>

        </mat-expansion-panel-header>

        <hr>

    </mat-expansion-panel>

    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Observations


            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>

</mat-accordion> -->