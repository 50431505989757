import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralConsentForTreatment } from 'src/app/Models/generalconsentfortreatment.model';
import { GeneralConsentForTreatmentService } from 'src/app/services/general-consent-for-treatment.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-general-consent',
  templateUrl: './patient-view-general-consent.component.html',
  styleUrls: ['./patient-view-general-consent.component.css'],
})
export class PatientViewGeneralConsentComponent implements OnInit {
  id = 0;
  GeneralConsentForTreatmentList: GeneralConsentForTreatment[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private generalConsentForTreatmentService: GeneralConsentForTreatmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.generalConsent(this.id);
  }

  generalConsent(id) {
    this.patientService.GetGeneralConsentForTreatment(id).subscribe((res) => {
      this.GeneralConsentForTreatmentList = res.GeneralConsentForTreatments;
    });
  }

  print() {
    window.print();
  }
}
