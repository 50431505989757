import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Invoice } from 'src/app/Models/invoice.model';
import { NurseDailyPatient } from 'src/app/Models/nursedailypatient.model';
import { Patient } from 'src/app/Models/patient.model';
import { DepartmentService } from 'src/app/services/department.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { NursedailypatientsService } from 'src/app/services/nursedailypatients.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-patient-has-unpaid-amount',
  templateUrl: './patient-has-unpaid-amount.component.html',
  styleUrls: ['./patient-has-unpaid-amount.component.css'],
})
export class PatientHasUnpaidAmountComponent implements OnInit {
  @Input() isNurse: boolean;
  @Output() selectedPatientIdsEmitter = new EventEmitter<any>();
  @Output() navigation = new EventEmitter<any>();
  selectedPatientIds: string[] = [];
  nursePatient: NurseDailyPatient[] = [];
  displayedColumns: string[] = [
    'PatientName',
    'BalanceAmount',
    'Total',
    'Status',
    'Date',
    'View',
  ];
  id = 0;
  patientList: Patient[] = [];
  filteredList;
  dptList: Department;

  dataSource: MatTableDataSource<Invoice>;

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  patientData;
  nurseId = 0;
  isSuccess = false;
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private userService: UserService,
    private nursePatientService: NursedailypatientsService,
    private dialog: MatDialog,
    private router: Router,
    private hosptalService: HospitalService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    // this.getDptPatients(this.id)

    this.getPatients(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatients(id) {
    this.hosptalService.GetInvoices(id).subscribe((res) => {
      console.log('invoice', res.Invoices);

      let x = res.Invoices.filter((y) => y.Status === 'UnPaid');
      this.dataSource = new MatTableDataSource(x);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewContact(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
}
