<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Hand Off Note</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Select Ward</mat-label>


                            <mat-select formControlName="WardId">

                                <mat-option *ngFor="let wrd of wardList"
                                    [value]="wrd.WardId">{{wrd.WardName}}</mat-option>

                            </mat-select>
                            <mat-error>Please select Patient Ward</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-label>Patient Name</mat-label>

                        <mat-form-field class="example-full-width">
                            <input type="text" formControlName="PatientName" matInput>
                            <mat-error>Please enter PatientName</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-label>Patient D.O.B</mat-label>

                        <mat-form-field class="example-full-width">
                            <input type="text" formControlName="PatientDOB" matInput>
                            <mat-error>Please enter Patient Date Of Birth</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-11">

                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Descriptive reason for Order</mat-label>

                            <textarea matInput (input)="onSubjectiveChange($event)" formControlName="HandOffNote"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140" [value]="service.message1" matInput
                                (blur)="onSubjectiveBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startService()"></button>
                        </mat-form-field>
                    </div>
                    <button type="button" color="accent" (click)="stopService()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>



                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>