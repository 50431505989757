import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicineService } from '../../services/medicine.service';
import { Medicine } from '../../Models/medicine.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-medicine',
  templateUrl: './view-medicine.component.html',
  styleUrls: ['./view-medicine.component.css'],
})
export class ViewMedicineComponent implements OnInit {
  displayedColumns: string[] = [
    'DrugName',
    'BrandName',
    'Category',
    'Dosage',
    'DrugCode',
    'Instruction',
    'Description',
    'Price',
    'Status',
    'Delete',
  ];
  medicineList: Medicine[] = [];
  dataSource: MatTableDataSource<any>;

  selectedFile: File;

  constructor(private medicineService: MedicineService) {}

  ngOnInit(): void {
    this.getMedicine();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      // this.medicineList = res;

      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getMedicine();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  uploadFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const data = JSON.parse(fileContent as string);

        // Validate the data structure
        if (!Array.isArray(data.drugs)) {
          alert('Invalid data structure');
        }
        data.drugs.forEach((drug) => {
          if (typeof drug.DrugName !== 'string') {
            alert('Invalid data structure');
          }
          // Add more checks as necessary...
        });

        this.medicineService.UploadJsonDrugData(data).subscribe(
          (res) => {
            alert('Upload With success');
            this.getMedicine();
          },
          (err) => {
            alert('Error uploading the data');
          }
        );
        // this.http.post('https://your-api-url.com/upload', data)
        // .subscribe(
        //     response => console.log(response),
        //     error => console.log(error)
        // );
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };
    fileReader.onerror = (error) => console.log(error);
  }

  deleteDrug(MedicineId) {
    this.medicineService.deleteMedicine(MedicineId).subscribe(
      (res) => {
        alert('Medicine Delete with success');
        this.getMedicine();
      },
      (err) => {
        alert('Unsuccessful');
      }
    );
  }
}
