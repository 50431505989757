import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NursingCarePlan } from 'src/app/Models/nurisngcareplan.model';
import { Patient } from 'src/app/Models/patient.model';
import { NursingCarePlanService } from 'src/app/services/nursing-care-plan.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-nursing-plan',
  templateUrl: './view-nursing-plan.component.html',
  styleUrls: ['./view-nursing-plan.component.css'],
})
export class ViewNursingPlanComponent implements OnInit {
  @ViewChild('ProcedureOrder') ProcedureOrder!: ElementRef;

  displayedColumns: string[] = ['Date', 'View', 'Update', 'Delete'];

  id = 0;
  nursingCarePlan: NursingCarePlan[] = [];
  dataSource: MatTableDataSource<any>;
  procedureDocument: string = '';
  patient: Patient;
  email = new FormControl('');
  Problem: string;
  Assessment: string;
  Intervention: string;
  Plan: string;
  Medication: string;
  Rationale: string;
  Evaluation: string;
  constructor(
    private patientService: PatientService,
    private nursingCarePlanService: NursingCarePlanService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetNursingCarePlan(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetNursingCarePlan(id) {
    this.patientService.getNursingCarePlan(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.NursingCarePlans.sort(function (x, y) {
          return y.NursingCarePlanId - x.NursingCarePlanId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.nursingCarePlanService.deleteNursingCarePlan(id).subscribe((res) => {
      alert('deleted');
      this.GetNursingCarePlan(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewLabReport(report: NursingCarePlan): void {
    // console.log('report: ', report);
    this.Problem = report.Problem;
    this.Assessment = report.Assessment;
    this.Intervention = report.Intervention;
    this.Rationale = report.Rationale;
    this.Evaluation = report.Evaluation;
  }
}
