import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-import',
  templateUrl: './patient-import.component.html',
  styleUrls: ['./patient-import.component.css']
})
export class PatientImportComponent {

}
