<mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">contact_emergency</mat-icon>
            Physical Therapy Doctors
        </ng-template>


        <div class="container mt-3">

            <div class="row">
                <div class="col-lg-6 "><span class="float-right"></span></div>
                <div class="col-lg-6 ">
                    <mat-form-field appearance="fill" class="example-full-width">
                        <mat-label>Search by Name</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

                    </mat-form-field>
                </div>
            </div>


            <div class="container">
                <!-- style="width: 18rem;" -->
                <div class="row">
                    <div class="col-lg-4" *ngFor="let item of filteredList">
                        <div class="card" style="width: 18rem;">
                            <ul class="list-group list-group-flush">
                                <img src="{{item.DoctorImage}}" class="doctor-image" alt="">
                                <li class="list-group-item">Qulification: {{item.DoctorName}}</li>
                                <li class="list-group-item">Qulification: {{item.DoctorQualification}}</li>

                                <li class="list-group-item">Doctor DOB: {{item.Dob}}</li>
                                <li class="list-group-item">Phone: {{item.Phone}}</li>
                                <li class="list-group-item">Email: {{item.Email}}</li>
                                <li class="list-group-item">National Id: {{item.NationalId}}</li>
                                <li class="list-group-item">Status: {{item.Status? 'Active' : 'Blocked'}}</li>
                                <li class="list-group-item">Doctor Address: {{item.Address}}</li>
                            </ul>
                        </div>
                        <button mat-mini-fab (click)="delete(item.PhysicalTherapyDoctorId)"> <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

            </div>

        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Physical Therapy Doctor
        </ng-template>
        <app-add-physical-therapy-doctor></app-add-physical-therapy-doctor>

    </mat-tab>
</mat-tab-group>