<div class="container ">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Update Other Task</h1>
      <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

        <mat-form-field class="example-full-width">
          <mat-label>Start Date</mat-label>
          <input type="date" formControlName="StartDate" matInput>
          <mat-error>Please enter TaskName</mat-error>

        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label> Start Time </mat-label>
          <input [ngxTimepicker]="picker" matInput formControlName="StartTime">
          <ngx-material-timepicker #picker></ngx-material-timepicker>
          <mat-error>Please enter Available Start Time</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label> End Time </mat-label>
          <input [ngxTimepicker]="picker2" matInput formControlName="EndTime">
          <ngx-material-timepicker #picker2></ngx-material-timepicker>
          <mat-error>Please enter Available TimeSlots</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>TaskName</mat-label>
          <input type="text" formControlName="TaskName" matInput>
          <mat-error>Please enter TaskName</mat-error>

        </mat-form-field>

        <mat-form-field appearance="fill" class="example-full-width">
          <mat-label>Description </mat-label>

          <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"></textarea>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Status</mat-label>
          <mat-select formControlName="Status">

            <mat-option *ngFor="let Status of statustList" [value]="Status">{{Status}}</mat-option>
          </mat-select>
          <mat-error>Select a Subject</mat-error>
        </mat-form-field>


        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>
</div>