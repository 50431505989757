<div class="container ">
    <h2 class="mt-4">IN PATIENT MRI SCREENING</h2>

    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <p>Warning: Certain implants,devices, or objects may be hazardous to you or may interfere with the MR
                    procedure.Do not enter MR system room
                    or MR environment if you have any questions or concerns regarding an implant,device, or object.
                    Consult the MRI Technologist before entering the MR scan room.
                    The magnet is always on.
                </p>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="HaveYouHadPriorSurgery">
                            Have You Had Prior Surgery?
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Surgery</mat-label>
                            <input type="text" formControlName="DateOfSurgery" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Type Of Surgery</mat-label>
                            <input type="text" formControlName="TypeOfSurgery" matInput>
                        </mat-form-field>
                    </div>


                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="HaveYouEverHadMRIbefore">
                            Have You Ever Had MRI before?
                        </mat-checkbox>
                    </div>

                    <div class="col-md-6">
                        <mat-checkbox color="primary"
                            formControlName="HaveYouExperiencedAnyProblemsRelatedToPreviuosMRI">
                            Have You Experienced Any Problems Related To Previuos MRI?
                        </mat-checkbox>
                    </div>


                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>When Was Last MRI</mat-label>
                            <input type="text" formControlName="WhenWasLastMRI" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Describe Problems Related To Previuos MRI</mat-label>
                            <input type="text" formControlName="DescribeProblemsRelatedToPreviuosMRI" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <hr>

                <div class="row" [formGroup]="Frm">

                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="HaveYouBeenDiagnosedWithCancer">
                            Have You Been Diagnosed With Cancer
                        </mat-checkbox>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>What Type Of Cancer</mat-label>
                            <input type="text" formControlName="WhatTypeOfCancer" matInput>
                        </mat-form-field>
                    </div>


                </div>
                <hr>
                <div class="row">

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="DoYouHaveHistoryOfRenalDisease">
                            Do You Have History Of Renal Disease
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="IsthereAnyPossibilityThatYouArepregnant">
                            Is there Any Possibility That You Arepregnant
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="BreastFeeding">
                            Breast Feeding
                        </mat-checkbox>
                    </div>



                </div>
                <hr>

                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="AnySurgeryInThePastSixWeeks">
                            Any Surgery In The Past Six Weeks
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="BrainSurgery">
                            BrainSurgery
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="CardiacPacemaker">
                            CardiacPacemaker
                        </mat-checkbox>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="MetalInYourEyes">
                            Metal In Your Eyes
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="AneurysmClip">
                            Aneurysm Clip
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="CardiacStent">
                            Cardiac Stent
                        </mat-checkbox>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="WireOrStaples">
                            Wire Or Staples
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="HarringtonRod">
                            Harrington Rod
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="ProstheticLimbs">
                            Prosthetic Limbs
                        </mat-checkbox>
                    </div>
                </div>
                <hr>
                <div class="row">

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="InsulinPump">
                            Insulin Pump
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="ElectronicImplant">
                            Electronic Implant
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="HeartValveReplacement">
                            Heart Valve Replacement
                        </mat-checkbox>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="MetalRodsPlatesPins">
                            Metal Rods Plates Pins
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="NeuroStimulatorOrTens">
                            Neuro Stimulator Or Tens
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="VascularPorterOrCatheter">
                            Vascular Porter Or Catheter
                        </mat-checkbox>
                    </div>
                </div>
                <hr>
                <!-- //A=Assessmen -->
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="StentShuntFilterCoil">
                            Stent Shunt Filter Coil
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="ImplantedDrugPump">
                            Implanted Drug Pump
                        </mat-checkbox>
                    </div>

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>When Was Stent Placed</mat-label>
                            <input type="text" formControlName="WhenWasStentPlaced" matInput>
                        </mat-form-field>
                    </div>





                </div>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="ForiegnObjectOrShrapnel">
                            Foriegn Object Or Shrapnel
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="EyeProsthesisOrImplant">
                            Eye Prosthesis Or Implant
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="BodyPiercingOrJwellary">
                            Body Piercing Or Jwellary
                        </mat-checkbox>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="HearingAids">
                            Hearing Aids
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="MagneticallyActivatedImplant">
                            Magnetically Activated Implant
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="MedicationPatch">
                            Medication Patch
                        </mat-checkbox>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="SwanganzOrThermodilutionCath">
                            Swanganz OrT hermodilutio nCath
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="PenelieImplant">
                            Penelie Implant
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="CochlearImaplant">
                            Cochlear Imaplant
                        </mat-checkbox>
                    </div>
                </div>
                <hr>
                <!-- R=Recomendations -->



                <div class="row" [formGroup]="Frm">


                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="BoneFusionStimulator">
                            BoneFusion Stimulator
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="TattooPermanantMakeUp">
                            Tattoo Permanant MakeUp
                        </mat-checkbox>
                    </div>






                </div>
                <hr>
                <h2>Nursing Section</h2>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="Claustrophobic">
                            Claustrophobic
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="CanPatientLieStillForExam">
                            Can Patient Lie Still For Exam
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="IsPatientOnEvent">
                            Is Patient On Event
                        </mat-checkbox>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Signature</mat-label>
                            <input type="text" formControlName="PatientSignature" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date</mat-label>
                            <input type="date" formControlName="Date" matInput>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">

                        <mat-form-field class="example-full-width">
                            <mat-label> Time </mat-label>
                            <input [ngxTimepicker]="picker2" matInput formControlName="Time">
                            <ngx-material-timepicker #picker2></ngx-material-timepicker>
                            <mat-error>Please enter Time</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Representative</mat-label>
                            <input type="text" formControlName="PatientRepresentative" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>