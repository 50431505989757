import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Department } from 'src/app/Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-physical-therapy-doctor',
  templateUrl: './add-physical-therapy-doctor.component.html',
  styleUrls: ['./add-physical-therapy-doctor.component.css'],
})
export class AddPhysicalTherapyDoctorComponent implements OnInit {
  id = 0;
  isSuccess = false;
  dptList: Department[];
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private physicalTherapyDoctorService: PhysicalTherapyDoctorService,
    private userService: UserService,
    private router: Router,
    private dptService: DepartmentService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDepartments();
    this.getDepartments();
    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: [''],
    DoctorName: ['', Validators.required],
    DoctorQualification: ['', Validators.required],
    DoctorSpecialization: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
  });

  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }
  get DoctorQualification() {
    return this.doctorFrm.get('DoctorQualification');
  }

  get DoctorSpecialization() {
    return this.doctorFrm.get('DoctorSpecialization');
  }

  get DoctorName() {
    return this.doctorFrm.get('DoctorName');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.doctorFrm.get('NationalId');
  }

  get Dob() {
    return this.doctorFrm.get('Dob');
  }
  get Address() {
    return this.doctorFrm.get('Address');
  }

  get Status() {
    return this.doctorFrm.get('Status');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }

  getDepartments() {
    this.dptService.getDepartments().subscribe((res) => {
      this.dptList = res;
    });
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    // console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let doctorSpecialization = this.doctorFrm.value['DoctorSpecialization'];
    let doctorQualification = this.doctorFrm.value['DoctorQualification'];
    let doctorName = this.doctorFrm.value['DoctorName'];
    let dob = this.doctorFrm.value['Dob'];
    let email = this.doctorFrm.value['Email'];
    let phoneNumber =
      this.doctorFrm.value['CountryCode'] + this.doctorFrm.value['PhoneNumber'];
    let nationalId = this.doctorFrm.value['NationalId'];
    let address = this.doctorFrm.value['Address'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];
    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerPTDoctor(userBody).subscribe((res) => {
      let body = {
        PhysicalTherapyDoctorId: res.Id,
        DoctorName: doctorName,
        Dob: dob,
        Email: email,
        PhoneNumber: phoneNumber,
        NationalId: nationalId,
        Address: address,
        Status: status,
        Password: password,
        DepartmentId: departmentId,
        DoctorImage: this.sysImage,
        DepartmentName: dptName,
        DoctorSpecialization: doctorSpecialization,
        DoctorQualification: doctorQualification,
      };

      this.physicalTherapyDoctorService
        .registerPhysicalTherapyDoctor(body)
        .subscribe((res) => {
          this.isSuccess = true;
          this.doctorFrm.reset();
        });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
