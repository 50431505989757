<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">

    <div class="d-flex justify-content-end m-2">
        <form (ngSubmit)="uploadFile()">
            <input type="file" class="formControl" (change)=" onFileSelected($event)" accept="application/json">
            <button type="submit btn btn-warning">Upload Pharmacys</button>
        </form>

    </div>

    <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="PharmacyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyName}} </td>
        </ng-container>



        <ng-container matColumnDef="PhoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PhoneNumber </th>
            <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let row"> {{row.Email}} </td>
        </ng-container>

        <ng-container matColumnDef="PharmacyAddress">
            <th class="message" mat-header-cell *matHeaderCellDef mat-sort-header> Pharmacy Address </th>
            <td class="message mt-4" mat-cell *matCellDef="let row"> {{row.PharmacyAddress}} </td>
        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status?'Current':'Closed Down'}} </td>
        </ng-container>
        <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef> Update Pharmacy</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/UpdatePharmacyList/',row.PharmacyId]" mat-mini-fab
                    color="success">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
            <td mat-cell *matCellDef="let row">
                <button class="btn btn-danger" (click)="deletePharmacy(row.PharmacyId)">
                    Delete
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>