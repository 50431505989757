<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of narcoticList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Narcotic (Opioid)
                    Medical Policy Agreement</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <strong>We have a primary goal of providing excellence in care to our patients.</strong>
                    <p>The best practice in our facility recognizes that patients undergoing operative procedures may
                        require narcotic pain medications. Your physician may prescribe pain medication during your
                        post-operative period with a limited supply and in accordance with The Texas Medical Board
                        regulations.</p>
                    <p>It is important to understand the physicians at _______________________DO NOT prescribe pain
                        medication pre-operatively (before you have surgery).</p>

                    <p>The physicians at ___________________________ do not manage chronic pain, if you need chronic
                        pain
                        management we can provide you a referral to a pain management specialist.</p>

                    <p>To ensure that we, as the provider, and you as the patient, understand how our practice will
                        ensure
                        high quality and safety in prescribing narcotics you will need to adhere and agree to the policy
                        agreement outlined below.</p>


                    <p>I, understand that I may receive narcotic medication from _____________ to treat my
                        post-operative
                        pain.</p>

                    <ul>
                        <li> If I am receiving opioid pain medication from a Neurosurgical Group of Texas physician I
                            will
                            not
                            seek narcotic pain medications from any other provider.</li>
                        <li> I understand that if I am receiving long term pain medicine from a pain management
                            physician, I
                            will make arrangements for post-operative pain medication with that physician. I will not
                            receive
                            pain medications post operatively from a Neurosurgical Group of Texas physician.</li>
                        <li> I will not take prescribed narcotics in large amounts or more frequently than prescribed.
                        </li>
                        <li> I will not buy and use additional narcotics or borrow/use narcotics prescribed to another
                            individual.</li>
                        <li> I will not use any illegal or street drugs (ex. Marijuana, Cocaine) while under the care of
                            the
                            Neurosurgical Group of Texas physician.</li>
                        <li> I will not purchase or use narcotics which may be available in another country or through
                            mail
                            order.</li>
                        <li> I understand that Neurosurgical Group of Texas will not provide refills on narcotics. A new
                            prescription may be provided at the first post-operative visit. Any ongoing need for
                            narcotic
                            pain
                            medication beyond that point will be referred to a pain management specialist.</li>
                        <li> I will not drink alcohol while on narcotics.</li>
                        <li> I understand it is illegal to refill narcotic medication by phone.</li>
                        <li> If I have left over narcotic pain medication, I will dispose of it. I will not donate or
                            sell
                            extra narcotics to another individual.</li>
                        <li> Patients having greater than expected amount of post-operative pain will be seen promptly
                            in
                            clinic.</li>
                        <li> I understand that if my pain acute post-operative pain medication requirements are beyond a
                            neurosurgeon’s scope of practice to manage I will be referred to a pain a management
                            physician.
                            I
                            understand it is my responsibility to follow up and schedule an appointment.</li>
                    </ul>



                    <strong>I UNDERSTAND AND AGREE TO THE CONDTIONS OF CARE DESCRIBED ABOVE AND WILL COMPLY WITH THEM.
                        FAILURE TO COMPLY WITH ANY OF THE TERMS OF THIS AGREEMENT MAY RESULT IN IMMEDIATE TERMINATION OF
                        SERVICE.</strong>


                    <tr>
                        <th>This consent was signed by: </th>
                        <td>{{item?.AuthorisedSignatoryName}}</td>

                    </tr>

                    <tr>
                        <th> Signature: </th>
                        <td><img src="{{item?.Signature}}" alt=""></td>

                    </tr>

                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>