import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { OtherTask } from 'src/app/Models/othertask.model';
import { OtherTaskService } from 'src/app/services/other-task.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-other-task',
  templateUrl: './view-other-task.component.html',
  styleUrls: ['./view-other-task.component.css'],
})
export class ViewOtherTaskComponent implements OnInit {
  displayedColumns: string[] = [
    'createdDate',
    'TaskName',
    'Description',
    'StartDate',
    'StartTime',
    'EndTime',
    'Status',
    'Update',
    'Delete',
  ];

  id = 0;
  OtherTakList: OtherTask[];
  dataSource: MatTableDataSource<any>;
  date: string;
  statustList: string[] = ['Progress', 'Completed'];
  Frm: FormGroup;
  TaskId: any;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private otherTaskService: OtherTaskService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getOtherTasks(this.id);
    this.date = this.datePipe.transform(new Date(), 'MM/dd/YYYY');

    this.Frm = this.fb.group({
      Status: [''],
      OtherTaskId: [''],
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getOtherTasks(id) {
    this.patientService.getotherTasks(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.OtherTasks.sort(function (x, y) {
          return y.OtherTaskId - x.OtherTaskId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  get Status() {
    return this.Frm.get('Status');
  }

  get OtherTaskId() {
    return this.Frm.get('OtherTaskId');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.otherTaskService.deleteOtherTask(id).subscribe((res) => {
      alert('deleted');
      this.getOtherTasks(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getOtherTasks(this.id);
    }
  }
}
