<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Create Discharge Summary</h1>
      <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Date Of Admission</mat-label>
              <input type="date" formControlName="DateOfAdmission" matInput>
              <mat-error>Please enter Date Of Admission</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Date Of Discharge</mat-label>
              <input type="date" formControlName="DateOfDischarge" matInput>
              <mat-error>Please enter Date Of Discharge</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Reffered By</mat-label>
              <input type="text" formControlName="RefferedBy" matInput>
              <mat-error>Please enter Reffered By</mat-error>

            </mat-form-field>
          </div>
        </div>



        <div class="row">


          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Complaints </mat-label>

              <textarea matInput formControlName="Complaints" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Diagnosis </mat-label>

              <textarea matInput formControlName="Diagnosis" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Treatments </mat-label>

              <textarea matInput formControlName="Treatments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


            </mat-form-field>
          </div>



          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>FutureTreatmentPlan </mat-label>

              <textarea matInput formControlName="FutureTreatmentPlan" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


            </mat-form-field>



          </div>




        </div>


        <div class="" formArrayName="DischargeMedications">
          <h2>Dicharge Medications </h2>
          <div class="row" *ngFor="let item of DischargeMedications.controls; let i=index">
            <ng-container [formGroup]="item">
              <div class="col-lg-5">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <mat-select formControlName="DrugName">
                    <!-- <mat-option>
                      <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                        (filtered)="flteredmedicineList = $event">
                      </lib-mat-select-search>
                    </mat-option> -->

                    <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                      <mat-option *cdkVirtualFor="let entry of medicineList" [value]="entry.DrugName">
                        {{entry.DrugName}}
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                  <!-- <input type="text" formControlName="DrugName" matInput > -->
                  <mat-error>Drug Name required</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Dosage</mat-label>
                  <mat-select formControlName="Dosage">

                    <mat-option *ngFor="let ds of medicineList" [value]="ds.Dosage">{{ds.Dosage}}</mat-option>

                  </mat-select>
                  <mat-error>Please Select Dosage</mat-error>

                </mat-form-field>
              </div> -->

              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Quantity</mat-label>
                  <input type="text" formControlName="Quantity" matInput>
                  <mat-error>Please enterQuantity</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Frequency</mat-label>
                  <input type="text" formControlName="Frequency" matInput>
                  <mat-error>Please enter Frequency</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Refill Duration</mat-label>
                  <input type="text" formControlName="Duration" matInput>
                  <mat-error>Please enter Duration</mat-error>

                </mat-form-field>
              </div>
              <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>

              <button type="button" class="ml-3" mat-mini-fab
                (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>

            </ng-container>
          </div>



        </div>
        <h2>Attended Doctors</h2>

        <div class="" formArrayName="AttendedDoctors" *ngFor="let doctor of AttendedDoctors.controls; let i=index">
          <div class="row">
            <ng-container [formGroup]="doctor">
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Doctor Name</mat-label>
                  <mat-select formControlName="DoctorName">

                    <mat-option *ngFor="let dr of doctorList" [value]="dr.DoctorName">{{dr.DoctorName}}</mat-option>

                  </mat-select>
                  <mat-error>Please Select Doctor Name</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <!-- <mat-form-field class="example-full-width">

                  <mat-label>Doctor Speciality</mat-label>
                  <mat-select formControlName="DoctorSpeciality">

                    <mat-option *ngFor="let sp of doctorSpeciality" [value]="sp">{{sp}}</mat-option>

                  </mat-select>
                </mat-form-field> -->
                <mat-form-field class="example-full-width">
                  <mat-label>Doctor Specialization</mat-label>
                  <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
                  <mat-select formControlName="DoctorSpeciality">
                    <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorSpecialization">{{
                      doctor.DoctorSpecialization }}</mat-option>
                  </mat-select>
                  <mat-error>Select a Doctor specialization</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Department</mat-label>
                  <mat-select formControlName="DepartmentName">

                    <mat-option *ngFor="let ds of dpartmentList"
                      [value]="ds.DepartmentName">{{ds.DepartmentName}}</mat-option>

                  </mat-select>
                  <mat-error>Please Select Department Name</mat-error>

                </mat-form-field>
              </div>



              <button type="button" mat-mini-fab color="primary"
                (click)="addAttendedDoctors()"><mat-icon>add</mat-icon></button>
              <button type="button" class="ml-3" mat-mini-fab
                (click)="removeAttendedDoctors(i)"><mat-icon>remove</mat-icon></button>

            </ng-container>
          </div>




        </div>

        <div class="row">
          <div class="col-lg-12">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Description </mat-label>

              <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>


            </mat-form-field>



            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Signature/Initial</mat-label>
                <input type="text" matInput formControlName="Signature">
              </mat-form-field>
            </div>

          </div>




        </div>













        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Discharge-Summary
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>