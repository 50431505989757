import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LabOrderSubject } from 'src/app/Models/labordersubject.model';
import { LabOrderSubjectService } from 'src/app/services/lab-order-subject.service';

@Component({
  selector: 'app-add-lab-order-subject',
  templateUrl: './add-lab-order-subject.component.html',
  styleUrls: ['./add-lab-order-subject.component.css']
})
export class AddLabOrderSubjectComponent implements OnInit {

  subjectList:LabOrderSubject[]=[];
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private labOrderSubjectService: LabOrderSubjectService) { }

  ngOnInit(): void {
    this.getSubjects();
  }


  subFrm = this.fb.group({
    Subject:['',Validators.required],
    
  })


  getSubject(){
  return this.subFrm.get('Subject')
  }




  getSubjects(){
    this.labOrderSubjectService.getLabOrderSubjects().subscribe(res=>{
      this.subjectList=res;
    })
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getSubject();
      
    }
  }
  formSubmit(){
    let subject = this.subFrm.value['Subject']

let body={
  "Subject":subject,
}

this.labOrderSubjectService.registerLabOrderSubject(body).subscribe(res=>{

 this.subjectList.push(res);
 this.isSuccess=true
 this.subFrm.reset();

})

  }

  deleteSubject(id) {
    this.labOrderSubjectService.deleteLabOrderSubject(id).subscribe(res => {
    this.getSubjects()



    })
  }
}
