import { Component, OnInit } from '@angular/core';
import { getMatIconNameNotFoundError } from '@angular/material/icon';
import { DoctornoteService } from '../../services/doctornote.service';
import { DoctorNote } from '../../Models/doctornote.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import * as moment from 'moment';
@Component({
  selector: 'app-vew-doctor-note',
  templateUrl: './vew-doctor-note.component.html',
  styleUrls: ['./vew-doctor-note.component.css'],
})
export class VewDoctorNoteComponent implements OnInit {
  loading = true;
  id = 0;
  patient: Patient;
  doctorNoteList: DoctorNote[] = [];
  isAdmitted = false;
  filteredList;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private doctornoteService: DoctornoteService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getdoctorNotes(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.DoctorNotes.sort(function (x, y) {
        return y.DoctorNoteId - x.DoctorNoteId;
      });

      this.doctorNoteList = res.DoctorNotes;
      this.loading = false;
      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });
  }

  // myDate = moment(someDate).format('MM/DD/YYYY HH:mm');
  getNotes() {}
  delete(id) {
    this.doctornoteService.deleteDoctorNote(id).subscribe((res) => {
      this.filteredList = this.doctorNoteList.filter(
        (item) => item.DoctorNoteId != id
      );
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.doctorNoteList.filter((x) => x.createdDate);
    }
  }
}
