<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Photographic Consent</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <hr>
                <mat-label></mat-label>
                <mat-radio-group formControlName="IAuthorizetoUseDisclosure">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <strong>I AUTHORIZE TO THE USE AND DISCLOSURE LISTED BELOW:</strong>
                <p>I authorize {{hospital.HospitalName}} the use and disclosure of photographic, video images, and/or
                    testimonials (i.e. picture
                    with the physician, thank you cards, google reviews, etc…) for marketing purposes .</p>
                <p> I understand that the image(s) or testimonials I’ve authorized for disclosure may be seen by members
                    of general public.</p>
                <p>I authorize {{hospital.HospitalName}} to take photographs and/or videos, or to allow
                    third parties to take photographs and/ or videos.</p>
                <p> I authorize {{hospital.HospitalName}} the use and disclosure of photographic, video images, and/or
                    testimonials or the
                    following uses:</p>
                <h2>For Public/Marketing Relations Purposes</h2>
                <mat-label>On {{hospital.HospitalName}} ,physician’s website or internet sites
                    Including but not limited to social media platforms such as
                    Facebook and Instagram.</mat-label>
                <mat-radio-group formControlName="Website">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-label>In {{hospital.HospitalName}} publications, brochures, office or marketing
                    Materials.
                </mat-label>
                <mat-radio-group formControlName="Publications">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-label>In the public media, such as newspapers, magazines, on the
                    Internet, and on television</mat-label>
                <mat-radio-group formControlName="Media">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-label>In presentations, publications, brochures, advertisements, or
                    Articles by agencies hired and contracted by the practice</mat-label>
                <mat-radio-group formControlName="Presentations">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <mat-label>I consent {{hospital.HospitalName}} to the use of my name. I understand that I may be
                    identified by name in printed,
                    internet, or broadcast information
                    that might accompany the photo or video image of me.</mat-label>
                <mat-radio-group formControlName="ConsentToUseMyName">
                    <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
                    <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
                </mat-radio-group>
                <div class="row">

                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>This consent was signed by(Patient/Legal Representative Print Name):</mat-label>
                            <input type="text" formControlName="AuthorisedSignatoryName" matInput>
                            <mat-error>Please enter Patient/Legal Representative Print Name</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field class="example-full-width">
                <mat-label>Signature:</mat-label>
                <input type="text" formControlName="Signauture" matInput>
                <mat-error>Please enter Description</mat-error>
              </mat-form-field> -->


                    </div>

                    <div class="container mt-5">
                        <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                            (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                            height="200">
                        </canvas>
                        <button type="button" class="ml-3" color="primary" mat-raised-button
                            (click)="saveSignature($event)">Save</button>

                        <button type="button" class="ml-3" color="accent" mat-raised-button
                            (click)="clearSignature($event)">Clear</button>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>