import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { LabManager } from '../Models/labmanager.model';

@Injectable({
  providedIn: 'root'
})
export class LabmanagerService {

 
  url = environment.Url+'LabManagers';

  header = new HttpHeaders({

    'Authorization': 'token' + localStorage.getItem('token')

  })

  constructor(private http: HttpClient) { }




  registerLabManager(body){
    return this.http.post<LabManager>(this.url,body).pipe(
     catchError(this.handleError)
   );
     }

   getLabManagers(){
    return this.http.get<LabManager[]>(this.url).pipe(catchError(this.handleError)
    
    );
   }
   getLabManagerById(id){
    return this.http.get<LabManager>(this.url+'/'+id)
  }

  updateLabManager(id,body){
   return this.http.put<LabManager>(this.url+'/'+id,body).pipe(catchError(this.handleError));
  }

  deleteLabManager(id){
return this.http.delete<LabManager>(this.url+'/'+id).pipe(catchError(this.handleError));
  }



  
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error)
        return throwError(
          error.error
        )


      }
     else if(error.status ==401){
       return throwError('invalid username or password')

     }
      else if (error.status == 0) {
        return throwError(
          'network failure please try again later '
        )
      }
      return throwError(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}



