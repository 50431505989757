import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NursingAssessment } from '../../Models/nursingassessment.model';
import { ActivatedRoute } from '@angular/router';
import { NursingAssessmentService } from '../../services/nursing-assessment.service';

@Component({
  selector: 'app-add-nursing-assesment',
  templateUrl: './add-nursing-assesment.component.html',
  styleUrls: ['./add-nursing-assesment.component.css'],
})
export class AddNursingAssesmentComponent implements OnInit {
  id = 0;
  isSuccess = false;
  nursingAssessmentForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private nurseAssementService: NursingAssessmentService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.initializeForm();
  }

  private initializeForm() {
    this.nursingAssessmentForm = this.fb.group({
      PatientId: [this.id, Validators.required],
      ReasonForAdmission: ['', Validators.required],
      BedNo: ['', Validators.required],
      DOA: [false],
      TreatingDoctor: ['', Validators.required],
      IdBand: [''],
      Date: ['', Validators.required],
      Time: ['', Validators.required],
      Department: [''],
      Food: [''],
      Drug: [''],
      BloodTranfusion: [''],
      DM: [''],
      HTN: [''],
      BA: [''],
      TB: [''],
      Epilepsy: [''],
      Cardiac: [''],
      OthersPreviousMedicalHistory: [''],
      PreviousSurgeries: [''],
      Veg: [''],
      NonVeg: [''],
      Egg: [''],
      GarlicFree: [''],
      OnionFree: [''],
      OthersDietAndNutrition: [''],
      Smoking: [''],
      Alcohol: [''],
      Tobbaco: [''],
      OthersHabits: [''],
      Others: [''],
      Dentures: [''],
      HearingAid: [''],
      ContactLens: [''],
      OtherDependentTech: [''],
      Rooms: [''],
      Belongings: [''],
      VisitingTime: [''],
      RightAndResponsibilities: [''],
      NursingPlanOfCare: [''],
      PatientLabelDate: [''],
      PatientLabelTime: [''],
      PatientLabelTreatingDoctor: [''],
      PatientLabelDepartment: [''],
      HR: [''],
      RR: [''],
      BP: [''],
      Ht: [''],
      Wt: [''],
      Location: [''],
      Severity: [''],
      Intervention: [''],
      AssessmentFrequency: [''],
      Concious: [''],
      Dizziness: [''],
      Disoriented: [''],
      Oriented: [''],
      Speech: [''],
      Swallow: [''],
      PupilAssessment: [''],
      Gcs: [''],
      GcsScore: [''],
      Unresponsive: [''],
      Tremors: [''],
      Paralysis: [''],
      Convulsions: [''],
      OthersCNSAssessment: [''],
      ChestPain: [''],
      Edema: [''],
      Cyanosis: [''],
      Rythm: [''],
      Volume: [''],
      Dyspnea: [''],
      Cough: [''],
      Oxygen: [''],
      Normal: [''],
      Wheeze: [''],
      Gasping: [''],
      Haemoptysis: [''],
      Expectorant: [''],
      OthersRespiratoryAssessment: [''],
      NauseaOrVomiting: [''],
      NormalGitAssessment: [''],
      AbdominalDistension: [''],
      Tenderness: [''],
      Diarrhea: [''],
      Constipation: [''],
      Incontinence: [''],
      LastBowelMovement: [''],
      NormalUrinaryAssessment: [''],
      UrinaryAssessmentIncontinence: [''],
      Retention: [''],
      Hematuria: [''],
      UrineColor: [''],
      CatheterType: [''],
      InsertedOn: [''],
      NormalSkinAssessment: [''],
      Pale: [''],
      Cyanotic: [''],
      Icterus: [''],
      Dehydrated: [''],
      Sweaty: [''],
      Cold: [''],
      Abrasion: [''],
      Redness: [''],
      Lession: [''],
      Wound: [''],
      OtherSkinAssessment: [''],
      GynecologyLmp: [''],
      Vulnerable: [''],
      SideRails: [false],
      AttendantInformed: [false],
      MedicationReconciliation: [''],
    });
  }

  public save() {
    this.nurseAssementService
      .registerNursingAssessment(this.nursingAssessmentForm.value)
      .subscribe((res) => {
        alert('Assessment form  submitted');
        this.nursingAssessmentForm.reset();
      });

    // localStorage.setItem('nursingAssessmentForm', JSON.stringify(this.nursingAssessmentForm.value));
  }
}
