

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Ward</h1>
            <form [formGroup]="wardFrm" (ngSubmit)="formSubmit()" class="example-form">
              
                <mat-form-field class="example-full-width">
                    <mat-label>Ward Name </mat-label>
                    <input type="text" matInput formControlName="WardName" >
                    <mat-error >Please enter ward Name</mat-error>
                  </mat-form-field>
                

              
                <mat-form-field class="example-full-width">
                  <mat-label>Description</mat-label>
                  <input type="text" matInput formControlName="WardDescription" >
                  <mat-error >Please enter Description</mat-error>
                </mat-form-field>

                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>
              </form>
              <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                Successfully Created Ward
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
        </div>
    </div>
</div>
