<div>
    <div class="regFrm mat-elevation-z8 ">


        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Sites</th>
                    <th scope="col">Latency</th>
                    <th scope="col">Ref.(ms)</th>
                    <th scope="col">Amplitude</th>
                    <th scope="col">Ref.(ms)</th>
                    <th scope="col">Amplitude Ratio(%)</th>
                    <th scope="col">Duration (ms)</th>
                    <th scope="col">Segments</th>
                    <th scope="col">Distance (cm)</th>
                    <th scope="col">Velocity(m/s)</th>
                    <th scope="col">Ref. (m/s)</th>
                    <th scope="col">Comments</th>
                </tr>
            </thead>
            <tbody>

                <h2>Right Median (APB)</h2>
                <tr>
                    <td>Wrist</td>
                    <td>4.2</td>
                    <td>4.4</td>
                    <td>8.5</td>
                    <td>4.0</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td>Elbow</td>
                    <td>8.7</td>
                    <td>-</td>
                    <td>7.8</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Elbow-Wrist</td>
                    <td>22</td>
                    <td>49</td>
                    <td>49</td>
                    <td>--</td>
                </tr>
                <h2>Right Ulnar (ADM)</h2>
                <tr>
                    <td>Wrist</td>
                    <td>2.7</td>
                    <td>3.3</td>
                    <td>6.8</td>
                    <td>6.0</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td>Bel Elbow</td>
                    <td>6.6</td>
                    <td>-</td>
                    <td>6.7</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Bel Elbow-Wrist</td>
                    <td>21.5</td>
                    <td>55</td>
                    <td>49</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td>Ab Elbow</td>
                    <td>8.5</td>
                    <td>-</td>
                    <td>6.7</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Ab Elbow-Wrist</td>
                    <td>10</td>
                    <td>53</td>
                    <td>49</td>
                    <td>--</td>
                </tr>
            </tbody>
        </table>
        <h2 class="clr">F-Wave Sites</h2>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Min F-Lat</th>

                </tr>
                <tr>
                    <th scope="col">Site</th>
                    <th scope="col">(ms)</th>
                    <th scope="col">Norm</th>
                </tr>
            </thead>
            <tbody>
                <h2>Right Median(APB)</h2>
                <tr>
                    <td>Wrist</td>
                    <td>28.3</td>
                    <td>
                        < 32</td>
                </tr>
                <h2>Right Ulnar(ADM)</h2>
                <tr>
                    <td>Wrist</td>
                    <td>29.9</td>
                    <td>
                        < 33.0>
                    </td>
                </tr>

            </tbody>
        </table>
        <h2 class="clr">Sensory</h2>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Sites</th>

                    <th scope="col">Latency1 (ms)</th>
                    <th scope="col">Latency2 (ms)</th>
                    <th scope="col">Ref.(ms)</th>
                    <th scope="col">Amplitude (uV)</th>
                    <th scope="col">Ref. (uV)</th>
                    <th scope="col">Segments</th>
                    <th scope="col">Neg Peak Cv</th>
                    <th scope="col">Distance(cm)</th>
                    <th scope="col">Velocity(m/s)</th>
                    <th scope="col">Ref. (m/s)</th>
                    <th scope="col">Comment</th>
                </tr>
            </thead>
            <tbody>
                <h2>Left Median (Rec:Wrist)</h2>
                <tr>
                    <td>Palm</td>
                    <td>1.88</td>
                    <td>
                        < 2.2>
                    </td>
                    <td>*48</td>
                    <td>> 50></td>
                    <td>--</td>

                    <td>Palm-Wrist</td>
                    <td>43</td>
                    <td>8</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>

                <h2>Right Median</h2>
                <tr>
                    <td>Wrist-Dig </td>
                    <td>*3.8</td>
                    <td>> 3.5</td>
                    <td>26</td>
                    <td>> 20</td>
                    <td>--</td>

                    <td>Wrist-Dig </td>
                    <td>34</td>
                    <td>8</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td>Palm-Wrist</td>
                    <td>*2.4</td>
                    <td>> 2.2</td>
                    <td>*23</td>
                    <td>> 50</td>
                    <td>-</td>

                    <td>Palm-Wrist</td>
                    <td>33</td>
                    <td>8</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>

                </tr>
                <h2>Left Ulnar(Rec:Wrist)</h2>
                <tr>
                    <td>Palm</td>
                    <td>1.90</td>
                    <td>> 2.2</td>
                    <td>*23</td>
                    <td>> 20</td>
                    <td>-</td>

                    <td>Palm-Wrist</td>
                    <td>42</td>
                    <td>8</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>

                </tr>
                <h2>Right Ulnar(Rec:Wrist)</h2>
                <tr>
                    <td>Wrist-Dig V</td>
                    <td>3.0</td>
                    <td>> 3.1</td>
                    <td>29</td>
                    <td>> 17</td>
                    <td>-</td>

                    <td>Wrist-Dig V</td>
                    <td>37</td>
                    <td>11</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>

                </tr>
                <tr>
                    <td>Palm-Wrist</td>
                    <td>1.80</td>
                    <td>> 2.2</td>
                    <td>27</td>
                    <td>> 12</td>
                    <td>-</td>

                    <td>Palm-Wrist</td>
                    <td>44</td>
                    <td>8</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>

                </tr>
                <h2>Right Radial(Rec:Wrist)</h2>
                <tr>
                    <td>Forearm</td>
                    <td>2.1</td>
                    <td>> 2.9</td>
                    <td>44</td>
                    <td>> 15</td>
                    <td>-</td>

                    <td>Forearm-Wrist</td>
                    <td>48</td>
                    <td>10</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>

                </tr>

            </tbody>
        </table>
        <h2 class="clr">Inter-Comparisons</h2>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Nerve 1</th>
                    <th scope="col">Value 1</th>
                    <th scope="col">Nerve 2</th>
                    <th scope="col">Value 2</th>
                    <th scope="col">Parameter</th>
                    <th scope="col">Result</th>
                    <th scope="col">Normal</th>
                </tr>
            </thead>
            <tbody>
                <h2>Sensory Sites</h2>
                <tr>
                    <td>R Median Pal-Wrist</td>
                    <td>2.4 ms</td>
                    <td>R Ulnar Palm-Wrist</td>
                    <td>1.80 ms</td>
                    <td>Peak Lat Diff</td>
                    <td>*0.60</td>
                    <td>
                        < 0.30</td>
                </tr>
                <tr>
                    <td>L Median Pal-Wrist</td>
                    <td>1.88 ms</td>
                    <td>L Ulnar Palm-Wrist</td>
                    <td>1.90 ms</td>
                    <td>Peak Lat Diff</td>
                    <td>0.02ms</td>
                    <td>
                        < 0.30</td>
                </tr>
            </tbody>
        </table>
        <h2 class="clr">CDF</h2>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Side</th>
                    <th scope="col">Muscle</th>
                    <th scope="col">Nerve</th>
                    <th scope="col">Root</th>
                    <th scope="col">Ins.Act</th>
                    <th scope="col">Fibs</th>
                    <th scope="col">Psw</th>
                    <th scope="col">Amp</th>
                    <th scope="col">Dur</th>
                    <th scope="col">Poly</th>
                    <th scope="col">Recrt</th>
                    <th scope="col">Int.Pat</th>
                    <th scope="col">Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Right</td>
                    <td>Deltoid</td>
                    <td>@Auxillary</td>
                    <td>C5-c6</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>0</td>
                    <td>Nml</td>
                    <td>Nml</td>

                </tr>
                <tr>
                    <td>Right</td>
                    <td>Biceps</td>
                    <td>Musculocut</td>
                    <td>C5-c6</td>
                    <td>Nml</td>
                    <td>*2+</td>
                    <td>Nml</td>
                    <td>*iNCR</td>
                    <td>*>12M</td>
                    <td>*2+</td>
                    <td>Nml</td>
                    <td>Nml</td>
                </tr>
                <tr>
                    <td>Right</td>
                    <td>Triceps</td>
                    <td>Radial</td>
                    <td>C5-c8</td>
                    <td>Nml</td>
                    <td>*1+</td>
                    <td>Nml</td>
                    <td>*iNCR</td>
                    <td>*>12M</td>
                    <td>*1+</td>
                    <td>Nml</td>
                    <td>Nml</td>
                </tr>
                <tr>
                    <td>Right</td>
                    <td>Branchiorad</td>
                    <td>Radial</td>
                    <td>C5-c6</td>
                    <td>Nml</td>
                    <td>*2+</td>
                    <td>Nml</td>
                    <td>*iNCR</td>
                    <td>*>12M</td>
                    <td>*2+</td>
                    <td>Nml</td>
                    <td>Nml</td>
                </tr>
                <tr>
                    <td>Right</td>
                    <td>ED</td>
                    <td>Radial,Posterior</td>
                    <td>C7-C8</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>0</td>
                    <td>Nml</td>
                    <td>Nml</td>
                </tr>
                <tr>
                    <td>Right</td>
                    <td>APB</td>
                    <td>Median</td>
                    <td>C8-T1</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>Nml</td>
                    <td>0</td>
                    <td>Nml</td>
                    <td>Nml</td>
                </tr>
                <tr>
                    <td>Right</td>
                    <td>C6 PSP</td>
                    <td>Rami</td>
                    <td>C6</td>
                    <td>Nml</td>
                    <td>*2+</td>
                    <td>Nml</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>

            </tbody>
        </table>


    </div>
</div>