<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-4" *ngFor="let item of intakeList">
            <mat-card class="mat-elevation-z8 mt-3">
                <h2 class="clr">Intake Assessments</h2>
                <h4>{{item.NurseName}}</h4>
                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>


                    <tr>
                        <th> Intake : </th>

                        <td>{{item.Intake }}</td>
                    </tr>
                    <tr>
                        <th> IVfluid : </th>
                        <td>{{item.IVfluid }}</td>

                    </tr>
                    <tr>
                        <th> Oral Intake : </th>
                        <td>{{item.OralIntake }}</td>

                    </tr>
                    <hr>
                    <tr>
                        <th> Intake Total : </th>
                        <td>{{item.IntakeTotal }}</td>

                    </tr>
                    <tr>
                        <th>Output : </th>
                        <td>{{item.Output }}</td>

                    </tr>
                    <tr>
                        <th> OutputTotal : </th>
                        <td>{{item.OutputTotal }}</td>

                    </tr>
                    <hr>
                    <tr>
                        <th> Date Of Last Bowel Movement : </th>
                        <td>{{item.DateOfLastBowelMovement}}</td>

                    </tr>
                    <tr>
                        <th> Previous Weight : </th>
                        <td>{{item.PreviousWeight }}</td>

                    </tr>
                    <tr>
                        <th> Current Weight : </th>
                        <td>{{item.CurrentWeight }}</td>

                    </tr>
                    <tr>
                        <th> Weight Gain /or/ Loss: </th>
                        <td>{{item.WeightGainorLoss }}</td>

                    </tr>

                </mat-card-content>
                <button class="float-right" [routerLink]="['/editIntakeAssessment/',item.IntakeAssessmentId]"
                    color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>

            </mat-card>

        </div>
    </div>
</div>