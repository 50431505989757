<div>

    <button mat-raised-button color="primary" [routerLink]="['/patientDash/',this.patientid]"><mat-icon
            mat-list-icon>undo</mat-icon>
        Dashboard</button>
</div>

<div class="container mt-5 lgForm">
    <div class="row">
        <div class="card my-5">
            <div class="card-header">
                <div class="card-content">
                    <p>DrugName: {{prescription.DrugName}}</p>
                    <p>Quantity: {{prescription.Quantity}}</p>
                    <p>Refill by: {{prescription.EndDate | date: 'MM/dd/yyyy'}}</p>
                    <p>Physician: {{prescription.DoctorName}}</p>

                </div>

            </div>
        </div>
    </div>
    <h3>Choose a Pick up Pharmacy </h3>
    <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">

            <div class="col-lg-10">
                <mat-form-field class="example-full-width">
                    <mat-label>Pharmacy Name</mat-label>
                    <mat-select formControlName="PatientsPharmacyId" (selectionChange)="SelectedPharmacy()">
                        <mat-option *ngFor="let ph of pharmacys" [value]="ph.PatientsPharmacyId">{{
                            ph.PharmacyName }}</mat-option>
                    </mat-select>
                    <mat-error>Select a Pharmacy</mat-error>
                </mat-form-field>
                {{pharmacy}}
            </div>
        </div>


        <div class="row">
            <div class="col-lg-10">


                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Reason for Refill request </mat-label>

                    <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="7" cdkAutosizeMaxRows="80"></textarea>
                    <mat-error *ngIf="Description.invalid && Description.touched">Please enter reason for
                        order</mat-error>

                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-10">

            <button class="float-right lgBtCl" mat-button>
                <mat-icon>save</mat-icon>
            </button>
        </div>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Submitted refill request
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </form>
</div>