import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { PhysicalTherapyVisit } from 'src/app/Models/physicaltheraapyvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhysicalTherapyVisitService } from 'src/app/services/physical-therapy-visit.service';

@Component({
  selector: 'app-view-physical-therapy-visit',
  templateUrl: './view-physical-therapy-visit.component.html',
  styleUrls: ['./view-physical-therapy-visit.component.css'],
})
export class ViewPhysicalTherapyVisitComponent implements OnInit {
  id = 0;
  patient: Patient;

  physicalTherapyVisitList: PhysicalTherapyVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  physicalTherapyVisitId: PhysicalTherapyVisit[];
  showItem = '';
  show = true;
  isHide: boolean;
  CurrentDoctorId: number;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private physicalTherapyVisitService: PhysicalTherapyVisitService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);

    this.getPtvisit(this.id);
    this.getPatientById(this.id);
  }

  printPage() {
    window.print();
    // document.execCommand('print');
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.physicalTherapyVisitList.filter((x) =>
        x.TreatmentDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  getPtvisit(id) {
    this.patientService.getPhysicalTherapyVisits(id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PhysicalTherapyVisits.sort(function (x, y) {
        return y.PhysicalTherapyVisitId - x.PhysicalTherapyVisitId;
      });
    });
  }
  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.physicalTherapyVisitService
        .deletePhysicalTherapyVisit(id)
        .subscribe((res) => {
          // this.filteredList = this.physicalTherapyVisitList.filter(
          //   (x) => x.PhysicalTherapyVisitId != id
          // );
          this.getPtvisit(this.id);
        });
    }
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;

      // console.log(this.isNewPatient);
    });
  }
}
