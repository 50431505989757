import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientAdmissionService } from 'src/app/services/patient-admission.service';

@Component({
  selector: 'app-update-patient-admission',
  templateUrl: './update-patient-admission.component.html',
  styleUrls: ['./update-patient-admission.component.css'],
})
export class UpdatePatientAdmissionComponent implements OnInit {
  isSuccess = false;

  id = 0;
  states;

  typesOfShoes: [
    { name: 'Primary'; completed: false; color: 'primary' },
    { name: 'Accent'; completed: false; color: 'accent' },
    { name: 'Warn'; completed: false; color: 'warn' }
  ];
  constructor(
    private fb: UntypedFormBuilder,
    private patientAdmissionService: PatientAdmissionService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientAdmissionService
      .getPatientAdmissionById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });
  }

  Frm = this.fb.group({
    SenderInformation: [''],
    SenderCallerName: [''],
    SenderCallerPhone: [''],
    SenderCallerEmail: [''],
    SenderCallerAddress: [''],

    PatientFirstName: [''],

    PatientLastName: [''],

    PatientDOB: [''],
    Gender: [''],

    DateOfAdmission: [''],
    TimeOfAdmission: [''],
    Disposition: [''],
    AdmissionSource: [''],
    ChiefComplaints: [''],
    AdmissionType: [''],
    AdmissionUnit: [''],
    AdmissionBed: [''],
    AttendingPhysician: [''],
    RefferringPhysician: [''],
    PrimaryCareDoctor: [''],

    Icd10DiagnosisCode: [''],
    Icd10ProcedureCode: [''],

    // Status: [''],
  });

  get DateOfAdmission() {
    return this.Frm.get('DateOfAdmission');
  }
  get TimeOfAdmission() {
    return this.Frm.get('TimeOfAdmission');
  }
  get Icd10DiagnosisCode() {
    return this.Frm.get('Icd10DiagnosisCode');
  }
  get Icd10ProcedureCode() {
    return this.Frm.get('Icd10ProcedureCode');
  }
  get PrimaryCareDoctor() {
    return this.Frm.get('PrimaryCareDoctor');
  }
  get RefferringPhysician() {
    return this.Frm.get('RefferringPhysician');
  }
  get AttendingPhysician() {
    return this.Frm.get('AttendingPhysician');
  }
  get AdmissionBed() {
    return this.Frm.get('AdmissionBed');
  }
  get AdmissionUnit() {
    return this.Frm.get('AdmissionUnit');
  }

  get ChiefComplaints() {
    return this.Frm.get('ChiefComplaints');
  }
  get Disposition() {
    return this.Frm.get('Disposition');
  }

  get AdmissionType() {
    return this.Frm.get('AdmissionType');
  }
  get AdmissionSource() {
    return this.Frm.get('AdmissionSource');
  }

  get SenderInformation() {
    return this.Frm.get('SenderInformation');
  }
  get SenderCallerName() {
    return this.Frm.get('SenderCallerName');
  }
  get SenderCallerPhone() {
    return this.Frm.get('SenderCallerPhone');
  }
  get SenderCallerEmail() {
    return this.Frm.get('SenderCallerEmail');
  }

  // get Status() {
  //   return this.Frm.get('Status');
  // }

  formSubmit() {
    let body = {
      ...this.Frm.value,
    };
    this.patientAdmissionService
      .updatePatientAdmission(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['/adminDash/']);
      });
  }
}
