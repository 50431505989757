<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Patient Insurance</h1>
      <form [formGroup]="ptInsuranceFrm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-4">
            <p>Do You Have Insurance</p>
            <mat-radio-group formControlName="DoyouhaveInsurance" aria-label="Select an option my-3">
              <mat-radio-button class="mx-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="mx-3 " value="false"> No</mat-radio-button>
            </mat-radio-group>

          </div>

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Primary PolicyHolder Name</mat-label>
              <input type="text" formControlName="PrimaryPolicyHolderName" matInput>
              <mat-error>Please enter Primary Policy Holder Name</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Secondary Policy Holder Name</mat-label>
              <input type="text" formControlName="SecondaryPolicyHolderName" matInput>
              <mat-error>Please enter Secondary Policy Holder Name</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Name of Insuarance Company</mat-label>
              <input type="text" formControlName="InsuaranceName" matInput>
              <mat-error>Please enter Name of Insurance Company</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance No</mat-label>
              <input type="text" formControlName="InsuaranceNo" matInput>
              <mat-error>Please enter Insuarance No</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Group No</mat-label>
              <input type="text" formControlName="GroupNo" matInput>
              <mat-error>Please enter Group No</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance Expiry Date</mat-label>
              <input type="date" formControlName="InsuaranceExpiryDate" matInput>
              <mat-error>Please enter Insuarance Expiry Date</mat-error>
            </mat-form-field>
          </div>



        </div>
        <mat-tab-group class="p-2 mt-2 w-100" (selectedTabChange)="InsuranceCardTabChange($event)">
          <mat-tab label="Web Cam Option" class="w-100" style="height: 300px;">
            <div class="row d-flex justify-centent-center w-100">
              <div class="row mt-5 col ">
                <h2 class="text-center"> &nbsp;&nbsp;&nbsp;&nbsp;Insurance Card Front Image</h2>
                <div class="col-md-12">
                  <webcam [trigger]="invokeObservableFront" (imageCapture)="captureImgFront($event)" [height]="180"
                    [width]="180"></webcam>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-danger" (click)="getSnapshotFront()" type="button">
                    Capture Image
                  </button>
                </div>
                <div class="col-12">
                  <div id="results">Your taken image manifests here...</div>
                  <img [src]="webcamImageFront?.imageAsDataUrl" height="180px" />
                </div>
              </div>

              <div class="row mt-5 col">
                <h2 class="text-center">Insurance Card Back Image</h2>
                <div class="col-md-12">
                  <webcam [trigger]="invokeObservableBack" (imageCapture)="captureImgBack($event)" [height]="180"
                    [width]="180"></webcam>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-danger" (click)="getSnapshotBack()" type="button">
                    Capture Image
                  </button>
                </div>
                <div class="col-12">
                  <div id="results">Your taken image manifests here...</div>
                  <img [src]="webcamImageBack?.imageAsDataUrl" height="180px" />
                </div>
              </div>

            </div>

          </mat-tab>
          <mat-tab label="Upload File Option" style="height: 300px;">
            <div class="row  mt-5 mb-5">
              <div class="col-md-6">
                <mat-label> Insurance Card Front Image </mat-label>
                <input type="file" (change)="image($event,frontInputTag)" #frontInputTag>

              </div>

              <div class="col-md-6">

                <mat-label> Insurance Card Back Image </mat-label>
                <input type="file" (change)="image2($event,backInputTag)" #backInputTag>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>


        <hr>
        <div class="row">
          <div class="col-md-12">
            <h2>Payment Policies</h2>
            <ul>
              <li>You are financially responsible for anything insurance does not cover. All copays are due and payable
                at each visit. The amount your insurance will
                allow and pay for and your financial responsibility is determined by your insurance company and the
                policy you have chosen. Your claim will be
                processed according to the benefits of your insurance plan. The deductible, co-insurance and co-pay are
                your financial responsibility. It is your
                responsibility to understand your insurance plan.</li>
              <li>$5 Fee for Co-pays not paid at the time of service.</li>
              <li>$50 No Show Fee for any Missed Appointment that was not cancelled or rescheduled 24 hours prior to the
                appointment. Please be considerate and
                call at least 24 hours before your appointment if you cannot come in.</li>
              <li>$35 NSF charge for any returned check from the bank.</li>
              <li>If you are a private patient without insurance, all charges are due at the time of the visit. We do
                not send a statement to private pay patients.</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 float-right">
            <mat-form-field class="example-full-width">
              <mat-label>Signature</mat-label>
              <input type="text" formControlName="Signature" matInput>
              <mat-error>Please enter your name as Signature</mat-error>
            </mat-form-field>
          </div>
        </div>



        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Added Patient Insurance
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>
</div>