import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DoctorDate } from 'src/app/Models/doctordate.model';
import { DoctordatesService } from 'src/app/services/doctordates.service';

@Component({
  selector: 'app-view-doctor-availability',
  templateUrl: './view-doctor-availability.component.html',
  styleUrls: ['./view-doctor-availability.component.css'],
})
export class ViewDoctorAvailabilityComponent implements OnInit {
  doctoDatesList: DoctorDate[];
  displayedColumns: string[] = ['DoctorId', 'DoctorName', 'AvailableDate'];
  dataSource: MatTableDataSource<any>;
  constructor(private doctorDatesService: DoctordatesService) {}
  ngOnInit(): void {
    this.getDoctorAvailability();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getDoctorAvailability() {
    this.doctorDatesService.getDoctorDates().subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.sort(function (x, y) {
          return y.DoctorDateId - x.DoctorDateId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDoctorAvailability();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
