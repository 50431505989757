<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">
    <mat-icon>print</mat-icon>
</button> -->




<div class="container" id="printable">
    <app-view-hospital></app-view-hospital>






    <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
        <div class="col-lg-6 ">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Search by Date</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

            </mat-form-field>
        </div>
    </div>

    <h1 class="text-center" style="font-weight:bold ;">Physical Therapy Initial Evaluation Reports</h1>

    <button *ngIf="show" class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
        value="print"><mat-icon>print</mat-icon></button>

    <div>
        <div class="">
            <h2 class="">Patient Information</h2>

            <div class="row">
                <div class="col-lg-2"><span class="bld">Patient No:</span>
                    {{patient?.PatientId}}</div>
                <div class="col-lg-3">
                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}} {{patient?.PatientLastName}}
                </div>
                <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
                <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
            </div>
        </div>
        <hr>



        <div class=" mt-5" *ngFor="let item of filteredList ">

            <div class="row">
                <div class="col-lg-6">
                    <h2 class="">InjuryDate Date: {{item?.InjuryDate | date :'short'}}</h2>
                    <!-- | date: 'dd/MM/yyyy' -->
                    <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item?.DoctorName}}</div>

                </div>

            </div>
            <hr>
            <!-- <div class="row" > 
        <div class="col-lg-12"><span class="bld">Diagnosis Codes:</span> {{item?.DiagnosisCodes}}</div>
    </div> -->
            <div class="row my-2 mx-1 justify-content-center">
                <table class="table table-striped table-borderless">
                    <thead id="invoice-header">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Diagnosis Codes</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let it of item?.InitialEvaluationDiagnosisCodes; let i=index ">
                            <td scope="col">#{{i+1}}</td>
                            <td>{{it.Code}}</td>

                        </tr>

                    </tbody>

                </table>
            </div>
            <hr>
            <h2>Subjective</h2>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Mechanism Of Injury:</span> {{item?.MechanismOfInjury}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Pain Increase sWith:</span> {{item?.PainIncreasesWith }}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Pain Decreases With:</span> {{item?.PainDecreasesWith }}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Medical History:</span> {{item?.MedicalHistory}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Living Environment:</span> {{item?.LivingEnvironment }}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Occupation:</span> {{item?.Occupation }}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Diagnosis Testing:</span> {{item?.DiagnosisTesting }}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Patient Goal:</span> {{item?.PatientGoal }}</div>
            </div>
            <hr>
            <h2>Pain Scale</h2>
            <div class="row">
                <div class="col-lg-4"><span class="bld">PainWorstLevel :</span> {{item?.PainWorstLevel}}</div>
                <div class="col-lg-4"><span class="bld">PainCurrentLevel :</span> {{item?.PainCurrentLevel}}</div>
                <div class="col-lg-4"><span class="bld">PainBestLevel :</span> {{item?.PainBestLevel}}</div>
            </div>

            <hr>
            <h2>Objective</h2>
            <h4>General</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">TreatmentArea :</span> {{item?.TreatmentArea}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Palpitation :</span> {{item?.Palpitation}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Posture :</span> {{item?.Posture}}</div>
            </div>

            <hr>
            <h2>Objective-Spine</h2>
            <div class="row">
                <!-- <table class="table table">
    <thead>
        <th>Cervical Rom</th>
        <th>Value</th>
    </thead>
    <tbody>
        <tr>
            <td>Flexion:{{item?.FlexionCervical}}</td>
            <td>Extension:{{item?.ExtensionCervical}}</td>
            <td>LeftSideFlexion:{{item?.LeftSideFlexionCervical}}</td>
            <td>RightSideFlexion:{{item?.RightSideFlexionCervical}}</td>
            <td>LeftRotation:{{item?.LeftRotationCervical}}</td>
            <td>RightRotation:{{item?.RightRotationCervical}}</td>
        </tr>
    </tbody>
   </table> -->

                <mat-card class="mat-elevation-z8 mt-3 ">
                    <h4>Cervical Rom</h4>
                    <hr>
                    <mat-card-content>
                        <thead>
                            <th>Cervical Rom </th>
                            <th>Value</th>
                        </thead>
                        <tr>
                            <th> Flexion: </th>
                            <td>{{item.FlexionCervical}}</td>

                        </tr>
                        <tr>
                            <th> Extension: </th>
                            <td>{{item.ExtensionCervical}}</td>

                        </tr>
                        <tr>
                            <th> Left Side Flexion: </th>
                            <td>{{item.LeftSideFlexionCervical}}</td>

                        </tr>
                        <tr>
                            <th> Right Side Flexion: </th>
                            <td>{{item.RightSideFlexionCervical}}</td>

                        </tr>
                        <tr>
                            <th> Left Rotation: </th>
                            <td>{{item.LeftRotationCervical}}</td>

                        </tr>
                        <tr>
                            <th> Right Rotation: </th>
                            <td>{{item.RightRotationCervical}}</td>

                        </tr>

                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-elevation-z8 mx-3 mt-3 ">
                    <h4>Lumbar Rom</h4>
                    <hr>
                    <mat-card-content>
                        <thead>
                            <th>Lumbar Strength</th>
                            <th>Value</th>
                        </thead>
                        <tr>
                            <th> Flexion: </th>
                            <td>{{item.FlexionLumbar}}</td>

                        </tr>
                        <tr>
                            <th> Extension: </th>
                            <td>{{item.ExtensionLumbar}}</td>

                        </tr>
                        <tr>
                            <th> Left Side Flexion: </th>
                            <td>{{item.LeftSideFlexionLumbar}}</td>

                        </tr>
                        <tr>
                            <th> Right Side Flexion: </th>
                            <td>{{item.RightSideFlexionLumbar}}</td>

                        </tr>
                        <tr>
                            <th> Left Rotation: </th>
                            <td>{{item.LeftRotationLumbar}}</td>

                        </tr>
                        <tr>
                            <th> Right Rotation: </th>
                            <td>{{item.RightRotationLumbar}}</td>

                        </tr>

                    </mat-card-content>
                </mat-card>


                <mat-card class="mat-elevation-z8 mx-3 mt-3 ">
                    <h4>Lumbar Strength</h4>
                    <hr>
                    <mat-card-content>
                        <thead>
                            <th>Lumbar Strength</th>
                            <th>Value</th>
                        </thead>
                        <tr>
                            <th> Lower Abdominals: </th>
                            <td>{{item.LowerAbdominals}}</td>

                        </tr>
                        <tr>
                            <th> Hip Flexors: </th>
                            <td>{{item.HipFlexors}}</td>

                        </tr>
                        <tr>
                            <th> Hip Extensors: </th>
                            <td>{{item.HipExtensors}}</td>

                        </tr>
                        <tr>
                            <th>Thomas Text: </th>
                            <td>{{item.ThomasText}}</td>

                        </tr>
                        <tr>
                            <th> Slump Test: </th>
                            <td>{{item.SlumpTest}}</td>

                        </tr>
                        <tr>
                            <th> Prone Instability Test: </th>
                            <td> {{item.PhoneInstabilityTest}}</td>

                        </tr>
                        <tr>
                            <th> Comments: </th>
                            <td>{{item.Comments}}</td>

                        </tr>

                    </mat-card-content>
                </mat-card>
            </div>

            <hr>


            <hr>
            <h2>Objective-Spine</h2>
            <div class="row">


                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">HIP ROM</th>
                            <th scope="col">ARROM-Left</th>
                            <th scope="col">ARROM-Right</th>
                            <th scope="col">FROM-Left</th>
                            <th scope="col">FROM-Right</th>
                            <th scope="col">Rotation-Left</th>
                            <th scope="col">Rotation-Right</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Abduction</th>
                            <td>{{item?.AbductionArromLeft}}</td>
                            <td>{{item?.AbductionArromRight}}</td>
                            <td>{{item?.LeftSideFlexionCervical}}</td>
                            <td>{{item?.RightSideFlexionCervical}}</td>

                        </tr>
                        <tr>
                            <th scope="row">Cervical</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{item?.LeftRotationCervical}}</td>
                            <td>{{item?.RightRotationCervical}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Flexion With Knee Flexed</th>
                            <td>{{item?.FlexionWithKneeFlexedArromLeft}}</td>
                            <td>{{item?.FlexionWithKneeFlexedArromRight}}</td>
                            <td>{{item?.FlexionWithKneeFlexedFromLeft}}</td>
                            <td>{{item?.FlexionWithKneeFromRight}}</td>

                        </tr>
                        <tr>
                            <th scope="row">Flexion With Knee Extended</th>
                            <td>{{item?.FlexionWithKneeExtendedArromLeft}}</td>
                            <td>{{item?.FlexionWithKneeExtendedArromRight}}</td>
                            <td>{{item?.FlexionWithKneeExtendedFromLeft}}</td>
                            <td>{{item?.FlexionWithKneeExtendedFromRight}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Hip Extension</th>
                            <td>{{item?.HipExtensionArromLeft}}</td>
                            <td>{{item?.HipExtensionArromRight}}</td>
                            <td>{{item?.HipExtensionFromLeft}}</td>
                            <td>{{item?.HipExtensionFromRight}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Hip External Rotation</th>
                            <td>{{item?.HipExternalRotationArromLeft}}</td>
                            <td>{{item?.HipExternalRotationArromRight}}</td>
                            <td>{{item?.HipExternalRotationFromLeft}}</td>
                            <td>{{item?.HipExternalRotationFromRight}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Knee Flexion</th>
                            <td>{{item?.KneeFlexionArromLeft}}</td>
                            <td>{{item?.KneeFlexionArromRight}}</td>
                            <td>{{item?.KneeFlexionFromLeft}}</td>
                            <td>{{item?.KneeFlexionFromRight}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Knee Extension</th>
                            <td>{{item?.KneeExtensionArromLeft}}</td>
                            <td>{{item?.KneeExtensionArromRight}}</td>
                            <td>{{item?.KneeExtensionFromLeft}}</td>
                            <td>{{item?.KneeExtensionFromRight}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <hr>


            <h2>Assessment</h2>
            <h4>Problem List</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Pain :</span> {{item?.Pain}}</div>
                <div class="col-lg-12"><span class="bld">Weekness :</span> {{item?.Weekness}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Mobility :</span> {{item?.Mobility}}</div>
                <div class="col-lg-12"><span class="bld">Ambulatiion :</span> {{item?.Ambulatiion}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Range Of Motion :</span> {{item?.RangeOfMotion}}</div>
                <div class="col-lg-12"><span class="bld">Problem 1 :</span> {{item?.Problem1}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Problem 2 :</span> {{item?.Problem2}}</div>
                <div class="col-lg-12"><span class="bld">Prognosis :</span> {{item?.Prognosis}}</div>
            </div>

            <hr>
            <h4>Goals</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Goal Time :</span> {{item?.GoalTime}}</div>
                <div class="col-lg-12"><span class="bld">Able to Sit For :</span> {{item?.AbletoSitFor}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">ReturnToWork :</span> {{item?.ReturnToWork}}</div>
                <div class="col-lg-12"><span class="bld">Be Independent and Self Grooming :</span>
                    {{item?.BeIndependentandSelfGrooming}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Functional Goal 1 :</span> {{item?.FunctionalGoal1}}</div>
                <div class="col-lg-12"><span class="bld">Functional Goal 2 :</span> {{item?.FunctionalGoal2}}</div>
            </div>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Functional Goal 3 :</span> {{item?.FunctionalGoal3}}</div>
            </div>

            <hr>
            <h4>Goals</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Frequency and Duration :</span> {{item?.FrequencyandDuration}}
                </div>
            </div>
            <div class="row">

                <div class="col-lg-12"><span class="bld">Treatment Plan :</span> {{item?.TreatmentPlan}}</div>
            </div>
            <hr>
            <h2>Treatment FlowSheet</h2>
            <h4>Manual Therapy</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Joint Mobilization :</span> {{item?.JointMobilization}}</div>
                <div class="col-lg-12"><span class="bld">Soft Tissue Mobilization :</span>
                    {{item?.SoftTissueMobilization}}</div>
                <div class="col-lg-12"><span class="bld">Stretching :</span> {{item?.Stretching}}</div>
            </div>
            <hr>
            <h2>Lower Extermity Exercise Flowsheet</h2>
            <h4>Other</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Pirformis Str :</span> {{item?.PirformisStr}}</div>
                <div class="col-lg-12"><span class="bld">Lunge Str :</span> {{item?.LungeStr}}</div>
            </div>
            <hr>
            <h2>Spine Execise Flowsheet</h2>
            <h4>Thoracic Extension</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Foam Roller :</span> {{item?.FoamRoller}}</div>
                <div class="col-lg-12"><span class="bld">Lion/Lat Stretch :</span> {{item?.LionLatStretch}}</div>
                <div class="col-lg-12"><span class="bld">Cat& Camel Stretch :</span> {{item?.CatandCamelStretch}}</div>
                <div class="col-lg-12"><span class="bld">LowerTrunk Rotation:</span> {{item?.LowerTrunkRotation}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">TimeIn :</span> {{item?.TimeIn}}</div>
                <div class="col-lg-12"><span class="bld">TimeOut :</span> {{item?.TimeOut}}</div>
                <div class="col-lg-12"><span class="bld">DoctorName :</span> {{item?.DoctorName}}</div>
                <div class="col-lg-12"><span class="bld">Signature :</span> {{item?.Signature}}</div>
            </div>
            <hr>

            <button class="" [routerLink]="['/updatePtInitalEvaluation/',item.PtInitalEvaluationId]"
                [disabled]="CurrentDoctorId != item?.DoctorId" color="primary" mat-mini-fab>
                <mat-icon matPrefix>edit</mat-icon>
            </button>
            <button class="btn btn-danger mx-3 my-3 " (click)="deleteVisit(item.PtInitalEvaluationId)" color="success"
                mat-mini-fab>
                <mat-icon matPrefix>delete</mat-icon>
            </button>
            <!-- <button class="btn btn-success mx-3 my-3 "
                [routerLink]="['/updatePtInitalEvaluation/',item.PtInitalEvaluationId]" color="success" mat-mini-fab>
                <mat-icon matPrefix>edit</mat-icon>
            </button>
            <button class="btn btn-danger mx-3 my-3 " (click)="deleteVisit(item.PtInitalEvaluationId)" color="success"
                mat-mini-fab>
                <mat-icon matPrefix>delete</mat-icon>
            </button> -->
            <hr class="bg-primary">


            <hr>

        </div>
    </div>





</div>