
<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Allergy</h1>
            <form [formGroup]="addAllergyFrm" (ngSubmit)="formSubmit()" class="example-form">
              <div class="row">
                <div class="col-lg">
                  <mat-form-field class="example-full-width">
                    <mat-label>Allergic To Substance?</mat-label>
                    <input type="text" formControlName="AllergicTo" matInput >
                    <mat-error >Please enter Substance which Allergic to</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Reaction</mat-label>
                    <input type="text" formControlName="Reaction" matInput >
                    <mat-error >Please enter Reaction</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input type="text" formControlName="Description" matInput >
                    <mat-error >Please enter Description</mat-error>
                  </mat-form-field>



                 </div>
                  </div>
                  <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    </button>
                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
            </form>
          
        </div>
       
    </div>
</div>