<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">comment</mat-icon>
      Todays Medications
    </ng-template>

    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table class="w-100" mat-table [dataSource]="dataSource" matSort>
        <!-- <ng-container matColumnDef="PrescriptionId">
                <th mat-header-cell *matHeaderCellDef > Id </th>
                <td mat-cell *matCellDef="let row"> {{row.PrescriptionId}} </td>
              </ng-container> -->

        <ng-container matColumnDef="DatePrescribed">
          <th mat-header-cell *matHeaderCellDef> Date Prescribed </th>
          <td mat-cell *matCellDef="let row"> {{row.CreatedDate | date: 'MM/dd/yyyy'}} </td>

        </ng-container>



        <ng-container matColumnDef="DrugName">
          <th mat-header-cell *matHeaderCellDef> DrugName </th>
          <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
        </ng-container>
        <ng-container matColumnDef="BrandName">
          <th mat-header-cell *matHeaderCellDef> BrandName </th>
          <td mat-cell *matCellDef="let row"> {{row.BrandName}} </td>
        </ng-container>
        <ng-container matColumnDef="Strength">
          <th mat-header-cell *matHeaderCellDef> Strength </th>
          <td mat-cell *matCellDef="let row"> {{row.Strength}} </td>
        </ng-container>
        <ng-container matColumnDef="Quantity">
          <th mat-header-cell *matHeaderCellDef> Quantity </th>
          <td mat-cell *matCellDef="let row"> {{row.Quantity}} </td>
        </ng-container>
        <ng-container matColumnDef="MorningTime">
          <th mat-header-cell *matHeaderCellDef> Morning </th>
          <td mat-cell *matCellDef="let row">
            {{row.MorningTime}} </td>
        </ng-container>
        <ng-container matColumnDef="MorningGivenTime">
          <th mat-header-cell *matHeaderCellDef> Given-M </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox value="checked" (change)="changeValue($event,row)"></mat-checkbox>
            <!-- <mat-checkbox [disabled]="row.MorningUpdatedTime ? true : false" [title]="row.MorningUpdatedTime ? row.MorningUpdatedTime : row.MorningTime" *ngIf="row.MorningTime" value="checked" (change)="changeValue($event,row, c)"></mat-checkbox> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="AfternoonTime">
          <th mat-header-cell *matHeaderCellDef> Afternoon </th>
          <td mat-cell *matCellDef="let row">
            {{row.AfternoonTime}} </td>
        </ng-container>
        <ng-container matColumnDef="AfternoonGivenStatus">
          <th mat-header-cell *matHeaderCellDef> Given-AF </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="row.AfternoonUpdatedTime ? true : false"
              [title]="row.AfternoonUpdatedTime ? row.AfternoonUpdatedTime : row.AfternoonTime"
              *ngIf="row.AfternoonTime" value="checked" (change)="changeValue1($event,row)"></mat-checkbox>

            <!-- <mat-checkbox [disabled]="row.AfternoonUpdatedTime ? true : false" [title]="row.AfternoonUpdatedTime ? row.AfternoonUpdatedTime : row.AfternoonTime" *ngIf="row.AfternoonTime" value="checked" (change)="changeValue($event,row, 'AfternoonTime')"></mat-checkbox> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="EveningTime">
          <th mat-header-cell *matHeaderCellDef> Evening </th>
          <td mat-cell *matCellDef="let row">
            {{row.EveningTime}} </td>
        </ng-container>
        <ng-container matColumnDef="EveningGivenStatus">
          <th mat-header-cell *matHeaderCellDef> Given-EVN </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="row.EveningUpdatedTime ? true : false"
              [title]="row.EveningUpdatedTime ? row.EveningUpdatedTime : row.EveningTime" *ngIf="row.EveningTime"
              value="checked" (change)="changeValue2($event,row)"></mat-checkbox>

            <!-- <mat-checkbox [disabled]="row.EveningUpdatedTime ? true : false" [title]="row.EveningUpdatedTime ? row.EveningUpdatedTime : row.EveningTime" *ngIf="row.EveningTime" value="checked" (change)="changeValue($event,row, 'EveningTime')"></mat-checkbox> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="NightTime">
          <th mat-header-cell *matHeaderCellDef> Night </th>
          <td mat-cell *matCellDef="let row">
            {{row.NightTime}} </td>
        </ng-container>
        <ng-container matColumnDef="NightGivenStatus">
          <th mat-header-cell *matHeaderCellDef> Given-NG </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [disabled]="row.NightUpdatedTime ? true : false"
              [title]="row.NightUpdatedTime ? row.NightUpdatedTime : row.NightTime" *ngIf="row.NightTime"
              value="checked" (change)="changeValue3($event,row)"></mat-checkbox>

            <!-- <mat-checkbox [disabled]="row.NightUpdatedTime ? true : false" [title]="row.NightUpdatedTime ? row.NightUpdatedTime : row.NightTime" *ngIf="row.NightTime" value="checked" (change)="changeValue($event,row, 'NightTime')"></mat-checkbox> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="QTime">
          <th mat-header-cell *matHeaderCellDef> Q-Time </th>
          <td mat-cell *matCellDef="let row"> {{row.QTime}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.Status?'Current':'Stopped'}} </td>
        </ng-container>


        <ng-container matColumnDef="Instruction">
          <th class="message" mat-header-cell *matHeaderCellDef> Instruction </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Instruction}} </td>
        </ng-container>

        <!-- modal  -->

        <!-- <ng-container  matColumnDef="View" >
            <th  mat-header-cell *matHeaderCellDef>Given</th>
            <td class="mx-5" mat-cell *matCellDef="let row"> 
              <button type="button"  [routerLink]="['/prescriptionGiven/',row.PrescriptionId]" mat-mini-fab color="primary">
                <mat-icon matPrefix>visibility</mat-icon>
              </button>
            </td>
          </ng-container>  -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <!-- <div class=" mt-5 mb-5">
      <h2 class="text-center">Medication Given Time</h2>

      <table class="w-100 table ">
        <thead>
          <th>Morning </th>
          <th>Nurse Name</th>
          <th>Afternoon </th>
          <th>Nurse Name</th>
          <th>Evening </th>
          <th>Nurse Name</th>
          <th>Night </th>
          <th>Nurse Name</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of medicationGiven">
            <td>{{item?.MorningGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
            <td>{{item?.MorningNurseName}}</td>
            <td>{{item?.AfternoonGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
            <td>{{item?.AfternoonNurseName}}</td>
            <td>{{item?.EveningGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
            <td>{{item?.EveningNurseName}}</td>
            <td>{{item?.NightGivenTime | date: 'dd/MM/yyyy, hh:mm a'}}</td>
            <td>{{item?.NightNurseName}}</td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">visibility</mat-icon>
      Med Tracker
    </ng-template>
    <app-med-tracker></app-med-tracker>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">science</mat-icon>
      New Lab Order
    </ng-template>
    <app-view-new-lab-order></app-view-new-lab-order>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">radiology</mat-icon>
      New Radiology Order
    </ng-template>
    <app-view-new-radiology-order></app-view-new-radiology-order>
  </mat-tab>

  <!-- <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">health_and_safety</mat-icon>
      Safety Management
    </ng-template>
    <app-view-safteymanagement></app-view-safteymanagement>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">search</mat-icon>
      Purposefull Rounds
    </ng-template>
    <app-view-purposefull-round></app-view-purposefull-round>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">health_and_safety</mat-icon>
      Routine Assessment
    </ng-template>
    <app-view-nurse-daily-assessment></app-view-nurse-daily-assessment>
  </mat-tab> -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">handshake</mat-icon>
      Hand Off Notes
    </ng-template>
    <app-view-nurse-hand-off-note></app-view-nurse-hand-off-note>
  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">task</mat-icon>
      Other Tasks
    </ng-template>
    <app-view-other-task></app-view-other-task>
  </mat-tab>


</mat-tab-group>