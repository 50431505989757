import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-nerve-conduction-study',
  templateUrl: './view-nerve-conduction-study.component.html',
  styleUrls: ['./view-nerve-conduction-study.component.css'],
})
export class ViewNerveConductionStudyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
