import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { RadiologyReportService } from 'src/app/services/radiology-report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-radiology-report',
  templateUrl: './upload-radiology-report.component.html',
  styleUrls: ['./upload-radiology-report.component.css']
})
export class UploadRadiologyReportComponent implements OnInit {

  id=0;
  imageUrl: string;
  doctorList: Doctor[];
  isSuccess= false;

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private radiologyReportService:RadiologyReportService,private router:Router,private doctorService:DoctorService) { }

  ngOnInit(): void {
     this.route.params.subscribe(res=>{
      this.id = res['id'];
     })
this.getDoctors();
  }



  labFrm = this.fb.group({
    ReportType:['',Validators.required],
    Document:[''],
    Description:['',Validators.required],
    Date:['',Validators.required],
    OrderedBy:['',Validators.required],
    LabName:['',Validators.required],
    ReportedBy:['',Validators.required]


  })

get ReportType(){
  return this.labFrm.get('ReportType')
}

get Document(){
  return this.labFrm.get('Document')
}
get Description(){
  return this.labFrm.get('Description')
}
get Date(){
  return this.labFrm.get('Date')
}
get OrderedBy(){
  return this.labFrm.get('OrderedBy')
}
get LabName(){
  return this.labFrm.get('LabName')
}
get ReportedBy(){
  return this.labFrm.get('ReportedBy')
}


image(e){
  let formData = new FormData();
  let files= e.target.files
  formData.append('file',files[0],files[0].name)

  
this.radiologyReportService.imageUpload(formData).subscribe(res=>{
  let stImg = environment.imgurl + res['path'];
  // this.imageUrl = stImg
  this.imageUrl = `<p><img src="${stImg}"/></p>`
   },err=>console.log(err))

}


getDoctors(){
  this.doctorService.getDoctors().subscribe(res=>{
    this.doctorList = res;
  })
}

  formSubmit(){
let reportType = this.labFrm.value['ReportType']
let document = this.labFrm.value['Document']
let description = this.labFrm.value['Description']
let date = this.labFrm.value['Date']
date=  moment(date).format('MM/DD/YYYY');
let orderedBy = this.labFrm.value['OrderedBy']
let labName = this.labFrm.value['LabName']
let reportedBy = this.labFrm.value['ReportedBy']

let body ={
  "PatientId":this.id,
  "ReportType":reportType,
  "Document":this.imageUrl,
  "Description":description,
  "Date":date,
  "OrderedBy":orderedBy,
  "LabName":labName,
  "ReportedBy":reportedBy
}
this.radiologyReportService.registerRadiologyReport(body).subscribe(res=>{
  this.isSuccess=true
this.labFrm.reset();

})

  }
}
