import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HippaCompliance } from 'src/app/Models/hippacompliance.model';
import { HippaComplianceService } from 'src/app/services/hippa-compliance.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-hipaacompliance',
  templateUrl: './patient-view-hipaacompliance.component.html',
  styleUrls: ['./patient-view-hipaacompliance.component.css'],
})
export class PatientViewHipaacomplianceComponent implements OnInit {
  id = 0;
  hippaComplianceList: HippaCompliance[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private hippaComplianceService: HippaComplianceService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getHippaCompliance(this.id);
  }

  getHippaCompliance(id) {
    this.patientService.getHippaCompliance(id).subscribe((res) => {
      this.hippaComplianceList = res.HippaCompliances;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.hippaComplianceService.deleteHippaCompliance(id).subscribe((res) => {
      this.getHippaCompliance(this.id);
    });
  }
}
