import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-medicine',
  templateUrl: './update-medicine.component.html',
  styleUrls: ['./update-medicine.component.css']
})
export class UpdateMedicineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
