import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseHandOffNote } from 'src/app/Models/nursehandoffnote.model';
import { NurseHandOffNoteService } from 'src/app/services/nurse-hand-off-note.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-nurse-hand-off-note',
  templateUrl: './view-nurse-hand-off-note.component.html',
  styleUrls: ['./view-nurse-hand-off-note.component.css'],
})
export class ViewNurseHandOffNoteComponent implements OnInit {
  id = 0;
  CurrentNurseId = 0;
  NurseHandOffNoteList: NurseHandOffNote[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'Date',
    'PatientName',
    'PatientDOB',
    'WardName',
    'HandOffNote',
    'Update',
    'Delete',
  ];
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private nurseHandOffNoteService: NurseHandOffNoteService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentNurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getNurseHandOffNote(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getNurseHandOffNote(id) {
    this.patientService.getNurseHandOffNote(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.NurseHandOffNotes.sort(function (x, y) {
          return y.NurseHandOffNoteId - x.NurseHandOffNoteId;
        })
      );
      this.dataSource.paginator = this.paginator;

      this.NurseHandOffNoteList = res.NurseHandOffNotes;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.nurseHandOffNoteService.deleteNurseHandOffNote(id).subscribe((res) => {
      alert('deleted');
      this.getNurseHandOffNote(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getNurseHandOffNote(this.id);
    }
  }

  print() {
    window.print();
  }
}
