import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DutyScheduleItem } from 'src/app/Models/dutyschduleitem.model';
import { DutySchedule } from 'src/app/Models/dutyschedule.model';
import { Ward } from 'src/app/Models/ward.model';
import { DutyScheduleService } from 'src/app/services/duty-schedule.service';
import { DutyschduleitemService } from 'src/app/services/dutyschduleitem.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-view-duty-schedule',
  templateUrl: './view-duty-schedule.component.html',
  styleUrls: ['./view-duty-schedule.component.css'],
})
export class ViewDutyScheduleComponent implements OnInit {
  displayedColumns: string[] = ['Date', 'WeekCommencing', 'View', 'Delete'];
  dataSource: MatTableDataSource<any>;

  wardList: Ward[] = [];
  Frm: FormGroup;
  dutySchduleItem: DutySchedule[];
  reTest: DutyScheduleItem[];
  processedData: DutySchedule[];
  DutyScheduleItems: DutyScheduleItem[];

  constructor(
    private wardService: WardService,
    private dutyScheduleService: DutyScheduleService,
    private fb: FormBuilder,
    private dutyItemsService: DutyschduleitemService
  ) {}

  ngOnInit(): void {
    this.getWard();

    this.Frm = this.fb.group({
      WardId: [''],
    });
    this.getDutySchedule();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get WardId() {
    return this.Frm.get('WardId');
  }

  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  getDutySchedule() {
    this.wardService.getDutySchedule(this.WardId.value).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.DutySchedules.sort(function (x, y) {
          return y.DutyScheduleId - x.DutyScheduleId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.dutyScheduleService.deleteDutySchedule(id).subscribe((res) => {
      this.getDutySchedule();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: DutySchedule): void {
    this.DutyScheduleItems = report.DutyScheduleItems;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    }, 0);
  }
}
