import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { BookedslotsService } from 'src/app/services/bookedslots.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-all-appointments',
  templateUrl: './view-all-appointments.component.html',
  styleUrls: ['./view-all-appointments.component.css'],
})
export class ViewAllAppointmentsComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'Detail',
    'Delete',
  ];

  id = 0;
  Appointments: PatientBooking[] = [];

  filteredList;
  dataSource: MatTableDataSource<any>;

  constructor(
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private bookingService: BookedslotsService
  ) {}

  ngOnInit(): void {
    this.getAppt();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt() {
    this.appointmentService.getPatientBookings().subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.sort(function (x, y) {
          return y.PatientBookingId - x.PatientBookingId;
        })
      );
      this.dataSource.paginator = this.paginator;
      this.filteredList = res;

      this.Appointments = res;
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.Appointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.appointmentService.deletePatientBooking(id).subscribe((res) => {
      this.bookingService.deletePatientBookedSlot(id).subscribe();
      // alert('Cancelled')

      this.getAppt();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
