<app-navbar></app-navbar>

<div class="container mt-5 lgForm">
    <form [formGroup]="setpassForm" (ngSubmit)="formSubmit()">
        <div class="row">
            <div class="col-lg">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>New Password</mat-label>
                    <mat-icon class="icon_grey " matPrefix>vpn_key</mat-icon>
                    <input type="tel" formControlName="Password" matInput>
                    <mat-error *ngIf="Password.invalid && Password.touched">Please enter new password</mat-error>
                  </mat-form-field>
                <mat-form-field appearance="fill"   class="example-full-width">
                    <mat-label>Confirm Password</mat-label>
                    <mat-icon class="icon_grey " matPrefix>confirmation_number</mat-icon>
                    <input type="tel" formControlName="ConfirmPassword" matInput>
                    <mat-error *ngIf="ConfirmPassword.invalid && ConfirmPassword.touched">Password does not match</mat-error>
                  </mat-form-field>
                  <button class="btn lgBtCl float-right text-white"  mat-mini-fab>
                      <mat-icon matSuffix>save</mat-icon>
                      
                    </button>
            </div>
        </div>
    </form>
    </div>
