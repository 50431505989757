<div class="container" id="printable">
    <app-view-hospital></app-view-hospital>


    <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
        <div class="col-lg-6 ">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Search by Date</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

            </mat-form-field>
        </div>
    </div>

    <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>

    <button *ngIf="show" class="btn backG float-right mr-3" onClick="window.focus(); window.print()"
        value="print"><mat-icon>print</mat-icon></button>

    <div>
        <div class="">
            <h2 class="">Patient Information</h2>

            <div class="row">
                <div class="col-lg-2"><span class="bld">Patient No:</span>
                    {{patient?.PatientId}}</div>
                <div class="col-lg-3">
                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}} {{patient?.PatientLastName}}
                </div>
                <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
                <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
            </div>
        </div>
        <hr>



        <div class=" mt-5" *ngFor="let item of filteredList ">

            <div class="row">
                <div class="col-lg-6">
                    <h2 class="">Treatment Date: {{item?.TreatmentDate | date :'short'}}</h2>
                    <!-- | date: 'dd/MM/yyyy' -->
                </div>

            </div>
            <div class="row">
                <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item?.DoctorName}}</div>
                <div class="col-lg-2"><span class="bld">Episode:</span> {{item?.Episode}}</div>
                <div class="col-lg-3"><span class="bld">Referrer:</span> {{item?.Referrer }}</div>
                <div class="col-lg-3"><span class="bld">Next Follow Up:</span> {{item?.NextFollowUp }}</div>
                <!-- <div class="col-lg-3"><span class="bld">Visit Time:</span> {{item?.VistTime}}</div> -->
            </div>

            <hr>
            <div class="row my-2 mx-1 justify-content-center">
                <table class="table table-striped table-borderless">
                    <thead id="invoice-header">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Diagnosis Codes</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let it of item?.DiagnosisCodes; let i=index ">
                            <td scope="col">#{{i+1}}</td>
                            <td>{{it.Code}}</td>

                        </tr>

                    </tbody>

                </table>
            </div>
            <!-- <div class="row">
                <div class="col-lg-12"><span class="bld">Diagnosis Codes :</span> {{item?.DiagnosisCodes}}</div>
            </div> -->
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Subjective :</span> {{item?.Subjective}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Objective :</span> {{item?.Objective}}</div>
            </div>



            <hr>


            <div class="row">
                <div class="col-lg-12">



                    <h3 class="bld">Assessment </h3>

                    <div [innerHTML]="item?.Assessment"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">



                    <h3 class="bld">Plan </h3>

                    <div [innerHTML]="item?.Plan"></div>
                </div>
            </div>
            <h2 class="my-3">Treatment FlowSheet</h2>
            <h4>Manual Therapy</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Joint Mobilization :</span> {{item?.JointMobilization}}</div>
                <div class="col-lg-12"><span class="bld">Soft Tissue Mobilization :</span>
                    {{item?.SoftTissueMobilization}}</div>
                <div class="col-lg-12"><span class="bld">Stretching :</span> {{item?.Stretching}}</div>
            </div>
            <hr>
            <h2>Lower Extermity Exercise Flowsheet</h2>
            <h4>Other</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Single Leg Balance :</span> {{item?.SingleLegBalance}}</div>
                <div class="col-lg-12"><span class="bld">Step Ups :</span> {{item?.StepUps}}</div>
                <div class="col-lg-12"><span class="bld">Quad Str :</span> {{item?.QuadStr}}</div>
            </div>
            <hr>
            <h2>Spine Execise Flowsheet</h2>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Bird Dogs :</span> {{item?.BirdDogs}}</div>

            </div>
            <h4>Thoracic Extension</h4>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Rotations :</span> {{item?.Rotations}}</div>
                <div class="col-lg-12"><span class="bld">Pelvic Tilts :</span> {{item?.PelvicTilts}}</div>
                <div class="col-lg-12"><span class="bld">Abdominal ISOs :</span> {{item?.AbdominalISOs}}</div>
                <div class="col-lg-12"><span class="bld">Lion/Lat Stretch:</span> {{item?.LionLatStretch}}</div>
                <div class="col-lg-12"><span class="bld">ChildsPose:</span> {{item?.ChildsPose}}</div>
                <div class="col-lg-12"><span class="bld">Cat & CamelStretch:</span> {{item?.CatandCamelStretch}}</div>
                <div class="col-lg-12"><span class="bld">Downward Dog:</span> {{item?.DownwardDog}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">TimeIn :</span> {{item?.TimeIn}}</div>
                <div class="col-lg-12"><span class="bld">TimeOut :</span> {{item?.TimeOut}}</div>
                <div class="col-lg-12"><span class="bld">DoctorName :</span> {{item?.DoctorName}}</div>
                <div class="col-lg-12"><span class="bld">Signature :</span> {{item?.Signature}}</div>
            </div>


            <hr class="bg-primary">


            <hr>

        </div>
    </div>





</div>



<!-- Large modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">
    <mat-icon>print</mat-icon>
</button>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="container" id="printable">
                <app-view-hospital></app-view-hospital>






                <div class="row">
                    <div class="col-lg-6 "><span class="float-right"></span></div>
                    <div class="col-lg-6 " *ngIf="isHide">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Search by Date</mat-label>
                            <mat-icon matPrefix>search</mat-icon>
                            <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

                        </mat-form-field>
                    </div>
                </div>

                <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>


                <div>
                    <div class="">
                        <h2 class="">Patient Information</h2>

                        <div class="row">
                            <div class="col-lg-2"><span class="bld">Patient No:</span>
                                {{patient?.PatientId}}</div>
                            <div class="col-lg-3">
                                <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}}
                                {{patient?.PatientLastName}}
                            </div>
                            <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
                            <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                            <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
                        </div>
                    </div>
                    <hr>



                    <div class=" mt-5" *ngFor="let item of filteredList ">

                        <div class="row">
                            <div class="col-lg-6">
                                <h2 class="">Treatment Date: {{item?.TreatmentDate | date :'short'}}</h2>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item?.DoctorName}}</div>
                            <div class="col-lg-2"><span class="bld">Episode:</span> {{item?.Episode}}</div>
                            <div class="col-lg-3"><span class="bld">Referrer:</span> {{item?.Referrer }}</div>
                            <div class="col-lg-3"><span class="bld">Next Follow Up:</span> {{item?.NextFollowUp }}</div>
                        </div>

                        <hr>
                        <div class="row my-2 mx-1 justify-content-center">
                            <table class="table table-striped table-borderless">
                                <thead id="invoice-header">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Diagnosis Codes</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let it of item?.DiagnosisCodes; let i=index ">
                                        <td scope="col">#{{i+1}}</td>
                                        <td>{{it.Code}}</td>

                                    </tr>

                                </tbody>

                            </table>
                        </div>
                     
                        <hr>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Subjective :</span> {{item?.Subjective}}</div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Objective :</span> {{item?.Objective}}</div>
                        </div>



                        <hr>


                        <div class="row">
                            <div class="col-lg-12">



                                <h3 class="bld">Assessment </h3>

                                <div [innerHTML]="item?.Assessment"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">



                                <h3 class="bld">Plan </h3>

                                <div [innerHTML]="item?.Plan"></div>
                            </div>
                        </div>
                        <h2 class="my-3">Treatment FlowSheet</h2>
                        <h4>Manual Therapy</h4>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Joint Mobilization :</span>
                                {{item?.JointMobilization}}</div>
                            <div class="col-lg-12"><span class="bld">Soft Tissue Mobilization :</span>
                                {{item?.SoftTissueMobilization}}</div>
                            <div class="col-lg-12"><span class="bld">Stretching :</span> {{item?.Stretching}}</div>
                        </div>
                        <hr>
                        <h2>Lower Extermity Exercise Flowsheet</h2>
                        <h4>Other</h4>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Single Leg Balance :</span>
                                {{item?.SingleLegBalance}}</div>
                            <div class="col-lg-12"><span class="bld">Step Ups :</span> {{item?.StepUps}}</div>
                            <div class="col-lg-12"><span class="bld">Quad Str :</span> {{item?.QuadStr}}</div>
                        </div>
                        <hr>
                        <h2>Spine Execise Flowsheet</h2>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Bird Dogs :</span> {{item?.BirdDogs}}</div>

                        </div>
                        <h4>Thoracic Extension</h4>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">Rotations :</span> {{item?.Rotations}}</div>
                            <div class="col-lg-12"><span class="bld">Pelvic Tilts :</span> {{item?.PelvicTilts}}</div>
                            <div class="col-lg-12"><span class="bld">Abdominal ISOs :</span> {{item?.AbdominalISOs}}
                            </div>
                            <div class="col-lg-12"><span class="bld">Lion/Lat Stretch:</span> {{item?.LionLatStretch}}
                            </div>
                            <div class="col-lg-12"><span class="bld">ChildsPose:</span> {{item?.ChildsPose}}</div>
                            <div class="col-lg-12"><span class="bld">Cat & CamelStretch:</span>
                                {{item?.CatandCamelStretch}}</div>
                            <div class="col-lg-12"><span class="bld">Downward Dog:</span> {{item?.DownwardDog}}</div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-12"><span class="bld">TimeIn :</span> {{item?.TimeIn}}</div>
                            <div class="col-lg-12"><span class="bld">TimeOut :</span> {{item?.TimeOut}}</div>
                            <div class="col-lg-12"><span class="bld">DoctorName :</span> {{item?.DoctorName}}</div>
                            <div class="col-lg-12"><span class="bld">Signature :</span> {{item?.Signature}}</div>
                        </div>
                        <hr>

                        <hr class="bg-primary">


                        <hr>

                    </div>
                </div>





            </div>
        </div>
    </div>
</div> -->
<!-- Large modal -->