import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PurposefullRoundService } from 'src/app/services/purposefull-round.service';

@Component({
  selector: 'app-update-purposeful-round',
  templateUrl: './update-purposeful-round.component.html',
  styleUrls: ['./update-purposeful-round.component.css']
})
export class UpdatePurposefulRoundComponent  implements OnInit {

  helper = new JwtHelperService()

  myToken = window.localStorage.getItem('token');

  id=0;

nurseId=0;
  isSuccess= false;
  Frm: FormGroup;

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private purfullRoundService:PurposefullRoundService,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id'];
  })
  let decodedToken = this.helper.decodeToken(this.myToken);
  this.nurseId = parseInt(decodedToken.nameid);

this.purfullRoundService.getPurposefullRoundById(this.id).subscribe(res=>{
  this.Frm.patchValue(res);
})
this.Frm = this.fb.group({
  PatientId: this.id,
  NurseId:this.nurseId,
  PatientPosition:[],
  PatientSleeping:[],
  PatientUnAvailable:[],
  RnNotified:[],
  Other:[''],
  Agitated:[],
  EyesClosed:[],
  Quiet:[],
  Awake:[],
  InBed:[],
  PatientNotinRoom:[],
  Confused:[],
  InChair:[],
  OtherComment:[''],
})
  }




get PatientId(){
  return this.Frm.get('PatientId')
}
get NurseId(){
  return this.Frm.get('NurseId')
}
get PatientPosition(){
  return this.Frm.get('PatientPosition')
}
get PatientSleeping(){
  return this.Frm.get('PatientSleeping')
}
get PatientUnAvailable(){
  return this.Frm.get('PatientUnAvailable')
}
get RnNotified(){
  return this.Frm.get('RnNotified')
}
get Other(){
  return this.Frm.get('Other')
}
get Agitated(){
  return this.Frm.get('Agitated')
}
get EyesClosed(){
  return this.Frm.get('EyesClosed')
}
get Quiet(){
  return this.Frm.get('Quiet')
}
get Awake(){
  return this.Frm.get('Awake')
}
get InBed(){
  return this.Frm.get('InBed')
}
get PatientNotinRoom(){
  return this.Frm.get('PatientNotinRoom')
}
get Confused(){
  return this.Frm.get('Confused')
}
get InChair(){
  return this.Frm.get('InChair')
}
get OtherComment(){
  return this.Frm.get('OtherComment')
}

formSubmit(){

  this.purfullRoundService.updatePurposefullRound(this.id,this.Frm.value).subscribe(res=>{
    this.isSuccess=true;
    
     this.router.navigate(['nurseDash'])
  })

}


}

