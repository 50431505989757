import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NarcoticMedicalPolicyAgreement } from 'src/app/Models/narcoticmedicalpolicyagreement.model';
import { NarcoticMedicalPolicyService } from 'src/app/services/narcotic-medical-policy.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-view-narcotic-consent',
  templateUrl: './patient-view-narcotic-consent.component.html',
  styleUrls: ['./patient-view-narcotic-consent.component.css'],
})
export class PatientViewNarcoticConsentComponent implements OnInit {
  id = 0;
  narcoticList: NarcoticMedicalPolicyAgreement[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private NarcoticMedicalPolicyAgreementService: NarcoticMedicalPolicyService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getHippaCompliance(this.id);
  }

  getHippaCompliance(id) {
    this.patientService
      .GetNarcoticMedicalPolicyAgreement(id)
      .subscribe((res) => {
        this.narcoticList = res.NarcoticMedicalPolicyAgreements;
      });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.NarcoticMedicalPolicyAgreementService.deleteNarcoticMedicalPolicyAgreement(
      id
    ).subscribe((res) => {
      this.getHippaCompliance(this.id);
    });
  }
}
