

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Braden Scale</h1>
            <form [formGroup]="bradForm" (ngSubmit)="formSubmit()" class="example-form">
           

              <div class="row">
              

                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Moisture</mat-label>
                    
                        <mat-select formControlName="Moisture">
        
                          <mat-option *ngFor="let ms of Moistures" [value]="ms">{{ms}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Moisture Score</mat-label>
                  <input type="number"  formControlName="MoistureScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Moisture Score</mat-error>
                </mat-form-field>
              </div>

            </div>   
              <div class="row">
              

                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Activity</mat-label>
                    
                        <mat-select formControlName="Activity">
        
                          <mat-option *ngFor="let act of Activities" [value]="act">{{act}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Activity Score</mat-label>
                  <input type="number"  formControlName="ActivityScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Activity Score</mat-error>
                </mat-form-field>
              </div>

            </div>   

            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Mobility</mat-label>
                    
                        <mat-select formControlName="Mobility">
        
                          <mat-option *ngFor="let mbt of Mobilities" [value]="mbt">{{mbt}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Mobility Score</mat-label>
                  <input type="number"  formControlName="MobilityScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Mobility Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Nutrition</mat-label>
                    
                        <mat-select formControlName="Nutrition">
        
                          <mat-option *ngFor="let nt of Nutritions" [value]="nt">{{nt}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Nutrition Score</mat-label>
                  <input type="number"  formControlName="NutritionScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter NutritionScore</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6"> 
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Friction And Shear</mat-label>
                    
                        <mat-select formControlName="FrictionAndShear">
        
                          <mat-option *ngFor="let fs of FrictionAndShears" [value]="fs">{{fs}}</mat-option>
              
                        </mat-select>
                      </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Friction And Shear Score</mat-label>
                  <input type="number"  formControlName="FrictionAndShearScore" (keyup)="sum($event)" matInput >
                  <mat-error >Please enter Friction And Shear Score</mat-error>
                </mat-form-field>
              </div>
            </div>
            
            <div class="row">
                <div class="col-lg-6"> 
                   
                        <mat-label>Braden Scale Score</mat-label>
              
              </div>
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Final Score</mat-label>
                  <input type="number" value="{{score}}"  formControlName="BradenScaleScore" (keyup)="sum($event)" matInput >
                
                </mat-form-field>
              </div>
            </div>
            
            

                 
                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>

                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
              </form>
            
        </div>
    </div>

</div>

