<div class="conatiner mx-5 my-5" id="printable">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-10" *ngFor="let item of medicalHistoryList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Medical History</h2>

                <p class="float-right">Date : {{item.createdDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-6">
                            <tr>
                                <th> None Of Other Problems Listed : </th>

                                <td>{{item?.NoneOfOtherProblemsListed ?'Yes':'No'}}</td>
                            </tr>

                            <tr>
                                <th> Chestpain: </th>
                                <td>{{item?.Chestpain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Osteoporosis: </th>
                                <td>{{item?.Osteoporosis ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Allergies: </th>
                                <td>{{item?.Allergies ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Congestive heart failure: </th>
                                <td>{{item?.Congestiveheartfailure ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Hypertension: </th>
                                <td>{{item?.Hypertension ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Hypogonadismmale: </th>
                                <td>{{item?.Hypogonadismmale ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Pulmonaryembolism: </th>
                                <td>{{item?.Pulmonaryembolism ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Anemia: </th>
                                <td>{{item?.Anemia ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Chronicfatiguesyndrome: </th>
                                <td>{{item?.Chronicfatiguesyndrome ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Seizuredisorders: </th>
                                <td>{{item?.Seizuredisorders ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Arthritisconditions: </th>
                                <td>{{item?.Arthritisconditions ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Depression: </th>
                                <td>{{item?.Depression ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Infection problems: </th>
                                <td>{{item?.Infectionproblems ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Shortness Of Breath: </th>
                                <td>{{item?.ShortnessOfBreath ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Asthma: </th>
                                <td>{{item?.Asthma ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <tr>
                                <th> Diabetes: </th>
                                <td>{{item?.Diabetes ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Insomnia: </th>
                                <td>{{item?.Insomnia ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Sinus Conditions: </th>
                                <td>{{item?.SinusConditions ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Arterial Fibrillation: </th>
                                <td>{{item?.ArterialFibrillation ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Bleeding Problems: </th>
                                <td>{{item?.BleedingProblems ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> BPH: </th>
                                <td>{{item?.BPH ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> CAD coronary Artery Disease: </th>
                                <td>{{item?.CADcoronaryArteryDisease ?'Yes':'No'}}</td>

                            </tr>


                            <tr>
                                <th> Cancer: </th>
                                <td>{{item?.Cancer ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Cardiac Arrest: </th>
                                <td>{{item?.CardiacArrest ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Celiac Disease: </th>
                                <td>{{item?.CeliacDisease ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Drug Or AlcoholAbuse: </th>
                                <td>{{item?.DrugOrAlcoholAbuse ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Erectile Dysfunction: </th>
                                <td>{{item?.ErectileDysfunction ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fibromyalgia: </th>
                                <td>{{item?.Fibromyalgia ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Gerd: </th>
                                <td>{{item?.Gerd ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Heart Disease: </th>
                                <td>{{item?.HeartDisease ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Hyperinsulinemia: </th>
                                <td>{{item?.Hyperinsulinemia ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <tr>
                                <th> Hyperlipidemia: </th>
                                <td>{{item?.Hyperlipidemia ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Irritable Bowel Syndrome: </th>
                                <td>{{item?.IrritableBowelSyndrome ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Kidney Problems: </th>
                                <td>{{item?.KidneyProblems ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Menopause: </th>
                                <td>{{item?.Menopause ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Migraines Or Headaches: </th>
                                <td>{{item?.MigrainesOrHeadaches ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Neuropathy: </th>
                                <td>{{item?.Neuropathy ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Onychomycosis: </th>
                                <td>{{item?.Onychomycosis ?'Yes':'No'}}</td>

                            </tr>

                        </div>

                    </div>





                </mat-card-content>


            </mat-card>
        </div>
    </div>
</div>