import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseHandOffNote } from 'src/app/Models/nursehandoffnote.model';
import { Ward } from 'src/app/Models/ward.model';
import { NurseHandOffNoteService } from 'src/app/services/nurse-hand-off-note.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-view-nurse-hand-off-note-by-ward',
  templateUrl: './view-nurse-hand-off-note-by-ward.component.html',
  styleUrls: ['./view-nurse-hand-off-note-by-ward.component.css'],
})
export class ViewNurseHandOffNoteByWardComponent implements OnInit {
  id = 0;
  CurrentNurseId = 0;

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  NurseHandOffNoteList: NurseHandOffNote[] = [];
  NoteList: NurseHandOffNote[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'select',
    'Date',
    'PatientName',
    'PatientDOB',
    'WardName',
    'HandOffNote',
    'Update',
    'Delete',
  ];

  wardList: Ward[] = [];
  Frm: FormGroup;
  patientData;

  constructor(
    private wardService: WardService,
    private route: ActivatedRoute,
    private router: Router,
    private nurseHandOffNoteService: NurseHandOffNoteService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentNurseId = parseInt(decodedToken.nameid);
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getWard();
    this.Frm = this.fb.group({
      WardId: [''],
    });
    this.NoteList;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get WardId() {
    return this.Frm.get('WardId');
  }

  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  getWardHandOffNote() {
    this.wardService.getNurseHandOffNote(this.WardId.value).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.NurseHandOffNotes.sort(function (x, y) {
          return y.NurseHandOffNoteId - x.NurseHandOffNoteId;
        })
      );
      this.dataSource.paginator = this.paginator;

      this.NurseHandOffNoteList = res.NurseHandOffNotes;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectPatients(event, patient) {
    this.patientData = {
      NurseHandOffNoteId: patient.NurseHandOffNoteId,
      PatientName: patient.PatientName,
      PatientDOB: patient.PatientDOB,
      WardName: patient.WardName,
      HandOffNote: patient.HandOffNote,
    };
    let index = this.NoteList?.findIndex(
      (patient) =>
        patient?.NurseHandOffNoteId === this.patientData?.NurseHandOffNoteId
    );
    if (event.checked) {
      if (index > -1) {
        this.NoteList[index] = this.patientData;
      } else {
        this.NoteList.push(this.patientData);
      }
    } else if (index > -1) {
      this.NoteList.splice(index, 1);
    }
  }
  delete(id) {
    this.nurseHandOffNoteService.deleteNurseHandOffNote(id).subscribe((res) => {
      alert('deleted');
      this.getWardHandOffNote();
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getWardHandOffNote();
    }
  }

  print() {
    window.print();
  }
}
