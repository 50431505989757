<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">code_file</mat-icon>
      Braden Scale Risk Fall Assessment
    </ng-template>
    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Date </th>
          <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
        </ng-container>
        <ng-container matColumnDef="Moisture">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Moisture </th>
          <td mat-cell *matCellDef="let row"> {{row.Moisture}} </td>
        </ng-container>


        <ng-container matColumnDef="Activity">
          <th mat-header-cell *matHeaderCellDef> Activity </th>
          <td mat-cell *matCellDef="let row"> {{row.Activity}} </td>
        </ng-container>
        <ng-container matColumnDef="Mobility">
          <th mat-header-cell *matHeaderCellDef> Mobility </th>
          <td mat-cell *matCellDef="let row"> {{row.Mobility}} </td>
        </ng-container>

        <ng-container matColumnDef="Nutrition">
          <th mat-header-cell *matHeaderCellDef> Nutrition </th>
          <td mat-cell *matCellDef="let row"> {{row.Nutrition}} </td>
        </ng-container>
        <ng-container matColumnDef="FrictionAndShear">
          <th mat-header-cell *matHeaderCellDef> FrictionAndShear </th>
          <td mat-cell *matCellDef="let row"> {{row.FrictionAndShear}} </td>
        </ng-container>
        <ng-container matColumnDef="BradenScaleScore">
          <th mat-header-cell *matHeaderCellDef> BradenScaleScore </th>
          <td mat-cell *matCellDef="let row"> {{row.BradenScaleScore}} </td>
        </ng-container>

        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef> View Report </th>
          <td mat-cell *matCellDef="let row">


            <!-- modal  -->
            <button type="button" (click)="viewReport(row)" class="btn btn-primary" data-toggle="modal"
              data-target=".bd-example-modal-lg"> View Report</button>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content" id="printable">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Braden Scale Report</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" id="printable">
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Moisture:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Moisture"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MoistureScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Activity:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Activity"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="ActivityScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Mobility:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Mobility"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="MobilityScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Nutrition:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="Nutrition"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="NutritionScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Friction And Shear:</h4>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="FrictionAndShear"></div>

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="FrictionAndShearScore"></div>

                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-4">
                        <h4>Braden Scale Score:</h4>

                      </div>
                      <div class="col-md-4">

                      </div>
                      <div class="col-md-4">
                        <div [innerHTML]="BradenScaleScore"></div>

                      </div>
                    </div>
                    <div class="row mt-5">
                      <ul>
                        <li> <strong>Sever Risk</strong>: Total Score < 9 </li>
                        <li> <strong>High Risk</strong>: Total Score 10 - 12 </li>
                        <li> <strong>Moderate Risk</strong>: Total Score 13 - 14 </li>
                        <li> <strong>Mild Risk</strong>: Total Score 15 - 18 </li>



                      </ul>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                    <button type="button" class="btn btn-secondary mx-4" data-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>
            </div>


            <!-- modal  -->



          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      New Braden Scale Fall Risk Assessment
    </ng-template>

    <app-add-bradenscale></app-add-bradenscale>
  </mat-tab>


</mat-tab-group>