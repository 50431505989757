import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Ward } from 'src/app/Models/ward.model';
import { DutyRosterByShiftService } from 'src/app/services/duty-roster-by-shift.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-add-duty-roster-by-shift',
  templateUrl: './add-duty-roster-by-shift.component.html',
  styleUrls: ['./add-duty-roster-by-shift.component.css'],
})
export class AddDutyRosterByShiftComponent implements OnInit {
  id = 0;
  StatusList: string[] = ['Paid', 'UnPaid', 'Pending'];
  Frm: UntypedFormGroup;
  isSuccess = false;
  wardList: Ward[];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private wardService: WardService,
    private dutyRosterbyShiftService: DutyRosterByShiftService
  ) {}

  ngOnInit(): void {
    this.getWards();

    this.Frm = this.fb.group({
      WardId: [],
      ChargeNurse: ['', Validators.required],
      UnitSecretory: [''],
      Shift: [''],
      MT: [''],
      Census: [''],
      Sitters: [''],
      CentralLines: [''],
      Inmates: [''],
      DNR: [''],
      PendingTransfer: [''],
      PendingDC: [''],
      Foleys: [''],
      Restraints: [''],
      Isolations: [''],
      VacantBeds: [''],
      Falls: [''],
      Plastics: [''],
      DutyRosterItems: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      Phones: [''],
      Names: [''],
      Profile: [''],
      Assignments: [''],
      Admission: [''],
      Discharge: [''],
    });
    this.DutyRosterItems.push(item);
  }

  public removeItem(i: number): void {
    this.DutyRosterItems.removeAt(i);
  }

  get DutyRosterItems(): UntypedFormArray {
    return this.Frm.get('DutyRosterItems') as UntypedFormArray;
  }
  get WardId() {
    return this.Frm.get('WardId');
  }
  get Plastics() {
    return this.Frm.get('Plastics');
  }

  get Bariatrics() {
    return this.Frm.get('Bariatrics');
  }

  get Falls() {
    return this.Frm.get('Falls');
  }

  get VacantBeds() {
    return this.Frm.get('VacantBeds');
  }

  get Isolations() {
    return this.Frm.get('Isolations');
  }

  get Restraints() {
    return this.Frm.get('Restraints');
  }

  get Foleys() {
    return this.Frm.get('Foleys');
  }

  get PendingDC() {
    return this.Frm.get('PendingDC');
  }

  get PendingTransfer() {
    return this.Frm.get('PendingTransfer');
  }

  get DNR() {
    return this.Frm.get('DNR');
  }

  get Inmates() {
    return this.Frm.get('Inmates');
  }

  get CentralLines() {
    return this.Frm.get('CentralLines');
  }

  get Sitters() {
    return this.Frm.get('Sitters');
  }

  get Census() {
    return this.Frm.get('Census');
  }

  get MT() {
    return this.Frm.get('MT');
  }

  get Shift() {
    return this.Frm.get('Shift');
  }

  get UnitSecretory() {
    return this.Frm.get('UnitSecretory');
  }

  get ChargeNurse() {
    return this.Frm.get('ChargeNurse');
  }

  get Phones() {
    return this.Frm.get('Phones');
  }
  get Names() {
    return this.Frm.get('Names');
  }

  get Profile() {
    return this.Frm.get('Profile');
  }
  get Assignments() {
    return this.Frm.get('Assignments');
  }
  get Admission() {
    return this.Frm.get('Admission');
  }

  get Discharge() {
    return this.Frm.get('Discharge');
  }

  getWards() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  formSubmit() {
    let wardId = this.Frm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;

    let body = {
      ...this.Frm.value,
      WardName: wardName,
      WardId: wardId,
    };

    this.dutyRosterbyShiftService
      .registerDutyRosterByShift(body)
      .subscribe((_) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
