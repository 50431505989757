
<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Company </h1>
            <form [formGroup]="addFrm" (ngSubmit)="formSubmit()" class="example-form">
              <div class="row">
                <div class="col-lg">
                  <mat-form-field class="example-full-width">
                    <mat-label>User Name</mat-label>
                    <input type="text" formControlName="UserName" matInput >
                    <mat-error >Please enter UserName</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Company Name</mat-label>
                    <input type="text" formControlName="CompanyName" matInput >
                    <mat-error >Please enter Company Name</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" formControlName="Email" matInput >
                    <mat-error >Please enter Email</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Password</mat-label>
                    <input type="text" formControlName="Password" matInput >
                    <mat-error >Please enter Password</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Phone Number</mat-label>
                    <input type="text" formControlName="PhoneNumber" matInput >
                    <mat-error >Please enter Phone Number</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Address</mat-label>
                    <input type="text" formControlName="Address" matInput >
                    <mat-error >Please enter Address</mat-error>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>ContactPerson</mat-label>
                    <input type="text" formControlName="ContactPerson" matInput >
                    <mat-error >Please enter ContactPerson</mat-error>
                  </mat-form-field>



                 </div>
                  </div>
                  <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    </button>
                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
            </form>
          
        </div>
       
    </div>
</div>