import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { TicketToRide } from 'src/app/Models/tickettoride.model';
import { PatientService } from 'src/app/services/patient.service';
import { TickettorideService } from 'src/app/services/tickettoride.service';

@Component({
  selector: 'app-view-ticket-to-ride',
  templateUrl: './view-ticket-to-ride.component.html',
  styleUrls: ['./view-ticket-to-ride.component.css'],
})
export class ViewTicketToRideComponent implements OnInit {
  panelOpenState = false;
  id = 0;
  ticketToRideList: TicketToRide[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private ticketToRideService: TickettorideService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getTicketToRide(this.id);
  }

  getTicketToRide(id) {
    this.patientService.getTicketToRide(id).subscribe((res) => {
      this.ticketToRideList = res.TicketToRides.sort(function (x, y) {
        return y.TicketToRideId - x.TicketToRideId;
      });
    });
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getTicketToRide(this.id);
    }
  }

  delete(id) {
    this.ticketToRideService.deleteTicketToRide(id).subscribe((res) => {
      this.getTicketToRide(this.id);
    });
  }
}
