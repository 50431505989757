import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { NurseDailyPatient } from 'src/app/Models/nursedailypatient.model';
import { Patient } from 'src/app/Models/patient.model';
import { DepartmentService } from 'src/app/services/department.service';
import { NursedailypatientsService } from 'src/app/services/nursedailypatients.service';
import { UserService } from 'src/app/services/user.service';
import { WardService } from 'src/app/services/ward.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Ward } from 'src/app/Models/ward.model';

@Component({
  selector: 'app-view-ward-patients',
  templateUrl: './view-ward-patients.component.html',
  styleUrls: ['./view-ward-patients.component.css'],
})
export class ViewWardPatientsComponent implements OnInit {
  @Input() isNurse: boolean;
  @Output() selectedPatientIdsEmitter = new EventEmitter<any>();
  @Output() navigation = new EventEmitter<any>();
  selectedPatientIds: string[] = [];
  nursePatient: NurseDailyPatient[] = [];
  displayedColumns: string[] = [
    'select',
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'WardName',
    'RoomNo',
    'View',
    'Edit',
    // 'Delete',
  ];
  id = 0;
  patientList: Patient[] = [];
  wardList: Ward[] = [];

  filteredList;
  dptList: Department;

  dataSource: MatTableDataSource<Patient>;

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  patientData;
  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;

  constructor(
    private wardService: WardService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private userService: UserService,
    private nursePatientService: NursedailypatientsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    // this.getDptPatients(this.id)

    this.getWard();

    this.Frm = this.fb.group({
      WardId: [''],
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  get WardId() {
    return this.Frm.get('WardId');
  }

  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  getPatients() {
    this.wardService.getWardPatients(this.WardId.value).subscribe((res) => {
      //  this.filteredList= res

      this.dataSource = new MatTableDataSource(
        res.Patients.sort(function (x, y) {
          return y.PatientId - x.PatientId;
        })
      );
      this.dataSource.paginator = this.paginator;
      // this.patientList=res;
    });
    if (this.isNurse) {
      this.displayedColumns.splice(0, 0, 'select', 'Id');
      this.displayedColumns = this.displayedColumns.filter((column, id) => {
        if (column === 'Edit' || column === 'Delete') {
          return;
        }
        return column;
      });
    }
  }

  // deletePatient(id) {
  //   this.patientService.deletePatient(id).subscribe((res) => {
  //     this.userService.deleteUser(id).subscribe(
  //       (res) => {
  //         this.getPatients();
  //       },
  //       (error) => console.log(error)
  //     );
  //   });
  // }

  selectPatients(event, patient) {
    this.patientData = {
      PatientId: patient.PatientId,
      PatientFirstName: patient.PatientFirstName,
      PatientLastName: patient.PatientLastName,
      PatientDOB: patient.PatientDOB,
      PatientNationalId: patient.PatientNationalId,
      PatientGender: patient.PatientGender,
      PatientEmail: patient.PatientEmail,
      PatientPhone: patient.PatientPhone,
      PrimaryDoctorsName: patient.PrimaryDoctorsName,
      WardName: patient.WardName,
      RoomNo: patient.RoomNo,
      NurseId: this.nurseId,
    };
    let index = this.patientList?.findIndex(
      (patient) => patient?.PatientId === this.patientData?.PatientId
    );
    if (event.checked) {
      if (index > -1) {
        this.patientList[index] = this.patientData;
      } else {
        this.patientList.push(this.patientData);
      }
    } else if (index > -1) {
      this.patientList.splice(index, 1);
    }
  }

  emitSelectedIds() {
    this.selectedPatientIdsEmitter.emit(this.nursePatient);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectedPatient() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Assign Patient to me',
        content:
          'Are you sure you want to assign selected patient to your Work Space?',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.patientList.forEach((patient) => {
            this.nursePatientService
              .registerNurseDailyPatient(patient)
              .subscribe((res) => {
                this.isSuccess = true;
              });
          });
          setTimeout(() => {
            this.navigation.emit();
          }, 2000);
        }
      },
    });
  }

  print() {
    window.print();
  }
}
