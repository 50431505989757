<!-- <div id="dashboard"> -->
  
    <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
           <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
           <div class="toolbar mat-elevation-z6" >

            <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>
 
            <mat-menu  class="ItemS" #crt="matMenu">
             
              <div class="ItemS " >
                <mat-icon mat-list-icon>Lab Dashboard</mat-icon>
                <div class="mx-2"  [routerLink]="['/labDash/']">dashboard</div>
                <mat-icon>navigate_next</mat-icon>
              </div>
            <div class="ItemS "  (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
             <mat-icon mat-list-icon>summarize</mat-icon>
             <div class="mx-2">PT Summary</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
           
             
 
             <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
               <mat-icon mat-list-icon>add</mat-icon>
               <div class="mx-2">New Lab-Report</div>
               <mat-icon>navigate_next</mat-icon>
             </div>
 
             <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
               <mat-icon mat-list-icon>science</mat-icon>
               <div class="mx-2">View Lab-Report</div>
               <mat-icon>navigate_next</mat-icon>
             </div>
             <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
               <mat-icon mat-list-icon>draft_orders</mat-icon>
               <div class="mx-2">View  Lab Order</div>
               <mat-icon>navigate_next</mat-icon>
             </div>
 
            
         </mat-menu>
     </div> 
             
           <!-- <div class="ItemS  " >
             <mat-icon mat-list-icon>add</mat-icon>
             <div class="mx-2">New Appointment</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
           <div class="ItemS  " >
             <mat-icon mat-list-icon>visibility</mat-icon>
             <div class="mx-2">Medical Records</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
            -->
         
          
 
 <!-- 
         </div> -->
         <!-- <div class="main">
     <div class="toolbar mat-elevation-z6" ></div> -->
 
     <span [ngSwitch]="showItem">
         
       <p *ngSwitchCase="'1'">
         <app-patientsummary></app-patientsummary>
       </p>
      
   
       <p *ngSwitchCase="'2'">
         <app-add-labreport></app-add-labreport>
       </p>
       <p *ngSwitchCase="'3'">
         <app-view-labreport></app-view-labreport>
       </p>
       
       <p *ngSwitchCase="'4'">
         <app-view-lab-order></app-view-lab-order>
       </p>
       
 
 
 
       <p  *ngSwitchDefault>
 
        <app-view-labreport></app-view-labreport>
     </p>
 
 
 
 
 
      
 
     </span>
 
         <!-- </div>
     </div> -->
 <!-- </div> -->
 