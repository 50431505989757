<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="PatientId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Patient Id </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
        </ng-container>


        <ng-container matColumnDef="DoctorName">
            <th mat-header-cell *matHeaderCellDef> Doctor Name </th>
            <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
        </ng-container>
        <ng-container matColumnDef="DoctorSpecialization">
            <th mat-header-cell *matHeaderCellDef> Doctor Specialization </th>
            <td mat-cell *matCellDef="let row"> {{row.DoctorSpecialization}} </td>
        </ng-container>

        <ng-container matColumnDef="VisitDate">
            <th mat-header-cell *matHeaderCellDef> VisitDate </th>
            <td mat-cell *matCellDef="let row"> {{row.VisitDate}} </td>
        </ng-container>
        <ng-container matColumnDef="VisitTime">
            <th mat-header-cell *matHeaderCellDef> VisitTime </th>
            <td mat-cell *matCellDef="let row"> {{row.VisitTime}} </td>
        </ng-container>
        <ng-container matColumnDef="Message">
            <th class="message" mat-header-cell *matHeaderCellDef> Message </th>
            <td class="message" mat-cell *matCellDef="let row"> {{row.Message}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="Detail">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
                <button type="button" [routerLink]="['/editAppointment/',element.PatientBookingId]" mat-mini-fab
                    color="primary">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element">
                <button (click)="delete(element.PatientBookingId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>