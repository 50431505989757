import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { UserService } from '../../services/user.service';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { WardService } from '../../services/ward.service';
import { Ward } from 'src/app/Models/ward.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { Department } from 'src/app/Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { Room } from 'src/app/Models/room.model';
import { Bed } from 'src/app/Models/bed.model';
import { BedService } from 'src/app/services/bed.service';
import { RoomService } from 'src/app/services/room.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-addpatient',
  templateUrl: './addpatient.component.html',
  styleUrls: ['./addpatient.component.css'],
})
export class AddpatientComponent implements OnInit {
  isSuccess = false;
  id = 0;
  Gender: string[] = ['Male', 'Female', 'Other'];
  bloodGroup: string[] = ['A+', 'B+', 'O+', 'AB+', 'A-', 'O-', 'B-', 'AB-'];
  maritalStatus: string[] = ['Married', 'Single', 'Widow', 'Other'];
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];
  departmentList: Department[];
  wardList: Ward[];
  roomList: Room[];
  bedList: Bed[];
  imageUrl: string;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    private doctorService: DoctorService,
    private wardService: WardService,
    private roomService: RoomService,
    private bedService: BedService,
    private departmentService: DepartmentService,
    private http: HttpClient,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDoctors();
    this.getDeparments();
    this.getWards();

    this.countryCodes();
    this.filteredOptions = this.ptFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  ptFrm = this.fb.group({
    PatientFirstName: ['', Validators.required],
    PatientLastName: ['', Validators.required],
    PatientDOB: ['', Validators.required],
    PatientGender: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    PatientAddress: ['', Validators.required],
    DoctorName: ['', Validators.required],
    PatientNationalId: ['', Validators.required],
    MaritalStatus: ['', Validators.required],
    Occupation: [''],
    BloodGroup: ['', Validators.required],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
    DoctorId: [],
    WardName: [''],
    RoomNo: [''],
    RoomId: [''],
    BedId: [''],
    BedNumber: [''],
    NurseName: [''],
    WardId: [],
    DepartmentName: [],
    DepartmentId: [],
    ReferredDoctor: [''],
    PatientHeight: [''],
    PatientWeight: [''],
    NameOfParent: [''],
  });

  get BedId() {
    return this.ptFrm.get('BedId');
  }
  get RoomId() {
    return this.ptFrm.get('RoomId');
  }
  get NameOfParent() {
    return this.ptFrm.get('NameOfParent');
  }
  get CountryCode() {
    return this.ptFrm.get('CountryCode');
  }

  get PatientHeight() {
    return this.ptFrm.get('PatientHeight');
  }

  get PatientWeight() {
    return this.ptFrm.get('PatientWeight');
  }

  get ReferredDoctor() {
    return this.ptFrm.get('ReferredDoctor');
  }
  get DepartmentName() {
    return this.ptFrm.get('DepartmentName');
  }
  get DepartmentId() {
    return this.ptFrm.get('DepartmentId');
  }
  get WardId() {
    return this.ptFrm.get('WardId');
  }

  get WardName() {
    return this.ptFrm.get('WardName');
  }
  get RoomNo() {
    return this.ptFrm.get('RoomNo');
  }
  get BedNumber() {
    return this.ptFrm.get('BedNumber');
  }
  get NurseName() {
    return this.ptFrm.get('NurseName');
  }

  get DoctorId() {
    return this.ptFrm.get('DoctorId');
  }
  get PatientFirstName() {
    return this.ptFrm.get('PatientFirstName');
  }
  get PatientLastName() {
    return this.ptFrm.get('PatientLastName');
  }
  get PatientDOB() {
    return this.ptFrm.get('PatientDOB');
  }
  get PatientGender() {
    return this.ptFrm.get('PatientGender');
  }
  get Email() {
    return this.ptFrm.get('Email');
  }
  get PhoneNumber() {
    return this.ptFrm.get('PhoneNumber');
  }
  get PatientAddress() {
    return this.ptFrm.get('PatientAddress');
  }

  get DoctorName() {
    return this.ptFrm.get('DoctorName');
  }
  get PatientNationalId() {
    return this.ptFrm.get('PatientNationalId');
  }
  get MaritalStatus() {
    return this.ptFrm.get('MaritalStatus');
  }
  get Occupation() {
    return this.ptFrm.get('Occupation');
  }
  get BloodGroup() {
    return this.ptFrm.get('BloodGroup');
  }
  get Password() {
    return this.ptFrm.get('Password');
  }

  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  getRooms() {
    this.wardService.getWardRooms(this.WardId.value).subscribe((res) => {
      this.roomList = res.Rooms;
    });
  }
  getBeds() {
    this.roomService.getRoomBeds(this.RoomId.value).subscribe((res) => {
      this.bedList = res.Beds;
    });
  }

  getWards() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }
  getDeparments() {
    this.departmentService.getDepartments().subscribe((res) => {
      this.departmentList = res;
    });
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.patientService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(e): void {
    e.preventDefault;
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let referredDoctor = this.ptFrm.value['ReferredDoctor'];
    let nameOfParent = this.ptFrm.value['NameOfParent'];
    let patientFirstName = this.ptFrm.value['PatientFirstName'];
    let patientLastName = this.ptFrm.value['PatientLastName'];
    let patientDOB = this.ptFrm.value['PatientDOB'];
    patientDOB = moment(patientDOB).format('MM/DD/YYYY');

    let patientGender = this.ptFrm.value['PatientGender'];
    let email = this.ptFrm.value['Email'];
    let phoneNumber =
      this.ptFrm.value['CountryCode'] + this.ptFrm.value['PhoneNumber'];
    let patientAddress = this.ptFrm.value['PatientAddress'];
    let patientWeight = this.ptFrm.value['PatientWeight'];
    let patientHeight = this.ptFrm.value['PatientHeight'];

    let patientNationalId = this.ptFrm.value['PatientNationalId'];
    let doctorsName = this.ptFrm.value['DoctorName'];
    let mMaritalStatus = this.ptFrm.value['MaritalStatus'];
    let occupation = this.ptFrm.value['Occupation'];
    let bloodGroup = this.ptFrm.value['BloodGroup'];
    let password = this.ptFrm.value['Password'];
    let doctorId = this.ptFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;
    let roomId = this.ptFrm.value['RoomId'];
    let roomNo = this.roomList.filter((id) => id.RoomId == roomId)[0].RoomNo;
    let bedId = this.ptFrm.value['BedId'];
    let bedNumber = this.bedList.filter((id) => id.BedId == bedId)[0].BedNumber;
    let wardId = this.ptFrm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;
    let departmentId = this.ptFrm.value['DepartmentId'];
    let departmentName = this.departmentList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: phoneNumber,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerUser(userBody).subscribe((res) => {
      let body = {
        PatientId: res.Id,
        DoctorId: doctorId,
        WardId: wardId,
        WardName: wardName,
        RoomNo: roomNo,
        BedNumber: bedNumber,
        DepartmentId: departmentId,
        DepartmentName: departmentName,
        PatientFirstName: patientFirstName,
        PatientLastName: patientLastName,
        PatientDOB: patientDOB,
        PatientGender: patientGender,
        Email: email,
        Password: password,
        PhoneNumber: phoneNumber,
        PatientAddress: patientAddress,
        PatientHeight: patientHeight,
        PatientWeight: patientWeight,
        NameOfParent: nameOfParent,
        DoctorName: idoctorName,
        ReferredDoctor: referredDoctor,
        PatientNationalId: patientNationalId,
        MaritalStatus: mMaritalStatus,
        Occupation: occupation,
        BloodGroup: bloodGroup,
        PatientImage: this.sysImage ? this.sysImage : this.imageUrl,
        Status: 'Active',
      };

      this.patientService.registerPatient(body).subscribe({
        next: (res) => {
          this.isSuccess = true;
          this.ptFrm.reset();

          this.emailService
            .sendEmail({
              Email: body.Email,
              Subject: 'Patient Login Credentials',
              Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binsera.net. </h3> `,
            })
            .subscribe();

          const smsBodyDetails =
            'Your Username ' +
            userBody.UserName +
            ' You may reset password now at https://binsera.net';
          let smsBody = {
            PhoneNumber: phoneNumber,
            Body: smsBodyDetails,
          };
          this.emailService.sendSms(smsBody).subscribe();
          this.isSuccess = true;
          this.ptFrm.reset();

          setTimeout(() => {}, 2000);
        },
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
