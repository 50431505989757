import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BloodTransfusionConsent } from 'src/app/Models/bloodtransfusionconsent.model';
import { BloodTransfusionConsentService } from 'src/app/services/blood-transfusion-consent.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-blood-transfusion-consent',
  templateUrl: './view-blood-transfusion-consent.component.html',
  styleUrls: ['./view-blood-transfusion-consent.component.css'],
})
export class ViewBloodTransfusionConsentComponent implements OnInit {
  id = 0;
  BloodTransfusionConsentList: BloodTransfusionConsent[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private bloodTransfusionConsentService: BloodTransfusionConsentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getBloodTransfusionConsent(this.id);
  }

  getBloodTransfusionConsent(id) {
    this.patientService.getBloodTransfusionConsent(id).subscribe((res) => {
      this.BloodTransfusionConsentList = res.BloodTransfusionConsents.sort(
        function (x, y) {
          return y.BloodTransfusionConsentId - x.BloodTransfusionConsentId;
        }
      );
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.bloodTransfusionConsentService
      .deleteBloodTransfusionConsent(id)
      .subscribe((res) => {
        this.getBloodTransfusionConsent(this.id);
      });
  }
}
