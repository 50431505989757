import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../../services/department.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HospitalService } from 'src/app/services/hospital.service';
import { Hospital } from 'src/app/Models/hospital.model';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css'],
})
export class AddDepartmentComponent implements OnInit {
  id = 0;
  hospitalList: Hospital[] = [];

  isSuccess = false;

  constructor(
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private hospitalService: HospitalService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getHospital();
  }

  dptFrm = this.fb.group({
    DepartmentName: ['', Validators.required],
    Description: ['', Validators.required],
    HospitalId: ['', Validators.required],
  });

  get HospitalId() {
    return this.dptFrm.get('HospitalId');
  }
  get DepartmentName() {
    return this.dptFrm.get('DepartmentName');
  }

  get Description() {
    return this.dptFrm.get('Description');
  }

  getHospital() {
    this.hospitalService.getHospitals().subscribe((res) => {
      this.hospitalList = res;
    });
  }

  formSubmit() {
    let dptName = this.dptFrm.value['DepartmentName'];
    let dptDescription = this.dptFrm.value['Description'];
    let hospitalId = this.dptFrm.value['HospitalId'];
    let hospitalName = this.hospitalList.filter(
      (it) => it.HospitalId == hospitalId
    )[0].HospitalName;

    let body = {
      HospitalId: hospitalId,
      DepartmentName: dptName,
      Description: dptDescription,
      HospitalName: hospitalName,
    };

    this.departmentService.registerDepartment(body).subscribe((res) => {
      this.isSuccess = true;
      this.dptFrm.reset();
    });
  }
}
