import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MedicationService} from '../../services/medication.service'

@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
export class MedicationComponent implements OnInit {

  constructor(private fb:UntypedFormBuilder,private medService:MedicationService,private router:Router ){ }

  ngOnInit(): void {
  }

  medForm = this.fb.group({
    Drug:['',Validators.required],
    Status:['',Validators.required],
    Date:['',Validators.required],
    Strength:['',Validators.required],
    Instruction:['',Validators.required]
  })

get _Drug(){
  return this.medForm.get('Drug')
}
get _Status(){
  return this.medForm.get('Status')
}
get _Date(){
  return this.medForm.get('Date')
}
get _Strength(){
  return this.medForm.get('Strength')
}
get _Instruction(){
  return this.medForm.get('Instruction')
}








  formSubmit(){

    let drug = this.medForm.value['Drug']
    let status = this.medForm.value['Status']
    let date = this.medForm.value['Date']
    let strength = this.medForm.value['Strength']
    let instruction = this.medForm.value['Instruction']


    let body ={
      "Drug":drug,
       "Status":status,
       "Date":date,
       "Strength":strength,
       "Instruction":instruction
    }

   

  }

}
