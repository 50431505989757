<button type="button" color="primary" mat-mini-fab data-toggle="modal" data-target=".bd-example-modal-lg">
  <mat-icon>print</mat-icon>
</button>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Report </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body " id="printable">

        <div class="container">
          <app-view-hospital></app-view-hospital>






          <!-- <div class="row">
    <div class="col-lg-6 "><span class="float-right"></span></div>
    <div class="col-lg-6 ">
      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Search by Date</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

      </mat-form-field>
    </div>
  </div> -->

          <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>

          <button class="btn backG float-right mr-3" (click)="printPage()"><mat-icon>print</mat-icon></button>

          <div class="">
            <h2 class="">Patient Information</h2>

            <div class="row" *ngIf="patient">
              <div class="col-lg-2"><span class="bld">Patient No:</span>
                {{patient.PatientId}}</div>
              <div class="col-lg-3">
                <span class="bld">Patient Name:</span> {{patient.PatientFirstName}} {{patient.PatientLastName}}
              </div>
              <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient.PatientDOB}}</div>
              <div class="col-lg-2"><span class="bld">SSN:</span> {{patient.PatientNationalId}}</div>
              <div class="col-lg-3"><span class="bld">Address:</span> {{patient.PatientAddress}}</div>
            </div>
          </div>
          <hr>



          <div class=" mt-5" *ngFor="let item of filteredList ">

            <div class="row">
              <div class="col-lg-6">
                <h2 class="">Patient Visits Date: {{item?.VistitDate }}
                </h2>

              </div>

            </div>
            <div class="row">
              <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item.DoctorName}}</div>
              <div class="col-lg-3"> <span class="bld">Doctor Speciality:</span> {{item.DoctorSpecialization}} </div>
              <div class="col-lg-2"><span class="bld">Visit Type:</span> {{item.VisitType}}</div>
              <div class="col-lg-2"><span class="bld">Visit Date:</span> {{item.VistitDate}}</div>
              <div class="col-lg-3"><span class="bld">Visit Time:</span> {{item.VistTime}}</div>
            </div>

            <hr>
            <div class="row">

              <div class="col-lg-2"><span class="bld">BloodPressure:</span> {{item.BloodPressure}}</div>
              <div class="col-lg-2"><span class="bld">Pulse:</span> {{item.Pulse}}</div>

            </div>

            <hr>
            <div class="row">
              <div class="col-lg-12"><span class="bld">Subjective :</span> {{item?.Subjective}}</div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12"><span class="bld">Objective :</span> {{item?.Objective}}</div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12"><span class="bld">Assessment :</span> {{item?.Assessment}}</div>
            </div>

            <!-- <div class="row">
  <div class="col-lg-6">
<h3 class="bld">Patient Complaints</h3>

      {{item.PatientComplaints}}
  </div>
  <div class="col-lg-6">
<h3 class="bld"> Issues Started From</h3>

      {{item.IssueStartDate}}
  </div>
 
</div>

<hr> -->

            <!-- <div class="row">
  <div class="col-lg-6">
      <h3 class="bld">Dignosis</h3>
  
              {{item.Dignosis}}
          </div>
  <div class="col-lg-6">
<h3 class="bld">Findings</h3>

      {{item.Findings}}
  </div>
  
</div> -->


            <hr>


            <div class="row">
              <div class="col-lg-12">
                <!-- <h3 class="bld">Treatments</h3>
  
              {{item.Treatments}}
          </div> -->


                <h3 class="bld">Tretment Plan (Patient Note)</h3>

                {{item.PatientNote}}
              </div>
              <!-- <div class="col-lg-2"><h3 class="bld">ConsultationFee:</h3> {{item.ConsultationFee}}</div> -->
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-6">
                <h3 class="bld">NextFollowUp: </h3> {{item.NextFollowUp}}
              </div>
              <div class="col-lg-6">
                <h3 class="bld">Signature:</h3> {{item.Signature}}
              </div>
            </div>
            <!-- 
    <button class="float-right" [routerLink]="['/updateNursePatientVisit/',item.PatientVisitId]" color="primary"
      mat-mini-fab>
      <mat-icon matPrefix>edit</mat-icon>
    </button> -->
            <!-- <button  class="btn btn-danger mx-3 my-3 " (click)="deleteVisit(item.PatientVisitId)"
color="success" mat-mini-fab>
<mat-icon matPrefix>delete</mat-icon>
</button>
<hr class="bg-primary"> -->




          </div>


          <hr>


        </div>
      </div>

      <div class="modal-footer">
        <button type="button" (click)="printPage()" class="btn btn-primary ">Print</button>

        <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>








<div class="container">
  <app-view-hospital></app-view-hospital>






  <div class="row">
    <div class="col-lg-6 "><span class="float-right"></span></div>
    <div class="col-lg-6 ">
      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Search by Date</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

      </mat-form-field>
    </div>
  </div>

  <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>

  <button class="btn backG float-right mr-3" (click)="printPage()"><mat-icon>print</mat-icon></button>

  <div class="">
    <h2 class="">Patient Information</h2>

    <div class="row" *ngIf="patient">
      <div class="col-lg-2"><span class="bld">Patient No:</span>
        {{patient.PatientId}}</div>
      <div class="col-lg-3">
        <span class="bld">Patient Name:</span> {{patient.PatientFirstName}} {{patient.PatientLastName}}
      </div>
      <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient.PatientDOB}}</div>
      <div class="col-lg-2"><span class="bld">SSN:</span> {{patient.PatientNationalId}}</div>
      <div class="col-lg-3"><span class="bld">Address:</span> {{patient.PatientAddress}}</div>
    </div>
  </div>
  <hr>



  <div class=" mt-5" *ngFor="let item of filteredList ">

    <div class="row">
      <div class="col-lg-6">
        <h2 class="">Patient Visits Date: {{item?.VistitDate }}
        </h2>

      </div>

    </div>
    <div class="row">
      <div class="col-lg-2"> <span class="bld">Doctor:</span> {{item.DoctorName}}</div>
      <div class="col-lg-3"> <span class="bld">Doctor Speciality:</span> {{item.DoctorSpecialization}} </div>
      <div class="col-lg-2"><span class="bld">Visit Type:</span> {{item.VisitType}}</div>
      <div class="col-lg-2"><span class="bld">Visit Date:</span> {{item.VistitDate}}</div>
      <div class="col-lg-3"><span class="bld">Visit Time:</span> {{item.VistTime}}</div>
    </div>

    <hr>
    <div class="row">

      <div class="col-lg-2"><span class="bld">BloodPressure:</span> {{item.BloodPressure}}</div>
      <div class="col-lg-2"><span class="bld">Pulse:</span> {{item.Pulse}}</div>

    </div>

    <hr>
    <div class="row">
      <div class="col-lg-12"><span class="bld">Subjective :</span> {{item?.Subjective}}</div>
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-12"><span class="bld">Objective :</span> {{item?.Objective}}</div>
    </div>
    <hr>
    <div class="row">
      <div class="col-lg-12"><span class="bld">Assessment :</span> {{item?.Assessment}}</div>
    </div>

    <!-- <div class="row">
  <div class="col-lg-6">
<h3 class="bld">Patient Complaints</h3>

      {{item.PatientComplaints}}
  </div>
  <div class="col-lg-6">
<h3 class="bld"> Issues Started From</h3>

      {{item.IssueStartDate}}
  </div>
 
</div>

<hr> -->

    <!-- <div class="row">
  <div class="col-lg-6">
      <h3 class="bld">Dignosis</h3>
  
              {{item.Dignosis}}
          </div>
  <div class="col-lg-6">
<h3 class="bld">Findings</h3>

      {{item.Findings}}
  </div>
  
</div> -->


    <hr>


    <div class="row">
      <div class="col-lg-12">
        <!-- <h3 class="bld">Treatments</h3>
  
              {{item.Treatments}}
          </div> -->


        <h3 class="bld">Plan (Patient Note)</h3>

        {{item.PatientNote}}
      </div>
      <!-- <div class="col-lg-2"><h3 class="bld">ConsultationFee:</h3> {{item.ConsultationFee}}</div> -->
    </div>


    <button class="float-right" [routerLink]="['/updateNursePatientVisit/',item.PatientVisitId]" color="primary"
      [disabled]="CurrentNurseId != item.NurseId" mat-mini-fab>
      <mat-icon matPrefix>edit</mat-icon>
    </button>
    <!-- <button  class="btn btn-danger mx-3 my-3 " (click)="deleteVisit(item.PatientVisitId)"
color="success" mat-mini-fab>
<mat-icon matPrefix>delete</mat-icon>
</button>
<hr class="bg-primary"> -->




  </div>


  <hr>


</div>