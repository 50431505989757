import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PrescriptionItem } from 'src/app/Models/prescriptionitem.model';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionitemService } from 'src/app/services/prescriptionitem.service';

@Component({
  selector: 'app-med-tracker',
  templateUrl: './med-tracker.component.html',
  styleUrls: ['./med-tracker.component.css'],
})
export class MedTrackerComponent implements OnInit {
  id = 0;
  medicationGiven: PrescriptionItem[] = [];
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  CurrentNurseId: number;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private medTrackerService: PrescriptionitemService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getMedicinenGiven(this.id);
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentNurseId = parseInt(decodedToken.nameid);
  }

  getMedicinenGiven(id) {
    this.patientService.getmedicineGiven(id).subscribe((res) => {
      this.medicationGiven = res.PrescriptionItems.sort(function (x, y) {
        return y.PrescriptionItemId - x.PrescriptionItemId;
      });
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getMedicinenGiven(this.id);
    }
  }

  delete(id) {
    this.medTrackerService.deletePrescriptionItem(id).subscribe((res) => {
      // this.nurseReports = this.nurseReports.filter(x=>x.NurseReportId !=id);
      alert('deleted');
      this.getMedicinenGiven(this.id);
    });
  }
}
