import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { PrescriptionItem } from 'src/app/Models/prescriptionitem.model';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';
import { PrescriptionitemService } from 'src/app/services/prescriptionitem.service';

@Component({
  selector: 'app-nurse-todo',
  templateUrl: './nurse-todo.component.html',
  styleUrls: ['./nurse-todo.component.css'],
})
export class NurseTodoComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  displayedColumns: string[] = [
    'DatePrescribed',
    'DrugName',
    'BrandName',
    'Strength',
    'Quantity',
    'MorningTime',
    'MorningGivenTime',
    'AfternoonTime',
    'AfternoonGivenStatus',
    'EveningTime',
    'EveningGivenStatus',
    'NightTime',
    'NightGivenStatus',
    'QTime',
    'Status',
    'Instruction',
  ];
  // 'MorningGivenTime'ngGivenStatus', 'AfternoonGivenStatus', 'EveningGivenStatus', 'NightGivenStatus',
  id = 0;
  prescriptionList: Prescription[] = [];
  medicationGiven: PrescriptionItem[] = [];

  dataSource: MatTableDataSource<any>;
  email = new FormControl('');
  patient: Patient;
  isSuccess: boolean;
  date: any;
  medicineGivenTime: any = {};
  checked: boolean = true;
  nurseId = 0;
  nurseName: string;
  prescptionItemsList: PrescriptionItem[][];
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private presciptionItemService: PrescriptionitemService,
    private prescriptionService: PrescriptionService,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getMedicinenGiven(this.id);
    this.getPrescription(this.id);

    this.getPatient(this.id);

    this.date = this.datePipe.transform(new Date(), 'MM/dd/YYYY');
    // moment(datePrescribed).format('MM/DD/YYYY');

    this.getNurse(this.nurseId);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('prescriptionList') prescription!: ElementRef<any>;

  ngAfterViewInit() {}

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPrescription(this.id);
    }
  }

  // getGivenTime(id){
  //   this.patientService.getPatientPrescription(id).subscribe(res=>{
  //     this.medicineGivenTime = res;
  //   })
  // }
  getPrescription(id) {
    this.patientService.getPatientPrescription(id)?.subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Prescriptions.filter(
          (x) => x.EndDate >= this.date && x.Status == true
        ).sort(function (x, y) {
          return y.PrescriptionId - x.PrescriptionId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  getMedicinenGiven(id) {
    this.patientService.getmedicineGiven(id).subscribe((res) => {
      this.medicationGiven = res.PrescriptionItems;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurseName = res.NurseName;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeValue(event, row) {
    if (event.checked) {
      const date = new Date();
      //   const index = this.medicineGivenTime?.Prescriptions.findIndex((perception)=> perception.PrescriptionId == row.PrescriptionId);
      //   if(index > -1){
      //     let updateTime = this.medicineGivenTime?.Prescriptions[index]?.PrescriptionGivenStatuses[0];
      //     if(givenTime === 'MorningGivenTime'&& updateTime?.MorningGivenStatus){
      //       row['MorningUpdatedTime'] = this.datePipe.transform(updateTime?.MorningGivenTime, 'hh:mm a');
      //     }else if(givenTime === 'AfternoonTime'&& updateTime?.AfternoonGivenStatus){
      //       row['AfternoonUpdatedTime'] = this.datePipe.transform(updateTime?.AfternoonGivenTime, 'hh:mm a');
      //     }else if(givenTime === 'EveningTime'&& updateTime?.EveningGivenStatus){
      //       row['EveningUpdatedTime'] = this.datePipe.transform(updateTime?.EveningGivenTime, 'hh:mm a');
      //     }else if(givenTime === 'NightTime'&& updateTime?.NightGivenStatus){
      //       row['NightUpdatedTime'] = this.datePipe.transform(updateTime?.NightGivenTime, 'hh:mm a');
      //   }
      // }
      let body = {
        PatientId: this.id,
        MorningGivenStatus: event.checked,
        MorningGivenTime: date.toString(),
        MorningNurseName: this.nurseName,
        DrugName: row.DrugName,
        NurseId: this.nurseId,
      };
      this.presciptionItemService
        .registerPrescriptionItem(body)
        .subscribe((res) => {
          alert('Confirm Medication has been given');
          this.getMedicinenGiven(this.id);
        });
    }
  }

  changeValue1(event, row) {
    if (event.checked) {
      const date = new Date();

      let body = {
        PatientId: this.id,
        AfternoonGivenStatus: event.checked,
        AfternoonGivenTime: date.toString(),
        AfternoonNurseName: this.nurseName,
        DrugName: row.DrugName,
        NurseId: this.nurseId,
      };
      this.presciptionItemService
        .registerPrescriptionItem(body)
        .subscribe((res) => {
          alert('Confirm Medication has been given');
          this.getMedicinenGiven(this.id);
        });
    }
  }
  changeValue2(event, row) {
    if (event.checked) {
      const date = new Date();

      let body = {
        PatientId: this.id,
        EveningGivenStatus: event.checked,
        EveningGivenTime: date.toString(),
        EveningNurseName: this.nurseName,
        DrugName: row.DrugName,
        NurseId: this.nurseId,
      };
      this.presciptionItemService
        .registerPrescriptionItem(body)
        .subscribe((res) => {
          alert('Confirm Medication has been given');
          this.getMedicinenGiven(this.id);
        });
    }
  }
  changeValue3(event, row) {
    if (event.checked) {
      const date = new Date();

      let body = {
        PatientId: this.id,
        NightGivenStatus: event.checked,
        NightGivenTime: date.toString(),
        NightNurseName: this.nurseName,
        DrugName: row.DrugName,
        NurseId: this.nurseId,
      };
      this.presciptionItemService
        .registerPrescriptionItem(body)
        .subscribe((res) => {
          alert('Confirm Medication has been given');
          this.getMedicinenGiven(this.id);
        });
    }
  }

  PrintPrescription(printPresciption: Prescription) {
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      // console.log("pres", this.prescription.nativeElement.innerText)
    }, 0);
  }

  print() {
    window.print();
  }

  sendPresciption() {
    let body = {
      Email: this.email.value,
      Subject:
        this.patient.PatientFirstName +
        ' ' +
        this.patient.PatientLastName +
        "'s" +
        'Prescription',
      Template: `${this.prescription.nativeElement.innerHTML}`,
    };
    this.patientService.sendPrescription(body).subscribe((res) => {
      this.isSuccess = true;
    });
  }
}
