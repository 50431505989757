import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { GeneralSaftey } from 'src/app/Models/generalsaftey.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-safteymanagement',
  templateUrl: './view-safteymanagement.component.html',
  styleUrls: ['./view-safteymanagement.component.css'],
})
export class ViewSafteymanagementComponent implements OnInit {
  panelOpenState = false;
  id = 0;
  safetyList: GeneralSaftey[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientSafetyManagement(this.id);
  }

  getPatientSafetyManagement(id) {
    this.patientService.getGeneralSaftey(id).subscribe((res) => {
      this.safetyList = res.GeneralSafteys.sort(function (x, y) {
        return y.GeneralSafteyId - x.GeneralSafteyId;
      });
    });
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPatientSafetyManagement(this.id);
    }
  }
}
