<div class="container ">
    <h2>Release of Information Form</h2>

    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">

            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Client Name</mat-label>
                            <input type="tel" formControlName="ClientName" placeholder="ClientName" matInput>
                            <mat-error>Please enter ClientName</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Provider Name</mat-label>
                            <input type="tel" formControlName="ProviderName" placeholder="ProviderName" matInput>
                            <mat-error>Please enter ProviderName</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <h2>I authorize the practice/clinician stated above to:
                    *</h2>
                <div class="row">

                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="Send">
                            Send
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="Receive">
                            Receive
                        </mat-checkbox>
                    </div>
                </div>


                <!-- AlamSet -->
                <h4>The following information:
                    *
                </h4>


                <div class="col-md-6">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="MedicalHistoryandEvaluations">
                            Medical history and evaluation(s)
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="MentalHealthEvaluations">
                            Mental health evaluations
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="DevelopmentalAndSocialHistory">
                            Developmental and/or social history
                        </mat-checkbox>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="EducationalReords">
                            Educational records
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="ProgressNotesandTreatmentSummary">
                            Progress notes, and treatment or closing summary
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Other</mat-label>
                            <!-- <input type="text" formControlName="Other" matInput> -->
                            <textarea matInput formControlName="Other" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone</mat-label>
                            <input type="text" formControlName="Phone" matInput>
                            <mat-error>Please enter Phone</mat-error>
                        </mat-form-field>

                    </div>


                </div>
                <h2>Your relationship to client:
                    *</h2>
                <div class="row">

                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Self">
                                Self
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ParentOrLegalGuardian">
                                Parent/legal guardian
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="PersonalRepresentative">
                                Personal representative
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other Relation</mat-label>
                                <input type="text" formControlName="OtherRelation" matInput>
                                <mat-error>Please enter Other Relation</mat-error>
                            </mat-form-field>

                        </div>

                    </div>
                </div>
                <h2>The above information above will be used for the following purposes:
                    *</h2>
                <div class="row">

                    <div class="col-md-4">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="PlanningApproprirateTreatment">
                                Planning appropriate treatment or program
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Continuingappropriatetreatmentorprogram">
                                Continuing appropriate treatment or program
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="DeterminingEligibility">
                                Determining eligibility for benefits or program
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="CaseReview">
                                Case review
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="UpdatingFiles">
                                Updating files
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="OtherUse">
                                Other
                            </mat-checkbox>
                        </div>



                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">

                        <p>I understand that this information may be protected by Title 45 (Code of Federal Rules of
                            Privacy
                            ofIndividually Identifiable Health Information, Parts 160 and 164) and Title 42 (Federal
                            Rules
                            of
                            Confidentialityof Alcohol and Drug Abuse Patient Records, Chapter 1, Part 2), plus
                            applicable
                            state
                            laws. I furtherunderstand that the information disclosed to the recipient may not be
                            protected
                            under
                            these guidelines ifthey are not a health care provider covered by state or federal rules.

                        </p>


                        <p>I understand that this authorization is voluntary, and I may revoke this consent at any time
                            by
                            providingwritten notice, and after (some states vary, usually 1 year) this consent
                            automatically
                            expires. I have beeninformed what information will be given, its purpose, and who will
                            receive
                            the
                            information. I understand that Ihave a right to receive a copy of this authorization. I
                            understand
                            that I have a right to refuse to sign thisauthorization.

                        </p>

                        <p>
                            If you are the legal guardian or representative appointed by the court for the client,
                            please
                            attach
                            a copy ofthis authorization to receive this protected health information.

                        </p>

                    </div>
                </div>




                <div class="container mt-5">
                    <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                        (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                        height="200">
                    </canvas>
                    <button type="button" class="ml-3" color="primary" mat-raised-button
                        (click)="saveSignature($event)">Save</button>

                    <button type="button" class="ml-3" color="accent" mat-raised-button
                        (click)="clearSignature($event)">Clear</button>
                </div>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4"></div>
                    <!-- <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Signature</mat-label>
                            <input type="tel" formControlName="Signature" placeholder="Signature" matInput>
                            <mat-error>Please enter Signature</mat-error>
                        </mat-form-field>
                    </div> -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Signature</mat-label>
                            <input type="date" formControlName="DateOfSignature" placeholder="Date" matInput>
                            <mat-error>Please enter Date Of Signature</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Updated
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>