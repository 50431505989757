import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RadiologyOrder } from 'src/app/Models/radilogyorder.model';
import { PatientService } from 'src/app/services/patient.service';
import { RadiologyOrderService } from 'src/app/services/radiology-order.service';

@Component({
  selector: 'app-view-all-radiology-orders',
  templateUrl: './view-all-radiology-orders.component.html',
  styleUrls: ['./view-all-radiology-orders.component.css'],
})
export class ViewAllRadiologyOrdersComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'OrderedBy',
    'Subject',
    'Description',
    'Delete',
  ];

  id = 0;
  radiologyOrder: RadiologyOrder[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';

  constructor(
    private patientService: PatientService,
    private radiologyOrderervice: RadiologyOrderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetRadiologyOrders();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  GetRadiologyOrders() {
    this.radiologyOrderervice.getRadiologyOrders().subscribe((res) => {
      this.radiologyOrder = res;

      this.dataSource = new MatTableDataSource(
        res.sort(function (x, y) {
          return y.RadiologyOrderId - x.RadiologyOrderId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.radiologyOrderervice.deleteRadiologyOrder(id).subscribe((res) => {
      // this.radiologyOrder = this.radiologyOrder.filter(x=>x.RadiologyOrderId !=id)
      this.GetRadiologyOrders();
    });
  }
  view() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewLabReport(report: RadiologyOrder): void {
    // console.log('report: ', report);
    this.labDocument = report.Description;
  }
}
