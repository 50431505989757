<div class="container">
  <div class="regFrm mat-elevation-z8">
    <div class="row">
      <div class="col-lg">
        <h1>New Patient Initial Evaluation</h1>

        <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
          <div class="row">



            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Injury Date</mat-label>
                <input type="date" formControlName="InjuryDate" matInput>
                <mat-error>Please enter Injury Date</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-8">
              <mat-form-field class="example-full-width">
                <mat-label>Medical History</mat-label>
                <input type="text" formControlName="MedicalHistory" matInput>
                <mat-error>Please enter Medical History</mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="col-lg-8">
                <mat-form-field class="example-full-width">
                  <mat-label>Diagnosis Codes</mat-label>
                  <input type="text" formControlName="DiagnosisCodes" matInput>
                  <mat-error >Please enter Diagnosis Codes</mat-error>
                </mat-form-field>
              </div> -->


          </div>
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Living Environment</mat-label>
                <input type="text" formControlName="LivingEnvironment" matInput>
                <mat-error>Please enter Living Environment</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Occupation</mat-label>
                <input type="text" formControlName="Occupation" matInput>
                <mat-error>Please enter Occupation</mat-error>
              </mat-form-field>
            </div>

          </div>
          <h3>Click + button to Add Multiple Diagnosis codes</h3>

          <div formArrayName="InitialEvaluationDiagnosisCodes">
            <div class="row" *ngFor="let item of InitialEvaluationDiagnosisCodes.controls; let i=index">
              <ng-container [formGroup]="item">
                <div class="col-lg-11">

                  <mat-form-field class="example-full-width">
                    <mat-label>Diagnosis Codes</mat-label>
                    <mat-select formControlName="Code">
                      <mat-option>
                        <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                          (filtered)="filteredOptions = $event">
                        </lib-mat-select-search>
                      </mat-option>
                      <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                        <mat-option *cdkVirtualFor="let entry of filteredOptions" [value]="entry.Code">
                          {{entry.Code}}
                        </mat-option>
                      </cdk-virtual-scroll-viewport>


                    </mat-select>


                    <mat-error>Diagnosis Codes required</mat-error>

                  </mat-form-field>

                </div>



                <div class="col-lg-1">
                  <button type="button" mat-mini-fab (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
                </div>
              </ng-container>
            </div>

            <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>


          </div>

          <h2>Subjective</h2>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Mechanism Of Injury</mat-label>
                <input type="text" formControlName="MechanismOfInjury" matInput>
                <mat-error>Please enter Mechanism Of Injury</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Pain Increases With</mat-label>
                <input type="text" formControlName="PainIncreasesWith" matInput>
                <mat-error>Please enter Pain Increases With</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Pain Decreases With</mat-label>
                <input type="text" formControlName="PainDecreasesWith" matInput>
                <mat-error>Please enter Pain Decreases With</mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Diagnosis Testing</mat-label>
                <input type="text" formControlName="DiagnosisTesting" matInput>
                <mat-error>Please enter Diagnosis Testing</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Patient Goal</mat-label>
                <input type="text" formControlName="PatientGoal" matInput>
                <mat-error>Please enter Patient Goal</mat-error>
              </mat-form-field>
            </div>

          </div>
          <h2>Pain Scale</h2>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Pain Worst Level</mat-label>
                <mat-select formControlName="PainWorstLevel">
                  <mat-option *ngFor="let painLevel of painScale"
                    [value]="painLevel.value">{{painLevel.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="PainWorstLevel" matInput> -->
                <mat-error>Please enter Pain Worst Level</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Pain Current Level</mat-label>
                <mat-select formControlName="PainCurrentLevel">
                  <mat-option *ngFor="let CurrentLevel of painScale"
                    [value]="CurrentLevel.value">{{CurrentLevel.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="PainCurrentLevel" matInput> -->
                <mat-error>Please enter Pain Current Level</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Pain Best Level</mat-label>
                <mat-select formControlName="PainBestLevel">
                  <mat-option *ngFor="let BestLevel of painScale"
                    [value]="BestLevel.value">{{BestLevel.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="PainBestLevel" matInput> -->
                <mat-error>Please enter Pain Best Level</mat-error>
              </mat-form-field>
            </div>

          </div>
          <h2>Objective</h2>
          <h4>General</h4>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Treatment Area</mat-label>
                <input type="text" formControlName="TreatmentArea" matInput>
                <mat-error>Please enter Treatment Area</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Palpitation</mat-label>
                <input type="text" formControlName="Palpitation" matInput>
                <mat-error>Please enter Palpitation</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Posture</mat-label>
                <input type="text" formControlName="Posture" matInput>
                <mat-error>Please enter Posture</mat-error>
              </mat-form-field>
            </div>

          </div>
          <h2>Objective Spine</h2>
          <h4>Cervical Rom</h4>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion </mat-label>
                <mat-select formControlName="FlexionCervical">
                  <mat-option *ngFor="let cervicalFle of allRom"
                    [value]="cervicalFle.value">{{cervicalFle.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionCervical" matInput> -->
                <mat-error>Please enter Flexion </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Extension </mat-label>
                <mat-select formControlName="ExtensionCervical">
                  <mat-option *ngFor="let cervicaExten of allRom"
                    [value]="cervicaExten.value">{{cervicaExten.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="ExtensionCervical" matInput> -->
                <mat-error>Please enter Extension </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Left Side Flexion </mat-label>
                <mat-select formControlName="LeftSideFlexionCervical">
                  <mat-option *ngFor="let cervicaLsF of allRom"
                    [value]="cervicaLsF.value">{{cervicaLsF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LeftSideFlexionCervical" matInput> -->
                <mat-error>Please enter Left Side Flexion</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Right Side Flexion </mat-label>
                <mat-select formControlName="RightSideFlexionCervical">
                  <mat-option *ngFor="let cervicalRF of allRom"
                    [value]="cervicalRF.value">{{cervicalRF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="RightSideFlexionCervical" matInput> -->
                <mat-error>Please enter Right Side Flexion </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Left Rotation </mat-label>
                <mat-select formControlName="LeftRotationCervical">
                  <mat-option *ngFor="let cervicalLR of allRom"
                    [value]="cervicalLR.value">{{cervicalLR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LeftRotationCervical" matInput> -->
                <mat-error>Please enter Left Rotation </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Right Rotation </mat-label>
                <mat-select formControlName="RightRotationCervical">
                  <mat-option *ngFor="let cervicalRR of allRom"
                    [value]="cervicalRR.value">{{cervicalRR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="RightRotationCervical" matInput> -->
                <mat-error>Please enter Right Rotation </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h4>Lumbar Rom</h4>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion </mat-label>
                <mat-select formControlName="FlexionLumbar">
                  <mat-option *ngFor="let lumbarF of allRom" [value]="lumbarF.value">{{lumbarF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionLumbar" matInput> -->
                <mat-error>Please enter Flexion </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Extension</mat-label>
                <mat-select formControlName="ExtensionLumbar">
                  <mat-option *ngFor="let lumbarE of allRom" [value]="lumbarE.value">{{lumbarE.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="ExtensionLumbar" matInput> -->
                <mat-error>Please enter Extension </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Left Side Flexion</mat-label>
                <mat-select formControlName="LeftSideFlexionLumbar">
                  <mat-option *ngFor="let lumbarLF of allRom" [value]="lumbarLF.value">{{lumbarLF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LeftSideFlexionLumbar" matInput> -->
                <mat-error>Please enter Left Side Flexion</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Right Side Flexion </mat-label>
                <mat-select formControlName="RightSideFlexionLumbar">
                  <mat-option *ngFor="let lumbarRF of allRom" [value]="lumbarRF.value">{{lumbarRF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="RightSideFlexionLumbar" matInput> -->
                <mat-error>Please enter Right Side Flexion </mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Left Rotation </mat-label>
                <mat-select formControlName="LeftRotationLumbar">
                  <mat-option *ngFor="let lumbarLR of allRom" [value]="lumbarLR.value">{{lumbarLR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LeftRotationLumbar" matInput> -->
                <mat-error>Please enter Left Rotation </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Right Rotation </mat-label>
                <mat-select formControlName="RightRotationLumbar">
                  <mat-option *ngFor="let lumbarRR of allRom" [value]="lumbarRR.value">{{lumbarRR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="RightRotationLumbar" matInput> -->
                <mat-error>Please enter Right Rotation </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h4>Lumbar Strength</h4>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Lower Abdominals </mat-label>
                <mat-select formControlName="LowerAbdominals">
                  <mat-option *ngFor="let lAbs of painScale" [value]="lAbs.value">{{lAbs.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LowerAbdominals" matInput> -->
                <mat-error>Please enter Lower Abdominals </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip Flexors</mat-label>
                <mat-select formControlName="HipFlexors">
                  <mat-option *ngFor="let hipF of painScale" [value]="hipF.value">{{hipF.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipFlexors" matInput> -->
                <mat-error>Please enter Hip Flexors </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip Extensors</mat-label>
                <mat-select formControlName="HipExtensors">
                  <mat-option *ngFor="let hipE of painScale" [value]="hipE.value">{{hipE.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExtensors" matInput> -->
                <mat-error>Please enter Hip Extensors</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Spine Special Tests</mat-label>
                <input type="text" formControlName="SpineSpecialTests" matInput>
                <mat-error>Please enter Spine Special Tests</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Thomas Text </mat-label>
                <mat-select formControlName="ThomasText">
                  <mat-option *ngFor="let tomTest of STPtestVal" [value]="tomTest.value">{{tomTest.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="ThomasText" matInput> -->
                <mat-error>Please enter Thomas Text </mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Slump Test </mat-label>
                <mat-select formControlName="SlumpTest">
                  <mat-option *ngFor="let slmTest of STPtestVal" [value]="slmTest.value">{{slmTest.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="SlumpTest" matInput> -->
                <mat-error>Please enter Slump Test </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Prone Instability Test </mat-label>
                <mat-select formControlName="PhoneInstabilityTest">
                  <mat-option *ngFor="let pronTest of STPtestVal"
                    [value]="pronTest.value">{{pronTest.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="PhoneInstabilityTest" matInput> -->
                <mat-error>Please enter Prone Instability Test </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Comments </mat-label>
                <input type="text" formControlName="Comments" matInput>
                <mat-error>Please enter Comments </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h2>Objective Spine</h2>
          <h4>HIP Rom</h4>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Abduction Arrom Left</mat-label>
                <mat-select formControlName="AbductionArromLeft">
                  <mat-option *ngFor="let AbductionAL of allRom"
                    [value]="AbductionAL.value">{{AbductionAL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="AbductionArromLeft" matInput> -->
                <mat-error>Please enter Abduction Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Abduction Arrom Right </mat-label>
                <mat-select formControlName="AbductionArromRight">
                  <mat-option *ngFor="let AbductionAR of allRom"
                    [value]="AbductionAR.value">{{AbductionAR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="AbductionArromRight" matInput> -->
                <mat-error>Please enter Abduction Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Abduction From Left </mat-label>
                <mat-select formControlName="AbductionFromLeft">
                  <mat-option *ngFor="let AbductionFL of allRom"
                    [value]="AbductionFL.value">{{AbductionFL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="AbductionFromLeft" matInput> -->
                <mat-error>Please enter Abduction From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Abduction From Right </mat-label>
                <mat-select formControlName="AbductionFromRight">
                  <mat-option *ngFor="let AbduustionFR of allRom"
                    [value]="AbduustionFR.value">{{AbduustionFR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="AbductionFromRight" matInput> -->
                <mat-error>Please enter Abduction From Right </mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Flexed Arrom Left</mat-label>
                <mat-select formControlName="FlexionWithKneeFlexedArromLeft">
                  <mat-option *ngFor="let fKneeFAL of allRom" [value]="fKneeFAL.value">{{fKneeFAL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeFlexedArromLeft" matInput> -->
                <mat-error>Please enter Flexion With Knee Flexed Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Flexed ArromRight </mat-label>
                <mat-select formControlName="FlexionWithKneeFlexedArromRight">
                  <mat-option *ngFor="let FkneeFaR of allRom" [value]="FkneeFaR.value">{{FkneeFaR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeFlexedArromRight" matInput> -->
                <mat-error>Please enter Flexion With Knee Flexed ArromRight </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Flexed From Left</mat-label>
                <mat-select formControlName="FlexionWithKneeFlexedFromLeft">
                  <mat-option *ngFor="let FkneeFFL of allRom" [value]="FkneeFFL.value">{{FkneeFFL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeFlexedFromLeft" matInput> -->
                <mat-error>Please enter Flexion With Knee Flexed From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee From Right </mat-label>
                <mat-select formControlName="FlexionWithKneeFromRight">
                  <mat-option *ngFor="let FkneeFR of allRom" [value]="FkneeFR.value">{{FkneeFR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeFromRight" matInput> -->
                <mat-error>Please enter Flexion With Knee From Right </mat-error>
              </mat-form-field>
            </div>

          </div>
          <hr>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Extended Arrom Left</mat-label>
                <mat-select formControlName="FlexionWithKneeExtendedArromLeft">
                  <mat-option *ngFor="let FkneeEAl of allRom" [value]="FkneeEAl.value">{{FkneeEAl.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeExtendedArromLeft" matInput> -->
                <mat-error>Please enter Flexion With Knee Extended Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Extended Arrom Right </mat-label>
                <mat-select formControlName="FlexionWithKneeExtendedArromRight">
                  <mat-option *ngFor="let FkneeEFAR of allRom"
                    [value]="FkneeEFAR.value">{{FkneeEFAR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeExtendedArromRight" matInput> -->
                <mat-error>Please enter Flexion With Knee Extended Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Extended From Left</mat-label>
                <mat-select formControlName="FlexionWithKneeExtendedFromLeft">
                  <mat-option *ngFor="let FkneeEFL of allRom" [value]="FkneeEFL.value">{{FkneeEFL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeExtendedFromLeft" matInput> -->
                <mat-error>Please enter Flexion With Knee Extended From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Flexion With Knee Extended From Right </mat-label>
                <mat-select formControlName="FlexionWithKneeExtendedFromRight">
                  <mat-option *ngFor="let FkneeEFR of allRom" [value]="FkneeEFR.value">{{FkneeEFR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FlexionWithKneeExtendedFromRight" matInput> -->
                <mat-error>Please enter Flexion With Knee Extended From Right </mat-error>
              </mat-form-field>
            </div>

          </div>
          <hr>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip Extension Arrom Left</mat-label>
                <mat-select formControlName="HipExtensionArromLeft">
                  <mat-option *ngFor="let hipEAL of allRom" [value]="hipEAL.value">{{hipEAL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExtensionArromLeft" matInput> -->
                <mat-error>Please enter HipExtension Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>HipExtension Arrom Right </mat-label>
                <mat-select formControlName="HipExtensionArromRight">
                  <mat-option *ngFor="let hipEaR of allRom" [value]="hipEaR.value">{{hipEaR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExtensionArromRight" matInput> -->
                <mat-error>Please enter HipExtension Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip Extension From Left</mat-label>
                <mat-select formControlName="HipExtensionFromLeft">
                  <mat-option *ngFor="let hipEfL of allRom" [value]="hipEfL.value">{{hipEfL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExtensionFromLeft" matInput> -->
                <mat-error>Please enter Hip Extension From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip Extension From Right </mat-label>
                <mat-select formControlName="HipExternalRotationArromLeft">
                  <mat-option *ngFor="let hipEfL of allRom" [value]="hipEfL.value">{{hipEfL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExtensionFromRight" matInput> -->
                <mat-error>Please enter Hip Extension From Right </mat-error>
              </mat-form-field>
            </div>

          </div>


          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip External Rotation Arrom Left</mat-label>
                <mat-select formControlName="HipExternalRotationArromLeft">
                  <mat-option *ngFor="let hipRaL of allRom" [value]="hipRaL.value">{{hipRaL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExternalRotationArromLeft" matInput> -->
                <mat-error>Please enter Hip External Rotation Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip External Rotation Arrom Right </mat-label>
                <mat-select formControlName="HipExternalRotationArromRight">
                  <mat-option *ngFor="let hipRaFR of allRom" [value]="hipRaFR.value">{{hipRaFR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExternalRotationArromRight" matInput> -->
                <mat-error>Please enter Hip External Rotation Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip External Rotation From Left</mat-label>
                <mat-select formControlName="HipExternalRotationFromLeft">
                  <mat-option *ngFor="let hipRfL of allRom" [value]="hipRfL.value">{{hipRfL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExternalRotationFromLeft" matInput> -->
                <mat-error>Please enter Hip External Rotation From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Hip External Rotation From Right </mat-label>
                <mat-select formControlName="HipExternalRotationFromRight">
                  <mat-option *ngFor="let hipRfR of allRom" [value]="hipRfR.value">{{hipRfR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="HipExternalRotationFromRight" matInput> -->
                <mat-error>Please enter Hip External Rotation From Right </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h4>Knee Rom</h4>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Flexion Arrom Left</mat-label>
                <mat-select formControlName="KneeFlexionArromLeft">
                  <mat-option *ngFor="let kneeFaL of allRom" [value]="kneeFaL.value">{{kneeFaL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeFlexionArromLeft" matInput> -->
                <mat-error>Please enter Knee Flexion Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Flexion Arrom Right </mat-label>
                <mat-select formControlName="KneeFlexionArromRight">
                  <mat-option *ngFor="let kneeFaR of allRom" [value]="kneeFaR.value">{{kneeFaR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeFlexionArromRight" matInput> -->
                <mat-error>Please enter Knee Flexion Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Flexion From Left</mat-label>
                <mat-select formControlName="KneeFlexionFromLeft">
                  <mat-option *ngFor="let kneeFfL of allRom" [value]="kneeFfL.value">{{kneeFfL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeFlexionFromLeft" matInput> -->
                <mat-error>Please enter Knee Flexion From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Flexion From Right </mat-label>
                <mat-select formControlName="KneeFlexionFromRight">
                  <mat-option *ngFor="let kneeFfR of allRom" [value]="kneeFfR.value">{{kneeFfR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeFlexionFromRight" matInput> -->
                <mat-error>Please enter Knee Flexion From Right </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Extension Arrom Left</mat-label>
                <mat-select formControlName="KneeExtensionArromLeft">
                  <mat-option *ngFor="let kneeEAFL of allRom" [value]="kneeEAFL.value">{{kneeEAFL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeExtensionArromLeft" matInput> -->
                <mat-error>Please enter Knee Extension Arrom Left</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Extension Arrom Right </mat-label>
                <mat-select formControlName="KneeExtensionArromRight">
                  <mat-option *ngFor="let kneeEAFR of allRom" [value]="kneeEAFR.value">{{kneeEAFR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeExtensionArromRight" matInput> -->
                <mat-error>Please enter Knee Extension Arrom Right </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Extension From Left</mat-label>
                <mat-select formControlName="KneeExtensionFromLeft">
                  <mat-option *ngFor="let kneeEFL of allRom" [value]="kneeEFL.value">{{kneeEFL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeExtensionFromLeft" matInput> -->
                <mat-error>Please enter Knee Extension From Left </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Knee Extension FromRight</mat-label>
                <mat-select formControlName="KneeExtensionFromRight">
                  <mat-option *ngFor="let kneeEfR of allRom" [value]="kneeEfR.value">{{kneeEfR.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="KneeExtensionFromRight" matInput> -->
                <mat-error>Please enter Knee Extension From Right </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h2>Assessment</h2>
          <h4>Problem List</h4>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Pain</mat-label>
                <mat-select formControlName="Pain">
                  <mat-option *ngFor="let painL of autoPain" [value]="painL.value">{{painL.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="Pain" matInput> -->
                <mat-error>Please enter Pain</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Weekness</mat-label>
                <input type="text" formControlName="Weekness" matInput>
                <mat-error>Please enter Weekness</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Mobility</mat-label>
                <input type="text" formControlName="Mobility" matInput>
                <mat-error>Please enter Mobility </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Ambulatiion </mat-label>
                <input type="text" formControlName="Ambulatiion" matInput>
                <mat-error>Please enter Ambulatiion </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Range Of Motion</mat-label>
                <mat-select formControlName="RangeOfMotion">
                  <mat-option *ngFor="let range of autoRange" [value]="range.value">{{range.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="RangeOfMotion" matInput> -->
                <mat-error>Please enter Range Of Motion</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Problem1 </mat-label>
                <input type="text" formControlName="Problem1" matInput>
                <mat-error>Please enter Problem1</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Problem2</mat-label>
                <input type="text" formControlName="Problem2" matInput>
                <mat-error>Please enter Problem2 </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Prognosis</mat-label>
                <input type="text" formControlName="Prognosis" matInput>
                <mat-error>Please enter Prognosis </mat-error>
              </mat-form-field>
            </div>

          </div>
          <h4>Goals</h4>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>GoalTime</mat-label>
                <input type="text" formControlName="GoalTime" matInput>
                <mat-error>Please enter GoalTime</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>AbletoSitFor</mat-label>
                <input type="text" formControlName="AbletoSitFor" matInput>
                <mat-error>Please enter AbletoSitFor</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>ReturnToWork</mat-label>
                <input type="text" formControlName="ReturnToWork" matInput>
                <mat-error>Please enter ReturnToWork </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>BeIndependentandSelfGrooming </mat-label>
                <input type="text" formControlName="BeIndependentandSelfGrooming" matInput>
                <mat-error>Please enter BeIndependentandSelfGrooming </mat-error>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>FunctionalGoal1</mat-label>
                <input type="text" formControlName="FunctionalGoal1" matInput>
                <mat-error>Please enter FunctionalGoal1</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>FunctionalGoal2 </mat-label>
                <input type="text" formControlName="FunctionalGoal2" matInput>
                <mat-error>Please enter FunctionalGoal2</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>FunctionalGoal3</mat-label>
                <input type="text" formControlName="FunctionalGoal3" matInput>
                <mat-error>Please enter FunctionalGoal3 </mat-error>
              </mat-form-field>
            </div>



          </div>
          <h2>Plan</h2>
          <h4>Treatment Plan</h4>

          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Frequency and Duration</mat-label>
                <input type="text" formControlName="FrequencyandDuration" matInput>
                <mat-error>Please enter Frequency and Duration</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Treatment Plan </mat-label>
                <input type="text" formControlName="TreatmentPlan" matInput>
                <mat-error>Please enter Treatment Plan</mat-error>
              </mat-form-field>
            </div>
          </div>
          <h2>Treatment Flow Sheet</h2>
          <h4>Manual Therapy</h4>

          <div class="row">
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Joint Mobilization</mat-label>
                <input type="text" formControlName="JointMobilization" matInput>
                <mat-error>Please enter Joint Mobilization</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Soft Tissue Mobilization </mat-label>
                <input type="text" formControlName="SoftTissueMobilization" matInput>
                <mat-error>Please enter Soft Tissue Mobilization</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-4">
              <mat-form-field class="example-full-width">
                <mat-label>Stretching </mat-label>
                <input type="text" formControlName="Stretching" matInput>
                <mat-error>Please enter Stretching</mat-error>
              </mat-form-field>
            </div>
          </div>
          <h2>Lower Extermity Exercise Flowsheet</h2>
          <h4>Other</h4>

          <div class="row">
            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Pirformis Str</mat-label>
                <mat-select formControlName="PirformisStr">
                  <mat-option *ngFor="let perfstr of autoFillItems"
                    [value]="perfstr.value">{{perfstr.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="PirformisStr" matInput> -->
                <mat-error>Please enter PirformisStr</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Lunge Str </mat-label>
                <mat-select formControlName="LungeStr">
                  <mat-option *ngFor="let lungstr of autoFillItems"
                    [value]="lungstr.value">{{lungstr.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LungeStr" matInput> -->
                <mat-error>Please enter Lunge Str</mat-error>
              </mat-form-field>
            </div>

          </div>

          <h2>Spine Execise Flowsheet</h2>
          <h4>Thoracic Extension</h4>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>FoamRoller</mat-label>
                <mat-select formControlName="FoamRoller">
                  <mat-option *ngFor="let formroll of autoFillItems"
                    [value]="formroll.value">{{formroll.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="FoamRoller" matInput> -->
                <mat-error>Please enter Foam Roller</mat-error>
              </mat-form-field>
            </div>


            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Lion/Lat Stretch </mat-label>
                <mat-select formControlName="LionLatStretch">
                  <mat-option *ngFor="let lionLat of autoFillItems"
                    [value]="lionLat.value">{{lionLat.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LionLatStretch" matInput> -->
                <mat-error>Please enter Lion/Lat Stretch</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Cat & Camel Stretch </mat-label>
                <mat-select formControlName="CatandCamelStretch">
                  <mat-option *ngFor="let catCamel of autoFillItems"
                    [value]="catCamel.value">{{catCamel.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="CatandCamelStretch" matInput> -->
                <mat-error>Please enter Cat & Camel Stretch</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Lower Trunk Rotation </mat-label>
                <mat-select formControlName="LowerTrunkRotation">
                  <mat-option *ngFor="let lowertrunk of autoFillItems"
                    [value]="lowertrunk.value">{{lowertrunk.value}}</mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="LowerTrunkRotation" matInput> -->
                <mat-error>Please enter Lower Trunk Rotation</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Doctor Name </mat-label>
                <input type="text" formControlName="DoctorName" matInput>
                <mat-error>Please enter Doctor Name</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Signature </mat-label>
                <input type="text" formControlName="Signature" matInput>
                <mat-error>Please enter Signature</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label> Time In</mat-label>
              <input [ngxTimepicker]="picker" matInput formControlName="TimeIn">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error>Please enter Time In</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label> Time Out </mat-label>
              <input [ngxTimepicker]="picker1" matInput formControlName="TimeOut">
              <ngx-material-timepicker #picker1></ngx-material-timepicker>
              <mat-error>Please enter Available Time Out</mat-error>
            </mat-form-field>

          </div>


          <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully submitted
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <button class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>