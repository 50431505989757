<!-- <div id="dashboard"> -->

<!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
<!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
<div class="toolbar mat-elevation-z6">

  <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS ">
      <mat-icon mat-list-icon>PT Dashboard</mat-icon>
      <div class="mx-2" [routerLink]="['/physicalTherapyDash/']">dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
      <mat-icon mat-list-icon>summarize</mat-icon>
      <div class="mx-2">Patient Summary</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2"> New Physical Therapy Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Visit</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Initial Evaluation</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View Inital Evaluation</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View PT Visit</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


  </mat-menu>
</div>



<span [ngSwitch]="showItem">

  <p *ngSwitchCase="'1'">
    <app-patientsummary></app-patientsummary>
  </p>


  <p *ngSwitchCase="'2'">
    <app-add-physical-therapy-visit></app-add-physical-therapy-visit>
  </p>
  <p *ngSwitchCase="'3'">
    <app-add-pt-initial-evaluation></app-add-pt-initial-evaluation>
  </p>

  <p *ngSwitchCase="'4'">
    <app-view-pt-initial-evaluation></app-view-pt-initial-evaluation>
  </p>

  <p *ngSwitchCase="'5'">
    <app-view-physical-therapy-visit></app-view-physical-therapy-visit>

  </p>

  <p *ngSwitchCase="'6'">
    <app-add-physicaltherapy-booking-byadmin></app-add-physicaltherapy-booking-byadmin>

  </p>


  <p *ngSwitchDefault>

    <app-view-physical-therapy-visit></app-view-physical-therapy-visit>
  </p>







</span>

<!-- </div>
     </div> -->
<!-- </div> -->