import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BedService } from '../../services/bed.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-bed',
  templateUrl: './view-bed.component.html',
  styleUrls: ['./view-bed.component.css'],
})
export class ViewBedComponent implements OnInit {
  displayedColumns: string[] = [
    'RoomNo',
    'BedNumber',
    'BedCharge',
    'Status',
    'Update',
    'Delete',
  ];

  dataSource: MatTableDataSource<any>;

  constructor(private bedService: BedService) {}

  ngOnInit(): void {
    this.getBeds();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getBeds() {
    this.bedService.getBeds().subscribe((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getBeds();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.bedService.deleteBed(id).subscribe((res) => {
      this.getBeds();
    });
  }
}
