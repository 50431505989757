import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WardService } from '../../services/ward.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-ward',
  templateUrl: './view-ward.component.html',
  styleUrls: ['./view-ward.component.css'],
})
export class ViewWardComponent implements OnInit {
  displayedColumns: string[] = [
    'WardName',
    'WardDescription',
    'Update',
    'Delete',
  ];

  dataSource: MatTableDataSource<any>;

  constructor(private wardService: WardService) {}

  ngOnInit(): void {
    this.getWard();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.sort(function (x, y) {
          return y.WardId - x.WardId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getWard();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.wardService.deleteWard(id).subscribe((res) => {
      this.getWard();
    });
  }
}
