
<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Text Message</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
              <div class="row">
                <div class="col-lg">
                  <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input type="text" formControlName="Email" matInput >
                    <mat-error >Please enter Email</mat-error>
                  </mat-form-field>
              
                  <mat-form-field class="example-full-width">
                    <mat-label>Subject</mat-label>
                    <input type="text" formControlName="Subject" matInput >
                    <mat-error >Please enter Subject</mat-error>
                  </mat-form-field>
              
                  <mat-form-field class="example-full-width">
                    <mat-label>Template</mat-label>
                    <input type="text" formControlName="Template" matInput >
                    <mat-error >Please enter Template</mat-error>
                  </mat-form-field>


                 </div>
                  </div>
                  <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    </button>
                    <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                      Successfully submitted
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
            </form>
          
        </div>
       
    </div>
</div>
