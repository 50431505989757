import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Medicine } from 'src/app/Models/medicine.model';
import { MedicineService } from '../../services/medicine.service';

@Component({
  selector: 'app-add-medicine',
  templateUrl: './add-medicine.component.html',
  styleUrls: ['./add-medicine.component.css']
})
export class AddMedicineComponent implements OnInit {
  isSuccess= false;

  id=0;
states;
medicineList:Medicine[];
  constructor(private fb:UntypedFormBuilder,private medicineService:MedicineService,
    private router:Router,private route:ActivatedRoute) { }

  ngOnInit(): void {

  
    this.route.params.subscribe(res=>{

      this.id= res['id'];

    })

    this.getMedicine();
  }


  prescriptionFrm= this.fb.group({
    DrugName:['',Validators.required],
    BrandName:['',Validators.required],
    Category:['',Validators.required],
    Dosage:['',Validators.required],
    Description:['',Validators.required],
    DrugCode:['',Validators.required],
    Instruction:['',Validators.required],
    Price:[''],
    Status:['']

  })

get Instruction(){
 return this.prescriptionFrm.get('Instruction')
}
get DrugCode(){
 return this.prescriptionFrm.get('DrugCode')
}
get DrugName(){
 return this.prescriptionFrm.get('DrugName')
}
get BrandName(){
 return this.prescriptionFrm.get('BrandName')
}
get Category(){
 return this.prescriptionFrm.get('Category')
}
get Dosage(){
 return this.prescriptionFrm.get('Dosage')
}
get Description(){
 return this.prescriptionFrm.get('Description')
}
get Price(){
 return this.prescriptionFrm.get('Price')
}
get Status(){
 return this.prescriptionFrm.get('Status')
}



getMedicine(){
  this.medicineService.getMedicines().subscribe(res=>{
    this.medicineList = res;
  })
}





  formSubmit(){

    let instruction = this.prescriptionFrm.value['Instruction']
    let drugCode = this.prescriptionFrm.value['DrugCode']
    let drugName = this.prescriptionFrm.value['DrugName']
    let dosage = this.prescriptionFrm.value['Dosage']
   let brandName = this.prescriptionFrm.value['BrandName']
   let category = this.prescriptionFrm.value['Category']
    
    let description = this.prescriptionFrm.value['Description']
  
    let price = this.prescriptionFrm.value['Price']
    let status = this.prescriptionFrm.value['Status']

    let body ={
     
      "DrugName":drugName,
      "BrandName":brandName,
      "Category":category,
      "Dosage":dosage,
      "Description":description,
      "DrugCode":drugCode,
      "Instruction":instruction,
      "Price":price,
      "Status":status
    }

this.medicineService.registerMedicine(body).subscribe(res=>{
  this.isSuccess=true
 this.prescriptionFrm.reset();

})
  }
}
