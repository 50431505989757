<div class="container mt-5">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Purposefull Rounds</h2>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="PatientPosition">
                        Pain, Position, Possession, Patty, Pump
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="PatientSleeping">
                        Patient Sleeping
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="PatientUnAvailable">
                        Patient UnAvailable
                    </mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" formControlName="RnNotified">
                        Is Rn Notified ?
                    </mat-checkbox>
                </div>
                <div class="col-lg-4">
                    <mat-form-field class="example-full-width">
                        <mat-label>Other</mat-label>
                        <!-- <input type="text" formControlName="Other" matInput> -->
                        <textarea matInput formControlName="Other" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"></textarea>
                    </mat-form-field>
                </div>

                <h2>Visual Saftey Checks
                </h2>
                <p> In Chair Taken today</p>

                <div class="row" [formGroup]="Frm">
                    <div class="col-md-4">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Agitated">
                                Agitated
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="EyesClosed">
                                Eyes Closed
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Quiet">
                                Quiet
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Awake">
                                Awake
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="InBed">
                                In Bed
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="PatientNotinRoom">
                                Patient Not in Room
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Confused">
                                Confused
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="InChair">
                                In Chair
                            </mat-checkbox>
                        </div>
                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other Comment</mat-label>
                                <!-- <input type="text" formControlName="OtherComment" matInput > -->
                                <textarea matInput formControlName="OtherComment" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="140"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Prescription
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>