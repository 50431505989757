<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Create New Invoice</h1>
      <form [formGroup]="invForm" (ngSubmit)="formSubmit()" class="example-form">
        <!-- <mat-label>Patient Name</mat-label>
        <input type="text" formControlName="PatientName" matInput readonly /> -->
        <div class="row">
          <div class="col-lg-7">
            <mat-form-field class="example-full-width">
              <mat-label>Procedure Code Look Up</mat-label>
              <mat-select formControlName="ProcedureCodeLookUp" (ngModelChange)="onSelectChange($event)">
                <mat-option>
                  <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                    (filtered)="filteredOptions = $event">
                  </lib-mat-select-search>
                </mat-option>
                <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" [itemSize]="20">
                  <mat-option *cdkVirtualFor="let entry of filteredOptions" [value]="entry" #e>
                    {{entry.Code}} &nbsp; <button type="button" class="ml-4" (click)="copy(entry.Code)" matPrefix>
                      &nbsp;
                      <mat-icon>content_copy</mat-icon></button>
                  </mat-option>
                </cdk-virtual-scroll-viewport>


              </mat-select>


              <mat-error> Codes required</mat-error>

            </mat-form-field>
          </div>

          <div class="col-lg-5">
            Per Unit Charge for the Selected Procedure code : ${{UnitCharge}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Patient Name</mat-label>
              <input type="text" formControlName="PatientName" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance Name</mat-label>
              <input type="text" formControlName="InsuaranceName" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Insuarance No</mat-label>
              <input type="text" formControlName="InsuaranceNo" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Group No</mat-label>
              <input type="text" formControlName="GroupNo" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="row">


          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Subject</mat-label>
              <input type="text" formControlName="Title" matInput>
              <mat-error>Please Enter Value</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Date of Invoice</mat-label>
              <input type="date" formControlName="Date" matInput>
              <mat-error>Please Enter Value</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Provider Name</mat-label>
              <input type="text" formControlName="DoctorName" matInput>
              <mat-error>Please Enter Provider Name</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>NPI Number</mat-label>
              <input type="text" formControlName="NpiNumber" matInput>
              <mat-error>Please Enter National Provider Number</mat-error>

            </mat-form-field>
          </div>
        </div>
        <!-- <h4 class="text-primary text-bold">
          Unit Charge : {{UnitCharge}}
        </h4> -->
        <div class="" formArrayName="InvoiceItems">
          <div class="row" *ngFor="let item of InvoiceItems.controls; let i=index">
            <ng-container [formGroup]="item">
              <!-- <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Procedure Code</mat-label>
                  <mat-select formControlName="ProcedureCode">

                    <mat-option>
                      <lib-mat-select-search [list]="cptCodeList" [searchProperties]="['Code']"
                        (filtered)="flteredmedicineList = $event">
                      </lib-mat-select-search>
                    </mat-option>

                    <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                      <mat-option *cdkVirtualFor="let entry of flteredmedicineList" [value]="entry.Code">
                        {{entry.Code}}
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                  <mat-error>Procedure Code required</mat-error>

                </mat-form-field>
              </div> -->
              <div class="col-lg-6">

                <mat-form-field class="example-full-width">
                  <mat-label>Procedure Code</mat-label>
                  <input type="text" matInput formControlName="ProcedureCode">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-3">
              
                <mat-form-field class="example-full-width">
                  <mat-label>Description (Auto size) </mat-label>

                  <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div> -->

              <div class="col-lg-1">

                <mat-form-field class="example-full-width">
                  <mat-label>Units</mat-label>
                  <input type="number" matInput formControlName="Units">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">

                <mat-form-field class="example-full-width">
                  <mat-label>Duration(min)</mat-label>
                  <input type="number" matInput formControlName="Duration">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <mat-label>Amount</mat-label>
                  <input type="number" matInput formControlName="Amount" (input)="getGrandTotal()">
                  <mat-error>Please Enter Value</mat-error>

                </mat-form-field>
              </div>
              <div class="col-lg-1">
                <button type="button" mat-mini-fab (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
              </div>
            </ng-container>
          </div>

          <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>


        </div>

        <div class="row">
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>CoPay</mat-label>
              <input type="number" matInput formControlName="CoPay" (input)="getGrandTotal()">
            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>SubTotal</mat-label>
              <input type="number" matInput formControlName="SubTotal" readonly>
            </mat-form-field>
          </div>

          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Total</mat-label>
              <input type="number" matInput formControlName="Total" readonly>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <!-- <input type="text" formControlName="DoctorSpecialization" matInput > -->
              <mat-select formControlName="Status">

                <mat-option *ngFor="let sp of StatusList" [value]="sp">{{sp}}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>
        </div>










        <button type="submit" class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Invoice
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>