import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-view-doctor-appointments-today',
  templateUrl: './view-doctor-appointments-today.component.html',
  styleUrls: ['./view-doctor-appointments-today.component.css'],
})
export class ViewDoctorAppointmentsTodayComponent implements OnInit {
  doctor: Doctor;

  id = 0;
  ptAppointments: PatientBooking[] = [];

  filteredList;
  dataSource: MatTableDataSource<Patient>;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  date;

  constructor(
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe(res=>{
    //   this.id= res['id'];
    // })

    this.getAppt(this.id);
    this.getDoctor(this.id);

    // this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    let date = new Date();
    this.date = moment(date).format('MM/DD/YYYY');
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt(id) {
    this.doctorService.getDoctorBooking(id).subscribe((res) => {
      this.filteredList = res.PatientBookings.filter(
        (x) => x.VisitDate == this.date
      );

      this.ptAppointments = res.PatientBookings;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
}
