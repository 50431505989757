import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarePlan } from '../../Models/careplan.model';
import { CareplanService } from '../../services/careplan.service';

@Component({
  selector: 'app-view-careplan-by-id',
  templateUrl: './view-careplan-by-id.component.html',
  styleUrls: ['./view-careplan-by-id.component.css'],
})
export class ViewCareplanByIdComponent implements OnInit {
  carePlanId!: string;
  carePlan: CarePlan;

  constructor(
    private carePlanService: CareplanService,
    private route: ActivatedRoute
  ) {
    const id = route.snapshot.params['id'];
    if (id) {
      this.getCarePlanById(id);
    }
  }

  ngOnInit(): void {}

  getCarePlanById(id) {
    this.carePlanService.getCarePlanById(id).subscribe((res) => {
      this.carePlan = res;
    });
  }
}
