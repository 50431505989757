import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { PhysicalTherapyVisit } from 'src/app/Models/physicaltheraapyvisit.model';
import { PtInitalEvaluation } from 'src/app/Models/ptinitialevaluation.model';
import { PatientService } from 'src/app/services/patient.service';
import { PtInitialEvaluationService } from 'src/app/services/pt-initial-evaluation.service';

@Component({
  selector: 'app-view-pt-initial-evaluation',
  templateUrl: './view-pt-initial-evaluation.component.html',
  styleUrls: ['./view-pt-initial-evaluation.component.css'],
})
export class ViewPtInitialEvaluationComponent implements OnInit {
  id = 0;
  patient: Patient;
  ptInitalEvaluationList: PtInitalEvaluation[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  physicalTherapyVisitId: PhysicalTherapyVisit[];
  showItem = '';
  show = true;
  isHide: boolean;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  CurrentDoctorId: number;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptInitalEvaluationService: PtInitialEvaluationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.patientService.getPTInitialEvaluations(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PtInitalEvaluations.sort(function (x, y) {
        return y.PtInitalEvaluationId - x.PtInitalEvaluationId;
      });
      this.ptInitalEvaluationList = res.PtInitalEvaluations;
    });
  }

  printPage() {
    // window.print();
    document.execCommand('print');
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptInitalEvaluationList.filter((x) =>
        x.InjuryDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptInitalEvaluationService
        .deletePtInitalEvaluation(id)
        .subscribe((res) => {
          this.filteredList = this.ptInitalEvaluationList.filter(
            (x) => x.PtInitalEvaluationId != id
          );
        });
    }
  }
}
