import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import { CptcodeService } from '../../services/cptcode.service';
import { CptCode } from 'src/app/Models/cptcode.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-doctor-patient-visit',
  templateUrl: './doctor-patient-visit.component.html',
  styleUrls: ['./doctor-patient-visit.component.css'],
})
export class DoctorPatientVisitComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;

  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';
  CurrentDoctorId: number;
  filteredList;
  cptCodeLlist: CptCode[];
  show = true;
  isHide: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private cptCodeService: CptcodeService
  ) {}

  ngOnInit(): void {
    // this.getCptCode();

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.CurrentDoctorId = parseInt(decodedToken.nameid);
      this.filteredList = res.PatientVisits.sort(function (x, y) {
        return x.PatientVisitId - y.PatientVisitId;
      });
      this.patientVisitList = res.PatientVisits.sort((a, b) => {
        return b.PatientVisitId - a.PatientVisitId;
      });
    });
  }
  printPage() {
    window.print();
  }

  search(searchValue) {
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  // getCptCode() {
  //   this.cptCodeService.getCptCodes().subscribe((res) => {
  //     this.cptCodeLlist = res;
  //   });
  // }
}
