

    
   <mat-tab-group (selectedTabChange)="tabChanged($event)">

        <mat-tab >
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">code_file</mat-icon>
                CPT Codes
              </ng-template>
            <div class="container mt-5">
                <table class="table table">
                    <thead>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Update</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of cptCodeList">
                            <td>{{item.Code}}</td>
                            <td>{{item.Description}}</td>
                            <td><button mat-mini-fab color="primary" ><mat-icon>edit</mat-icon></button></td>
                            <td><button mat-mini-fab color="accent" ><mat-icon>delete</mat-icon></button></td>
                        </tr>
                    </tbody>
                </table>
                </div>    
                </mat-tab>
            

    <mat-tab > 
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register CPT Code
          </ng-template>
        <div class="container ">
            <div class="row">
                <div class="col-lg regFrm mat-elevation-z8">
                    <h1>Register CPT Code</h1>
                    <form [formGroup]="cptFrm" (ngSubmit)="formSubmit()" class="example-form">
                        <mat-form-field class="example-full-width">
                            <mat-label>CPT Code</mat-label>
                            <input type="text" formControlName="Code" matInput >
                            <mat-error>Please enter CPT Code</mat-error>
                          </mat-form-field>
          
                        <mat-form-field class="example-full-width">
                          <mat-label>Description</mat-label>
                          <input type="text" formControlName="Description" matInput >
                          <mat-error>Please enter Description</mat-error>

                        </mat-form-field>
        
                        
        
                        <button  class="float-right lgBtCl" mat-button >
                            <mat-icon>save</mat-icon>
                            Save</button>

                            <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                                Successfully submitted
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                      </form>
                    
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
   