import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { Vaccine } from 'src/app/Models/vaccine.model';
import { PatientService } from 'src/app/services/patient.service';
import { VaccineService } from 'src/app/services/vaccine.service';

@Component({
  selector: 'app-view-patient-immunization',
  templateUrl: './view-patient-immunization.component.html',
  styleUrls: ['./view-patient-immunization.component.css'],
})
export class ViewPatientImmunizationComponent implements OnInit {
  id = 0;
  vaccineList: Vaccine[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'VaccineName',
    'VaccineProvider',
    'DateTaken',
    'Description',
    'View',
    // 'Delete',
  ];
  VaccineProvider: string;
  VaccineName: string;
  DateTaken: string;
  Description: string;
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private vaccineService: VaccineService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.getVaccine(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  print() {
    window.print();
  }

  getVaccine(id) {
    this.patientService.getVaccines(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.Vaccines.sort(function (x, y) {
          return y.VaccineId - x.VaccineId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.vaccineService.deleteVaccine(id).subscribe((res) => {
      alert('deleted');
      this.getVaccine(this.id);
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getVaccine(this.id);
    }
  }

  public viewLabReport(report: Vaccine): void {
    this.VaccineProvider = report.VaccineProvider;
    this.VaccineName = report.VaccineName;
    this.DateTaken = report.DateTaken;
    this.Description = report.Description;

    // this.TestName = report.TestName;
    // this.TestResultValue = report.TestResultValue;
    // this.ReferanceRange = report.ReferanceRange;
    // this.Units = report.Units;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
}
