import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { WardService } from '../../services/ward.service';

@Component({
  selector: 'app-add-ward',
  templateUrl: './add-ward.component.html',
  styleUrls: ['./add-ward.component.css']
})
export class AddWardComponent implements OnInit {
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private wardService:WardService) { }

  ngOnInit(): void {
  }

  wardFrm = this.fb.group({
    WardName:['',Validators.required],
    WardDescription:['',Validators.required],
  })


get WardName(){
 return this.wardFrm.get('WardName')
}
get WardDescription(){
 return this.wardFrm.get('WardDescription')
}



  formSubmit(){

    let wardName = this.wardFrm.value['WardName']
    let wardDescription = this.wardFrm.value['WardDescription']


    let body={
      "WardName":wardName,
      "WardDescription":wardDescription
    }

this.wardService.registerWard(body).subscribe(res=>{
  this.isSuccess=true
  this.wardFrm.reset();
})
  }
}
