import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent  implements OnInit {

  userList:User[]=[]

  constructor(private userService:UserService){}

  ngOnInit() {
this.getCopmpany();
  }

  getCopmpany(){
    this.userService.getAllUsers().subscribe(res=>{
this.userList =res;
console.log(res)
    })
  }
}
