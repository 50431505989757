import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { PhysicalTherapyDoctorDate } from 'src/app/Models/physicaltherapydoctordate.model';
import { PhysicalTherapyDoctorDateService } from 'src/app/services/physical-therapy-doctor-date.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';

@Component({
  selector: 'app-add-pt-docctor-availability',
  templateUrl: './add-pt-docctor-availability.component.html',
  styleUrls: ['./add-pt-docctor-availability.component.css']
})
export class AddPtDocctorAvailabilityComponent implements OnInit {

  doctorList:PhysicalTherapyDoctor[]=[];
  isSuccess= false;

  constructor(private fb:UntypedFormBuilder,private doctorDatesService:PhysicalTherapyDoctorDateService,private doctorService:PhysicalTherapyDoctorService) { }

  ngOnInit(): void {

    this.getDoctors();
  }


  timFrm = this.fb.group({
    UnAvailableDate:['',Validators.required],
    DoctorName:[''],
    PhysicalTherapyDoctorId:['',Validators.required]
  })

get DoctorName(){
  return  this.timFrm.get('DoctorName')
}
get UnAvailableDate(){
  return  this.timFrm.get('UnAvailableDate')
}
get PhysicalTherapyDoctorId(){
  return this.timFrm.get('PhysicalTherapyDoctorId')
    }

getDoctors(){
  this.doctorService.getPhysicalTherapyDoctors().subscribe(res=>{
    this.doctorList = res;
  })
}


  formSubmit(){

    let unavailableDate = this.timFrm.value['UnAvailableDate']
    unavailableDate = moment(unavailableDate).format('MM/DD/YYYY');
   let  doctorId = this.timFrm.value['PhysicalTherapyDoctorId']
   let idoctorName = this.doctorList.filter(it => it.PhysicalTherapyDoctorId == doctorId)[0].DoctorName;

    let body={
      "UnAvailableDate":unavailableDate,
      "PhysicalTherapyDoctorId":doctorId,
      "DoctorName":idoctorName

    }

    this.doctorDatesService.registerPhysicalTherapyDoctorDate(body).subscribe(res=>{
      this.isSuccess=true
      this.timFrm.reset();
    })
  }
}

