<!-- <mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">handshake</mat-icon>
            Hand Off Note
        </ng-template> -->

<div class="ml-3">
    <button class="float-right mr-4 mt-4" color="primary" (click)="print()" mat-mini-fab>
        <mat-icon>print</mat-icon>
    </button>
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8" id="printable">
    <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
        </ng-container>
        <ng-container matColumnDef="PatientName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>PatientName </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
        </ng-container>


        <ng-container matColumnDef="PatientDOB">
            <th mat-header-cell *matHeaderCellDef> PatientDOB </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientDOB}} </td>
        </ng-container>

        <ng-container matColumnDef="WardName">
            <th mat-header-cell *matHeaderCellDef> WardName </th>
            <td mat-cell *matCellDef="let row"> {{row.WardName}} </td>
        </ng-container>

        <ng-container matColumnDef="HandOffNote">
            <th class="message" mat-header-cell *matHeaderCellDef> HandOffNote </th>
            <td class="message" mat-cell *matCellDef="let row"> {{row.HandOffNote}} </td>
        </ng-container>

        <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let row">

                <button class="" [routerLink]="['/updateNurseHandOffNote/',row.NurseHandOffNoteId]"
                    [disabled]="CurrentNurseId != row.NurseId" color="primary" mat-mini-fab>
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let row">
                <button (click)="delete(row.NurseHandOffNoteId)" mat-mini-fab [disabled]="CurrentNurseId != row.NurseId"
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Hand off Note
        </ng-template>

        <app-add-nurse-hand-off-note></app-add-nurse-hand-off-note>
    </mat-tab>


</mat-tab-group> -->