<div class="container ">
    <h2>Ticket to Ride</h2>

    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Primary Diagnosis</mat-label>
                            <input type="text" formControlName="PrimaryDiagnosis" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Test / Procedure To Be Performed</mat-label>
                            <input type="text" formControlName="TestToBePerformed" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="NurseConfirmedTest">
                            Nurse Confirmed Test/Procedure to be performed
                        </mat-checkbox>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date</mat-label>
                            <input type="date" formControlName="Date" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field class="example-full-width">
                            <mat-label> Time </mat-label>
                            <input [ngxTimepicker]="picker" matInput formControlName="Time">
                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                            <mat-error>Please enter Time</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nurse Signature</mat-label>
                            <input type="text" formControlName="NurseSignature" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Transporter Initials</mat-label>
                            <input type="text" formControlName="TransporterInitials" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <!-- AlamSet -->
                <h4>B=Background
                </h4>


                <div class="row" [formGroup]="Frm">

                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="DNR">
                            DNR
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="Restraints">
                            Restraints
                        </mat-checkbox>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Type</mat-label>
                            <input type="text" formControlName="Type" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="HighRiskFalls">
                            High Risk Falls
                        </mat-checkbox>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Transporter Initials</mat-label>
                            <input type="text" formControlName="TransporterInitials" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <div class="row">


                    <div class="col-md-6">
                        <mat-label class="mx-3">Isolation: </mat-label>

                        <mat-radio-group aria-label="Select an option" formControlName="Isolation">
                            <mat-radio-button value="Contact">Contact</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Airbone">Airbone</mat-radio-button>
                            <mat-radio-button value="Droplet">Droplet</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Language</mat-label>
                            <input type="text" formControlName="PatientLanguage" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="LatextAllergy">
                            Latext Allergy
                        </mat-checkbox>
                    </div>
                    <div class="col-md-5">
                        <mat-label>Impairment: </mat-label>

                        <mat-radio-group aria-label="Select an option" formControlName="Impairment">
                            <mat-radio-button value="Speech">Speech</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Hearing">Hearing</mat-radio-button>
                            <mat-radio-button value="Vission">Vission</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>
                        </mat-radio-group>
                    </div>


                    <div class="col-md-5">
                        <mat-form-field class="example-full-width">
                            <mat-label>Other</mat-label>
                            <input type="text" formControlName="ImpairmentOther" matInput>
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">

                    <div class="col-md-6">

                        <mat-label class="mx-3">Mobility:</mat-label>

                        <mat-radio-group aria-label="Select an option" formControlName="Mobility">
                            <mat-radio-button value="Strecher">Strecher</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Bed">Bed</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Wheelchair">Wheelchair</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Other</mat-label>
                            <input type="text" formControlName="PatientLanguageOther" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <!-- //A=Assessmen -->
                <h2>A=Assessment</h2>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="SedatedandorPainMedication">
                            Sedated and/ or Pain Medication
                        </mat-checkbox>
                    </div>
                    <div class="col-md-3">
                        <mat-checkbox color="primary" formControlName="CanPatientStandForTest">
                            Can Patient Stand For Test?
                        </mat-checkbox>
                    </div>

                    <div class="col-md-5">
                        <mat-label>Mental Status: </mat-label>

                        <mat-radio-group aria-label="Select an option" formControlName="MentalStatus">
                            <mat-radio-button value="Strecher">Strecher</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Bed">Bed</mat-radio-button>
                            <mat-radio-button value="Wheelchair">Wheelchair</mat-radio-button>
                        </mat-radio-group>
                    </div>





                </div>

                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="Oxygen">
                            Oxygen
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Rate</mat-label>
                            <input type="text" formControlName="Rate" matInput>
                        </mat-form-field>
                    </div>
                </div>

                <hr>
                <!-- R=Recomendations -->


                <h4>R=Recomendations</h4>

                <div class="row" [formGroup]="Frm">


                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="IsthereanyAnticipatedProblems">
                            Is there any Anticipated Problems
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Describe</mat-label>
                            <input type="text" formControlName="Describe" matInput>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="TestProcedureCompleted">
                            Test Procedure Completed
                        </mat-checkbox>
                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> Completed Time </mat-label>
                            <input [ngxTimepicker]="picker2" matInput formControlName="CompletedTime">
                            <ngx-material-timepicker #picker2></ngx-material-timepicker>
                            <mat-error>Please enter CompletedTime</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="ChangePatientStatus">
                            Change Patient Status
                        </mat-checkbox>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Describe Change In Patient Status</mat-label>
                            <input type="text" formControlName="DescribeChangeInPatientStatus" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Transporter Sign</mat-label>
                            <input type="text" formControlName="TransporterSign" matInput>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-checkbox color="primary" formControlName="NurseNotifiedUponPatientReturningToRoom">
                            Nurse Notified Upon Patient Returning T oRoom
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="BedAlarmActivated">
                            Bed Alarm Activated
                        </mat-checkbox>
                    </div>
                </div>


                <hr>

                <!-- //IV Lines -->
                <h2> IV Lines
                </h2>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>IV Location</mat-label>
                            <input type="text" formControlName="IVLocation" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-label>Infusa Port: </mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="InfusaPort">
                            <mat-radio-button class="mx-2" value="InfusaPort">InfusaPort</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>

                        </mat-radio-group>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Periferal</mat-label>
                            <input type="text" formControlName="Periferal" matInput>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-label class="mx-3">Hickman: </mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="Hickman">
                            <mat-radio-button value="Left">L</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Right">R</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>

                        </mat-radio-group>

                    </div>
                    <div class="col-md-4">
                        <mat-label class="mx-3">PICC: </mat-label>

                        <mat-radio-group aria-label="Select an option" formControlName="Picc">
                            <mat-radio-button value="Left">L</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Right">R</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>

                        </mat-radio-group>

                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Central</mat-label>
                            <input type="text" formControlName="Central" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="Epidural">
                            Epidural
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="PCA">
                            PCA
                        </mat-checkbox>
                    </div>

                    <div class="col-md-4">
                        <mat-label>Fistula: </mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="Fistula">
                            <mat-radio-button value="Left">L</mat-radio-button>
                            <mat-radio-button class="mx-2" value="Right">R</mat-radio-button>
                            <mat-radio-button value="None">None</mat-radio-button>

                        </mat-radio-group>
                    </div>
                </div>
                <hr>
                <h2> OTHER LINES</h2>
                <div class="row">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="GastricTube">
                            Gastric Tube
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="DobHoffFeedingTube">
                            Dob Hoff Feeding Tube
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="TinkOffPdTube">
                            Tink Off PDTube
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="NgTube">
                            NG Tube
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="DialysisAccess">
                            Dialysis Access
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="ArterialLine">
                            Arterial Line
                        </mat-checkbox>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="BileBag">
                            Bile Bag
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="RectalTube">
                            Rectal Tube
                        </mat-checkbox>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="EtTube">
                            ET Tube
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2">
                        <mat-checkbox color="primary" value="Yes" formControlName="Trach">
                            Trach
                        </mat-checkbox>
                    </div>





                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="RecievedBackToUnit">
                            Is Patient Recieved Back To Unit
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row mt-3">

                    <div class="col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>StaffSignature</mat-label>
                            <input type="text" formControlName="StaffSignature" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Signature Date</mat-label>
                            <input type="date" formControlName="SignatureDate" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">

                        <mat-form-field class="example-full-width">
                            <mat-label> SignatureTime </mat-label>
                            <input [ngxTimepicker]="picker3" matInput formControlName="SignatureTime">
                            <ngx-material-timepicker #picker3></ngx-material-timepicker>
                            <mat-error>Please enter SignatureTime</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>