<!-- <ion-header [style.visibility]="content_visibility"> -->
  <!-- <ion-toolbar color="primary"> -->
    <!-- <ion-title>
      QR Code
    </ion-title> -->
  <!-- </ion-toolbar> -->
<!-- </ion-header> -->

<!-- <ion-content [style.visibility]="content_visibility"> -->


    <div class="mt-5">
        <button mat-raised-button color="primary"
          
          (click)="startScan($event)">
          Scan QR Code
        </button>
      </div>
      <div *ngIf="isSuccess"  class="alert alert-success alert-dismissible fade show mt-5" role="alert">
       <h2>Confirmed - You will be called shortly</h2>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="mt-5 p-5" *ngIf="Token">
        <h4>Your Token Number is : {{ Token }}</h4>
      </div>
        <!-- <ion-item *ngIf="scannedResult">
          <ion-label class="ion-text-wrap">Scanned Result: {{scannedResult}}</ion-label>
        </ion-item> -->
        
      <!-- </ion-content> -->