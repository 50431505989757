<div id="">

  <div class="toolbar mat-elevation-z6 ">
    <button type="button" (click)="logout()" class="btn  float-right mx-3 my-3 logout " mat-raised-button>
      <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
      <span>Logout</span>
    </button>
    <button mat-raised-button class="divBg mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">
      <!-- <div> -->

      <!-- <button class="btn ItemS" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
     <mat-icon mat-list-icon>add</mat-icon>
    New Registrations
    &nbsp;<mat-icon>arrow_downward</mat-icon>
   </button> -->
      <!-- </div> -->
      <!-- <div class="collapse" id="collapseExample">
   <div class="card card-body divBg"> -->
      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Hospital</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS "  (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New Department</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New Doctor</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New Nurse</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <div class="ItemS " (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Patient</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS "  (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New LabManager</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='26'" [ngClass]="{'active': showItem =='26'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2"> New Pharmacyst</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2">New Medicine</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='14'" [ngClass]="{'active': showItem =='14'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2">New Cpt Code</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->

      <!-- <div class="ItemS "  (click)="showItem='20'" [ngClass]="{'active': showItem =='20'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2">New Ward</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='21'" [ngClass]="{'active': showItem =='21'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2">New Room</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- <div class="ItemS "  (click)="showItem='22'" [ngClass]="{'active': showItem =='22'}">
       <mat-icon mat-list-icon>add</mat-icon>
       <div class="mx-2">New Bed</div>
       <mat-icon>navigate_next</mat-icon>
     </div> -->
      <!-- </div> -->
      <!-- </div> -->


      <div class="ItemS " (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>
          dashboard
        </mat-icon>
        <div class="mx-2"> Dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
        <mat-icon mat-list-icon>medication</mat-icon>
        <div class="mx-2"> Hospital</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
        <mat-icon mat-list-icon>
          contact_emergency
        </mat-icon>
        <div class="mx-2"> Physicians</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
        <mat-icon mat-list-icon>
          healing
        </mat-icon>
        <div class="mx-2"> Nurses</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='33'" [ngClass]="{'active': showItem =='33'}">
        <mat-icon mat-list-icon>
          contact_emergency
        </mat-icon>
        <div class="mx-2"> PT Physicians</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>
          personal_injury
        </mat-icon>
        <div class="mx-2"> Patients</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
        <mat-icon mat-list-icon>
          account_balance
        </mat-icon>
        <div class="mx-2">Invoice Receivable</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS " (click)="showItem='41'" [ngClass]="{'active': showItem =='41'}">
        <mat-icon mat-list-icon>
          pending
        </mat-icon>
        <div class="mx-2"> Insurance Pending Payments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
        <mat-icon mat-list-icon>
          paid
        </mat-icon>
        <div class="mx-2"> Un Paid Balances</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
      <div class="ItemS " (click)="showItem='7'" [ngClass]="{'active': showItem =='7'}">
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="mx-2"> All Invoices</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
        <mat-icon mat-list-icon>book_online</mat-icon>
        <div class="mx-2"> All Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>today</mat-icon>
        <div class="mx-2"> Todays Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
        <mat-icon mat-list-icon>book_online</mat-icon>
        <div class="mx-2"> All Physical Therapy Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
        <mat-icon mat-list-icon>today</mat-icon>
        <div class="mx-2"> Todays Physical Therapy Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='12'" [ngClass]="{'active': showItem =='12'}">
        <mat-icon mat-list-icon>view_module</mat-icon>
        <div class="mx-2"> Departments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='23'" [ngClass]="{'active': showItem =='23'}">
        <mat-icon>hotel</mat-icon>
        <div class="mx-2"> Wards</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='24'" [ngClass]="{'active': showItem =='24'}">
        <mat-icon>bedroom_parent</mat-icon>
        <div class="mx-2"> Rooms</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='25'" [ngClass]="{'active': showItem =='25'}">
        <mat-icon>bed</mat-icon>
        <div class="mx-2"> Beds</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
        <mat-icon>blur_circular</mat-icon>
        <div class="mx-2"> Medicine</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
        <mat-icon>add</mat-icon>
        <div class="mx-2">Add Pharmacy</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='47'" [ngClass]="{'active': showItem =='47'}">
        <mat-icon>local_pharmacy</mat-icon>
        <div class="mx-2"> View Pharmacy List</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='37'" [ngClass]="{'active': showItem =='37'}">
        <mat-icon>science</mat-icon>
        <div class="mx-2"> Lab Test List </div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
        <mat-icon>radiology</mat-icon>
        <div class="mx-2"> Radiology Test List </div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='19'" [ngClass]="{'active': showItem =='19'}">
        <mat-icon>code_file</mat-icon>
        <div class="mx-2"> CPT Codes</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='27'" [ngClass]="{'active': showItem =='27'}">
        <mat-icon>group</mat-icon>
        <div class="mx-2"> Pharmacist's</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='28'" [ngClass]="{'active': showItem =='28'}">
        <mat-icon>biotech</mat-icon>
        <div class="mx-2"> Lab Members</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='31'" [ngClass]="{'active': showItem =='31'}">
        <mat-icon>image</mat-icon>
        <div class="mx-2"> Radiology Member</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS  " >
   <mat-icon mat-list-icon>visibility</mat-icon>
   <div class="mx-2" [routerLink]="['/hospitalDash/',this.id]">Hospital</div>
   <mat-icon>navigate_next</mat-icon>
 </div> -->
      <!-- <div class="ItemS "  (click)="showItem='29'" [ngClass]="{'active': showItem =='29'}">
   <mat-icon >add</mat-icon>
   <div class="mx-2">Add Dcotor Off Dates</div>
   <mat-icon>navigate_next</mat-icon>
 </div> -->
      <div class="ItemS " (click)="showItem='32'" [ngClass]="{'active': showItem =='32'}">
        <mat-icon>calendar_today</mat-icon>
        <div class="mx-2"> Doctor Off Days</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='34'" [ngClass]="{'active': showItem =='34'}">
        <mat-icon>calendar_today</mat-icon>
        <div class="mx-2"> PT Doctor Off Days</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
        <mat-icon>sms</mat-icon>
        <div class="mx-2"> Send SMS</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
        <mat-icon>mail</mat-icon>
        <div class="mx-2"> Send Email</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='43'" [ngClass]="{'active': showItem =='43'}">
        <mat-icon>disc_full</mat-icon>
        <div class="mx-2">Bulk Import </div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS "  (click)="showItem='30'" [ngClass]="{'active': showItem =='30'}">
   <mat-icon >time</mat-icon>
   <div class="mx-2"> Time Slots</div>
   <mat-icon>navigate_next</mat-icon>
 </div> -->

    </mat-menu>




  </div>

  <div class="main">
    <!-- <div class="toolbar mat-elevation-z6" ><button type="button"(click)="logout()" class="btn float-right mx-3 my-3 logout " color="accent" mat-raised-button >
<mat-icon matPrefix>highlight_off</mat-icon> 
<span>Logout</span>
</button></div> -->

    <!-- Revenue -->




    <span [ngSwitch]="showItem">

      <div *ngSwitchCase="'0'">

      </div>

      <p *ngSwitchCase="'1'">
        <app-add-hospital></app-add-hospital>
      </p>
      <p *ngSwitchCase="'2'">
        <app-add-doctor></app-add-doctor>
      </p>
      <p *ngSwitchCase="'3'">
        <app-addpatient></app-addpatient>
      </p>

      <p *ngSwitchCase="'4'">
        <app-view-doctor></app-view-doctor>
      </p>

      <p *ngSwitchCase="'5'">
        <app-viewpatient></app-viewpatient>
      </p>

      <p *ngSwitchCase="'6'">
        <app-view-hospital></app-view-hospital>
      </p>
      <p *ngSwitchCase="'7'">
        <app-view-all-invoices></app-view-all-invoices>
      </p>

      <p *ngSwitchCase="'8'">
        <app-view-all-appointments></app-view-all-appointments>
      </p>
      <p *ngSwitchCase="'9'">
        <app-add-nurse></app-add-nurse>
      </p>
      <p *ngSwitchCase="'10'">
        <app-view-nurse></app-view-nurse>
      </p>

      <p *ngSwitchCase="'11'">
        <app-add-department></app-add-department>
      </p>

      <p *ngSwitchCase="'12'">
        <app-view-department></app-view-department>
      </p>
      <p *ngSwitchCase="'13'">
        <app-add-medicine></app-add-medicine>
      </p>
      <p *ngSwitchCase="'14'">
        <app-add-cptcode></app-add-cptcode>
      </p>
      <p *ngSwitchCase="'15'">
        <app-view-medicine></app-view-medicine>
      </p>
      <p *ngSwitchCase="'16'">
        <app-add-lab-manager></app-add-lab-manager>
      </p>
      <div *ngSwitchCase="'17'">
        <div class="container-fluid mt-2">
          <div class="statistic-container">
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.Total | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.Paid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.Unpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.Pending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.Balance | currency }}</span></span>

                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Monthly Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.MonthTotal | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.MonthPaid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.MonthUnpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.MonthPending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.MonthReceivable | currency
                        }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.Total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.Active }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.Discharged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      No. of new patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.NewActive }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.NewDischarged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

          </div>
        </div>
        <app-viewpatient></app-viewpatient>
      </div>

      <p *ngSwitchCase="'18'">
        <app-view-appointments-today></app-view-appointments-today>
      </p>
      <p *ngSwitchCase="'19'">
        <app-add-cptcode></app-add-cptcode>
      </p>

      <p *ngSwitchCase="'20'">
        <app-add-ward></app-add-ward>
      </p>
      <p *ngSwitchCase="'21'">
        <app-add-room></app-add-room>
      </p>
      <p *ngSwitchCase="'22'">
        <app-add-bed></app-add-bed>
      </p>

      <p *ngSwitchCase="'23'">
        <app-view-ward></app-view-ward>
      </p>
      <p *ngSwitchCase="'24'">
        <app-view-room></app-view-room>
      </p>
      <p *ngSwitchCase="'25'">
        <app-view-bed></app-view-bed>
      </p>
      <p *ngSwitchCase="'26'">
        <app-add-pharmacy-member></app-add-pharmacy-member>
      </p>
      <p *ngSwitchCase="'27'">
        <app-view-pharmacy-member></app-view-pharmacy-member>
      </p>
      <p *ngSwitchCase="'28'">
        <app-view-lab-manager></app-view-lab-manager>
      </p>

      <!-- <p *ngSwitchCase="'29'">
 <app-add-doctor-dates></app-add-doctor-dates>
</p> -->

      <p *ngSwitchCase="'30'">
        <app-add-time-slot></app-add-time-slot>
      </p>

      <p *ngSwitchCase="'31'">
        <app-view-radiology-member></app-view-radiology-member>
      </p>
      <p *ngSwitchCase="'32'">
        <app-view-doctor-availability></app-view-doctor-availability>
      </p>
      <p *ngSwitchCase="'33'">
        <app-view-physical-therapy-doctor></app-view-physical-therapy-doctor>
      </p>
      <p *ngSwitchCase="'34'">
        <app-view-pt-docctor-availability></app-view-pt-docctor-availability>
      </p>
      <p *ngSwitchCase="'35'">
        <app-sms></app-sms>
      </p>
      <p *ngSwitchCase="'36'">
        <app-email></app-email>
      </p>
      <p *ngSwitchCase="'37'">
        <app-view-lab-order-subject></app-view-lab-order-subject>
      </p>
      <p *ngSwitchCase="'38'">
        <app-view-radiology-order-subject></app-view-radiology-order-subject>
      </p>

      <p *ngSwitchCase="'40'">
        <app-patient-has-balance-topay></app-patient-has-balance-topay>
      </p>
      <p *ngSwitchCase="'41'">
        <app-patient-has-insurance-pending-payment></app-patient-has-insurance-pending-payment>
      </p>
      <p *ngSwitchCase="'42'">
        <app-patient-has-unpaid-amount></app-patient-has-unpaid-amount>
      </p>
      <p *ngSwitchCase="'43'">
        <app-bulk-import-patient></app-bulk-import-patient>
      </p>
      <p *ngSwitchCase="'44'">
        <app-view-physical-therapy-booking></app-view-physical-therapy-booking>
      </p>
      <p *ngSwitchCase="'45'">
        <app-view-todays-physical-therapy-appointment></app-view-todays-physical-therapy-appointment>
      </p>
      <p *ngSwitchCase="'46'">
        <app-add-paharmacy></app-add-paharmacy>
      </p>
      <p *ngSwitchCase="'47'">
        <app-pharmacylist></app-pharmacylist>
      </p>
      <div *ngSwitchDefault>
        <div class="container-fluid mt-2">
          <div class="statistic-container">
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.Total | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.Paid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.Unpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.Pending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.Balance | currency }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Monthly Revenue
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Revenue?.MonthTotal | currency }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Paid: <span class="text-muted ml-2">{{ Revenue?.MonthPaid | currency }}</span></span>
                    <span>Unpaid: <span class="text-muted ml-2">{{ Revenue?.MonthUnpaid | currency }}</span></span>
                    <span>Pending: <span class="text-muted ml-2">{{ Revenue?.MonthPending | currency }}</span></span>
                    <span>Receivable: <span class="text-muted ml-2">{{ Revenue?.MonthBalance | currency }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.Total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.Active }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.Discharged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      No. of new patients
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ Patients?.New }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ Patients?.NewActive }}</span></span>
                    <span>Discharged: <span class="text-muted ml-2">{{ Patients?.NewDischarged }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Doctors
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ HospitalStaff?.Doctors?.total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span>Active: <span class="text-muted ml-2">{{ HospitalStaff?.Doctors?.active }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="statistic-card">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Total Nurses
                    </mat-panel-title>
                    <mat-panel-description>
                      <span class="text-success">{{ HospitalStaff?.Nurses?.total }}</span>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="d-flex flex-column">
                    <span> Active: <span class="text-muted ml-2">{{ HospitalStaff?.Nurses?.active }}</span></span>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

          </div>
        </div>
        <app-viewpatient></app-viewpatient>

      </div>

    </span>

  </div>