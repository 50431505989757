import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Department } from 'src/app/Models/department.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { DepartmentService } from 'src/app/services/department.service';
import { DischargeSummaryService } from 'src/app/services/discharge-summary.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';

@Component({
  selector: 'app-add-discharge-summary',
  templateUrl: './add-discharge-summary.component.html',
  styleUrls: ['./add-discharge-summary.component.css'],
})
export class AddDischargeSummaryComponent implements OnInit {
  id = 0;
  medicineList: Medicine[];

  isSuccess = false;
  flteredmedicineList: Observable<any>;

  Frm: FormGroup;
  doctorList: Doctor[];
  dpartmentList: Department[];
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private departmentService: DepartmentService,
    private doctorService: DoctorService,
    private dischargeService: DischargeSummaryService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      PatientId: this.id,
      DateOfAdmission: [''],
      DateOfDischarge: [''],
      RefferedBy: [''],
      Complaints: [''],
      Diagnosis: [''],
      Treatments: [''],
      FutureTreatmentPlan: [''],
      Description: [''],
      Signature: [''],

      DischargeMedications: this.fb.array([]),
      AttendedDoctors: this.fb.array([]),
    });
    this.addItem();

    this.getMedicine();
    this.getDoctors();
    this.addAttendedDoctors();
    this.getDepartments();
  }

  public addItem(): void {
    const item = this.fb.group({
      DrugName: [''],
      // Dosage: [''],
      Frequency: [''],
      Duration: [''],
      Quantity: [''],
    });
    this.DischargeMedications.push(item);
  }

  public removeItem(i: number): void {
    this.DischargeMedications.removeAt(i);
  }

  public addAttendedDoctors(): void {
    const doctor = this.fb.group({
      DoctorName: [''],
      DoctorSpeciality: [''],
      DepartmentName: [''],
    });
    this.AttendedDoctors.push(doctor);
  }

  public removeAttendedDoctors(i: number): void {
    this.AttendedDoctors.removeAt(i);
  }

  get RefferedBy() {
    return this.Frm.get('RefferedBy');
  }

  get DateOfDischarge() {
    return this.Frm.get('DateOfDischarge');
  }

  get DateOfAdmission() {
    return this.Frm.get('DateOfAdmission');
  }

  get Complaints() {
    return this.Frm.get('Complaints');
  }
  get Diagnosis() {
    return this.Frm.get('Diagnosis');
  }
  get Treatments() {
    return this.Frm.get('Treatments');
  }
  get FutureTreatmentPlan() {
    return this.Frm.get('FutureTreatmentPlan');
  }
  get Description() {
    return this.Frm.get('Description');
  }

  get DoctorSpeciality() {
    return this.Frm.get('DoctorSpeciality');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get DepartmentName() {
    return this.Frm.get('DepartmentName');
  }

  get AttendedDoctors(): UntypedFormArray {
    return this.Frm.get('AttendedDoctors') as UntypedFormArray;
  }

  get DischargeMedications(): UntypedFormArray {
    return this.Frm.get('DischargeMedications') as UntypedFormArray;
  }

  get Quantity() {
    return this.Frm.get('Quantity');
  }

  get NurseName() {
    return this.Frm.get('NurseName');
  }
  get DrugName() {
    return this.Frm.get('DrugName');
  }
  get Frequency() {
    return this.Frm.get('Frequency');
  }
  get Dosage() {
    return this.Frm.get('Dosage');
  }
  get Duration() {
    return this.Frm.get('Duration');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }

  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  getDepartments() {
    this.departmentService.getDepartments().subscribe((res) => {
      this.dpartmentList = res;
    });
  }

  formSubmit() {
    this.dischargeService
      .registerDischargeSummary(this.Frm.value)
      .subscribe((res) => {
        this.Frm.reset();
        this.isSuccess = true;
      });
  }
}
