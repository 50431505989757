import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { Invoice } from '../../Models/invoice.model';
import { Patient } from '../../Models/patient.model';
import { InvoiceService } from '../../services/invoice.service';
import { InvoiceItem } from '../../Models/invoiceitem.model';
import { Observable } from 'rxjs';
import { InvoiceItemService } from '../../services/invoice-item.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css'],
})
export class ViewInvoiceComponent implements OnInit {
  id = 0;
  invoiceList: Invoice[] = [];
  invoiceItemList: InvoiceItem[] = [];

  filteredList;
  patient: Patient;
  displayedColumns: string[] = [
    'PatientName',
    'Title',
    'Total',
    'Date',
    'Status',
    'Print',
    'Update',
    'Delete',
  ];

  dataSource: MatTableDataSource<any>;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private invoiceItemService:InvoiceItemService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getInvoice(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getInvoice(id) {
    this.patientService.getPatieninvoices(id).subscribe((res) => {
      this.patient = res;
 
      this.dataSource = new MatTableDataSource(res.Invoices);
      this.dataSource.paginator = this.paginator;
    });
  }

  getInvoiceItems(invoiceId){
    return this.invoiceService.getInvoiceItems(invoiceId);
  }

  
  printPage() {
    window.print();
  }
  printPageArea(areaID) {
    var printContent = document.getElementById(areaID).innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  }

  delete(id){
    this.invoiceService.deleteInvoice(id).subscribe(res=>{
      this.invoiceItemService.deleteInvoiceItem(id).subscribe();
      
      this.getInvoice(this.id)

    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
