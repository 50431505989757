import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CarePlan } from 'src/app/Models/careplan.model';
import { CareplanService } from 'src/app/services/careplan.service';
import { NursingAssessment } from '../../Models/nursingassessment.model';
import { NursingAssessmentService } from '../../services/nursing-assessment.service';

@Component({
  selector: 'app-view-nursing-assessment-plan',
  templateUrl: './view-nursing-assessment-plan.component.html',
  styleUrls: ['./view-nursing-assessment-plan.component.css'],
})
export class ViewNursingAssessmentPlanComponent implements OnInit {
  displayedColumns: string[] = [
    'NurseAssessmentId',
    'BedNo',
    'Department',
    'Date',
    'View',
  ];
  dataSource: MatTableDataSource<any>;
  filteredList;
  nursingAssessmentPlan!: NursingAssessment[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private nursingAssessmentService: NursingAssessmentService) {}

  ngOnInit(): void {
    this.getNursingAssessmentReports();
  }

  private getNursingAssessmentReports(): void {
    this.nursingAssessmentService.getNursingAssessments().subscribe({
      next: (res) => {
        this.nursingAssessmentPlan = res.sort(function (x, y) {
          return y.NurseAssessmentId - x.NurseAssessmentId;
        });
        this.dataSource = new MatTableDataSource(this.nursingAssessmentPlan);
      },
    });
  }

  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
