<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>




    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Discharge Date </th>
      <td mat-cell *matCellDef="let row"> {{row.DateOfDischarge | date: 'short'}} </td>
    </ng-container>



    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View Discharge Summary </th>
      <td mat-cell *matCellDef="let row">
        <button type="button" class="btn btn-primary" [routerLink]="['/printDischargeSummary/',row.PatientId]"
          data-toggle="modal" data-target=".bd-example-modal-lg"> View Discharge Summary </button>

        <!-- <button type="button"  class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg"> View Discharge Summary </button>
  
                  <div class="modal fade bd-example-modal-lg"  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">Print Order</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body " id="printable" >
                          <app-view-hospital></app-view-hospital>
                        
                           <div class="areaStyle mb-1">
                             <P>Patient ID: {{patient?.PatientId}} </P>
         
                              <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                             <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                             <P>Patient Address: {{patient?.PatientAddress}} </P>
                             </div>
                       

                             <div class="container mt-5" *ngFor="let item of dischargeSummaryList">

                                <p>Date of Discharge {{item.createdDate | date: 'short'}}</p>
                                <p>Complaints: {{item.Complaints}}</p>
                                <p>Diagnosis: {{item.Diagnosis}}</p>
                                <p>Treatments: {{item.Treatments}}</p>
                                <p>Future Treatment Plan: {{item.FutureTreatmentPlan}}</p>
                                <p>Description: {{item.Description}}</p>
<hr>
                                <h4>Discharge Medications</h4>
                                <table class="table table w-100 ">
                                    <thead><tr>
                                        <th>#</th>
                                        <th>DrugName</th>
                                        <th>Dosage</th>
                                        <th>Frequency</th>
                                        <th>Duration</th>
                                        <th>Quantity</th>
                                    </tr></thead>
                                    <tbody>
                                        <tr *ngFor="let drug of item.DischargeMedications; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{drug.DrugName}}</td>
                                            <td>{{drug.Dosage}}</td>
                                            <td>{{drug.Frequency}}</td>
                                            <td>{{drug.Duration}}</td>
                                            <td>{{drug.Quantity}}</td>
                                           
                                         
                                               
                                         
                                        </tr>
                                       
                                        
                                    </tbody>
                                </table>
                                <hr>
                                <h4>Attended Doctors</h4>
                                <ul *ngFor="let dct of item.AttendedDoctors">
                                <li>{{dct.DoctorName}}</li>
                                <li>{{dct.DoctorSpeciality}}</li>
                                </ul>
                                <hr>
                                <p>Signature: {{item.Signature}}</p>

                            </div>

                             
                        </div>
                        <div class="modal-footer">
                          <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
  
                          <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                      
                        </div>
                      </div>
                    </div>
                  </div> -->

      </td>
    </ng-container>



    <ng-container matColumnDef="Edit">
      <th mat-header-cell *matHeaderCellDef> Edit</th>
      <td mat-cell *matCellDef="let row">
        <button type="button" [routerLink]="['/updateDischargeSummary/',row.DischargeSummaryId]" mat-mini-fab
          color="primary">
          <mat-icon matPrefix>edit</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button (click)="delete(row.DischargeSummaryId)" mat-mini-fab
          color="accent"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>