import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hospital } from 'src/app/Models/hospital.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { ReleaseOfInformationService } from 'src/app/services/release-of-information.service';

@Component({
  selector: 'app-add-release-of-information',
  templateUrl: './add-release-of-information.component.html',
  styleUrls: ['./add-release-of-information.component.css'],
})
export class AddReleaseOfInformationComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  nurse: Nurse;
  hospital: Hospital;
  patientName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private releaseOfInformationService: ReleaseOfInformationService,
    private router: Router,
    private nurseService: NurseService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
    this.nurseService.getNurseById(this.nurseId).subscribe((res) => {
      this.nurse = res;
    });
    this.Frm = this.fb.group({
      ClientName: [''],
      ProviderName: [''],
      Send: [false],
      Receive: [false],
      MedicalHistoryandEvaluations: [false],
      MentalHealthEvaluations: [false],
      DevelopmentalAndSocialHistory: [false],
      EducationalReords: [false],
      ProgressNotesandTreatmentSummary: [false],
      Other: [''],
      Phone: [''],
      ToFrom: [''],
      Self: [false],
      ParentOrLegalGuardian: [false],
      PersonalRepresentative: [false],
      OtherRelation: [''],
      PlanningApproprirateTreatment: [false],
      Continuingappropriatetreatmentorprogram: [false],
      DeterminingEligibility: [false],
      CaseReview: [false],
      UpdatingFiles: [false],
      OtherUse: [false],
      // Signature: ['', Validators.required],
      DateOfSignature: ['', Validators.required],
    });
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get Send() {
    return this.Frm.get('Send');
  }

  get Receive() {
    return this.Frm.get('Receive');
  }

  get MedicalHistoryandEvaluations() {
    return this.Frm.get('MedicalHistoryandEvaluations');
  }

  get MentalHealthEvaluations() {
    return this.Frm.get('MentalHealthEvaluations');
  }

  get DevelopmentalAndSocialHistory() {
    return this.Frm.get('DevelopmentalAndSocialHistory');
  }

  get EducationalReords() {
    return this.Frm.get('EducationalReords');
  }

  get ProgressNotesandTreatmentSummary() {
    return this.Frm.get('ProgressNotesandTreatmentSummary');
  }

  get Other() {
    return this.Frm.get('Other');
  }
  get ToFrom() {
    return this.Frm.get('ToFrom');
  }
  get Phone() {
    return this.Frm.get('Phone');
  }
  get Self() {
    return this.Frm.get('Self');
  }
  get ParentOrLegalGuardian() {
    return this.Frm.get('ParentOrLegalGuardian');
  }
  get PersonalRepresentative() {
    return this.Frm.get('PersonalRepresentative');
  }
  get OtherRelation() {
    return this.Frm.get('OtherRelation');
  }
  get PlanningApproprirateTreatment() {
    return this.Frm.get('PlanningApproprirateTreatment');
  }
  get DeterminingEligibility() {
    return this.Frm.get('DeterminingEligibility');
  }
  get CaseReview() {
    return this.Frm.get('CaseReview');
  }
  get UpdatingFiles() {
    return this.Frm.get('UpdatingFiles');
  }
  get OtherUse() {
    return this.Frm.get('OtherUse');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  get DateOfSignature() {
    return this.Frm.get('DateOfSignature');
  }
  get ProviderName() {
    return this.Frm.get('ProviderName');
  }
  get ClientName() {
    return this.Frm.get('ClientName');
  }
  get Continuingappropriatetreatmentorprogram() {
    return this.Frm.get('Continuingappropriatetreatmentorprogram');
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.ClientName.setValue(
        res.PatientFirstName + ' ' + res.PatientLastName
      );
    });
  }
  formSubmit() {
    let body = {
      ...this.Frm.value,
      PatientId: this.id,
      Signature: this.signatureImg,
    };
    this.releaseOfInformationService
      .registerReleaseOfInformation(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }

  @ViewChild('signPad', { static: false })
  signPad!: ElementRef<HTMLCanvasElement>;
  @Output() signatureSaved = new EventEmitter();
  private signatureImg?: string;
  private sigPadElement: any;
  private context: any;
  private isDrawing!: boolean;

  public ngAfterViewInit(): void {
    this.sigPadElement = this.signPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  onMouseDown(e: any): void {
    // The mouse button is clicked, which means the start of drawing the signature
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any): void {
    // The mouse button is released, so this means the end of drawing the signature
    this.isDrawing = false;
  }

  onMouseMove(e: any): void {
    if (this.isDrawing) {
      // if we're not drawing we need to ignore the events
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  clearSignature(e): void {
    e.preventDefault;

    this.signatureImg = undefined;
    this.context.clearRect(
      0,
      0,
      this.sigPadElement.width,
      this.sigPadElement.height
    );
    this.context.beginPath();
  }

  saveSignature(e): void {
    e.preventDefault;
    this.signatureImg = this.sigPadElement.toDataURL('image/png');

    this.signatureSaved.emit(this.signatureImg);
    // console.log('bite', this.signatureImg);
  }

  private relativeCoords(event: any): { x: number; y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY,
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }
}
