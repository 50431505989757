import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { NurseDailyAssessment } from 'src/app/Models/nursedailyassessment.mode';
import { NurseDailyAssessmentService } from 'src/app/services/nurse-daily-assessment.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-nurse-daily-assessment',
  templateUrl: './view-nurse-daily-assessment.component.html',
  styleUrls: ['./view-nurse-daily-assessment.component.css'],
})
export class ViewNurseDailyAssessmentComponent implements OnInit {
  id = 0;
  nurseAssesmentList: NurseDailyAssessment[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private nurseDailyAssessmentService: NurseDailyAssessmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientInitialnurseAssesment(this.id);
  }

  getPatientInitialnurseAssesment(id) {
    this.patientService.getNurseDailyAssessment(id).subscribe((res) => {
      this.nurseAssesmentList = res.NurseDailyAssessments.sort(function (x, y) {
        return y.NurseDailyAssessmentId - x.NurseDailyAssessmentId;
      });
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.nurseDailyAssessmentService
      .deleteNurseDailyAssessment(id)
      .subscribe((res) => {
        this.getPatientInitialnurseAssesment(this.id);
      });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPatientInitialnurseAssesment(this.id);
    }
  }
}
