<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            View Patient Admission

        </ng-template>
        <div class="conatiner mx-5 my-5 " id="printable">
            <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
                data-target=".bd-example-modal-lg"> Print </button>
            <div class="row">
                <div class="col-md-12" *ngFor="let item of patientAdmissionList">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-4">
                                    MRN: {{patient.PatientId}}
                                </div>
                                <div class="col-md-4">
                                    Patient Name: {{patient.PatientFirstName}} {{patient.PatientLastName}}

                                </div>
                                <div class="col-md-4">
                                    D.O.B: {{patient.PatientDOB}}

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Date Of Admission: {{item.DateOfAdmission | date: 'MM/dd/yyyy'}}</p>

                                </div>
                                <div class="col-md-6">
                                    <p>Time Of Admission: {{item.TimeOfAdmission}}</p>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Sender/Caller Information: {{item.SenderInformation}}</p>

                                </div>
                                <div class="col-md-2">
                                    <p>Name: {{item.SenderCallerName}}</p>

                                </div>
                                <div class="col-md-2">
                                    <p>Phone: {{item.SenderCallerPhone}}</p>

                                </div>
                                <div class="col-md-3">
                                    <p>Email: {{item.SenderCallerEmail}}</p>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <p>Disposition: {{item.Disposition}}</p>
                                </div>
                                <div class="col-md-6">
                                    <p>Admission Source: {{item.AdmissionSource}}</p>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Admission Type: {{item.AdmissionType}}</p>

                                </div>
                                <div class="col-md-4">
                                    <p>Admission Bed: {{item.AdmissionBed}}</p>

                                </div>
                                <div class="col-md-4">
                                    <p>Admission Unit: {{item.AdmissionUnit}}</p>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <p>ICD-10 Diagnosis Code: {{item.Icd10DiagnosisCode}}</p>

                                </div>
                                <div class="col-md-6">
                                    <p> ICD-10 Procedure Code (Inpatient): {{item.Icd10ProcedureCode}}</p>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Chief Complaints: {{item.ChiefComplaints}}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Attending Physician: {{item.AttendingPhysician}}</p>

                                </div>
                                <div class="col-md-4">
                                    <p>Reffering Physician: {{item.RefferringPhysician}}</p>

                                </div>
                                <div class="col-md-4">
                                    <p>Primary Care Physician: {{item.PrimaryCareDoctor}}</p>

                                </div>
                            </div>
                        </mat-card-content>
                        <button class="float-right" [routerLink]="['/UpdatePatientAdmission/',item.PatientAdmissionId]"
                            color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
                        <button class="float-right mx-3" (click)="delete(item.PatientAdmissionId)" color="accent"
                            mat-mini-fab><mat-icon>delete</mat-icon> </button>

                    </mat-card>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register New Patient Admission
        </ng-template>

        <app-add-patient-admission></app-add-patient-admission>
    </mat-tab>
</mat-tab-group>