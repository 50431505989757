<button (click)="exportImage()"class="btn lgBtCl float-right " mat-raised-button><mat-icon>save</mat-icon> </button> 

<div class="sidebar mt-3">
  <div class="colours">
    <div class="row">
      <div class="colour " colour="red" style="background-color: red"></div>
      <div class="colour" colour="blue" style="background-color: blue"></div>
      <div class="colour" colour="brown" style="background-color: brown"></div>
      <div class="colour" colour="yellow" style="background-color: yellow"></div>
    </div>
    <div class="row">
      <div class="colour" colour="green" style="background-color: green"></div>
      <div class="colour" colour="black" style="background-color: black"></div>
      <div class="colour" colour="orange" style="background-color: orange"></div>
      <div class="colour" colour="teal" style="background-color: teal"></div>
    </div>

 
  </div>
  <div class="tools">
  <div class="row">
    <div class="tool col-lg-3" tool="pencil">
      <mat-icon>edit</mat-icon>
     </div>
     <div class="tool col-lg-3 mx-1" tool="clear">
       <mat-icon>delete</mat-icon>
     </div>
     <div class="tool col-lg-3 " tool="eraser">
       <mat-icon>phonelink_erase</mat-icon>
     </div>
  </div>
  </div>
</div>
<canvas #canvas></canvas>
