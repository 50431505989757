<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">hotel</mat-icon>
      Wards
    </ng-template>

    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="WardName">
          <th mat-header-cell *matHeaderCellDef> WardName </th>
          <td mat-cell *matCellDef="let row"> {{row.WardName}} </td>
        </ng-container>
        <ng-container matColumnDef="WardDescription">
          <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.WardDescription}} </td>
        </ng-container>


        <ng-container matColumnDef="Update">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let row">
            <button type="button" [routerLink]="['/updateWard/',row.WardId]" mat-mini-fab color="primary">
              <mat-icon matPrefix>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> Delete</th>
          <td mat-cell *matCellDef="let row">
            <button (click)="delete(row.WardId)" mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Register new Ward
    </ng-template>

    <app-add-ward></app-add-ward>
  </mat-tab>


</mat-tab-group>