import { Component, OnInit } from '@angular/core';
import { LabManager } from 'src/app/Models/labmanager.model';
import { LabmanagerService } from '../../services/labmanager.service';
import { UserService } from '../../services/user.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-lab-manager',
  templateUrl: './view-lab-manager.component.html',
  styleUrls: ['./view-lab-manager.component.css']
})
export class ViewLabManagerComponent implements OnInit {
  labManagerList:LabManager[];

  constructor(private labManagerService:LabmanagerService,private userService:UserService) { }

  ngOnInit(): void {
    this.getLabManager();
  }




  getLabManager(){
    this.labManagerService.getLabManagers().subscribe(res=>{
      this.labManagerList=res;
    })
  }


  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getLabManager();
      
    }
  }

  delete(id){
    this.labManagerService.deleteLabManager(id).subscribe(res=>{
      this.userService.deleteUser(id)
      this.getLabManager();
    })
  }
}
