import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Ward } from 'src/app/Models/ward.model';
import { DutyScheduleService } from 'src/app/services/duty-schedule.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';
import { WardService } from 'src/app/services/ward.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-duty-schedule',
  templateUrl: './add-duty-schedule.component.html',
  styleUrls: ['./add-duty-schedule.component.css'],
})
export class AddDutyScheduleComponent implements OnInit {
  helper = new JwtHelperService();
  wardList: Ward[];
  autoFillItems = [
    { value: 'Sunday' },
    { value: 'Monday' },
    { value: 'Tuesday' },
    { value: 'Wednessday' },
    { value: 'Thursday' },
    { value: 'Friday' },
    { value: 'Saturday' },
  ];
  id = 0;
  myToken = window.localStorage.getItem('token');
  isSuccess = false;
  imageUrl = '';
  hsFrm: FormGroup;
  listening: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dutyScheduleService: DutyScheduleService,
    private router: Router,
    private wardService: WardService,
    public service: VoiceRecognitionService
  ) {
    this.service.init();
  }

  ngOnInit(): void {
    this.getWards();

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.hsFrm = this.fb.group({
      WardId: [],
      WeekCommencing: ['', Validators.required],
      DutyScheduleItems: this.fb.array([]),
    });
  }
  public removeItem(i: number): void {
    this.DutyScheduleItems.removeAt(i);
  }

  public addItem(): void {
    const item = this.fb.group({
      FullName: ['', Validators.required],
      DateOfDuty: ['', Validators.required],
      DayOfDuty: ['', Validators.required],
      Shift: ['', Validators.required],
      Comments: [''],
    });
    this.DutyScheduleItems.push(item);
  }
  get DutyScheduleItems(): UntypedFormArray {
    return this.hsFrm.get('DutyScheduleItems') as UntypedFormArray;
  }
  get WardId() {
    return this.hsFrm.get('WardId');
  }
  get Comments() {
    return this.hsFrm.get('Comments');
  }

  get WeekCommencing() {
    return this.hsFrm.get('WeekCommencing');
  }

  get FullName() {
    return this.hsFrm.get('FullName');
  }
  get DateOfDuty() {
    return this.hsFrm.get('DateOfDuty');
  }
  get DayOfDuty() {
    return this.hsFrm.get('DayOfDuty');
  }
  get Shift() {
    return this.hsFrm.get('Shift');
  }

  getWards() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }

  formSubmit() {
    let wardId = this.hsFrm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;

    let body = {
      ...this.hsFrm.value,
      WardName: wardName,
      WardId: wardId,
      NurseId: this.id,
    };

    this.dutyScheduleService.registerDutySchedule(body).subscribe((res) => {
      this.isSuccess = true;
      this.hsFrm.reset();

      // this.router.navigate(['/viewHospital/'])
    });
  }
}
