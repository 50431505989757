<h2 class="txt-primary ml-5 mt-3">Routine Medications</h2>

<div class="container-fluid">
  <div class="ml-3">
    <button class="float-right mr-4 mt-4" color="primary" (click)="print()" mat-mini-fab>
      <mat-icon>print</mat-icon>
    </button>
    <div class="row">
      <div class="col-lg-6 float-right">
        <mat-form-field class="example-full-width mt-5">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class=" mat-elevation-z8" id="printable">
    <table mat-table [dataSource]="dataSource" matSort>


      <!-- <ng-container matColumnDef="PartofDay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>PartofDay </th>
        <td mat-cell *matCellDef="let row"> {{row.PartofDay}} </td>
      </ng-container> -->


      <!-- 
      <ng-container matColumnDef="DrugName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> DrugName </th>
        <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
      </ng-container>
      <ng-container matColumnDef="BrandName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> BrandName </th>
        <td mat-cell *matCellDef="let row"> {{row.BrandName}} </td>
      </ng-container>
      <ng-container matColumnDef="Dosage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage </th>
        <td mat-cell *matCellDef="let row"> {{row.Dosage}} </td>
      </ng-container>  -->
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row"> {{row[0].Date}} </td>
      </ng-container>
      <ng-container matColumnDef="Time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
        <td mat-cell *matCellDef="let row"> {{row[0].Time}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="Comments">
        <th class="message" mat-header-cell *matHeaderCellDef mat-sort-header> Comments </th>
        <td class="message mt-4" mat-cell *matCellDef="let row"> {{row.Comments}} </td>
      </ng-container>
      <ng-container matColumnDef="NurseName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NurseName </th>
        <td mat-cell *matCellDef="let row"> {{row.NurseName}} </td>
      </ng-container>
      <ng-container matColumnDef="Signature">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Signature </th>
        <td mat-cell *matCellDef="let row"> {{row.Signature}} </td>
      </ng-container> -->

      <!-- 
      <ng-container matColumnDef="Print">
        <th  mat-header-cell *matHeaderCellDef> view </th>
        <td mat-cell *matCellDef="let element"> 
          <button type="button"  [routerLink]="['/printMedication/',element.MedicationId]" mat-mini-fab color="primary">
            <mat-icon matPrefix>search</mat-icon>
          </button>
        </td>
      </ng-container> -->
      <!-- modlel -->

      <ng-container matColumnDef="ViewReport">
        <th mat-header-cell *matHeaderCellDef> View Report </th>
        <td mat-cell *matCellDef="let row">


          <button type="button" (click)="PrintReport(row)" class="btn btn-primary" data-toggle="modal"
            data-target=".bd-example-modal-lg">View Report</button>

          <div class="modal w-100 fade bd-example-modal-lg" tabindex="-1" role="dialog" *ngIf="currentMedciation"
            aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Print Report</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body " id="printable" #medicationList>
                  <app-view-hospital></app-view-hospital>
                  <div>
                    <div class="areaStyle mb-1">
                      <div class="row">
                        <div class="col-12 text-right">
                          <p>{{currentMedciation[0].Date}}</p>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-4">Patient ID: </div>
                            <div class="col-8">{{patient?.PatientId}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">Patient D.O.B: </div>
                            <div class="col-8">{{patient?.PatientDOB}}</div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="row">
                            <div class="col-4">Patient Name: </div>
                            <div class="col-8">{{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}}</div>
                          </div>
                          <div class="row">
                            <div class="col-4">Patient Address: </div>
                            <div class="col-8">{{patient?.PatientAddress}} </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>

                    <div *ngFor="let medication of currentMedciation" class="areaStyle mb-4">
                      <div class="d-flex justify-content-between align-items-center">
                        <h2>{{medication.PartofDay}} :</h2>
                        <button mat-mini-fab color="primary"
                          [routerLink]="['/editNursePtMedication/',medication.MedicationId]"
                          [queryParams]="{patientId: id }"><mat-icon>edit</mat-icon></button>
                      </div>
                      <div>
                        <table class="table table-striped">
                          <thead>
                            <th>#</th>
                            <th>Drug Name</th>
                            <th>Brand Name</th>
                            <th>Dosage</th>
                            <th>No. of Tablets</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let drug of medication.Drugs; let i=index">
                              <td>{{i+1}}</td>
                              <td>{{drug.DrugName}}</td>
                              <td>{{drug.BrandName}}</td>
                              <td>{{drug.Dosage}}</td>
                              <td>{{drug.NumberOfDrugs}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="areaStyle">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-4">Nurse Name:
                              </div>
                              <div class="col-8">{{medication.NurseName}}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-3">
                                Signature: </div>
                              <div class="col-9">{{medication.Signature}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
                <div class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">



                  <div>
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
                    <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                  </div>
                  <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
              </div>
            </div>
          </div>

        </td>
      </ng-container>
      <!-- modlel -->
      <!-- <ng-container matColumnDef="Detail">
        <th mat-header-cell *matHeaderCellDef>Edit/Update</th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/editNursePtMedication/',element.MedicationId]" mat-mini-fab
            color="primary">
            <mat-icon matPrefix>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>View Medication</th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/printMedication/',element.MedicationId]" mat-mini-fab color="primary">
            <i class="fa-duotone fa-pills"></i>
          </button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>