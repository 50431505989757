

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Add Medication</h1>
            <form [formGroup]="medForm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Drug</mat-label>
                    <input type="text" formControlName="Drug" matInput >
                  </mat-form-field>
  
                <mat-form-field class="example-full-width">
                  <mat-label>Date</mat-label>
                  <input type="text" formControlName="Date" matInput >
                </mat-form-field>

                

                <mat-form-field class="example-full-width">
                  <mat-label>Strength</mat-label>
                  <input type="Strength" matInput formControlName="Strength" >
                </mat-form-field>
              
                <mat-form-field class="example-full-width">
                  <mat-label>Instruction</mat-label>
                  <input type="Instruction" matInput formControlName="Instruction" >
                </mat-form-field>

                <button  class="float-right lgBtCl" mat-button >
                    <mat-icon>save</mat-icon>
                    Save</button>
              </form>

        </div>
    </div>

</div>
