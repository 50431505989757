
<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab >
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">doctor</mat-icon>
         View Doctor Un Avaialble Days (Off Duty)
          </ng-template>
  
          <div class="ml-3">
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                      </mat-form-field>
                </div>
            </div>
          </div>
          <div class=" mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
          
            
              <ng-container matColumnDef="DoctorId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Doctor Id </th>
                <td mat-cell *matCellDef="let row"> {{row.DoctorId}} </td>
              </ng-container>
          
          
        
              <ng-container matColumnDef="DoctorName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Doctor Name </th>
                <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
              </ng-container>
              <ng-container matColumnDef="AvailableDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Un Available Dates </th>
                <td mat-cell *matCellDef="let row"> {{row.AvailableDate}} </td>
              </ng-container>
         
              <!-- <ng-container matColumnDef="Detail">
                <th  mat-header-cell *matHeaderCellDef> Patient Details</th>
                <td mat-cell *matCellDef="let element"> 
                  <button type="button"  [routerLink]="['/doctorPatient/',element.PatientId]" mat-mini-fab color="primary">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                  </button>
                </td>
              </ng-container> -->
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
          </div>
  </mat-tab>
        
  
  <mat-tab > 
    <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Register Doctor Un Avaialble Days
      </ng-template>
      <app-add-doctor-dates></app-add-doctor-dates>
  </mat-tab>
  
  
  </mat-tab-group>
  