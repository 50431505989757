<div class="toolbar mat-elevation-z6">

  <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS ">
      <mat-icon mat-list-icon>Radiology Dashboard</mat-icon>
      <div class="mx-2" [routerLink]="['/radiologyDash/']">dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
      <mat-icon mat-list-icon>summarize</mat-icon>
      <div class="mx-2">Patient Summary</div>
      <mat-icon>navigate_next</mat-icon>
    </div>




    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Radiology-Report</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">MRI Screening</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>science</mat-icon>
      <div class="mx-2">View Radiology-Report</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View Radiology Order</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>show_chart</mat-icon>
      <div class="mx-2">Upload MRI</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View MRI</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS" (click)="showItem='7'" [ngClass]="{'active': showItem =='7'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">Create Invoice</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">View Invoice</div>
      <mat-icon>navigate_next</mat-icon>
    </div>


  </mat-menu>
</div>


<span [ngSwitch]="showItem">

  <p *ngSwitchCase="'1'">
    <app-patientsummary></app-patientsummary>
  </p>


  <p *ngSwitchCase="'2'">
    <app-add-radiology-report></app-add-radiology-report>
  </p>
  <p *ngSwitchCase="'3'">
    <app-view-radiology-report></app-view-radiology-report>
  </p>

  <p *ngSwitchCase="'4'">
    <app-view-radiology-order></app-view-radiology-order>
  </p>

  <p *ngSwitchCase="'5'">
    <app-add-mri></app-add-mri>
  </p>
  <p *ngSwitchCase="'6'">
    <app-view-mri></app-view-mri>
  </p>


  <p *ngSwitchCase="'7'">
    <app-add-invoice></app-add-invoice>
  </p>


  <p *ngSwitchCase="'8'">
    <app-view-invoice></app-view-invoice>
  </p>


  <p *ngSwitchCase="'9'">
    <app-view-mri-screening></app-view-mri-screening>
  </p>




  <p *ngSwitchDefault>

    <app-view-radiology-report></app-view-radiology-report>
  </p>







</span>

<!-- </div>
     </div> -->
<!-- </div> -->