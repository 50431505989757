import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PhysicalTherapyBooking } from 'src/app/Models/physicaltherapybooking.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhysicaltherapyBookedslotService } from 'src/app/services/physicaltherapy-bookedslot.service';
import { PhysicaltherapyBookingService } from 'src/app/services/physicaltherapy-booking.service';

@Component({
  selector: 'app-view-physical-therapy-booking',
  templateUrl: './view-physical-therapy-booking.component.html',
  styleUrls: ['./view-physical-therapy-booking.component.css'],
})
export class ViewPhysicalTherapyBookingComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'Delete',
  ];

  id = 0;
  ptAppointments: PhysicalTherapyBooking[] = [];
  dataSource: MatTableDataSource<any>;
  filteredList;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: PhysicaltherapyBookingService,
    private bookedSlotService: PhysicaltherapyBookedslotService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getAppt(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt(id) {
    this.patientService.getPhysicalTherapyBookings(id).subscribe((res) => {
      // this.filteredList = res.PatientBookings
      this.dataSource = new MatTableDataSource(
        res.PhysicalTherapyBookings.sort(function (x, y) {
          return y.PhysicalTherapyBookingId - x.PhysicalTherapyBookingId;
        })
      );
      this.dataSource.paginator = this.paginator;
      //  this.ptAppointments= res.PatientBookings
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.appointmentService
      .deletePhysicalTherapyBooking(id)
      .subscribe((res) => {
        this.bookedSlotService
          .deletePhysicalTherapyBookedSlot(id)
          .subscribe((res) => {
            alert('Cancelled');
          });
        // alert('Cancelled')

        this.getAppt(this.id);
      });
  }
}
