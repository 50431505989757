<div class="d-flex p-2 flex-column">
    <h2 class="text-center">Care Plan</h2>
    <h5 class="text-center">(MEDICATION ORDERS TO BE WRITTEN IN MEDICINE CARD)</h5>

    <mat-horizontal-stepper [linear]="'true'" #stepper color="primary">

        <form [formGroup]="CarePlan">
            <mat-step label="Care Plan Investigations">
                <div class="block">
                    <div class="row mx-0">
                        <div class="col-md-2 col__pad col__left">
                            <span>Care Plan:</span>
                        </div>
                        <div class="col-md-10 ">
                            <mat-radio-group formControlName="CarePlanName">
                                <div class="row">
                                    <div class="col-md-3 col-6 col__left">
                                        <mat-radio-button color="primary" style="padding-top:10px"
                                            value="Curative">Curative</mat-radio-button>
                                    </div>
                                    <div class="col-md-3 col-6 col__left">
                                        <mat-radio-button color="primary" style="padding-top:10px"
                                            value="Preventive">Preventive</mat-radio-button>
                                    </div>
                                    <div class="col-md-3 col-6 col__left">
                                        <mat-radio-button color="primary" style="padding-top:10px"
                                            value="Rehabilitative">Rehabilitative</mat-radio-button>
                                    </div>
                                    <div class="col-md-3 col-6">
                                        <mat-radio-button color="primary" style="padding-top:10px"
                                            value="Palliative">Palliative</mat-radio-button>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row mx-0">
                        <div class="col-md-2 col__pad col__left">
                            <span>Investigations:</span>
                        </div>
                        <div class="col-md-10 col__pad ">
                            <span>Doctors Order (Diet, Physio & any other special instructions)</span>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row mx-0">
                        <div class="col-md-2 col__pad-h col__left">
                            <span>Other Investigations:</span>
                        </div>
                        <div class="col-md-10 col__pad-h ">
                            <mat-form-field class="w-100">
                                <mat-label>Other Investigations</mat-label>
                                <textarea [rows]="'4'" matInput placeholder="Other Investigations"
                                    formControlName="OtherInvestigations"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row mx-0">
                        <div class="col-md-2 col__pad-h col__left">
                            <span>Likely Date of Discharge:</span>
                        </div>
                        <div class="col-md-3 col__pad-h ">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="datePicker" formControlName="DischargePlanDate">
                                <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                                <mat-error>Discharge date is required*</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-7 col__pad-h ">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Identify Special Needs ...</mat-label>
                                <textarea [rows]="'4'" matInput placeholder="Identify Special Needs regarding care following discharge 
        (ex. Age Extremes, Restricted Mobility, Continuing nursing & rehabilitation needs, assistance with daily living activities. etc)
                            " formControlName="SpecialNeeds"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <button mat-raised-button matStepperNext color="primary"
                    [disabled]="carePlan.DischargePlanDate.errors?.required">Next</button>

            </mat-step>
            <mat-step label="Diet & Nutrition">
                <ng-template mat-tab-label>
                    <span class="me-2 pt-1">Diet</span>
                    <mat-icon>menu_book</mat-icon>
                </ng-template>
                <div class="block">
                    <div class="row mx-0">
                        <div class="block block__top w-100">
                            <div class="row mx-0 ">
                                <div class="col-md-2 col__pad-h col__left">
                                    <h4>Diet</h4>
                                </div>
                                <div class="col-md-10">
                                    <mat-radio-group formControlName="DietType">
                                        <div class="row mx-0">
                                            <div class="col-md-3 col__pad-h col__left">
                                                <mat-radio-button color="primary"
                                                    value="Normal">Normal</mat-radio-button>
                                                <mat-radio-button color="primary" value="TheraputicDiet">Theraputic Diet
                                                    (Specify)</mat-radio-button>
                                            </div>
                                            <div class="col-md-9 col__pad-h col-12">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Details</mat-label>
                                                    <textarea matInput placeholder="Specify diet"
                                                        formControlName="DietDetails"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="block block__top w-100">
                            <div class="row mx-0">
                                <div class="col-md-2 col__pad-h col__left">
                                    <h4>Dietary Consultation</h4>
                                </div>
                                <div class="col-md-10">
                                    <mat-radio-group formControlName="DietryConsultationType">
                                        <div class="row mx-0">
                                            <div class="col-md-3 col__pad-h col__left">
                                                <mat-radio-button color="primary" value="true">Yes</mat-radio-button>
                                                <br>
                                                <mat-radio-button color="primary" value="false">No</mat-radio-button>
                                            </div>
                                            <div class="col-md-9 col__pad-h col-12">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Details</mat-label>
                                                    <textarea matInput
                                                        formControlName="DietryConsultationDetails"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="block block__top w-100">
                            <div class="row mx-0">
                                <div class="col-md-2 col__pad-h col__left">
                                    <h4>Physiotherapy</h4>
                                </div>
                                <div class="col-md-10">
                                    <mat-radio-group formControlName="PhysiotherapyType">
                                        <div class="row mx-0">
                                            <div class="col-md-3 col__pad-h col__left">
                                                <mat-radio-button color="primary" value="true">Yes</mat-radio-button>
                                                <br>
                                                <mat-radio-button color="primary" value="false">No</mat-radio-button>
                                            </div>
                                            <div class="col-md-9 col__pad-h col-12">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Details</mat-label>
                                                    <textarea matInput
                                                        formControlName="PhysiotherapyDetails"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="block block__top w-100">
                            <div class="row mx-0">
                                <div class="col-md-2 col__pad-h col__left">
                                    <h4>Restraint Required</h4>
                                </div>
                                <div class="col-md-10">
                                    <mat-radio-group formControlName="RestraintRequiredType">
                                        <div class="row mx-0">
                                            <div class="col-md-3 col__pad-h col__left">
                                                <mat-radio-button color="primary" value="true">Yes</mat-radio-button>
                                                <h5>(if Yes, please take consent & fill up restraint form )</h5>
                                                <mat-radio-button color="primary" value="false">No</mat-radio-button>
                                            </div>
                                            <div class="col-md-9 col__pad-h col-12">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <mat-label>Details</mat-label>
                                                    <textarea [rows]="'4'" matInput
                                                        formControlName="RestraintRequiredDetails"></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="block block__top w-100">
                            <div class="row mx-0">
                                <div class="col-12 col__pad-h">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Any special instructions (Specify) </mat-label>
                                        <textarea [rows]="'10'" matInput
                                            placeholder="Any special instructions (Specify)"
                                            formControlName="SpecialInstructions"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button mat-raised-button matStepperPrevious color="primary">Previous</button>
                <button mat-raised-button matStepperNext color="primary">Next</button>
            </mat-step>
            <mat-step label="Laboratory ">
                <ng-template mat-tab-label>
                    <span class="me-2 pt-1">Laboratory</span>
                    <mat-icon>science</mat-icon>
                </ng-template>
                <div class="block">
                    <div class="container-fluid">
                        <div class="row py-2">
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="CBC">
                                    CBC
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="RBS">
                                    RBS
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="RFT">
                                    RFT
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="LFT">
                                    LFT
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="SerumElectrolytes">
                                    Serum Electrolytes
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="UrineRoutine">
                                    Urine Routine
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="ThyroidProfile">
                                    Thyroid Profile
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="HIV">
                                    HIV
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="HBSAg">
                                    HBSAg
                                </mat-checkbox>
                            </div>
                            <div class="col-md-3 col-6">
                                <mat-checkbox color="primary" formControlName="HCV">
                                    HCV
                                </mat-checkbox>
                            </div>
                            <div class="col-12 mt-3">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Please specify other laboratory tests</mat-label>
                                    <textarea [rows]="'5'" matInput formControlName="OtherLaboratoryTest"
                                        placeholder="Please specify other laboratory tests"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <button mat-raised-button matStepperPrevious color="primary">Previous</button>
                <button mat-raised-button matStepperNext color="primary">Next</button>
            </mat-step>

            <mat-step label="Radiology">
                <ng-template mat-tab-label>
                    <span class="me-2 pt-1">Radiology</span>
                    <mat-icon>warning</mat-icon>
                </ng-template>
                <div class="block container-fluid">
                    <div class="row py-2">
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="XRay">
                                X-RAY
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="USG">
                                USG
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="CT">
                                CT
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="MRI">
                                MRI
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="MAMMOGRAM">
                                MAMMOGRAM
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="DOPPLER">
                                DOPPLER
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-6">
                            <mat-checkbox color="primary" formControlName="PET">
                                PET
                            </mat-checkbox>
                        </div>
                        <div class="col-md-6 mt-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Please specify other tests</mat-label>
                                <textarea [rows]="'8'" matInput placeholder="Please specify other tests"
                                    formControlName="OtherRadioLogyTest"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 mt-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Surgery/Procedure planned and special orders if
                                    any/preparations</mat-label>
                                <textarea [rows]="'8'" matInput
                                    placeholder="Surgery/Procedure planned and special orders if any/preparations"
                                    formControlName="SurgeryOrProcedure"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <button mat-raised-button matStepperPrevious color="primary">Previous</button>
                <button mat-raised-button matStepperNext color="primary">Next</button>
            </mat-step>
            <mat-step label="Cross Consultations">
                <div class="block">
                    <div class="col-12 col__pad text-center">
                        <span class="mb-0">Cross Consultation</span>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row mx-0">
                        <div class="col-md-5 col__left col__pad col-5">
                            Name of The Doctor
                        </div>
                        <div class="col-md-5 col__pad col-5">
                            Department
                        </div>
                        <div class="col-md-2 col__pad col-2">
                            Action
                        </div>
                    </div>
                </div>
                <ng-container formArrayName="CrossConsultations">
                    <div class="block block__top">
                        <ng-container *ngFor="let consultation of CrossConsultations.controls; let index=index">
                            <div [formGroupName]="index">
                                <div class="row mx-0">
                                    <div class="col-md-5 col__left col__pad-h col-5">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Name of The Doctor</mat-label>
                                            <input matInput formControlName="DoctorName">
                                            <mat-error>Name of doctor is required*</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-5 col__pad-h col__left col-5">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Department</mat-label>
                                            <input matInput formControlName="DepartmentName">
                                            <mat-error>Department name is required*</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2  d-flex justify-content-center align-items-center col-2">
                                        <!-- <button mat-raised-button color="primary" >Remove</button> -->
                                        <mat-icon color="primary"
                                            (click)="removeCrossConsultation(index)">delete</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </ng-container>
                <div class="block block__top">
                    <div class="row mx-0">
                        <button mat-raised-button class="w-100" color="primary"
                            (click)="addCrossConsultation()">Add</button>
                    </div>
                </div>

                <div class="block block__top">
                    <div class="col-12 col__pad text-center">
                        <span class="mb-0">Please Fill in Cross Consultation Form</span>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row mx-0">
                        <div class="col-md-6 col__left col__pad">
                            <div class="row mx-0">
                                <div class="col-md-6 col__pad col-6">
                                    Name of Doctor Documented
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Name of doctor documented"
                                            formControlName="DoctorDocumentedName">
                                        <mat-error>required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    Signature
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Signature of doctor documented"
                                            formControlName="DoctorDocumentedSignature">
                                    </mat-form-field>
                                    <!-- <button mat-raised-button color="primary"
                                        (click)="openSignaturePad(signaturePad, 'DoctorDocumentedSignature')"> Open
                                        Signature pad </button> -->
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    Date
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Date</mat-label>
                                        <input matInput [matDatepicker]="datePicker1"
                                            formControlName="DoctorDocumentedDate">
                                        <mat-datepicker-toggle matIconSuffix
                                            [for]="datePicker1"></mat-datepicker-toggle>
                                        <mat-datepicker #datePicker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    Time
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Time</mat-label>

                                        <input matInput placeholder="Time of Documentation" [ngxTimepicker]="timePicker"
                                            formControlName="DoctorDocumentedTime">
                                        <mat-icon matSuffix>time</mat-icon>
                                        <mat-error>required</mat-error>

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col__left col__pad">
                            <div class="row mx-0">
                                <div class="col-md-6 col__pad col-6">
                                    Name of Primary Consultant Documented
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Name of Primary Consultant"
                                            formControlName="ConsultantDocumentedName">
                                        <mat-error>required</mat-error>

                                    </mat-form-field>
                                </div>
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Signature of doctor documented"
                                        formControlName="ConsultantDocumentedSignature">
                                </mat-form-field>
                                <!-- <div class="col-md-6 col__pad col-6">
                                    Signature
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <button mat-raised-button color="primary"
                                        (click)="openSignaturePad(signaturePad, 'ConsultantDocumentedSignature')"> Open
                                        Signature pad </button>

                                </div> -->
                                <div class="col-md-6 col__pad col-6">
                                    Date
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Date</mat-label>
                                        <input matInput [matDatepicker]="datePicker2"
                                            formControlName="ConsultantDocumentedDate">
                                        <mat-datepicker-toggle matIconSuffix
                                            [for]="datePicker2"></mat-datepicker-toggle>
                                        <mat-datepicker #datePicker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    Time
                                </div>
                                <div class="col-md-6 col__pad col-6">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Time</mat-label>
                                        <input matInput placeholder="Time of Documentation" color="primary"
                                            [ngxTimepicker]="timePicker2" formControlName="ConsultantDocumentedTime">
                                        <mat-icon matSuffix>time</mat-icon>
                                        <mat-error>required</mat-error>

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button mat-raised-button matStepperPrevious color="primary">Previous</button>
                <button mat-raised-button color="primary" (click)="show()">Save</button>

            </mat-step>
        </form>
    </mat-horizontal-stepper>
</div><ngx-material-timepicker #timePicker></ngx-material-timepicker>
<ngx-material-timepicker #timePicker2></ngx-material-timepicker>
<!-- 
<ng-template #signaturePad>
    <div class="container px-3">
        <div class="row m-auto">
            <canvas #canvas id="signature-pad" class="signature-pad" width=450 height=200></canvas>
        </div>
        <div class="row mx-auto my-2">
            <span>Sign Above</span>
        </div>
        <div class="row m-auto">
            <button class="mx-1" color="primary" mat-raised-button (click)="clear()">Clear</button>
            <button class="mx-1" color="primary" mat-raised-button (click)="save()">Save</button>
        </div>
    </div>
</ng-template> -->