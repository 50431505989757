import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LabmanagerService } from '../../services/labmanager.service';
import { Department } from '../../Models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-lab-manager',
  templateUrl: './add-lab-manager.component.html',
  styleUrls: ['./add-lab-manager.component.css'],
})
export class AddLabManagerComponent implements OnInit {
  id = 0;
  isSuccess = false;
  dptList: Department[];
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private labmanagerService: LabmanagerService,
    private userService: UserService,
    private router: Router,
    private dptService: DepartmentService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDepartments();

    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: [''],
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],

    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
  });

  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }

  get FullName() {
    return this.doctorFrm.get('FullName');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.doctorFrm.get('NationalId');
  }

  get Dob() {
    return this.doctorFrm.get('Dob');
  }
  get Address() {
    return this.doctorFrm.get('Address');
  }

  get Status() {
    return this.doctorFrm.get('Status');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }

  getDepartments() {
    this.dptService.getDepartments().subscribe((res) => {
      this.dptList = res;
    });
  }

  formSubmit() {
    let fullName = this.doctorFrm.value['FullName'];
    let dob = this.doctorFrm.value['Dob'];
    let email = this.doctorFrm.value['Email'];
    let phoneNumber =
      this.doctorFrm.value['CountryCode'] + this.doctorFrm.value['PhoneNumber'];
    let nationalId = this.doctorFrm.value['NationalId'];
    let address = this.doctorFrm.value['Address'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];
    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerLabManager(userBody).subscribe((res) => {
      let body = {
        LabManagerId: res.Id,
        FullName: fullName,
        Dob: dob,
        Email: email,
        PhoneNumber: phoneNumber,
        NationalId: nationalId,
        Address: address,
        Status: status,
        Password: password,
        DepartmentId: departmentId,
      };

      this.labmanagerService.registerLabManager(body).subscribe((res) => {
        this.isSuccess = true;
        this.doctorFrm.reset();
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
