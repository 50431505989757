import { Component } from '@angular/core';

@Component({
  selector: 'app-add-nerve-study-subtype',
  templateUrl: './add-nerve-study-subtype.component.html',
  styleUrls: ['./add-nerve-study-subtype.component.css']
})
export class AddNerveStudySubtypeComponent {

}
