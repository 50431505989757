import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BloodSugar } from 'src/app/Models/bloodsugar.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-blood-sugar-chart',
  templateUrl: './blood-sugar-chart.component.html',
  styleUrls: ['./blood-sugar-chart.component.css'],
})
export class BloodSugarChartComponent implements OnInit {
  heartRateChart: any = {
    series: [
      {
        name: 'Blood Sugar',
        data: [],
      },
    ],
    title: {
      text: 'Blood Sugar Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  id = 0;
  bloodSugarReports: BloodSugar[] = [];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getBloodSugar(this.id);
  }

  getBloodSugar(id) {
    this.patientService.getBloodSugarResult(id).subscribe((res) => {
      this.bloodSugarReports = res.BloodSugars;
      this.heartRateChart.series[0].data = this.bloodSugarReports.map(
        (report) => [new Date(report.TestDate).getTime(), report.SugarLevel]
      );
    });
  }
}
