import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PhysicalTherapyDoctor } from 'src/app/Models/physicaltherapydoctor.model';
import { DepartmentService } from 'src/app/services/department.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-physical-therapy-doctor',
  templateUrl: './view-physical-therapy-doctor.component.html',
  styleUrls: ['./view-physical-therapy-doctor.component.css'],
})
export class ViewPhysicalTherapyDoctorComponent implements OnInit {
  id = 0;
  showItem = '';
  doctorList: PhysicalTherapyDoctor[] = [];
  filteredList: PhysicalTherapyDoctor[];
  dataSource: MatTableDataSource<PhysicalTherapyDoctor>;
  constructor(
    private route: ActivatedRoute,
    private departService: DepartmentService,
    private doctorService: PhysicalTherapyDoctorService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getDoctor();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getDoctor() {
    this.doctorService.getPhysicalTherapyDoctors().subscribe((res) => {
      this.doctorList = res;
      this.filteredList = res.sort(function (x, y) {
        return y.PhysicalTherapyDoctorId - x.PhysicalTherapyDoctorId;
      });
    });
  }

  // getDoctor(id){
  // this.departService.getDepartmentDoctors(id).subscribe(res=>{
  //   this.doctorList = res.Doctors;
  //   this.filteredList = res.Doctors;
  // })
  // }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDoctor();
    }
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.doctorList.filter((x) =>
        x.DoctorName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.doctorService.deletePhysicalTherapyDoctor(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      //  this.filteredList = this.doctorList.filter(x=>x.DoctorId !=id)
      this.getDoctor();
    });
  }
}
