import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.css']
})
export class SuperAdminDashboardComponent {

  id=0;
  showItem='';
  
 
    constructor(private route:ActivatedRoute,private router:Router) { }
  
    ngOnInit(): void {
      this.route.params.subscribe(res=>{
        this.id = res['id']
      })
    }




  logout(){
    localStorage.removeItem('token');
    this.router.navigate(['/'])
  }
}
