<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="DrugName">
            <th mat-header-cell *matHeaderCellDef> Drug Name </th>
            <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
        </ng-container>
        <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef> StartDate </th>
            <td mat-cell *matCellDef="let row"> {{row.StartDate | date: 'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef> Refill by </th>
            <td mat-cell *matCellDef="let row"> {{row.EndDate | date: 'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="Description">
            <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="PharmacyName">
            <th mat-header-cell *matHeaderCellDef> Pharmacy Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyName}} </td>
        </ng-container>
        <ng-container matColumnDef="PharmacyAddress">
            <th mat-header-cell *matHeaderCellDef>Pharmacy Address </th>
            <td mat-cell *matCellDef="let row"> {{row.PharmacyAddress}} </td>
        </ng-container>


        <ng-container matColumnDef="Detail">
            <th mat-header-cell *matHeaderCellDef>Update/Approve</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/appovePrescription/',row.PrescriptionId]" mat-mini-fab
                    color="primary">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <!-- 
        <ng-container matColumnDef="Aprove">
            <th mat-header-cell *matHeaderCellDef> Aprove</th>
            <td mat-cell *matCellDef="let row">
                <button (click)="delete(row.RefillRequestId)" mat-mini-fab
                    color="primary"><mat-icon>approval</mat-icon></button>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let row">
                <button (click)="delete(row.RefillRequestId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>