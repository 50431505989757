<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">view_quilt</mat-icon>
      CPT Code
    </ng-template>


    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">

      <div class="d-flex justify-content-end m-2">
        <form (ngSubmit)="uploadFile()">
          <input type="file" class="formControl" (change)=" onFileSelected($event)" accept="application/json">
          <button type="submit btn btn-warning">Upload ICD Codes</button>
        </form>

      </div>
      <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="Code">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Code </th>
          <td mat-cell *matCellDef="let row"> {{row.Code}} </td>
        </ng-container>


        <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef> Description </th>
          <td class="message" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="Update">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element">
        <button type="button" [routerLink]="['/editCptCode/',element.CptCodeId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>edit</mat-icon>
        </button>
      </td>
    </ng-container> -->
        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> Delete</th>
          <td mat-cell *matCellDef="let element">
            <button (click)="delete(element.CptCodeId)" mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Register CPT Code
    </ng-template>
    <app-add-cptcode></app-add-cptcode>
  </mat-tab>


</mat-tab-group>