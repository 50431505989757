import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Labreport } from 'src/app/Models/labreport.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-lab-test-chart',
  templateUrl: './lab-test-chart.component.html',
  styleUrls: ['./lab-test-chart.component.css'],
})
export class LabTestChartComponent implements OnInit {
  labTestChart: any = {
    xAxis: {
      type: 'datetime',
    },
    series: [],
    title: {
      text: 'Lab Results Graph',
    },
    chart: {
      type: 'line',
    },
  };
  id = 0;
  labReports: Labreport[] = [];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getlabReport(this.id);
  }

  getlabReport(id) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReports = res.LabReports;
      this.labTestChart.series = [];
      let arr: any[] = [];
      if (this.labReports?.length > 0) {
        this.labReports.forEach((element) => {
          if (element.LabTests.length > 0) {
            element.LabTests.forEach((x) => {
              arr.push({
                name: x.TestName,
                data: x.TestResultValue,
                date: element.Date,
              });
            });
          }
        });
        arr.forEach((x) => {
          const index = this.labTestChart.series?.findIndex(
            (k) => k.name === x.name
          );
          if (index > -1) {
            this.labTestChart.series[index].data?.push([
              new Date(x.date)?.getTime(),
              x.data,
            ]);
          } else {
            this.labTestChart.series?.push({
              name: x.name,
              data: [[new Date(x.date)?.getTime(), x.data]],
            });
          }
        });
      }
    });
  }
}
