import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CptCode } from 'src/app/Models/cptcode.model';
import { CptcodeService } from 'src/app/services/cptcode.service';

@Component({
  selector: 'app-view-cptcode',
  templateUrl: './view-cptcode.component.html',
  styleUrls: ['./view-cptcode.component.css'],
})
export class ViewCptcodeComponent implements OnInit {
  displayedColumns: string[] = ['Code', 'Description', 'Delete'];
  cptCodeList: CptCode[] = [];
  dataSource: MatTableDataSource<CptCode>;
  selectedFile: File;

  constructor(private cptCodeService: CptcodeService) {}

  ngOnInit(): void {
    this.getCptCodes();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getCptCodes() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.cptCodeList = res;
    });
  }

  delete(id) {
    this.cptCodeService.deleteCptCode(id).subscribe((res) => {
      this.getCptCodes();
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  uploadFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const data = JSON.parse(fileContent as string);

        // Validate the data structure
        if (!Array.isArray(data.Cptcodes)) {
          // alert('Invalid data structure');
        }
        data.Cptcodes.forEach((item) => {
          if (typeof item.Code !== 'string') {
            // alert('Invalid data structure');
          }
          // Add more checks as necessary...
        });

        this.cptCodeService.UploadJsonIcd10Data(data).subscribe(
          (res) => {
            alert('Upload With success');
            this.getCptCodes();
          },
          (err) => {
            alert('Error uploading the data');
          }
        );
        // this.http.post('https://your-api-url.com/upload', data)
        // .subscribe(
        //     response => console.log(response),
        //     error => console.log(error)
        // );
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };
    fileReader.onerror = (error) => console.log(error);
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getCptCodes();
    }
  }
}
