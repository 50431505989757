<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Duty Roster By Shift </h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">


                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Select Ward</mat-label>
                            <mat-select formControlName="WardId">

                                <mat-option *ngFor="let wrd of wardList"
                                    [value]="wrd.WardId">{{wrd.WardName}}</mat-option>

                            </mat-select>


                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Charge Nurse</mat-label>
                            <input type="text" formControlName="ChargeNurse" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Unit Secretory</mat-label>
                            <input type="text" formControlName="UnitSecretory" matInput>
                            <mat-error>Please Enter UnitSecretory</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Shift</mat-label>
                            <input type="text" formControlName="Shift" placeholder="AM/PM" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>MT</mat-label>
                            <input type="text" formControlName="MT" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Census</mat-label>
                            <input type="text" formControlName="Census" matInput>
                            <mat-error>Please Enter Census</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Sitters</mat-label>
                            <input type="text" formControlName="Sitters" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Central Lines</mat-label>
                            <input type="text" formControlName="CentralLines" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Inmates</mat-label>
                            <input type="text" formControlName="Inmates" matInput>
                            <mat-error>Please Enter Census</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>DNR</mat-label>
                            <input type="text" formControlName="DNR" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Pending Transfer</mat-label>
                            <input type="text" formControlName="PendingTransfer" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Pending DC</mat-label>
                            <input type="text" formControlName="PendingDC" matInput>
                            <mat-error>Please Enter PendingDC</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Foleys</mat-label>
                            <input type="text" formControlName="Foleys" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Restraints</mat-label>
                            <input type="text" formControlName="Restraints" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Isolations</mat-label>
                            <input type="text" formControlName="Isolations" matInput>
                            <mat-error>Please Enter Isolations</mat-error>

                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Vacant Beds</mat-label>
                            <input type="text" formControlName="VacantBeds" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Falls</mat-label>
                            <input type="text" formControlName="Falls" matInput>
                            <mat-error>Please Enter Value</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Bariatrics</mat-label>
                            <input type="text" formControlName="Bariatrics" matInput>
                            <mat-error>Please Enter Bariatrics</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Plastics</mat-label>
                            <input type="text" formControlName="Plastics" matInput>
                            <mat-error>Please Enter Plastics</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h2> Assignment Jobs</h2>

                <div class="" formArrayName="DutyRosterItems">
                    <div class="row" *ngFor="let item of DutyRosterItems.controls; let i=index">
                        <ng-container [formGroup]="item">
                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Phones</mat-label>
                                    <input type="text" matInput formControlName="Phones">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Names</mat-label>
                                    <input type="text" matInput formControlName="Names">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-lg-1">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Profile</mat-label>
                                    <input type="text" matInput formControlName="Profile">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>

                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Assignments</mat-label>
                                    <input type="text" matInput formControlName="Assignments">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Admission</mat-label>
                                    <input type="text" matInput formControlName="Admission">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-lg-2">

                                <mat-form-field class="example-full-width">
                                    <mat-label>Discharge</mat-label>
                                    <input type="text" matInput formControlName="Discharge">
                                    <mat-error>Please Enter Value</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-lg-1">
                                <button type="button" mat-mini-fab
                                    (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
                            </div>
                        </ng-container>
                    </div>

                    <button type="button" mat-mini-fab color="primary"
                        (click)="addItem()"><mat-icon>add</mat-icon></button>


                </div>







                <button type="submit" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Invoice
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>