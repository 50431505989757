import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

@Component({
  selector: 'app-labpatient',
  templateUrl: './labpatient.component.html',
  styleUrls: ['./labpatient.component.css']
})
export class LabpatientComponent implements OnInit {

  id=0;
  patient:Patient
  patientVisitList:PatientVisit[]=[];
  isAdmitted=false;
  filteredList;
  pvId=0;
    patientVisitId: PatientVisit[];
    showItem='';
  
    
    constructor(private patientService:PatientService, private route: ActivatedRoute,
      private router: Router,private ptVisitService:PatientvisitService) { }
  
    ngOnInit(): void {
      this.route.params.subscribe(res=>{
        this.id= res['id']
      })
      this.patientService.getPatientvisits(this.id).subscribe(res=>{
             this.patient = res;
  
             this.filteredList = res.PatientVisits;
           this.patientVisitList = res.PatientVisits;
  
          //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
          //   this.isAdmitted
          //  }
  
      })
    }
    printPage() {
  
      window.print();
  
    }
  
  
    search(searchValue) {
      // console.log(searchValue);
      if (searchValue.value != null) {
        this.filteredList = this.patientVisitList.filter((x) =>
          x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
        );
      }
    }
  
  
    deleteVisit(id){
      let C_confirm = confirm('Are you Sure you want to delete ?');
      if (C_confirm == true){
      this.ptVisitService.deletePatientVisit(id).subscribe(res=>{
      
        this.filteredList = this.patientVisitList.filter(x=>x.PatientVisitId !=id);
      })
  
    }
    }
  }
  