
<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab >
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">view_quilt</mat-icon>
           Departments
          </ng-template>


          <div class=" mt-5">
            <table class="table table">
        <thead>
            <th>DepartmentName</th>
            <th>Description</th>
            <!-- <th>Update</th> -->
            <th>Delete</th>
        </thead>
        
        <tbody>
        <tr *ngFor="let item of dptList" >
            <td>{{item.DepartmentName}}</td>
            <td>{{item.Description}}</td>
            <!-- <td><button mat-mini-fab color="primary"><mat-icon>edit</mat-icon></button></td> -->
            <td><button mat-mini-fab color="accent" (click)="deleteDpt(item.DepartmentId)"><mat-icon>delete</mat-icon></button></td>
        </tr>
        </tbody>
            </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        
        </div>
        
</mat-tab>
        

<mat-tab > 
    <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Register Departments
      </ng-template>
      <app-add-department></app-add-department>
</mat-tab>


</mat-tab-group>

