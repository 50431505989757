<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Release Of Information
        </ng-template>
        <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
            data-target=".bd-example-modal-lg"> Print </button>
        <div class="row">
            <div class="col-md-6" *ngFor="let item of release">

                <mat-card class="mat-elevation-z8 mt-3 ">

                    <mat-card-content id="printable">

                        <h2 class="clr">Release of Information</h2>
                        <p>Provider Name : {{item.ProviderName}}</p>
                        <p>Client Name : {{item.ClientName}}</p>
                        <p class="float-right">Date : {{item.createdDate | date: 'medium' }}</p>

                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>I authorize the practice/clinician stated above to: *</h2>
                                <tr>
                                    <th> Send : </th>

                                    <td>{{item.Send ?'Yes':'No'}}</td>
                                </tr>
                                <tr>
                                    <th> Receive : </th>
                                    <td>{{item.Receive ?'Yes':'No'}}</td>

                                </tr>


                            </div>





                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>The following information:*</h2>

                                <tr>
                                    <th> Medical History and Evaluations : </th>
                                    <td>{{item.MedicalHistoryandEvaluations ?'Yes':'No'}}</td>

                                </tr>

                                <tr>
                                    <th>Mental Health Evaluations ? : </th>
                                    <td>{{item.MentalHealthEvaluations ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Developmental and /or SocialHistory : </th>
                                    <td>{{item.DevelopmentalAndSocialHistory }}</td>

                                </tr>
                                <tr>
                                    <th> Educational Records: </th>
                                    <td>{{item.EducationalReords ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Progress Notes and Treatment or closing Summary : </th>
                                    <td>{{item.ProgressNotesandTreatmentSummary ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th>Other : </th>
                                    <td>{{item.Other}}</td>

                                </tr>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <h2> RelationShip to Client</h2>
                                <tr>
                                    <th> Self : </th>
                                    <td>{{item.Self ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Parent Or Legal Guardian : </th>
                                    <td>{{item.ParentOrLegalGuardian ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Personal Representative : </th>
                                    <td>{{item.PersonalRepresentative ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Other Relation : </th>
                                    <td>{{item.OtherRelation}}</td>

                                </tr>


                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <tr>
                                    <th> To/From : </th>
                                    <td>{{item.ToFrom}}</td>

                                </tr>

                                <tr>
                                    <th> Phone : </th>
                                    <td>{{item.Phone}}</td>

                                </tr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h2> The above information above will be used for the following purposes:
                                    * </h2>
                                <tr>
                                    <th> Planning appropriate treatment or program : </th>
                                    <td>{{item.PlanningApproprirateTreatment ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Continuing appropriate treatment or program : </th>
                                    <td>{{item.Continuingappropriatetreatmentorprogram ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Determining eligibility for benefits or program : </th>
                                    <td>{{item.DeterminingEligibility ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Case review : </th>
                                    <td>{{item.CaseReview ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Updating files : </th>
                                    <td>{{item.UpdatingFiles ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Other : </th>
                                    <td>{{item.OtherUse ?'Yes':'No'}}</td>

                                </tr>



                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">

                                <p>I understand that this information may be protected by Title 45 (Code of Federal
                                    Rules of
                                    Privacy
                                    ofIndividually Identifiable Health Information, Parts 160 and 164) and Title 42
                                    (Federal
                                    Rules
                                    of
                                    Confidentialityof Alcohol and Drug Abuse Patient Records, Chapter 1, Part 2), plus
                                    applicable
                                    state
                                    laws. I furtherunderstand that the information disclosed to the recipient may not be
                                    protected
                                    under
                                    these guidelines ifthey are not a health care provider covered by state or federal
                                    rules.

                                </p>


                                <p>I understand that this authorization is voluntary, and I may revoke this consent at
                                    any
                                    time
                                    by
                                    providingwritten notice, and after (some states vary, usually 1 year) this consent
                                    automatically
                                    expires. I have beeninformed what information will be given, its purpose, and who
                                    will
                                    receive
                                    the
                                    information. I understand that Ihave a right to receive a copy of this
                                    authorization. I
                                    understand
                                    that I have a right to refuse to sign thisauthorization.

                                </p>

                                <p>
                                    If you are the legal guardian or representative appointed by the court for the
                                    client,
                                    please
                                    attach
                                    a copy ofthis authorization to receive this protected health information.

                                </p>

                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">

                                <tr>
                                    <th> Signature: </th>
                                    <td><img src="{{item?.Signature}}" alt=""></td>


                                </tr>
                                <tr>
                                    <th>Date Of Signature : </th>
                                    <td>{{item.DateOfSignature}}</td>

                                </tr>

                            </div>


                        </div>
                    </mat-card-content>
                    <button class="float-right" [routerLink]="['/editReleaseInformation/',item.ReleaseOfInformationId]"
                        color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
                    <button class="float-right mx-5" (click)="delete(item.ReleaseOfInformationId)" color="accent"
                        mat-mini-fab><mat-icon>delete</mat-icon> </button>

                </mat-card>
            </div>
        </div>
        <hr>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Release Of Information
        </ng-template>

        <app-add-release-of-information></app-add-release-of-information>
    </mat-tab>


</mat-tab-group>