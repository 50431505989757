import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadLabReportService } from 'src/app/services/upload-lab-report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-upload-lab-repor',
  templateUrl: './add-upload-lab-repor.component.html',
  styleUrls: ['./add-upload-lab-repor.component.css'],
})
export class AddUploadLabReporComponent implements OnInit {
  id = 0;
  imageUrl: string;
  isSuccess = false;
  uploadProgress: number = 0;
  constructor(
    private uploadLabReportService: UploadLabReportService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  mriFrm = this.fb.group({
    LabReportImage: [''],
  });

  get LabReportImage() {
    return this.mriFrm.get('LabReportImage');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.uploadLabReportService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
    //   (res: HttpEvent<any>) => {
    //     if (res.type === HttpEventType.UploadProgress) {
    //       this.uploadProgress = Math.round((100 * res.loaded) / res.total);
    //     } else if (res.type === HttpEventType.Response) {
    //       let stImg = environment.imgurl + res['path'];
    //       // this.imageUrl = stImg
    //       this.imageUrl = `<p><img src="${stImg}"/></p>`;
    //       this.uploadProgress = 100;
    //     }
    //   },
    //   ({ error }) => {
    //     console.log(error);
    //   }
    // );
  }

  formSubmit() {
    let LabReportImage = this.mriFrm.value['LabReportImage'];

    let body = {
      PatientId: this.id,
      LabReportImage: this.imageUrl,
    };
    // console.log(body);
    this.uploadLabReportService
      .registerUploadLabReport(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.mriFrm.reset();
      });
  }
}
