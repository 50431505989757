<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Create Vitals Report</h1>
      <form [formGroup]="medFrm" (ngSubmit)="formSubmit()" class="example-form">






        <div class="row">

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Respiration</mat-label>
              <input type="text" formControlName="Respiration" matInput>
              <mat-error>Please enter Respiration</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Temparature</mat-label>
              <input type="text" matInput formControlName="Temparature">
              <mat-error>Please enter Respiration</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-2">

            <mat-form-field class="example-full-width">
              <mat-label>Heart Beat </mat-label>
              <input type="text" matInput formControlName="HeartBeat">
              <mat-error>Please enter Heart Beat</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Pressure (Systolic/Diastolic )</mat-label>
              <input type="text" matInput formControlName="BloodPressure">
              <mat-error>Please enter Blood Pressure</mat-error>

            </mat-form-field>

          </div>
        </div>
        <div class="row">

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label> Time </mat-label>
              <input [ngxTimepicker]="picker" matInput formControlName="Time">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error>Please enter Time</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-8">
            <mat-form-field class="example-full-width">
              <mat-label>Nurse Notes/Comments </mat-label>
              <!-- <mat-icon matPrefix>event</mat-icon> -->
              <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
              <textarea matInput formControlName="Comments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="100"></textarea>


              <mat-error *ngIf="Comments.invalid && Comments.touched">Please enter Comment's</mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
                  <mat-label>Comments</mat-label>
                  <input type="text" matInput formControlName="Comments" >
                </mat-form-field> -->
          </div>







        </div>


        <div class="row">


          <div class="col-lg-6">
            <h4>{{nurse?.NurseName}}</h4>
            <!-- <mat-form-field class="example-full-width">
              <mat-label>Nurse Name</mat-label>
              <input type="text" matInput formControlName="NurseName">
              <mat-error>Please enter Nurse Name</mat-error>

            </mat-form-field> -->

          </div>

          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Signature/Initial</mat-label>
              <input type="text" matInput formControlName="Signature">
              <mat-error>Signature required</mat-error>
            </mat-form-field>
          </div>
        </div>











        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Generated Report
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>