import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { PharmacyMemberService } from '../../services/pharmacy-member.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-pharmacy-member',
  templateUrl: './add-pharmacy-member.component.html',
  styleUrls: ['./add-pharmacy-member.component.css'],
})
export class AddPharmacyMemberComponent implements OnInit {
  id = 0;
  isSuccess = false;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private phService: PharmacyMemberService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.countryCodes();
    this.filteredOptions = this.phFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  phFrm = this.fb.group({
    // DepartmentId:[''],
    FullName: ['', Validators.required],
    NpiNumber: ['', Validators.required],
    LicenseNumber: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
  });

  get FullName() {
    return this.phFrm.get('FullName');
  }
  get Email() {
    return this.phFrm.get('Email');
  }
  get DepartmentId() {
    return this.phFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.phFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.phFrm.get('NationalId');
  }

  get Dob() {
    return this.phFrm.get('Dob');
  }
  get Address() {
    return this.phFrm.get('Address');
  }

  get Status() {
    return this.phFrm.get('Status');
  }
  get Password() {
    return this.phFrm.get('Password');
  }
  get CountryCode() {
    return this.phFrm.get('CountryCode');
  }
  get NpiNumber() {
    return this.phFrm.get('NpiNumber');
  }
  get LicenseNumber() {
    return this.phFrm.get('LicenseNumber');
  }

  formSubmit() {
    let fullName = this.phFrm.value['FullName'];
    let dob = this.phFrm.value['Dob'];
    dob = moment(dob).format('MM/DD/YYYY');

    let email = this.phFrm.value['Email'];
    let phoneNumber =
      this.phFrm.value['CountryCode'] + this.phFrm.value['PhoneNumber'];
    let nationalId = this.phFrm.value['NationalId'];
    let address = this.phFrm.value['Address'];
    let status = this.phFrm.value['Status'];
    let password = this.phFrm.value['Password'];
    let npiNumber = this.phFrm.value['NpiNumber'];
    let licenseNumber = this.phFrm.value['LicenseNumber'];

    let userBody = {
      Email: email,
      Password: password,
      UserName: phoneNumber,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerPharmacyMember(userBody).subscribe((res) => {
      let body = {
        PharmacyMemberId: res.Id,
        NpiNumber: npiNumber,
        LicenseNumber: licenseNumber,
        FullName: fullName,
        Dob: dob,
        Email: email,
        PhoneNumber: phoneNumber,
        NationalId: nationalId,
        Address: address,
        Status: status,
        Password: password,
      };

      this.phService.registerPharmacyMember(body).subscribe((res) => {
        this.isSuccess = true;
        this.phFrm.reset();
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
