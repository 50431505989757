import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-radiology-patient',
  templateUrl: './radiology-patient.component.html',
  styleUrls: ['./radiology-patient.component.css']
})
export class RadiologyPatientComponent implements OnInit {
  showItem='';
  id=0;
  constructor(private patientService:PatientService, private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{
      this.id= res['id']
    })
  }

}
