<div>

  <button mat-raised-button color="primary" [routerLink]="['/adminDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>Update Patient</h2>
      <form [formGroup]="ptFrm" (ngSubmit)="formSubmit()" class="example-form">

        <div class="row">

          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>First Name</mat-label>
              <input type="text" formControlName="PatientFirstName" matInput>
              <mat-error>Please enter First Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Last Name</mat-label>
              <input type="text" formControlName="PatientLastName" matInput>
              <mat-error>Please enter Last Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Birth Date</mat-label>
              <input type="text" formControlName="PatientDOB" matInput>
              <mat-error>Please enter D.O.B</mat-error>
            </mat-form-field>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Gender</mat-label>
              <!-- <input type="text" formControlName="PatientGender" matInput >
            <mat-error >Please enter Gender</mat-error> -->
              <mat-select formControlName="PatientGender">

                <mat-option *ngFor="let sex of Gender" [value]="sex">{{sex}}</mat-option>

              </mat-select>
              <mat-error>Please select an option</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="Email" matInput>
              <mat-error>Please enter Email</mat-error>
            </mat-form-field>

          </div>
          <!-- <div class="col-1">
            <mat-form-field class="example-full-width">
              <mat-label>Country Code</mat-label>
              <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput placeholder="+1, +91">
              <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Phone Number</mat-label>
              <input type="text" formControlName="PhoneNumber" matInput>
              <mat-error>Please enter Cell Phone Number</mat-error>
            </mat-form-field>

          </div>
        </div>


        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Doctor Name</mat-label>
              <!-- <input type="text" formControlName="DoctorName" matInput >
          <mat-error >Please enter Doctor Name</mat-error> -->

              <mat-select formControlName="DoctorId">

                <mat-option *ngFor="let doctor of doctorList"
                  [value]="doctor.DoctorId">{{doctor.DoctorName}}</mat-option>

              </mat-select>
              <mat-error>Please select Doctor</mat-error>
            </mat-form-field>



          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Referring Physician</mat-label>
              <input type="text" formControlName="ReferredDoctor" matInput>
              <mat-error>Please enter Patient Referred by Doctor</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label> National Id</mat-label>
              <input type="text" formControlName="PatientNationalId" matInput>
              <mat-error>Please enter Patient NationalId</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Group</mat-label>
              <!-- <input type="text" formControlName="BloodGroup" matInput > -->
              <!-- <mat-error >Please enter Blood Group</mat-error> -->

              <mat-select formControlName="BloodGroup">

                <mat-option *ngFor="let bl of bloodGroup" [value]="bl">{{bl}}</mat-option>

              </mat-select>
              <mat-error>Please select Blood Group</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Marital Status</mat-label>
              <!-- <input type="text" formControlName="MaritalStatus" matInput > -->
              <!-- <mat-error >Please enter Patient Marital Status</mat-error> -->
              <mat-select formControlName="MaritalStatus">

                <mat-option *ngFor="let ms of maritalStatus" [value]="ms">{{ms}}</mat-option>

              </mat-select>
              <mat-error>Please select Marital Status</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Occupation</mat-label>
              <input type="text" formControlName="Occupation" matInput>
              <mat-error>Please enter Occupation</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="exoomample-full-width">
              <mat-label>Height</mat-label>
              <input type="text" formControlName="PatientHeight" matInput>
              <mat-error>Please enter Patient Height</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Weight</mat-label>
              <input type="text" formControlName="PatientWeight" matInput>
              <mat-error>Please enter Patient Weight</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Name Of Parent (If patient is a minor)</mat-label>
              <input type="text" formControlName="NameOfParent" matInput>
              <mat-error>Please enter Name Of Parent</mat-error>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Department</mat-label>


              <mat-select formControlName="DepartmentId">

                <mat-option *ngFor="let dpt of departmentList"
                  [value]="dpt.DepartmentId">{{dpt.DepartmentName}}</mat-option>

              </mat-select>
              <mat-error>Please select Patient Admission Department</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Select OPD /IPD Ward</mat-label>

              <mat-select formControlName="WardId">

                <mat-option *ngFor="let ed of wardList" [value]="ed.WardId">{{ed.WardName}}</mat-option>

              </mat-select>

              <mat-error>Please select Patient Admission (Ward/OPD)</mat-error>

            </mat-form-field>
          </div>



          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>RoomNo</mat-label>
              <input type="text" formControlName="RoomNo" matInput>
              <mat-error>Please enter Patient RoomNo</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Bed No</mat-label>
              <input type="text" formControlName="BedNumber" matInput>
              <mat-error>Please enter Patient BedNumber</mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-8">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Patient Address </mat-label>

              <textarea matInput formControlName="PatientAddress" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="12"></textarea>

            </mat-form-field>
          </div>
          <div class="col-lg-4">

            <p>Is New Patient</p>
            <mat-radio-group formControlName="IsNewPatient" aria-label="Select an option my-3">
              <mat-radio-button class="mx-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="mx-3 " value="false"> No</mat-radio-button>
            </mat-radio-group>

          </div>


        </div>
        <div class="row">
          <div class="col-lg-6">
            <input type="file" (change)="image($event)">


          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Capture Image from Camera</h4>

            <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="180" [width]="180">
            </webcam>
          </div>
          <div class="col-md-12">
            <button class="btn btn-danger" (click)="getSnapshot($event)" type="button">
              Capture Image
            </button>
          </div>
          <div class="col-12">
            <div id="results">Your taken image manifests here...</div>
            <img [src]="webcamImage?.imageAsDataUrl" height="180px" />
          </div>
        </div>




        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>

      </form>
      <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Created New Patient
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>