import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-update-ward',
  templateUrl: './update-ward.component.html',
  styleUrls: ['./update-ward.component.css'],
})
export class UpdateWardComponent implements OnInit {
  isSuccess = false;
  id = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private wardService: WardService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.wardService.getWardById(this.id).subscribe((res) => {
      this.wardFrm.patchValue(res);
    });
  }

  wardFrm = this.fb.group({
    WardName: ['', Validators.required],
    WardDescription: ['', Validators.required],
  });

  get WardName() {
    return this.wardFrm.get('WardName');
  }
  get WardDescription() {
    return this.wardFrm.get('WardDescription');
  }

  formSubmit() {
    let wardName = this.wardFrm.value['WardName'];
    let wardDescription = this.wardFrm.value['WardDescription'];

    let body = {
      WardName: wardName,
      WardDescription: wardDescription,
    };

    this.wardService.updateWard(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.wardFrm.reset();
      this.router.navigate(['/adminDash']);
    });
  }
}
