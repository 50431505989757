<div id="dashboard">
    <div class="content">
        <div class="sidebar mat-elevation">
           <div class="header">
            <!-- <img src="../../../assets/admin.png" class="image" alt=""> -->
  
            <h4 class="text-white ml-2">Hospital Dashboard</h4>
           </div>
  
           
          
           <div class="ItemS "  (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2"> New Patient</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
          
           <div class="ItemS "  (click)="showItem='2'" [ngClass]="{'active': showItem =='1'}">
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2"> Patients</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
           
  
          
        
        
  
  
     
        
          
  
         
        
  
  
        </div>
      
          <div class="main">
      <div class="toolbar mat-elevation-z6" ></div>
  
      <div class="container mt-3 ">
        <div class="row">
            <div class="col-lg-4">
              <!-- <h2>Methodist Hospital</h2> -->
                <!-- <img src="../../../assets/methodist.png" style="max-width: 270px;" alt=""> -->
                <div [innerHTML]="hospital.HospitalLogo"></div>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 ">
                <p>Address: {{hospital.HospitalAddress}}</p>
                <p>Phone: {{hospital.HospitalPhone}}</p>
                <p>Email: {{hospital.HospitalEmail}}</p>
            </div>
        </div>
    </div>
    <hr>
     
    <span [ngSwitch]="showItem">

     
      <p *ngSwitchCase="'1'">
        <app-addpatient></app-addpatient>
      </p>
    
      
    
      <p *ngSwitchCase="'2'">
        <app-viewpatient></app-viewpatient>
      </p>
    
      <!-- <p *ngSwitchCase="'6'">
        <app-view-hospital></app-view-hospital>
      </p> -->
    
      <p *ngSwitchDefault>
        <app-viewpatient></app-viewpatient>

      </p>

    </span>
      </div>
  </div>
