import { Nurse } from './../Models/nurse.model';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root',
})
export class NurseService {
  url = environment.Url + 'Nurses';
  dalyPatinetUrl = environment.Url + 'Nurses/nurseDailyPatients';

  imgUrl = environment.Url + 'ImageUpload';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }

  registerNurse(body) {
    return this.http
      .post<Nurse>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getNurses() {
    return this.http.get<Nurse[]>(this.url).pipe(catchError(this.handleError));
  }
  getNurseById(id) {
    return this.http.get<Nurse>(this.url + '/' + id);
  }
  getNurseDalyPatients(id) {
    return this.http.get<Nurse>(this.dalyPatinetUrl + '/' + id);
  }

  updateNurse(id, body) {
    return this.http
      .put<Nurse>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteNurse(id) {
    return this.http
      .delete<Nurse>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
