import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseService } from 'src/app/services/nurse.service';
import { TickettorideService } from 'src/app/services/tickettoride.service';

@Component({
  selector: 'app-add-ticket-to-ride',
  templateUrl: './add-ticket-to-ride.component.html',
  styleUrls: ['./add-ticket-to-ride.component.css'],
})
export class AddTicketToRideComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private ticketTorideService: TickettorideService,
    private router: Router,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.Frm = this.fb.group({
      PatientId: this.id,
      NurseId: this.nurseId,
      PrimaryDiagnosis: [''],
      TestToBePerformed: [''],
      Date: [''],
      Time: [''],
      NurseConfirmedTest: [false],
      NurseSignature: [''],
      TransporterInitials: [''],
      DNR: [false],
      Restraints: [false],
      Type: [''],
      HighRiskFalls: [false],
      Isolation: [''],
      LatextAllergy: [false],
      Impairment: [''],
      ImpairmentOther: [''],
      PatientLanguage: [''],
      PatientLanguageOther: [''],
      Mobility: [''],
      SedatedandorPainMedication: [false],
      CanPatientStandForTest: [false],
      MentalStatus: [''],
      Rate: [''],
      Oxygen: [false],
      IsthereanyAnticipatedProblems: [false],
      Describe: [''],
      TestProcedureCompleted: [false],
      CompletedTime: [''],
      ChangePatientStatus: [false],
      DescribeChangeInPatientStatus: [''],
      TransporterSign: [''],
      NurseNotifiedUponPatientReturningToRoom: [false],
      BedAlarmActivated: [false],
      SideRailsUp: [false],
      RecievedBackToUnit: [false],
      StaffSignature: [''],
      SignatureDate: [''],
      SignatureTime: [''],
      IVLocation: [''],
      InfusaPort: [''],
      Periferal: [''],
      Hickman: [''],
      Picc: [''],
      Central: [''],
      Epidural: [''],
      PCA: [''],
      Fistula: [''],
      GastricTube: [''],
      DobHoffFeedingTube: [''],
      TinkOffPdTube: [''],
      NgTube: [''],
      DialysisAccess: [''],
      ArterialLine: [''],
      BileBag: [''],
      RectalTube: [''],
      EtTube: [''],
      Trach: [''],
    });
  }

  get PrimaryDiagnosis() {
    return this.Frm.get('PrimaryDiagnosis');
  }
  get TestToBePerformed() {
    return this.Frm.get('TestToBePerformed');
  }
  get Date() {
    return this.Frm.get('Date');
  }
  get Time() {
    return this.Frm.get('Time');
  }
  get NurseConfirmedTest() {
    return this.Frm.get('NurseConfirmedTest');
  }
  get TransporterInitials() {
    return this.Frm.get('TransporterInitials');
  }

  //B=Background
  get DNR() {
    return this.Frm.get('DNR');
  }
  get Restraints() {
    return this.Frm.get('Restraints');
  }

  get Type() {
    return this.Frm.get('Type');
  }

  get HighRiskFalls() {
    return this.Frm.get('HighRiskFalls');
  }

  get Isolation() {
    return this.Frm.get('Isolation');
  }

  get LatextAllergy() {
    return this.Frm.get('LatextAllergy');
  }

  get Impairment() {
    return this.Frm.get('Impairment');
  }

  get ImpairmentOther() {
    return this.Frm.get('ImpairmentOther');
  }

  get PatientLanguage() {
    return this.Frm.get('PatientLanguage');
  }

  get PatientLanguageOther() {
    return this.Frm.get('PatientLanguageOther');
  }

  get Mobility() {
    return this.Frm.get('Mobility');
  }
  //A=Assessment
  get SedatedandorPainMedication() {
    return this.Frm.get('SedatedandorPainMedication');
  }
  get CanPatientStandForTest() {
    return this.Frm.get('CanPatientStandForTest');
  }
  get MentalStatus() {
    return this.Frm.get('MentalStatus');
  }

  get Oxygen() {
    return this.Frm.get('Oxygen');
  }

  //R=Recomendations
  get IsthereanyAnticipatedProblems() {
    return this.Frm.get('IsthereanyAnticipatedProblems');
  }

  get Describe() {
    return this.Frm.get('Describe');
  }

  //POST TEST / PROCEDURE TRANSPORT
  get TestProcedureComppleted() {
    return this.Frm.get('TestProcedureComppleted');
  }
  get CompletedTime() {
    return this.Frm.get('CompletedTime');
  }
  get ChangePatientStatus() {
    return this.Frm.get('ChangePatientStatus');
  }
  get DescribeChangeInPatientStatus() {
    return this.Frm.get('DescribeChangeInPatientStatus');
  }

  get TransporterSign() {
    return this.Frm.get('TransporterSign');
  }
  get NurseNotifiedUponPatientReturningToRoom() {
    return this.Frm.get('NurseNotifiedUponPatientReturningToRoom');
  }
  get BedAlarmActivated() {
    return this.Frm.get('BedAlarmActivated');
  }
  get SideRailsUp() {
    return this.Frm.get('SideRailsUp');
  }
  get RecievedBackToUnit() {
    return this.Frm.get('RecievedBackToUnit');
  }
  get StaffSignature() {
    return this.Frm.get('StaffSignature');
  }
  get SignatureDate() {
    return this.Frm.get('SignatureDate');
  }
  get SignatureTime() {
    return this.Frm.get('SignatureTime');
  }
  get Rate() {
    return this.Frm.get('Rate');
  }

  formSubmit() {
    this.ticketTorideService
      .registerTicketToRide(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
