<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">pill</mat-icon>
            Past Medication
        </ng-template>

        <div class="ml-3">
            <button class="float-right mr-4 mt-4" color="primary" (click)="print()" mat-mini-fab>
                <mat-icon>print</mat-icon>
            </button>
            <div class="row">
                <div class="col-lg-6 float-right">
                    <mat-form-field class="example-full-width mt-5">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div id="printable">


            <table mat-table [dataSource]="dataSource" matSort>


                <ng-container matColumnDef="DrugName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
                </ng-container>

                <ng-container matColumnDef="Dosage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage </th>
                    <td mat-cell *matCellDef="let row"> {{row.Dosage}} </td>
                </ng-container>
                <ng-container matColumnDef="StartDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> StartDate </th>
                    <td mat-cell *matCellDef="let row"> {{row.StartDate}} </td>
                </ng-container>
                <ng-container matColumnDef="EndDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EndDate </th>
                    <td mat-cell *matCellDef="let row"> {{row.EndDate}} </td>
                </ng-container>

                <ng-container matColumnDef="Update">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Edit </th>
                    <td mat-cell *matCellDef="let row"><button type="button"
                            [routerLink]="['/updatePastMedication/',row.PastMedicationId]" mat-mini-fab color="primary">
                            <mat-icon matPrefix>edit</mat-icon>
                        </button></td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
                    <td mat-cell *matCellDef="let row"><button mat-mini-fab color="accent"
                            (click)="deleteDrug(row.PastMedicationId)">
                            <mat-icon matPrefix>delete</mat-icon>
                        </button>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            Register Past Medication
        </ng-template>
        <app-add-pastmedication></app-add-pastmedication>
    </mat-tab>


</mat-tab-group>