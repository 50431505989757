import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Room } from 'src/app/Models/room.model';
import { BedService } from '../../services/bed.service';
import { RoomService } from '../../services/room.service';

@Component({
  selector: 'app-add-bed',
  templateUrl: './add-bed.component.html',
  styleUrls: ['./add-bed.component.css']
})
export class AddBedComponent implements OnInit {
  roomList: Room[];
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private bedService:BedService,private roomService:RoomService) { }

  ngOnInit(): void {

    this.getRooms();
  }

  bedFrm = this.fb.group({
    RoomId:[],
    BedNumber:['',Validators.required],
    RoomNumber:['',Validators.required],
    BedCharge:['',Validators.required],
    Status:['',Validators.required]
  })

  get RoomId(){
  return  this.bedFrm.get('RoomId')
  }
  get BedNumber(){
  return  this.bedFrm.get('BedNumber')
  }
  get RoomNumber(){
  return  this.bedFrm.get('RoomNumber')
  }
  get BedCharge(){
  return  this.bedFrm.get('BedCharge')
  }
  get Status(){
  return  this.bedFrm.get('Status')
  }


  getRooms(){
    this.roomService.getRooms().subscribe(res=>{
      this.roomList= res;
    })
  }

  formSubmit(){
    let bedNumber = this.bedFrm.value['BedNumber']
    let bedCharge = this.bedFrm.value['BedCharge']
    let status = this.bedFrm.value['Status']
    let roomId = this.bedFrm.value['RoomId']
    let roomNo = this.roomList.filter(it => it.RoomId == roomId)[0].RoomNo; 


let body={
  "BedNumber":bedNumber,
  "BedCharge":bedCharge,
  "Status":status,
  "RoomId":roomId,
  "RoomNo":roomNo,
}

this.bedService.registerBed(body).subscribe(res=>{
  this.isSuccess=true
  this.bedFrm.reset();
})


  }
}
