import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-nurse-patient-create-new',
  templateUrl: './nurse-patient-create-new.component.html',
  styleUrls: ['./nurse-patient-create-new.component.css']
})
export class NursePatientCreateNewComponent implements OnInit {
id=0;
helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  nurseId=0;

  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe(res=>{
      this.id = res['id'];
    })


  }







  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }

}
