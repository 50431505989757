import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientvisitService } from '../../services/patientvisit.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { CptCode } from 'src/app/Models/cptcode.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-update-patient-visit',
  templateUrl: './update-patient-visit.component.html',
  styleUrls: ['./update-patient-visit.component.css'],
})
export class UpdatePatientVisitComponent implements OnInit {
  cptCodeList: CptCode[];
  @ViewChild('undo', { static: true })
  public undoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('redo', { static: true })
  public redoBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('pen', { static: true })
  public penBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('eraser', { static: true })
  public erasorBtn!: ElementRef<HTMLButtonElement>;
  @ViewChild('editor', { static: true })
  public editor!: ElementRef<HTMLDivElement>;
  selectedCPTCodes: string[] = [];
  isEraserActive = false;
  isPenActive = true;

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  DoctorId: number;
  CurrentDoctorId: number;
  addVisitFrm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private patientVisitService: PatientvisitService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private plt: Platform,
    private cptCodeService: CptcodeService,
    private cd: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.addVisitFrm = this.fb.group({
      DoctorName: [''],
      NurseName: [''],
      DoctorSpecialization: [''],
      RoomNo: [''],
      AdmissionDate: [''],
      DischargeDate: [''],
      VisitType: [''],
      VistitDate: [''],
      VistTime: [''],

      PatientNote: [''],
      BloodPressure: [''],
      Pulse: [''],
      CptCode: [''],
      Objective: [''],
      Subjective: [''],
      Assessment: [''],
      NextFollowUp: [''],
      Signature: [''],
    });

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientVisitService.getPatientVisitById(this.id).subscribe((res) => {
      this.DoctorId = res.DoctorId;
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.CurrentDoctorId = parseInt(decodedToken.nameid);
      this.DoctorName.setValue(res.DoctorName);
      this.NurseName.setValue(res.NurseName);
      this.DoctorSpecialization.setValue(res.DoctorSpecialization);
      this.RoomNo.setValue(res.RoomNo);
      this.AdmissionDate.setValue(res.AdmissionDate);
      this.DischargeDate.setValue(res.DischargeDate);
      this.VisitType.setValue(res.VisitType);
      this.VistitDate.setValue(res.VistitDate);
      this.VistTime.setValue(res.VistTime);

      this.PatientNote.setValue(res.PatientNote);
      this.BloodPressure.setValue(res.BloodPressure);
      this.Pulse.setValue(res.Pulse);
      this.CptCode.setValue(res.CptCode);
      this.Objective.setValue(res.Objective);
      this.Subjective.setValue(res.Subjective);
      this.Assessment.setValue(res.Assessment);
      this.NextFollowUp.setValue(res.NextFollowUp);
      this.Signature.setValue(res.Signature);
    });
    this.getsCptCode();
  }

  get Assessment() {
    return this.addVisitFrm.get('Assessment');
  }
  get Objective() {
    return this.addVisitFrm.get('Objective');
  }
  get Subjective() {
    return this.addVisitFrm.get('Subjective');
  }
  get CptCode() {
    return this.addVisitFrm.get('CptCode');
  }

  get DoctorName() {
    return this.addVisitFrm.get('DoctorName');
  }
  get NurseName() {
    return this.addVisitFrm.get('NurseName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get RoomNo() {
    return this.addVisitFrm.get('RoomNo');
  }
  get AdmissionDate() {
    return this.addVisitFrm.get('AdmissionDate');
  }
  get DischargeDate() {
    return this.addVisitFrm.get('DischargeDate');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get VistitDate() {
    return this.addVisitFrm.get('VistitDate');
  }
  get VistTime() {
    return this.addVisitFrm.get('VistTime');
  }

  get PatientNote() {
    return this.addVisitFrm.get('PatientNote');
  }

  get BloodPressure() {
    return this.addVisitFrm.get('BloodPressure');
  }
  get Pulse() {
    return this.addVisitFrm.get('Pulse');
  }
  get NextFollowUp() {
    return this.addVisitFrm.get('NextFollowUp');
  }
  get Signature() {
    return this.addVisitFrm.get('Signature');
  }

  getsCptCode() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }

  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let nurseName = this.addVisitFrm.value['NurseName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    // let roomNo = this.addVisitFrm.value['RoomNo']
    // let admissionDate = this.addVisitFrm.value['AdmissionDate']
    // let dischargeDate = this.addVisitFrm.value['DischargeDate']
    let visitType = this.addVisitFrm.value['VisitType'];
    let vistitDate = this.addVisitFrm.value['VistitDate'];
    let vistTime = this.addVisitFrm.value['VistTime'];

    let patientNote = this.addVisitFrm.value['PatientNote'];
    let bloodPressure = this.addVisitFrm.value['BloodPressure'];
    let pulse = this.addVisitFrm.value['Pulse'];
    let cptCode = this.addVisitFrm.value['CptCode'];
    let objective = this.addVisitFrm.value['Objective'];
    let subjective = this.addVisitFrm.value['Subjective'];
    let assessment = this.addVisitFrm.value['Assessment'];
    let nextFollowUp = this.addVisitFrm.value['NextFollowUp'];
    let signature = this.addVisitFrm.value['Signature'];

    let body = {
      DoctorName: doctorName,
      NurseName: nurseName,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VistitDate: vistitDate,
      VistTime: vistTime,
      PatientNote: patientNote,
      BloodPressure: bloodPressure,
      Pulse: pulse,
      CptCode: cptCode,
      Subjective: subjective,
      Objective: objective,
      Assessment: assessment,
      NextFollowUp: nextFollowUp,
      Signature: signature,
    };

    this.patientVisitService
      .updatePatientVisit(this.id, body)
      .subscribe((res) => {
        this.router.navigate(['/doctorPatient/', this.id]);
      });
  }

  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedCPTCodes.includes(value)) {
      this.selectedCPTCodes.push(value);
      this.CptCode.setValue(this.selectedCPTCodes.join(','));
    }
  }
}
