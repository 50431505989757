import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { CareplanService } from '../../services/careplan.service';
import { ActivatedRoute } from '@angular/router';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-careplan',
  templateUrl: './add-careplan.component.html',
  styleUrls: ['./add-careplan.component.css'],
})
export class AddCareplanComponent implements OnInit {
  CarePlan: any;
  selectedTabIndex = 1;
  id = 0;

  isSuccess = false;
  // dialogRef: MatDialogRef<AddCareplanComponent>;
  constructor(
    private fb: UntypedFormBuilder,
    private carePlanService: CareplanService,
    private route: ActivatedRoute // private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.CarePlan = this.fb.group({
      PatientId: this.id,
      CarePlanName: ['', Validators.required],
      CBC: [false],
      RBS: [false],
      LFT: [false],
      RFT: [false],
      SerumElectrolytes: [false],
      UrineRoutine: [false],
      ThyroidProfile: [false],
      HIV: [false],
      HBSAg: [false],
      HCV: [false],
      OtherLaboratoryTest: [''],
      DietType: [null],
      DietDetails: [''],
      DietryConsultationType: [null],
      DietryConsultationDetails: [''],
      PhysiotherapyType: [null],
      PhysiotherapyDetails: [''],
      RestraintRequiredType: [null],
      RestraintRequiredDetails: [''],
      SpecialInstructions: [''],
      XRay: [false],
      USG: [false],
      CT: [false],
      MRI: [false],
      MAMMOGRAM: [false],
      DOPPLER: [false],
      PET: [false],
      OtherRadioLogyTest: [],
      SurgeryOrProcedure: [],
      OtherInvestigations: [''],
      DischargePlanDate: [''],
      SpecialNeeds: [''],
      DoctorDocumentedName: [''],
      DoctorDocumentedSignature: [''],
      DoctorDocumentedTime: [''],
      ConsultantDocumentedName: [''],
      ConsultantDocumentedSignature: [''],
      ConsultantDocumentedTime: [''],
      DoctorDocumentedDate: [''],
      ConsultantDocumentedDate: [''],
      CrossConsultations: this.fb.array([]),
    });

    this.addCrossConsultation();
  }

  get carePlan() {
    return this.CarePlan.controls;
  }

  get CrossConsultations(): UntypedFormArray {
    return this.CarePlan.controls['CrossConsultations'] as UntypedFormArray;
  }

  addCrossConsultation() {
    const CrossConsultationsForm: UntypedFormGroup = this.fb.group({
      DoctorName: ['', Validators.required],
      DepartmentName: ['', Validators.required],
    });

    this.CrossConsultations.push(CrossConsultationsForm);
  }

  removeCrossConsultation(index: number) {
    this.CrossConsultations.removeAt(index);
  }

  show() {
    this.carePlanService
      .registerCarePlan(this.CarePlan.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.CarePlan.reset();
      });
  }

  // openSignaturePad(template: any, controlName: string) {
  //   this.dialogRef = this.dialog.open(template, {
  //     width: '500px',
  //     height: '300px',
  //   });

  //   this.dialogRef.afterOpened().subscribe((result) => {});

  //   this.dialogRef.afterClosed().subscribe((result) => {
  //     this.CarePlan.controls[controlName].setValue(result);
  //   });
  // }
  // clear() {}

  // save() {}
}
