import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CptCode } from 'src/app/Models/cptcode.model';
import { NursingCarePlanService } from 'src/app/services/nursing-care-plan.service';
import { VoiceRecognitionAssessmentService } from 'src/app/services/voice-recognition-assessment.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionPlanService } from 'src/app/services/voice-recognition-plan.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-update-nursing-plan',
  templateUrl: './update-nursing-plan.component.html',
  styleUrls: ['./update-nursing-plan.component.css'],
})
export class UpdateNursingPlanComponent implements OnInit {
  cities!: CptCode[];

  selectedCities!: CptCode[];

  id = 0;
  myControl = new FormControl('');

  isSuccess = false;
  DoctorId: Number;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  hospitalId: number;
  message: string;
  listening = false;
  listeningObjective = false;
  listeningAssessment = false;
  listeningPatientNote = false;

  subjectiveText: string = '';
  subjectiveTextArr: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private nursingPlanService: NursingCarePlanService,
    private fb: UntypedFormBuilder,
    public service: VoiceRecognitionService,
    public serviceObjective: VoiceRecognitionObjectiveService,
    public serviceAssessment: VoiceRecognitionAssessmentService,
    public servicePlan: VoiceRecognitionPlanService
  ) {
    this.service.init();
    this.serviceObjective.init();
    this.serviceAssessment.init();
    this.servicePlan.init();
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.DoctorId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.nursingPlanService.getNursingCarePlanById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });
  }

  Frm = this.fb.group({
    NurseName: [''],
    Intervention: [''],
    Rationale: [''],
    Assessment: [''],
    Evaluation: [''],
  });

  get Assessment() {
    return this.Frm.get('Assessment');
  }
  get Rationale() {
    return this.Frm.get('Rationale');
  }
  get Intervention() {
    return this.Frm.get('Intervention');
  }

  get NurseName() {
    return this.Frm.get('NurseName');
  }

  get Evaluation() {
    return this.Frm.get('Evaluation');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.cptCodeList.filter = filterValue.trim().toLowerCase();
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onObjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }

  onAssessmentBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onPatientNoteBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }

  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningObjective = true;
  }
  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningObjective = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  startServiceAssessment() {
    this.serviceAssessment.startAssessment();
    this.listeningAssessment = true;
  }
  stopServiceAssessment() {
    this.serviceAssessment.stopAssessment();
    this.listeningAssessment = false;
  }
  onAssessmentChange(e) {
    this.serviceAssessment.Assessment = e.target.value;
  }
  onPatientNoteChange(e) {
    this.servicePlan.PatientNote = e.target.value;
  }
  startServicePatientNote() {
    this.servicePlan.startPatientNote();
    this.listeningPatientNote = true;
  }
  stopServicePatientNote() {
    this.servicePlan.stopPatientNote();
    this.listeningPatientNote = false;
  }
  formSubmit() {
    let body = {
      ...this.Frm.value,
    };
    this.nursingPlanService
      .updateNursingCarePlan(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
        // this.router.navigate(['/doctorPatient/', this.id]);
      });
  }
}
