<div class="container-xl">
    <div class="regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg">
                <h1>New Patient Visit</h1>
                <div style="margin: 100px;">
                    <!-- <h1>Voice recognition</h1>
            <button style="margin: 10px;" (click)="startService()">Start</button>
            <button style="margin: 10px;" (click)="stopService()">Stop</button> -->

                    <!-- <p>{{service.message}}</p> -->
                </div>
                <form [formGroup]="addVisitFrm" (ngSubmit)="formSubmit()" class="example-form">
                    <div class="row">
                        <!-- <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Doctor Name</mat-label>
                        

                                <mat-select formControlName="DoctorName">

                                    <mat-option *ngFor="let doctor of doctorList"
                                        [value]="doctor.DoctorName">{{doctor.DoctorName}}</mat-option>

                                </mat-select>
                            </mat-form-field>



                        </div> -->
                        <!-- <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Doctor Specialization</mat-label>
                                <mat-select formControlName="DoctorSpecialization">

                                    <mat-option *ngFor="let sp of doctorSpeciality" [value]="sp">{{sp}}</mat-option>

                                </mat-select>
                                <mat-error>Please select DoctorSpecialization </mat-error>

                            </mat-form-field>
                        </div> -->
                        <div class="col-lg-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nurse Name</mat-label>
                                <input type="text" formControlName="NurseName" matInput>
                                <!-- <mat-error >Please enter Nurse Name</mat-error> -->
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4">

                            <mat-form-field class="example-full-width">
                                <mat-label>Visit Type</mat-label>
                                <!-- <input type="text" formControlName="VisitType" matInput >
                      <mat-error >Please enter Visit Type</mat-error> -->

                                <mat-select formControlName="VisitType">

                                    <mat-option *ngFor="let op of options" [value]="op">{{op}}</mat-option>

                                </mat-select>
                                <mat-error>Please select Visit Type </mat-error>
                            </mat-form-field>



                        </div>
                    </div>

                    <div class="row">


                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>VistitDate</mat-label>
                                <input type="date" formControlName="VistitDate" matInput>
                                <mat-error>Please enter Date</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Vist Time</mat-label>
                                <!-- <input type="text" formControlName="VistTime" matInput > -->
                                <input [ngxTimepicker]="picker" matInput formControlName="VistTime">
                                <ngx-material-timepicker #picker></ngx-material-timepicker>
                                <mat-error>Please enter Visit Time</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>BloodPressure</mat-label>
                                <input type="text" formControlName="BloodPressure" matInput>
                                <!-- <mat-error >Please enter BloodPressure</mat-error> -->
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Pulse</mat-label>
                                <input type="text" formControlName="Pulse" matInput>
                                <!-- <mat-error >Please enter BloodPressure</mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">

                            <mat-form-field class="w-100">
                                <mat-chip-list>
                                    <ng-container *ngIf="selectedIcdCodes.length">
                                        <mat-chip *ngFor="let item of selectedIcdCodes" (removed)="removeOption(item)">
                                            {{ item }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip></ng-container>
                                    <mat-label>Diagnosis:</mat-label>

                                    <input type="text" placeholder="Type code to search" aria-label="Diagnosis Codes"
                                        matInput [formControl]="IcdCode" [matAutocomplete]="auto" />
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption($event)">

                                        <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                                            <mat-option *cdkVirtualFor="let entry of filteredValues"
                                                [value]="entry.Code">
                                                {{entry.Code}}
                                            </mat-option>
                                        </cdk-virtual-scroll-viewport>
                                    </mat-autocomplete>
                                </mat-chip-list>
                            </mat-form-field>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Subjective:</mat-label>

                                <input type="text" (input)="onSubjectiveChange($event)" formControlName="Subjective"
                                    [value]="service.message1" matInput (blur)="onSubjectiveBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                    (click)="startService()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopService()" mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Objective:</mat-label>

                                <input type="text" (input)="onObjectiveChange($event)" formControlName="Objective"
                                    [value]="serviceObjective.Objective" matInput (blur)="onObjectiveBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listeningObjective"
                                    (click)="startServiceObjective()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopServiceObjective()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Assessment:</mat-label>

                                <input type="text" (input)="onAssessmentChange($event)" formControlName="Assessment"
                                    [value]="serviceAssessment.Assessment" matInput (blur)="onAssessmentBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listeningAssessment"
                                    (click)="startServiceAssessment()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopServiceAssessment()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>

                    <div class="row">
                        <div class="col-lg-12 app-speech-input">

                            <mat-form-field class="example-full-width">
                                <mat-label>Treatment Plan</mat-label>

                                <textarea matInput (input)="onPatientNoteChange($event)" formControlName="PatientNote"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="140" [value]="servicePlan.PatientNote"
                                    (blur)="onPatientNoteBlur()"></textarea>
                                <button class="mx-1" type="button" class="app-speech-input-btn"
                                    [disabled]="listeningPatientNote" (click)="startServicePatientNote()"></button>

                            </mat-form-field>
                        </div>
                        <!-- <button type="button" class="mx-1" color="accent" (click)="stopServicePatientNote()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>


                    <div class="row">
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Next Follow Up Date</mat-label>
                                <input type="date" formControlName="NextFollowUp" matInput>
                                <mat-error>Please enter Date</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Signature</mat-label>
                                <input type="text" formControlName="Signature" matInput>
                                <mat-error>Please enter initial</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                        Successfully submitted
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <button class="float-right lgBtCl" mat-button>
                        <mat-icon>save</mat-icon>
                    </button>

                </form>



            </div>

        </div>

    </div>
</div>