<div class="pb-5 w-100 ">

  <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
  <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
  <div class="toolbar mat-elevation-z6">

    <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">


      <div class="ItemS ">
        <mat-icon mat-list-icon>Doctor Dashboard</mat-icon>
        <div class="mx-2" [routerLink]="['/doctorDash/']">dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='27'" [ngClass]="{'active': showItem =='27'}">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2">Back</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>nature_people</mat-icon>
        <div class="mx-2">Patient Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='20'" [ngClass]="{'active': showItem =='20'}">
        <mat-icon mat-list-icon>query_stats</mat-icon>
        <div class="mx-2">Med Tracker</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='21'" [ngClass]="{'active': showItem =='21'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">NurseReport</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
        <mat-icon mat-list-icon>note</mat-icon>
        <div class="mx-2">NurseNote</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='41'" [ngClass]="{'active': showItem =='41'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Vital Chart</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='64'" [ngClass]="{'active': showItem =='64'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Blood Sugar</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='65'" [ngClass]="{'active': showItem =='65'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2"> Blood Sugar Chart</div>
        <mat-icon>navigate_next</mat-icon>
      </div>






      <div class="ItemS" (click)="showItem='47'" [ngClass]="{'active': showItem =='47'}">
        <mat-icon mat-list-icon>view_kanban</mat-icon>
        <div class="mx-2">View Nurse Routine Assessment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='48'" [ngClass]="{'active': showItem =='48'}">
        <mat-icon mat-list-icon>view_kanban</mat-icon>
        <div class="mx-2">View Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- <hr> -->

      <!-- <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Emergency Contact</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Inusurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Inusurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='37'" [ngClass]="{'active': showItem =='37'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New ID Proof</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View ID Proof </div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
      <hr>
      <div class="ItemS" (click)="showItem='49'" [ngClass]="{'active': showItem =='49'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Doctor Inital Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Visit</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Round Book</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='74'" [ngClass]="{'active': showItem =='74'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <!-- <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
              <mat-icon mat-list-icon>add</mat-icon>
              <div class="mx-2">New Script</div>
              <mat-icon>navigate_next</mat-icon>
            </div> -->

      <div class="ItemS" (click)="showItem='28'" [ngClass]="{'active': showItem =='28'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='30'" [ngClass]="{'active': showItem =='30'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='29'" [ngClass]="{'active': showItem =='29'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='31'" [ngClass]="{'active': showItem =='31'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Lab-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Radiology-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <!-- <div class="ItemS" (click)="showItem='12'" [ngClass]="{'active': showItem =='12'}">
              <mat-icon mat-list-icon>add</mat-icon>
              <div class="mx-2">New Allergy</div>
              <mat-icon>navigate_next</mat-icon>
            </div> -->

      <!-- <div class="ItemS" (click)="showItem='14'" [ngClass]="{'active': showItem =='14'}">
              <mat-icon mat-list-icon>add</mat-icon>
              <div class="mx-2">New Surgery</div>
              <mat-icon>navigate_next</mat-icon>
            </div> -->
      <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS" (click)="showItem='22'" [ngClass]="{'active': showItem =='22'}">
              <mat-icon mat-list-icon>add</mat-icon>
              <div class="mx-2">Braden Scale</div>
              <mat-icon>navigate_next</mat-icon>
            </div>
            <div class="ItemS" (click)="showItem='24'" [ngClass]="{'active': showItem =='22'}">
              <mat-icon mat-list-icon>add</mat-icon>
              <div class="mx-2">Hester Davies RF</div>
              <mat-icon>navigate_next</mat-icon>
            </div> -->

      <hr>

      <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
        <mat-icon mat-list-icon>note_add</mat-icon>
        <div class="mx-2">Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Care Plan</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='43'" [ngClass]="{'active': showItem =='43'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
        <mat-icon mat-list-icon>bug_report</mat-icon>
        <div class="mx-2">Lab-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='34'" [ngClass]="{'active': showItem =='34'}">
        <mat-icon mat-list-icon>radiology</mat-icon>
        <div class="mx-2">Radiology-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <hr>
      <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
        <mat-icon mat-list-icon>warning</mat-icon>
        <div class="mx-2">Allergies</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
        <mat-icon mat-list-icon>content_cut</mat-icon>
        <div class="mx-2">Surgical History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="mx-2">View Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>note</mat-icon>
        <div class="mx-2"> View Roundbook</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='23'" [ngClass]="{'active': showItem =='23'}">
        <mat-icon mat-list-icon>linear_scale</mat-icon>
        <div class="mx-2">Braden Scale</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='25'" [ngClass]="{'active': showItem =='25'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Hester Davies</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='32'" [ngClass]="{'active': showItem =='32'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Nursing Assessment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='33'" [ngClass]="{'active': showItem =='33'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Care Plan</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='39'" [ngClass]="{'active': showItem =='39'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload MRI</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">View MRI images</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='75'" [ngClass]="{'active': showItem =='75'}">
        <mat-icon mat-list-icon>file_open</mat-icon>
        <div class="mx-2">View Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Nurse Todo</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='73'" [ngClass]="{'active': showItem =='73'}">
        <mat-icon mat-list-icon>vaccines</mat-icon>
        <div class="mx-2"> View Vaccine</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- 
          <div class="ItemS" (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
            <mat-icon mat-list-icon>title</mat-icon>
            <div class="mx-2">Radiology Order Subjects</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </div>
    </mat-menu>
    <h2 class="ml-5 text-white text-center"><img class="myimg" src="{{doctor.DoctorImage}}" alt="">
      {{doctor.DoctorName}} <span class="text-white "> </span></h2>

  </div>

  <!-- <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">New Appointment</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
          <div class="ItemS  " >
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">Medical Records</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
           -->



  <!-- 
        </div> -->
  <!-- <div class="main">
    <div class="toolbar mat-elevation-z6" ></div> -->

  <div [ngSwitch]="showItem">



    <p *ngSwitchCase="'1'">
      <app-patientsummary></app-patientsummary>
    </p>
    <p *ngSwitchCase="'2'">
      <app-add-emergency-contact></app-add-emergency-contact>
    </p>

    <p *ngSwitchCase="'3'">
      <app-add-patient-insurance></app-add-patient-insurance>
    </p>
    <p *ngSwitchCase="'4'">
      <app-add-patient-visit></app-add-patient-visit>
    </p>
    <p *ngSwitchCase="'5'">
      <app-add-appointment-patient></app-add-appointment-patient>
    </p>
    <p *ngSwitchCase="'6'">
      <app-draw-pad></app-draw-pad>
    </p>
    <p *ngSwitchCase="'7'">
      <app-vew-doctor-note></app-vew-doctor-note>
    </p>
    <p *ngSwitchCase="'8'">
      <app-add-prescription></app-add-prescription>
    </p>
    <p *ngSwitchCase="'9'">
      <app-prescription-display></app-prescription-display>
    </p>
    <p *ngSwitchCase="'10'">
      <app-add-labreport></app-add-labreport>
    </p>
    <p *ngSwitchCase="'11'">
      <app-view-labreport></app-view-labreport>
    </p>
    <p *ngSwitchCase="'12'">
      <app-add-allergy></app-add-allergy>
    </p>
    <p *ngSwitchCase="'13'">
      <app-view-allergy></app-view-allergy>
    </p>

    <p *ngSwitchCase="'14'">
      <app-add-surgical-history></app-add-surgical-history>
    </p>
    <p *ngSwitchCase="'15'">
      <app-view-surgical-history></app-view-surgical-history>
    </p>
    <p *ngSwitchCase="'16'">
      <app-add-invoice></app-add-invoice>
    </p>
    <p *ngSwitchCase="'17'">
      <app-view-invoice></app-view-invoice>
    </p>
    <p *ngSwitchCase="'18'">
      <app-vew-doctor-note></app-vew-doctor-note>
    </p>
    <p *ngSwitchCase="'19'">
      <app-patientdetails></app-patientdetails>
    </p>
    <p *ngSwitchCase="'20'">
      <app-view-medication></app-view-medication>
    </p>

    <p *ngSwitchCase="'21'">
      <app-view-nursereport></app-view-nursereport>
    </p>
    <p *ngSwitchCase="'22'">
      <app-add-bradenscale></app-add-bradenscale>
    </p>
    <p *ngSwitchCase="'23'">
      <app-bradenscale></app-bradenscale>
    </p>
    <p *ngSwitchCase="'24'">
      <app-add-hester-davies></app-add-hester-davies>
    </p>
    <p *ngSwitchCase="'25'">
      <app-hester-davies></app-hester-davies>
    </p>
    <p *ngSwitchCase="'26'">
      <app-patient></app-patient>
    </p>
    <p *ngSwitchCase="'27'">
      <app-patientdetails></app-patientdetails>

    </p>
    <p *ngSwitchCase="'28'">
      <app-add-lab-order></app-add-lab-order>

    </p>
    <p *ngSwitchCase="'29'">
      <app-add-radiology-order></app-add-radiology-order>

    </p>
    <p *ngSwitchCase="'30'">
      <app-view-lab-order></app-view-lab-order>

    </p>
    <p *ngSwitchCase="'31'">
      <app-view-radiology-order></app-view-radiology-order>

    </p>

    <p *ngSwitchCase="'32'">
      <app-view-nursing-assessment-plan></app-view-nursing-assessment-plan>

    </p>

    <p *ngSwitchCase="'33'">
      <app-view-careplan></app-view-careplan>
    </p>

    <p *ngSwitchCase="'34'">
      <app-view-radiology-report></app-view-radiology-report>
    </p>
    <p *ngSwitchCase="'35'">
      <app-add-radiology-report></app-add-radiology-report>
    </p>
    <p *ngSwitchCase="'36'">
      <app-view-patient-insurance></app-view-patient-insurance>
    </p>

    <p *ngSwitchCase="'37'">
      <app-add-patient-idproof></app-add-patient-idproof>
    </p>

    <p *ngSwitchCase="'38'">
      <app-view-patient-idproof></app-view-patient-idproof>
    </p>
    <p *ngSwitchCase="'39'">
      <app-add-mri></app-add-mri>
    </p>

    <p *ngSwitchCase="'40'">
      <app-view-mri></app-view-mri>
    </p>

    <p *ngSwitchCase="'41'">
      <app-vitals-chart></app-vitals-chart>
    </p>


    <p *ngSwitchCase="'42'">
      <app-nurse-todo></app-nurse-todo>
    </p>


    <p *ngSwitchCase="'43'">
      <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>
    </p>


    <p *ngSwitchCase="'44'">
      <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>
    </p>


    <p *ngSwitchCase="'45'">
      <app-view-nurse-note></app-view-nurse-note>
    </p>

    <p *ngSwitchCase="'46'">
      <app-add-careplan></app-add-careplan>
    </p>
    <p *ngSwitchCase="'47'">
      <app-view-nurse-daily-assessment></app-view-nurse-daily-assessment>

    </p>
    <p *ngSwitchCase="'48'">
      <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>

    </p>
    <p *ngSwitchCase="'49'">
      <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>

    </p>
    <p *ngSwitchCase="'64'">
      <app-view-blood-sugar></app-view-blood-sugar>

    </p>
    <p *ngSwitchCase="'65'">
      <app-blood-sugar-chart></app-blood-sugar-chart>

    </p>
    <p *ngSwitchCase="'73'">
      <app-view-vaccine></app-view-vaccine>

    </p>
    <p *ngSwitchCase="'74'">
      <app-add-refferal></app-add-refferal>
    </p>
    <p *ngSwitchCase="'75'">
      <app-view-refferal></app-view-refferal>

    </p>


    <!-- <p *ngSwitchCase="'42'">
       <app-add-radiology-order-subject></app-add-radiology-order-subject>
       </p> -->

    <div *ngSwitchDefault>


      <app-patientdetails></app-patientdetails>



    </div>







  </div>

  <!-- </div>
    </div> -->
</div>