<div class="container-xl">
    <div class="regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg">
                <h1>Update Progess Note</h1>
                <div style="margin: 100px;">
                    <!-- <h1>Voice recognition</h1>
            <button style="margin: 10px;" (click)="startService()">Start</button>
            <button style="margin: 10px;" (click)="stopService()">Stop</button> -->

                    <!-- <p>{{service.message}}</p> -->
                </div>
                <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Problem:</mat-label>

                                <input type="text" (input)="onObjectiveChange($event)" formControlName="Problem"
                                    [value]="serviceObjective.Objective" matInput (blur)="onObjectiveBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listeningObjective"
                                    (click)="startServiceObjective()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopServiceObjective()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Assessment:</mat-label>

                                <input type="text" (input)="onAssessmentChange($event)" formControlName="Assessment"
                                    [value]="serviceAssessment.Assessment" matInput (blur)="onAssessmentBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listeningAssessment"
                                    (click)="startServiceAssessment()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopServiceAssessment()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12 app-speech-input">
                            <mat-form-field class="example-full-width">
                                <mat-label>Intervention:</mat-label>

                                <input type="text" (input)="onSubjectiveChange($event)" formControlName="Intervention"
                                    [value]="service.message1" matInput (blur)="onSubjectiveBlur()">
                                <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                    (click)="startService()"></button>

                            </mat-form-field>

                        </div>
                        <!-- <button type="button" color="accent" (click)="stopService()" mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>



                    <div class="row">
                        <div class="col-lg-12 app-speech-input">

                            <mat-form-field class="example-full-width">
                                <mat-label>Plan</mat-label>

                                <textarea matInput (input)="onPatientNoteChange($event)" formControlName="Plan"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="140" [value]="servicePlan.PatientNote"
                                    (blur)="onPatientNoteBlur()"></textarea>
                                <button class="mx-1" type="button" class="app-speech-input-btn"
                                    [disabled]="listeningPatientNote" (click)="startServicePatientNote()"></button>

                            </mat-form-field>
                        </div>
                        <!-- <button type="button" class="mx-1" color="accent" (click)="stopServicePatientNote()"
                mat-mini-fab><mat-icon>stop</mat-icon></button> -->
                    </div>
                    <button class="float-right lgBtCl" mat-button>
                        <mat-icon>save</mat-icon>
                    </button>



                </form>


                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

        </div>

    </div>
</div>