<div>

  <button mat-raised-button color="primary" [routerLink]="['/doctorDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>
</div>
<div class="container">
  <div class="regFrm mat-elevation-z8">
    <div class="row">
      <div class="col-lg">
        <h1>Update</h1>
        <form [formGroup]="addVisitFrm" class="example-form" (ngSubmit)="formSubmit()">
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Doctor Name</mat-label>
                <input type="text" formControlName="DoctorName" matInput />
                <mat-error>Please enter Doctor Name</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>DoctorSpecialization</mat-label>
                <input type="text" formControlName="DoctorSpecialization" matInput />
                <mat-error>Please enter doctor speciality</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Nurse Name</mat-label>
                <input type="text" formControlName="NurseName" matInput />
                <mat-error>Please enter Nurse Name</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>VisitType</mat-label>
                <input type="text" formControlName="VisitType" matInput />
                <mat-error>Please enter Visit Type</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>VistitDate</mat-label>
                <input type="text" formControlName="VistitDate" matInput />
                <mat-error>Please enter Date</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Vist Time</mat-label>
                <input type="text" formControlName="VistTime" matInput />
                <mat-error>Please enter Visit Time</mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>BloodPressure</mat-label>
                <input type="text" formControlName="BloodPressure" matInput />
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Pulse</mat-label>
                <input type="text" formControlName="Pulse" matInput />
              </mat-form-field>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Diagnosis:</mat-label>
                <input type="text" formControlName="CptCode" matInput>

              </mat-form-field>
              <!-- <mat-form-field class="w-100">
                <mat-chip-list>
                  <ng-container *ngIf="selectedCPTCodes.length">
                    <mat-chip-list>
                      <mat-chip #chipList *ngFor="let option of selectedCPTCodes" [removable]="true"
                        (removed)="removeOption(option)">{{option}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>

                  </ng-container>
                  <mat-label>Diagnosis:</mat-label>

                  <input type="text" placeholder="Pick one" aria-label="CPT Codes" matInput [formControl]="CptCode"
                    [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addOption($event)">
                    <mat-option *ngFor="let option of cptCodeList" [value]="option.Code">
                      {{ option.Code }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-chip-list>
              </mat-form-field> -->
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Subjective:</mat-label>
                <input type="text" formControlName="Subjective" matInput>

              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Objective:</mat-label>
                <input type="text" formControlName="Objective" matInput>

              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mat-form-field class="example-full-width">
                <mat-label>Assessment:</mat-label>
                <input type="text" formControlName="Assessment" matInput>

              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 app-speech-input">

              <mat-form-field class="example-full-width">
                <mat-label>Treatment Plan</mat-label>

                <textarea matInput formControlName="PatientNote" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140"></textarea>


              </mat-form-field>
            </div>
            <!-- <button type="button" class="mx-1" color="accent" (click)="stopServicePatientNote()"
              mat-mini-fab><mat-icon>stop</mat-icon></button> -->
          </div>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Next Follow Up Date</mat-label>
                <input type="text" formControlName="NextFollowUp" matInput>
                <mat-error>Please enter Date</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label>Signature</mat-label>
                <input type="text" formControlName="Signature" matInput>
                <mat-error>Please enter initial</mat-error>
              </mat-form-field>
            </div>
          </div>



          <button class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
          </button>
        </form>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>


  </div>
</div>