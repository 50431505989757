<button mat-raised-button color="primary" [routerLink]="['/adminDash/']"><mat-icon mat-list-icon>undo</mat-icon>
    Dashboard</button>

<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1 class="mb-5">Update Admissions</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

                <div class="row  ">
                    <div class="col-lg-6 ">
                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Admission</mat-label>
                            <input type="date" formControlName="DateOfAdmission" matInput>
                            <mat-error>Please enter Date</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Time Of Admission</mat-label>
                            <!-- <input type="text" formControlName="VistTime" matInput > -->
                            <input [ngxTimepicker]="picker" matInput formControlName="TimeOfAdmission">
                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                            <mat-error>Please enter TimeOfAdmission</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mat-label>Sender/Caller Information: </mat-label>
                        <mat-radio-group formControlName="SenderInformation" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="Patient">Patient</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Hospital"> Hospital</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Provider"> Provider</mat-radio-button>

                        </mat-radio-group>


                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Sender/Caller Name</mat-label>
                            <input type="text" formControlName="SenderCallerName" matInput>
                            <mat-error>Please enter name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Sender/Caller Phone</mat-label>
                            <input type="text" matInput formControlName="SenderCallerPhone">
                            <mat-error>Please enter Phone</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Sender/Caller Email</mat-label>
                            <input type="text" matInput formControlName="SenderCallerEmail">
                            <mat-error>Please enter email</mat-error>

                        </mat-form-field>
                    </div>

                </div>






                <div class="row mt-2">
                    <div class="col-lg-12">
                        <mat-label>Disposition: </mat-label>
                        <mat-radio-group formControlName="Disposition" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="Home">Home</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Expired"> Expired</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Nursing Home Transfer"> Nursing Home
                                Transfer</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Other Hospital Transfer"> Other Hospital
                                Transfer</mat-radio-button>

                        </mat-radio-group>


                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-12">
                        <mat-label>Admission Source: </mat-label>

                        <mat-radio-group formControlName="AdmissionSource" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="ER/ED">ER/ED</mat-radio-button>
                            <mat-radio-button class="mx-1 " value="Direct"> Direct</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Scheduled"> Scheduled</mat-radio-button>
                            <mat-radio-button>Direct Transferred From:
                                <mat-form-field class="example-full-width">
                                    <input matInput formControlName="AdmissionSource">
                                </mat-form-field>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>



                <div class="row mt-2">

                    <div class="col-lg-12">
                        <mat-label class="mb-3">Admission Type: </mat-label> <br>

                        <mat-radio-group formControlName="AdmissionType" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="Med/Surg">Med/Surg</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="ICU/CCU"> ICU/CCU</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Mental Health"> Mental Health</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="Long Term Acute Care"> Long Term Acute
                                Care</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Pediatric">Pediatric</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Swing Bed">Swing Bed</mat-radio-button>
                            <mat-radio-button class="mx-3" value="CH">CH</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Detox">Detox</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Inpatient Acute Rehab">Inpatient Acute
                                Rehab</mat-radio-button>
                            <mat-radio-button class="mx-3" value="Maternity">Maternity</mat-radio-button>

                        </mat-radio-group>


                    </div>
                </div>



                <div class="row mt-2">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Admission Unit</mat-label>
                            <input type="text" formControlName="AdmissionUnit" matInput>
                            <mat-error>Please enter a Unit</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Admission Bed</mat-label>
                            <input type="text" matInput formControlName="AdmissionBed">
                            <mat-error>Please enter Bed</mat-error>

                        </mat-form-field>

                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>ICD 10 Diagnosis Code</mat-label>
                            <input type="text" formControlName="Icd10DiagnosisCode" matInput>
                            <mat-error>Please enter a ICD Code</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>ICD 10 Procedure Code (in patient)</mat-label>
                            <input type="text" matInput formControlName="Icd10ProcedureCode">
                            <mat-error>Please enter CPT Code</mat-error>

                        </mat-form-field>

                    </div>


                </div>
                <div class="row">

                    <div class="col-lg-12">

                        <mat-form-field class="example-full-width">
                            <mat-label> Chief Complaints</mat-label>
                            <input type="text" matInput formControlName="ChiefComplaints">
                            <mat-error>Please enter ChiefComplaints</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Attending Physician</mat-label>
                            <input type="text" matInput formControlName="AttendingPhysician">
                            <mat-error>Please enter Physician Name</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Refferring Physician</mat-label>
                            <input type="text" matInput formControlName="RefferringPhysician">
                            <mat-error>Please enter Physician Name</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Primary Care Physician</mat-label>
                            <input type="text" matInput formControlName="PrimaryCareDoctor">
                            <mat-error>Please enter Physician Name</mat-error>

                        </mat-form-field>
                    </div>
                </div>



                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>