<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>HIPAA Compliance Patient Consent Form</h1>
      <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

        <hr>
        <p>Our Notice of Privacy Practices provides information about how we may use or disclose protected health
          information.</p>
        <p>The notice contains a patient’s rights section describing your rights under the law. You ascertain that by
          your signature that
          you have reviewed our notice before signing this consent.</p>

        <p>The terms of the notice may change, if so, you will be notified at your next visit to update your
          signature/date.</p>
        <p>You have the right to restrict how your protected health information is used and disclosed for treatment,
          payment or
          healthcare operations. We are not required to agree with this restriction, but if we do, we shall honor this
          agreement. The
          HIPAA (Health Insurance Portability and Accountability Act of 1996) law allows for the use of the information
          for treatment,
          payment, or healthcare operations.</p>

        <p>By signing this form, you consent to our use and disclosure of your protected healthcare information and
          potentially
          anonymous usage in a publication. You have the right to revoke this consent in writing, signed by you.
          However, such a
          revocation will not be retroactive.</p>

        <p>
          By signing this form, I understand that: </p>
        <ul>
          <li>Protected health information may be disclosed or used for treatment, payment, or healthcare operations.
          </li>
          <li>The practice reserves the right to change the privacy policy as allowed by law.</li>
          <li>The practice has the right to restrict the use of the information but the practice does not have to agree
            to those
            restrictions.</li>
          <li>The patient has the right to revoke this consent in writing at any time and all full disclosures will then
            cease.</li>
          <li>The practice may condition receipt of treatment upon execution of this consent.</li>
        </ul>

        <div class="row">

          <div class="col-md-12">
            <mat-label>May we phone, email, or send a text to you to confirm appointments?</mat-label>
            <mat-radio-group formControlName="PhoneEmailTextConsent">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>

            <br>
            <mat-label>May we leave a message on your answering machine at home or on your cell phone?</mat-label>
            <mat-radio-group formControlName="LeaveVoiceMessageConsent">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>

            <br>
            <mat-label>May we discuss your medical condition with any member of your family?</mat-label>
            <mat-radio-group formControlName="ConsetToDiscussMedicalConditionWithFamilyMember">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>

            <br>
            <mat-form-field class="example-full-width">
              <mat-label>If YES, please name the members allowed:</mat-label>
              <input type="text" formControlName="AuthorizedFamilyMemberName" matInput>
              <!-- <mat-error >Please enter Description</mat-error> -->
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>This consent was signed by::</mat-label>
              <input type="text" formControlName="PatientName" matInput>
              <mat-error>Please enter Description</mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
              <mat-label>Signature:</mat-label>
              <input type="text" formControlName="Signauture" matInput>
              <mat-error>Please enter Description</mat-error>
            </mat-form-field> -->


          </div>

          <div class="container mt-5">
            <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
              (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350" height="200">
            </canvas>
            <button type="button" class="ml-3" color="primary" mat-raised-button
              (click)="saveSignature($event)">Save</button>

            <button type="button" class="ml-3" color="accent" mat-raised-button
              (click)="clearSignature($event)">Clear</button>
          </div>



        </div>
        <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>

  </div>
</div>