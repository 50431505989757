import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Medicine } from 'src/app/Models/medicine.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { DrugService } from 'src/app/services/drug.service';
import { MedicationService } from 'src/app/services/medication.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-update-medication',
  templateUrl: './update-medication.component.html',
  styleUrls: ['./update-medication.component.css']
})
export class UpdateMedicationComponent implements OnInit {
  id = 0
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token')
  nurse: Nurse
  options: string[] = ['Morning', 'Afternoon', 'Evening', 'Night'];
  isSuccess = false;
  medicineList: Medicine[];
  nurseId = 0;
  medFrm: UntypedFormGroup;
  patientId: string;

  constructor(private fb: UntypedFormBuilder, private medicineService: MedicineService, private medicationService: MedicationService,
    private router: Router, private route: ActivatedRoute, private nurseService: NurseService, private drugService: DrugService) { }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid)
    this.route.params.subscribe(res => {
      this.id = res['id']
    });
    this.route.queryParams.subscribe(res => {
      this.patientId = res.patientId
    })
    this.getMedicine();
    this.medFrm = this.fb.group({
      NurseName: [''],
      PatientId: this.id,
      NurseId: this.nurseId,
      Date: [''],
      Time: [''],
      Description: [''],
      Comments: [''],
      Signature: [''],
      PartofDay: [''],
      Drugs: this.fb.array([]),

    });

    // this.addItem();
    this.getMedicationData();
  }


  private getMedicationData(): void {

    this.medicationService.getDrugs(this.id).subscribe({
      next: (res) => {
        this.medFrm.patchValue(res);
        res.Drugs.forEach((drug, id) => {
          this.addItem();
          this.Drugs.at(id).patchValue(drug)
        })
      }
    });

  }




  public addItem(): void {
    const item = this.fb.group({
      DrugId: [''],
      DrugName: [''],
      Dosage: [''],
      BrandName: [''],
      NumberOfDrugs: [''],
    });
    this.Drugs.push(item);
  }

  public removeItem(i, DrugId): void {
    this.Drugs.removeAt(i);
    this.drugService.deleteDrug(DrugId).subscribe();
  }

  get Drugs(): UntypedFormArray {
    return this.medFrm.get('Drugs') as UntypedFormArray;
  }








  get NumberOfDrugs() {
    return this.medFrm.get('NumberOfDrugs')
  }
  get PartofDay() {
    return this.medFrm.get('PartofDay')
  }
  get NurseName() {
    return this.medFrm.get('NurseName')
  }
  get DrugName() {
    return this.medFrm.get('DrugName')
  }
  get BrandName() {
    return this.medFrm.get('BrandName')
  }
  get Dosage() {
    return this.medFrm.get('Dosage')
  }
  get Date() {
    return this.medFrm.get('Date')
  }
  get Time() {
    return this.medFrm.get('Time')
  }
  get Comments() {
    return this.medFrm.get('Comments')
  }
  get Signature() {
    return this.medFrm.get('Signature')
  }


  getMedicine() {
    this.medicineService.getMedicines().subscribe(res => {
      this.medicineList = res;
    })
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe(res => {
      this.nurse = res
    })

  }




  formSubmit() {

    let numberOfDrugs = this.medFrm.value['NumberOfDrugs']
    let partofDay = this.medFrm.value['PartofDay']
    let nurseName = this.medFrm.value['NurseName']
    let drugName = this.medFrm.value['DrugName']
    let dosage = this.medFrm.value['Dosage']
    let brandName = this.medFrm.value['BrandName']
    let date = this.medFrm.value['Date']
    date = moment(date).format('MM/DD/YYYY');


    let time = this.medFrm.value['Time']

    let comments = this.medFrm.value['Comments']
    let signature = this.medFrm.value['Signature']
    let nurseId = this.medFrm.value['NurseId']
    // let idoctorName = this.nurseList.filter(it => it.NurseId == nurseId)[0].NurseName;

    let body = {
      "NurseId": this.nurseId,
      "DrugName": drugName,
      "BrandName": brandName,
      "Dosage": dosage,
      "Comments": comments,
      "Date": date,
      "Time": time,
      "NurseName": nurseName,
      "Signature": signature,
      "PartofDay": partofDay,
      "NumberOfDrugs": numberOfDrugs
    }

    this.medFrm.value.Drugs = this.medFrm.value.Drugs.filter(drug => drug.DrugId === '').map(drug => {
      delete drug.DrugId;
      return drug;
    });

    this.medicationService.updateMedication(this.id, this.medFrm.value).subscribe(res => {
      this.router.navigate(['nursePatient',this.patientId ])
    })
  }
}
