import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { Procedure } from 'src/app/Models/procedure.model';
import { ProgressNote } from 'src/app/Models/progressnote.model';
import { PatientService } from 'src/app/services/patient.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ProgressNoteService } from 'src/app/services/progress-note.service';

@Component({
  selector: 'app-view-progress-note',
  templateUrl: './view-progress-note.component.html',
  styleUrls: ['./view-progress-note.component.css'],
})
export class ViewProgressNoteComponent implements OnInit {
  @ViewChild('ProcedureOrder') ProcedureOrder!: ElementRef;

  displayedColumns: string[] = [
    'Date',
    'Problem',
    'ReportedBy',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  progressNote: ProgressNote[] = [];
  dataSource: MatTableDataSource<any>;
  procedureDocument: string = '';
  patient: Patient;
  email = new FormControl('');
  Problem: string;
  Assessment: string;
  Intervention: string;
  Plan: string;
  Medication: string;
  constructor(
    private patientService: PatientService,
    private progressNoteService: ProgressNoteService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetProgressNote(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetProgressNote(id) {
    this.patientService.getProgressNote(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.ProgressNotes.sort(function (x, y) {
          return y.ProgressNoteId - x.ProgressNoteId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.progressNoteService.deleteProgressNote(id).subscribe((res) => {
      alert('deleted');
      this.GetProgressNote(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewLabReport(report: ProgressNote): void {
    // console.log('report: ', report);
    this.Problem = report.Problem;
    this.Assessment = report.Assessment;
    this.Intervention = report.Intervention;
    this.Medication = report.Medication;
    this.Plan = report.Plan;
  }
}
