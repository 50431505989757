import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit{

  isSuccess=false;

  constructor(private fb:FormBuilder,private emailService:EmailService){

  }
  ngOnInit(): void {
    
  }

  Frm = this.fb.group({
    Email:['',Validators.required],
    Subject:['',Validators.required],
    Template:['',Validators.required],
  })


get Email(){
  return this.Frm.get('Email')
}


get Subject(){
  return this.Frm.get('Subject')
}

get Template(){
  return this.Frm.get('Template')
}





  formSubmit(){

    let subject = this.Frm.value['Subject']
    let email = this.Frm.value['Email']
    let template = this.Frm.value['Template']

    let EmailPayload={
      Subject:subject,
      Email:email,
      Template:template
    }
this.emailService.sendGenericEmail(EmailPayload).subscribe(res=>{

  this.Frm.reset();
  this.isSuccess=true;
})
  }
}
