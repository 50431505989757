import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { DiclosureandconsentService } from 'src/app/services/diclosureandconsent.service';
import { NurseService } from 'src/app/services/nurse.service';

@Component({
  selector: 'app-update-disclosure-and-consent',
  templateUrl: './update-disclosure-and-consent.component.html',
  styleUrls: ['./update-disclosure-and-consent.component.css'],
})
export class UpdateDisclosureAndConsentComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  nurse: Nurse;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private diclosureandconsentService: DiclosureandconsentService,
    private router: Router,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.getNurse(this.nurseId);

    this.diclosureandconsentService
      .getDisclosureAndConsentById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });

    this.Frm = this.fb.group({
      PatientId: this.id,
      NurseId: this.nurseId,
      CareProvider: [''],
      HealthCondition: [''],
      PlannedProcedure: [''],
      UseOfBlood: [''],
      UseOfBloodPatientInitial: [''],
      RiskOfProcedure: [''],
      AuthorisedPersonName: [''],
      AuthorisedPersonSignatire: [''],
      AuthorisedPersonRelationtoPatient: [''],
      SignDate: [''],
      SignTime: [''],
      Witness: [''],
      WitnessSignature: [''],
      WitnessSignatureDate: [''],
      WitnessSignatureTime: [''],
      Addresss: [''],
      CityStateZip: [''],
      InterpreterName: [''],
      DoctorSignature: [''],
      DoctorSignatureDate: [''],
      DoctorSignatureTime: [''],
      Anesthesia: [false],
      AnesthesiaInital: [''],
      CardioVascularSystem: [false],
      CardioVascularSystemInitials: [''],
      Surgical: [false],
      SurgicalInitials: [''],
      NonSurgicalCoronaryAngioplasty: [false],
      NonSurgicalCoronaryAngioplastyInitials: [''],
      Dignostic: [false],
      DignosticInitials: [''],
      Vascular: [false],
      VascularInitials: [''],
      Angiography: [false],
      AngiographyInitials: [''],
      Angioplasty: [false],
      AngioplastyInitials: [''],
      EndoVascularStending: [false],
      EndoVascularStendingInitials: [''],
      VascularThrombolysis: [false],
      VascularThrombolysisInitials: [''],
      AniographyWithOccationTechnique: [false],
      AniographyWithOccationTechniqueInitials: [''],
      MensentericAngiographyWithInfusionalTherapy: [false],
      MensentericAngiographyWithInfusionalTherapyInitials: [''],
      InferiorVenaCavalFilterInsertionAndRemoval: [false],
      InferiorVenaCavalFilterInsertionAndRemovalInitials: [''],
      PulmonaryAngiograpgy: [false],
      PulmonaryAngiograpgyInitials: [''],
      PercutneousTreatmentOfPseudcaneurysm: [false],
      PercutneousTreatmentOfPseudcaneurysmInitials: [''],
      VascularAccess: [false],
      VascularAccessInitials: [''],
      VaricoseVeinTreatment: [false],
      VaricoseVeinTreatmentInitials: [''],
      DigestiveSystemTreatmentAndProcedures: [false],
      DigestiveSystemTreatmentAndProceduresInitials: [''],
      Open: [false],
      OpenInitials: [''],
      EarTreatmentsAndProcedures: [''],
      EarTreatmentsAndProceduresInitials: [''],
      EndocrineSystemTreatmentsAndProcedures: [false],
      EndocrineSystemTreatmentsAndProceduresInitials: [''],
      EyeTreatmentsAndProcedures: [false],
      EyeTreatmentsAndProceduresInitials: [''],
      FemaleGenitalSystemTreatmentsAndProcedures: [false],
      FemaleGenitalSystemTreatmentsAndProceduresInitials: [''],
      MaleGenitalSystemTreatmentsAndProcedures: [false],
      MaleGenitalSystemTreatmentsAndProceduresInitials: [''],
      HemmaticAndLymphaticSystem: [false],
      HemmaticAndLymphaticSystemInitials: [''],
      BreatSurgery: [false],
      BreatSurgeryInitials: [''],
      MaternityAndRelatedCases: [false],
      MaternityAndRelatedCasesInitials: [''],
      MuskuloskeletalSystemTreatmentsAndProcedures: [false],
      MuskuloskeletalSystemTreatmentsAndProceduresInitials: [''],
      NervousSystemTreatmentsAndProcedures: [false],
      NervousSystemTreatmentsAndProceduresInitials: [''],
      Radiology: [false],
      RadiologyInitials: [''],
      RespiratorySystemTreatmentsAndProcedures: [false],
      RespiratorySystemTreatmentsAndProceduresInitials: [''],
      UrenarySystem: [false],
      UrenarySystemInitials: [''],
      Dialysis: [false],
      DialysisInitials: [''],
      PsychatricProcedures: [false],
      PsychatricProceduresInitials: [''],
      RadiationTherapy: [false],
      RadiationTherapyInitials: [''],
      LaproscopicOrThoracoscopicSurgery: [false],
      LaproscopicOrThoracoscopicSurgeryInitials: [''],
      PainManagementProcedures: [false],
      PainManagementProceduresInitials: [''],
      DentalSurgeryProcedures: [false],
      DentalSurgeryProceduresInitials: [''],
      PlasticSurgery: [false],
      PlasticSurgeryInitials: [''],
    });
  }

  get Surgical() {
    return this.Frm.get('Surgical');
  }
  get SurgicalInitials() {
    return this.Frm.get('SurgicalInitials');
  }
  get HealthCondition() {
    return this.Frm.get('HealthCondition');
  }
  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get NurseName() {
    return this.Frm.get('NurseName');
  }
  get CareProvider() {
    return this.Frm.get('CareProvider');
  }
  get PlannedProcedure() {
    return this.Frm.get('PlannedProcedure');
  }
  get UseOfBloodPatientInitial() {
    return this.Frm.get('UseOfBloodPatientInitial');
  }
  get RiskOfProcedure() {
    return this.Frm.get('RiskOfProcedure');
  }
  get AuthorisedPersonName() {
    return this.Frm.get('AuthorisedPersonName');
  }
  get AuthorisedPersonSignatire() {
    return this.Frm.get('AuthorisedPersonSignatire');
  }
  get AuthorisedPersonRelationtoPatient() {
    return this.Frm.get('AuthorisedPersonRelationtoPatient');
  }
  get SignDate() {
    return this.Frm.get('SignDate');
  }
  get SignTime() {
    return this.Frm.get('SignTime');
  }
  get Witness() {
    return this.Frm.get('Witness');
  }

  get WitnessSignature() {
    return this.Frm.get('WitnessSignature');
  }
  get WitnessSignatureDate() {
    return this.Frm.get('WitnessSignatureDate');
  }
  get WitnessSignatureTime() {
    return this.Frm.get('WitnessSignatureTime');
  }
  get Addresss() {
    return this.Frm.get('Addresss');
  }
  get CityStateZip() {
    return this.Frm.get('CityStateZip');
  }
  get InterpreterName() {
    return this.Frm.get('InterpreterName');
  }
  get DoctorSignature() {
    return this.Frm.get('DoctorSignature');
  }
  get DoctorSignatureDate() {
    return this.Frm.get('DoctorSignatureDate');
  }
  get DoctorSignatureTime() {
    return this.Frm.get('DoctorSignatureTime');
  }
  get Anesthesia() {
    return this.Frm.get('Anesthesia');
  }
  get AnesthesiaInital() {
    return this.Frm.get('AnesthesiaInital');
  }
  get CardioVascularSystem() {
    return this.Frm.get('CardioVascularSystem');
  }
  get CardioVascularSystemInitials() {
    return this.Frm.get('CardioVascularSystemInitials');
  }
  get NonSurgicalCoronaryAngioplasty() {
    return this.Frm.get('NonSurgicalCoronaryAngioplasty');
  }
  get NonSurgicalCoronaryAngioplastyInitials() {
    return this.Frm.get('NonSurgicalCoronaryAngioplastyInitials');
  }
  get Dignostic() {
    return this.Frm.get('Dignostic');
  }
  get DignosticInitials() {
    return this.Frm.get('DignosticInitials');
  }
  get Vascular() {
    return this.Frm.get('Vascular');
  }
  get VascularInitials() {
    return this.Frm.get('VascularInitials');
  }
  get Angiography() {
    return this.Frm.get('Angiography');
  }
  get AngiographyInitials() {
    return this.Frm.get('AngiographyInitials');
  }
  get Angioplasty() {
    return this.Frm.get('Angioplasty');
  }
  get AngioplastyInitials() {
    return this.Frm.get('AngioplastyInitials');
  }
  get EndoVascularStending() {
    return this.Frm.get('EndoVascularStending');
  }
  get EndoVascularStendingInitials() {
    return this.Frm.get('EndoVascularStendingInitials');
  }
  get VascularThrombolysis() {
    return this.Frm.get('VascularThrombolysis');
  }
  get VascularThrombolysisInitials() {
    return this.Frm.get('VascularThrombolysisInitials');
  }
  get AniographyWithOccationTechnique() {
    return this.Frm.get('AniographyWithOccationTechnique');
  }
  get AniographyWithOccationTechniqueInitials() {
    return this.Frm.get('AniographyWithOccationTechniqueInitials');
  }
  get MensentericAngiographyWithInfusionalTherapy() {
    return this.Frm.get('MensentericAngiographyWithInfusionalTherapy');
  }
  get MensentericAngiographyWithInfusionalTherapyInitials() {
    return this.Frm.get('MensentericAngiographyWithInfusionalTherapyInitials');
  }
  get InferiorVenaCavalFilterInsertionAndRemoval() {
    return this.Frm.get('InferiorVenaCavalFilterInsertionAndRemoval');
  }
  get InferiorVenaCavalFilterInsertionAndRemovalInitials() {
    return this.Frm.get('InferiorVenaCavalFilterInsertionAndRemovalInitials');
  }
  get PulmonaryAngiograpgy() {
    return this.Frm.get('PulmonaryAngiograpgy');
  }
  get PulmonaryAngiograpgyInitials() {
    return this.Frm.get('PulmonaryAngiograpgyInitials');
  }
  get PercutneousTreatmentOfPseudcaneurysm() {
    return this.Frm.get('PercutneousTreatmentOfPseudcaneurysm');
  }
  get PercutneousTreatmentOfPseudcaneurysmInitials() {
    return this.Frm.get('PercutneousTreatmentOfPseudcaneurysmInitials');
  }
  get VascularAccess() {
    return this.Frm.get('VascularAccess');
  }
  get VascularAccessInitials() {
    return this.Frm.get('VascularAccessInitials');
  }
  get VaricoseVeinTreatment() {
    return this.Frm.get('VaricoseVeinTreatment');
  }
  get VaricoseVeinTreatmentInitials() {
    return this.Frm.get('VaricoseVeinTreatmentInitials');
  }
  get DigestiveSystemTreatmentAndProcedures() {
    return this.Frm.get('DigestiveSystemTreatmentAndProcedures');
  }
  get DigestiveSystemTreatmentAndProceduresInitials() {
    return this.Frm.get('DigestiveSystemTreatmentAndProceduresInitials');
  }
  get Open() {
    return this.Frm.get('Open');
  }
  get OpenInitials() {
    return this.Frm.get('OpenInitials');
  }
  get EarTreatmentsAndProcedures() {
    return this.Frm.get('EarTreatmentsAndProcedures');
  }
  get EarTreatmentsAndProceduresInitials() {
    return this.Frm.get('EarTreatmentsAndProceduresInitials');
  }
  get EndocrineSystemTreatmentsAndProcedures() {
    return this.Frm.get('EndocrineSystemTreatmentsAndProcedures');
  }
  get EndocrineSystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('EndocrineSystemTreatmentsAndProceduresInitials');
  }
  get EyeTreatmentsAndProcedures() {
    return this.Frm.get('EyeTreatmentsAndProcedures');
  }
  get EyeTreatmentsAndProceduresInitials() {
    return this.Frm.get('EyeTreatmentsAndProceduresInitials');
  }
  get FemaleGenitalSystemTreatmentsAndProcedures() {
    return this.Frm.get('FemaleGenitalSystemTreatmentsAndProcedures');
  }
  get FemaleGenitalSystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('FemaleGenitalSystemTreatmentsAndProceduresInitials');
  }
  get MaleGenitalSystemTreatmentsAndProcedures() {
    return this.Frm.get('MaleGenitalSystemTreatmentsAndProcedures');
  }
  get MaleGenitalSystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('MaleGenitalSystemTreatmentsAndProceduresInitials');
  }
  get HemmaticAndLymphaticSystem() {
    return this.Frm.get('HemmaticAndLymphaticSystem');
  }
  get HemmaticAndLymphaticSystemInitials() {
    return this.Frm.get('HemmaticAndLymphaticSystemInitials');
  }
  get BreatSurgery() {
    return this.Frm.get('BreatSurgery');
  }
  get BreatSurgeryInitials() {
    return this.Frm.get('BreatSurgeryInitials');
  }
  get MaternityAndRelatedCases() {
    return this.Frm.get('MaternityAndRelatedCases');
  }
  get MaternityAndRelatedCasesInitials() {
    return this.Frm.get('MaternityAndRelatedCasesInitials');
  }
  get MuskuloskeletalSystemTreatmentsAndProcedures() {
    return this.Frm.get('MuskuloskeletalSystemTreatmentsAndProcedures');
  }
  get MuskuloskeletalSystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('MuskuloskeletalSystemTreatmentsAndProceduresInitials');
  }
  get NervousSystemTreatmentsAndProcedures() {
    return this.Frm.get('NervousSystemTreatmentsAndProcedures');
  }
  get NervousSystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('NervousSystemTreatmentsAndProceduresInitials');
  }
  get Radiology() {
    return this.Frm.get('Radiology');
  }
  get RadiologyInitials() {
    return this.Frm.get('RadiologyInitials');
  }
  get RespiratorySystemTreatmentsAndProcedures() {
    return this.Frm.get('RespiratorySystemTreatmentsAndProcedures');
  }
  get RespiratorySystemTreatmentsAndProceduresInitials() {
    return this.Frm.get('RespiratorySystemTreatmentsAndProceduresInitials');
  }
  get UrenarySystem() {
    return this.Frm.get('UrenarySystem');
  }
  get UrenarySystemInitials() {
    return this.Frm.get('UrenarySystemInitials');
  }
  get Dialysis() {
    return this.Frm.get('Dialysis');
  }
  get DialysisInitials() {
    return this.Frm.get('DialysisInitials');
  }
  get PsychatricProcedures() {
    return this.Frm.get('PsychatricProcedures');
  }
  get PsychatricProceduresInitials() {
    return this.Frm.get('PsychatricProceduresInitials');
  }
  get RadiationTherapy() {
    return this.Frm.get('RadiationTherapy');
  }
  get RadiationTherapyInitials() {
    return this.Frm.get('RadiationTherapyInitials');
  }
  get LaproscopicOrThoracoscopicSurgery() {
    return this.Frm.get('LaproscopicOrThoracoscopicSurgery');
  }
  get LaproscopicOrThoracoscopicSurgeryInitials() {
    return this.Frm.get('LaproscopicOrThoracoscopicSurgeryInitials');
  }
  get PainManagementProcedures() {
    return this.Frm.get('PainManagementProcedures');
  }
  get PainManagementProceduresInitials() {
    return this.Frm.get('PainManagementProceduresInitials');
  }
  get DentalSurgeryProcedures() {
    return this.Frm.get('DentalSurgeryProcedures');
  }
  get DentalSurgeryProceduresInitials() {
    return this.Frm.get('DentalSurgeryProceduresInitials');
  }
  get PlasticSurgery() {
    return this.Frm.get('PlasticSurgery');
  }
  get PlasticSurgeryInitials() {
    return this.Frm.get('PlasticSurgeryInitials');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  public save() {
    let body = {
      ...this.Frm.value,
      NurseName: this.nurse.NurseName,
    };
    this.diclosureandconsentService
      .updateDisclosureAndConsent(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
