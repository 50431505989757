<div class="row  mt-5" *ngIf="loading" >
    <div class="text-center col-lg ml-5">
      <mat-spinner ></mat-spinner>

    </div>
  </div>
<div class="container mt-2">
    <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
    <div class="col-lg-6 ">
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Search by Date</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input type="text" #searchValue  (keyup)="search(searchValue)" matInput>
          
          </mat-form-field>
</div>
    </div>
</div>

<div class="container"> 
  <button class="btn backG float-right mr-3 mx-3 my-3" color="primary" onClick="window.focus(); window.print()" value="print" mat-raised-button><mat-icon>print</mat-icon></button>

<div class="row mt-3" *ngFor="let note of filteredList" >
    <div class="col-lg-2"><span>{{note.createdDate| date: 'MM/dd/yyyy, h:mm a'}}</span></div>
<!-- <div [innerHTML]="note.DoctorNoteImg"></div> -->
<div class="col-lg-10">
  <p>{{note.DoctorNoteImg}}</p>
</div>
<button class="btn backG float-right mr-3 mx-5 my-5"  (click)="delete(note.DoctorNoteId)" mat-raised-button> <mat-icon>delete</mat-icon></button>
</div>

<hr class="bg-primary">
</div>