<table class="table table-striped">
    <thead>

        <th>Crated Date</th>
        <th>Full Name</th>
        <th>Date Of Duty </th>
        <th>Shift</th>
    </thead>
    <tbody>
        <tr *ngFor="let rw of myList">
            <td>{{rw.createdDate | date:'dd/MM/YYYY'}}</td>
            <td>{{rw.FullName}}</td>
            <td>{{rw.DateOfDuty}}</td>
            <td>{{rw.Shift}}</td>
        </tr>
    </tbody>
</table>