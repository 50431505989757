<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">code_file</mat-icon>
            Hester Davies Fall Risk Assessment
        </ng-template>
        <app-hester-davies></app-hester-davies>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Hester Davies Fall Risk Assessment
        </ng-template>
        <app-add-hester-davies></app-add-hester-davies>

    </mat-tab>
</mat-tab-group>