<div class="d-flex flex-column py-4">
    <h2 class="text-center">Care Plan View</h2>
    <h5 class="text-center">(MEDICATION ORDERS TO BE WRITTEN IN MEDICINE CARD)</h5>
    <div class="container-fluid">
        <div class="row ">
            <div class="col-12 d-flex px-0 justify-content-center align-items-center w-100">
                <mat-card class="mat-elevation-z8" style="width: 90%">
                    <mat-card-content>
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Care Plan Info</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Care Plan Type
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.CarePlanName }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Likely Date of Discharge
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DischargePlanDate | date: 'MM/dd/yyyy' }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Special Needs</h2>
                                    </div>
                                </div>
                                <div class="col-12 pl-0 d-flex align-items-center text-justify">{{
                                    carePlan?.SpecialNeeds }}
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Investigations</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12  d-flex align-items-center text-justify">{{
                                        carePlan?.OtherInvestigations }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 w-100">
                                <h2 class="mb-1">Diet & Nutrition</h2>
                            </div>
                            <div class="col-md-6 mb-2">

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Diet
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DietType }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 d-flex align-items-center mt-sm-0 mt-1">Details
                                    </div>
                                    <div class="col-md-6 col-12 d-flex align-items-center">{{
                                        carePlan?.DietDetails }}
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Dietary Consultation

                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DietryConsultationType ? 'Yes' : 'No' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6  d-flex align-items-center mt-sm-0 mt-1">Details
                                    </div>
                                    <div class="col-md-6 col-12  d-flex align-items-center">{{
                                        carePlan?.DietryConsultationDetails }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex ">Physiotherapy
                                    </div>
                                    <div class="col-md-6 col-4 d-flex ">{{
                                        carePlan?.PhysiotherapyType ? 'Yes' : 'No' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 d-flex  mt-sm-0 mt-1">Details
                                    </div>
                                    <div class="col-md-6 col-12 d-flex ">{{
                                        carePlan?.PhysiotherapyDetails }}
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6 col-8 d-flex ">Restraint Required

                                    </div>
                                    <div class="col-md-6 col-4 d-flex ">{{
                                        carePlan?.RestraintRequiredType ? 'Yes' : 'No' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 d-flex  mt-sm-0 mt-1">Details
                                    </div>
                                    <div class="col-md-6 col-12 d-flex align-items-center">{{
                                        carePlan?.RestraintRequiredDetails }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">

                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Laboratory</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">CBC
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.CBC ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">RBS
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.RBS ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">RFT
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.RFT ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">LFT
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.LFT ? 'Yes' : 'No' }}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">HIV
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.HIV ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">HBSAg
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.HBSAg ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">HCV
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.HCV ? 'Yes' : 'No' }}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Serum Electrolytes
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.SerumElectrolytes ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Urine Routine
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.UrineRoutine ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Thyroid Profile
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.ThyroidProfile ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Other Laboratory Tests Details</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12 d-flex mt-sm-0 mt-1">{{
                                        carePlan?.OtherLaboratoryTest }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-3">

                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Radiology</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">X-RAY
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.XRay ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">USG
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.USG ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">CT
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.CT ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">MRI
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.MRI ? 'Yes' : 'No' }}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">MAMMOGRAM
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.MAMMOGRAM ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">DOPPLER
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DOPPLER ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">PET
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.PET ? 'Yes' : 'No' }}
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Other Radiology Tests Details</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class=" col-12 d-flex mt-sm-0 mt-1">{{
                                        carePlan?.OtherLaboratoryTest }}
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <h2 class="mb-1">Surgery Procedure Details</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class=" col-12 d-flex mt-sm-0 mt-1">{{
                                        carePlan?.SurgeryOrProcedure }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="mb-1">Cross Consultations</h2>
                                    </div>
                                </div>
                                <ng-container *ngFor="let item of carePlan?.CrossConsultations">
                                    <div class="row">
                                        <div class="col-md-6 col-8 d-flex align-items-center">Name of Doctor/Department
                                        </div>
                                        <div class="col-md-6 col-4 d-flex align-items-center">{{
                                            item?.NameOfDoctor }} / {{
                                            item?.Department }}
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-6 mb-2">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <h2 class="mb-1">Care Plan Documentation</h2>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Name of Doctor Documented
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DoctorDocumentedName }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Date
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DoctorDocumentedDate | date: 'MM/dd/yyyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Time
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DoctorDocumentedTime }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Doctor Signature
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.DoctorDocumentedSignature }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Name of Primary Consultant
                                        Documented
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.ConsultantDocumentedName }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Date
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.ConsultantDocumentedDate | date: 'MM/dd/yyyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Time
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.ConsultantDocumentedTime }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-8 d-flex align-items-center">Primary Consultant Signature
                                    </div>
                                    <div class="col-md-6 col-4 d-flex align-items-center">{{
                                        carePlan?.ConsultantDocumentedSignature }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

</div>