import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CarePlan } from '../../Models/careplan.model';
import { CareplanService } from '../../services/careplan.service';

@Component({
  selector: 'app-view-careplan',
  templateUrl: './view-careplan.component.html',
  styleUrls: ['./view-careplan.component.css'],
})
export class ViewCareplanComponent implements OnInit {
  displayedColumns: string[] = [
    'CarePlanId',
    'CarePlanName',
    'Date',
    'View',
    'Delete',
  ];
  dataSource: MatTableDataSource<any>;
  filteredList;
  carePlans!: CarePlan[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private carePlanService: CareplanService) {}

  ngOnInit(): void {
    this.getCarePlanData();
  }

  getCarePlanData() {
    this.carePlanService.getCarePlans().subscribe((res) => {
      this.carePlans = res;
      this.dataSource = new MatTableDataSource(
        this.carePlans.sort(function (x, y) {
          return y.CarePlanId - x.CarePlanId;
        })
      );
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  delete(id) {
    this.carePlanService.deleteCarePlan(id).subscribe((res) => {
      this.getCarePlanData();
    });
  }
}
