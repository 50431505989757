<!-- 
<mat-tab-group  (selectedTabChange)="tabChanged($event)">

  <mat-tab >
      <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">warning</mat-icon>
         Allergies
        </ng-template> -->

<div class="ml-3">
    <form [formGroup]="Frm" class="example-form">
        <div class="row">
            <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                    <mat-label>Ward Name</mat-label>
                    <mat-select formControlName="WardId" (selectionChange)="getWardHandOffNote()">
                        <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">{{
                            ward.WardName }}</mat-option>
                    </mat-select>
                    <mat-error>Select a Ward</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <button type="button" (click)="print()" class="float-right mr-5" color="primary"
        mat-mini-fab><mat-icon>print</mat-icon></button>
    <div id="printable">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
                <td mat-cell *matCellDef="let row"> <mat-checkbox class="example-margin"
                        (change)="selectPatients($event, row)"></mat-checkbox> </td>
            </ng-container>
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
            </ng-container>
            <ng-container matColumnDef="PatientName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Patient Name </th>
                <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
            </ng-container>


            <ng-container matColumnDef="PatientDOB">
                <th class="message" mat-header-cell *matHeaderCellDef> Patient D.O.B </th>
                <td class="message" mat-cell *matCellDef="let row"> {{row.PatientDOB}} </td>
            </ng-container>
            <ng-container matColumnDef="WardName">
                <th class="message" mat-header-cell *matHeaderCellDef> Ward Name </th>
                <td class="message" mat-cell *matCellDef="let row"> {{row.WardName}} </td>
            </ng-container>

            <ng-container matColumnDef="HandOffNote">
                <th class="message" mat-header-cell *matHeaderCellDef> Hand Off Note </th>
                <td class="message" mat-cell *matCellDef="let row"> {{row.HandOffNote}} </td>
            </ng-container>

            <ng-container matColumnDef="Update">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let row">
                    <button class="" [routerLink]="['/updateNurseHandOffNote/',row.NurseHandOffNoteId]"
                        [disabled]="CurrentNurseId != row.NurseId" color="primary" mat-mini-fab>
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef> Delete</th>
                <td mat-cell *matCellDef="let row">
                    <button (click)="delete(row.NurseHandOffNoteId)" mat-mini-fab
                        [disabled]="CurrentNurseId != row.NurseId" color="accent"><mat-icon>delete</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">
    View </button> -->

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Print Order</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body " id="printable">
                <table class="table">
                    <thead>
                        <th>Patient Name</th>
                        <th>Patient D.O.B</th>
                        <th>Ward</th>
                        <th>Nurse Hand Off Note</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patient of NoteList">
                            <td>{{patient.PatientName}}</td>
                            <td>{{patient.PatientDOB}}</td>
                            <td>{{patient.WardName}}</td>
                            <td>{{patient.HandOffNote}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>



<!-- </mat-tab>
                
          
          <mat-tab > 
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add</mat-icon>
                Register Allergy
              </ng-template>
          
              <app-add-allergy></app-add-allergy>
          </mat-tab>
          
          
          </mat-tab-group> -->