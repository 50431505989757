import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ward } from 'src/app/Models/ward.model';
import { RoomService } from 'src/app/services/room.service';
import { WardService } from 'src/app/services/ward.service';

@Component({
  selector: 'app-update-room',
  templateUrl: './update-room.component.html',
  styleUrls: ['./update-room.component.css'],
})
export class UpdateRoomComponent implements OnInit {
  wardList: Ward[];
  id = 0;
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private wardService: WardService,
    private roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.roomService.getRoomById(this.id).subscribe((res) => {
      this.roomFrm.patchValue(res);
    });
    this.getWards();
  }

  roomFrm = this.fb.group({
    WardId: [],
    RoomNo: ['', Validators.required],
    Description: ['', Validators.required],
    RoomCharge: [''],
    Status: ['', Validators.required],
    ExpectedAvailability: [''],
  });

  get WardId() {
    return this.roomFrm.get('WardId');
  }
  get RoomNo() {
    return this.roomFrm.get('RoomNo');
  }
  get Description() {
    return this.roomFrm.get('Description');
  }
  get RoomCharge() {
    return this.roomFrm.get('RoomCharge');
  }
  get Status() {
    return this.roomFrm.get('Status');
  }
  get ExpectedAvailability() {
    return this.roomFrm.get('ExpectedAvailability');
  }

  getWards() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  formSubmit() {
    let roomNo = this.roomFrm.value['RoomNo'];
    let description = this.roomFrm.value['Description'];
    let roomCharge = this.roomFrm.value['RoomCharge'];
    let status = this.roomFrm.value['Status'];
    let expectedAvailability = this.roomFrm.value['ExpectedAvailability'];

    let wardId = this.roomFrm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;

    let body = {
      RoomNo: roomNo,
      Description: description,
      RoomCharge: roomCharge,
      status: status,
      ExpectedAvailability: expectedAvailability,
      WardName: wardName,
      WardId: wardId,
    };

    this.roomService.updateRoom(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.roomFrm.reset();
      this.router.navigate(['/adminDash']);
    });
  }
}
