import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { ReleaseOfInformation } from 'src/app/Models/releaseofinformation.model';
import { PatientService } from 'src/app/services/patient.service';
import { ReleaseOfInformationService } from 'src/app/services/release-of-information.service';

@Component({
  selector: 'app-view-release-of-information',
  templateUrl: './view-release-of-information.component.html',
  styleUrls: ['./view-release-of-information.component.css'],
})
export class ViewReleaseOfInformationComponent implements OnInit {
  panelOpenState = false;
  id = 0;
  release: ReleaseOfInformation[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private releaseOfInformation: ReleaseOfInformationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatientReleaseInformation(this.id);
  }

  getPatientReleaseInformation(id) {
    this.patientService.getReleaseInformation(id).subscribe((res) => {
      this.release = res.ReleaseOfInformations;
      console.log(this.release);
    });
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPatientReleaseInformation(this.id);
    }
  }

  delete(id) {
    this.releaseOfInformation
      .deleteReleaseOfInformation(id)
      .subscribe((res) => {
        alert('deleted');
        this.getPatientReleaseInformation(this.id);
      });
  }
}
