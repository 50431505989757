import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PhysicalTherapyDoctorDate } from 'src/app/Models/physicaltherapydoctordate.model';
import { PhysicalTherapyDoctorDateService } from 'src/app/services/physical-therapy-doctor-date.service';

@Component({
  selector: 'app-view-pt-docctor-availability',
  templateUrl: './view-pt-docctor-availability.component.html',
  styleUrls: ['./view-pt-docctor-availability.component.css']
})
export class ViewPtDocctorAvailabilityComponent implements OnInit {
  doctoDatesList: PhysicalTherapyDoctorDate[];
  displayedColumns: string[] = ['PhysicalTherapyDoctorId', 'DoctorName','UnAvailableDate'];
  dataSource: MatTableDataSource<any>;
constructor( 
  private doctorDatesService:PhysicalTherapyDoctorDateService


){}
  ngOnInit(): void {

this.getDoctorAvailability();
}
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;


getDoctorAvailability(){
  this.doctorDatesService.getPhysicalTherapyDoctorDates().subscribe(res=>{
    this.dataSource = new MatTableDataSource(res);
    this.dataSource.paginator=this.paginator;
  })
}

tabChanged(event: MatTabChangeEvent): void {
  if (event.index === 0) {
    this.getDoctorAvailability();
    
  }
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}
