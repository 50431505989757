import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PurposefullRound } from 'src/app/Models/purposefullround.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-purposefull-round',
  templateUrl: './view-purposefull-round.component.html',
  styleUrls: ['./view-purposefull-round.component.css'],
})
export class ViewPurposefullRoundComponent implements OnInit {
  id = 0;
  roundsList: PurposefullRound[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientRounds(this.id);
  }

  getPatientRounds(id) {
    this.patientService.getpurposefulRounds(id).subscribe((res) => {
      this.roundsList = res.PurposefullRounds.sort(function (x, y) {
        return y.PurposefullRoundId - x.PurposefullRoundId;
      });
    });
  }

  print() {
    window.print();
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPatientRounds(this.id);
    }
  }
}
