<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>New Pharmacyst</h2>
            <form [formGroup]="phFrm" (ngSubmit)="formSubmit()" class="example-form">

                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Full Name</mat-label>
                            <input type="text" formControlName="FullName" matInput>
                            <mat-error>Please enter FullName</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Npi Number</mat-label>
                            <input type="text" formControlName="NpiNumber" matInput>
                            <mat-error>Please enter Npi Number</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>License Number</mat-label>
                            <input type="text" formControlName="LicenseNumber" matInput>
                            <mat-error>Please enter License Number</mat-error>
                        </mat-form-field>

                    </div>


                </div>
                <div class="row">
                    <div class="col-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone Number</mat-label>
                            <input type="text" formControlName="PhoneNumber" matInput>
                            <mat-error>Please enter PhoneNumber</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>NationalId</mat-label>
                            <input type="tel" formControlName="NationalId" matInput>
                            <mat-error>Please enter NationalId</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> D.O.B</mat-label>
                            <input type="date" formControlName="Dob" matInput>
                            <mat-error>Please enter D.O.B</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label> Email</mat-label>
                            <input type="email" formControlName="Email" matInput>
                            <mat-error>Please enter Email</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label> Password</mat-label>
                            <input type="text" formControlName="Password" matInput>
                            <mat-error>Please enter Password</mat-error>
                        </mat-form-field>
                        <span *ngIf="phFrm.get('Password')?.touched && phFrm.get('Password')?.invalid">
                            <mat-error *ngIf="phFrm.get('Password')?.errors.required">Please enter Password</mat-error>
                            <mat-error *ngIf="phFrm.get('Password')?.errors.pattern"> Password must contain more than 7
                                characters, 1 numeric, 1 upper case letter, and 1 special
                                character($@$!%*?&).</mat-error>
                        </span>
                    </div>

                </div>
                <div class="row">



                    <div class="col-lg-8">

                        <mat-form-field class="example-full-width">
                            <mat-label> Address</mat-label>
                            <input type="tel" formControlName="Address" matInput>
                            <mat-error>Please enter Address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <p>Status</p>
                        <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Pharmcy Member
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>