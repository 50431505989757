import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PhysicalTherapyVisit } from 'src/app/Models/physicaltheraapyvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhysicalTherapyVisitService } from 'src/app/services/physical-therapy-visit.service';

@Component({
  selector: 'app-physical-therapy-patient',
  templateUrl: './physical-therapy-patient.component.html',
  styleUrls: ['./physical-therapy-patient.component.css']
})
export class PhysicalTherapyPatientComponent implements OnInit {

  id=0;
  patient:Patient
  PhysicalTherapyVisitList:PhysicalTherapyVisit[]=[];
  isAdmitted=false;
  filteredList;
  pvId=0;
    PhysicalTherapyVisitId: PhysicalTherapyVisit[];
    showItem='';
  
    
    constructor(private patientService:PatientService, private route: ActivatedRoute,
      private router: Router,private ptVisitService:PhysicalTherapyVisitService) { }
  
    ngOnInit(): void {
      this.route.params.subscribe(res=>{
        this.id= res['id']
      })
      this.patientService.getPatientvisits(this.id).subscribe(res=>{
             this.patient = res;
  
             this.filteredList = res.PhysicalTherapyVisits;
           this.PhysicalTherapyVisitList = res.PhysicalTherapyVisits;
  
          //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
          //   this.isAdmitted
          //  }
  
      })
    }
    printPage() {
  
      window.print();
  
    }
  
  
    search(searchValue) {
      // console.log(searchValue);
      if (searchValue.value != null) {
        this.filteredList = this.PhysicalTherapyVisitList.filter((x) =>
          x.TreatmentDate.toLowerCase().includes(searchValue.value.toLowerCase())
        );
      }
    }
  
  
    deleteVisit(id){
      let C_confirm = confirm('Are you Sure you want to delete ?');
      if (C_confirm == true){
      this.ptVisitService.deletePhysicalTherapyVisit(id).subscribe(res=>{
      
        this.filteredList = this.PhysicalTherapyVisitList.filter(x=>x.PhysicalTherapyVisitId !=id);
      })
  
    }
    }
  }
  
