import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from '../../services/department.service';
import { Department } from '../../Models/department.model';

@Component({
  selector: 'app-department-dashboard',
  templateUrl: './department-dashboard.component.html',
  styleUrls: ['./department-dashboard.component.css']
})
export class DepartmentDashboardComponent implements OnInit {
id=0;
showItem='';

dpt:Department
  constructor(private route:ActivatedRoute,private dptService:DepartmentService,private router:Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id']
    })
this.getDptbyId(this.id)
  }

getDptbyId(id){
this.dptService.getDepartmentById(id).subscribe(res=>{
 this.dpt=res;
})
}


logout(){
  localStorage.removeItem('token');
  this.router.navigate(['/'])
}
}
