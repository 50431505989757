import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Patient } from 'src/app/Models/patient.model';
import { DepartmentService } from 'src/app/services/department.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-labdashboard',
  templateUrl: './labdashboard.component.html',
  styleUrls: ['./labdashboard.component.css']
})
export class LabdashboardComponent implements OnInit {
  userList:Patient[]=[];
  id=0;
  patientList:Patient[]=[];
  filteredList;
  dptList:Department
  dataSource: MatTableDataSource<Patient>;
  helper = new JwtHelperService();
  
  myToken = window.localStorage.getItem('token')

  showItem='';

  constructor(private patientService:PatientService,private dptService:DepartmentService,private route:ActivatedRoute,private router:Router) { }


  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id  = parseInt(decodedToken.nameid)
 
this.getPatients();
}
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;


ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}

 getPatients(){
  this.patientService.getPatients().subscribe(res=>{
   this.filteredList= res

    this.patientList=res;
   
  })

 }

 search(searchValue) {
  // console.log(searchValue);
  if (searchValue.value != null) {
    this.filteredList = this.patientList.filter((x) =>
      x.PatientNationalId.toLowerCase().includes(searchValue.value.toLowerCase())
    );
  }
}


logout(){
  localStorage.removeItem('token');
  this.router.navigate(['/'])
}
}

