import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DepartmentService } from 'src/app/services/department.service';
import { Doctor } from '../../Models/doctor.model';
import { DoctorService } from '../../services/doctor.service';
import { UserService } from '../../services/user.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-view-doctor',
  templateUrl: './view-doctor.component.html',
  styleUrls: ['./view-doctor.component.css']
})
export class ViewDoctorComponent implements OnInit {
  id=0;
  showItem='';
  doctorList:Doctor[]=[]
  filteredList: Doctor[];
  dataSource: MatTableDataSource<Doctor>;
    constructor(private route:ActivatedRoute,private departService:DepartmentService,private doctorService:DoctorService,private userService:UserService) { }
  
    ngOnInit(): void {
      this.route.params.subscribe(res=>{
        this.id = res['id']
      })
  
      this.getDoctor();
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  getDoctor(){
  this.doctorService.getDoctors().subscribe(res=>{
    this.doctorList = res;
    this.filteredList = res;
  })
  }
  
  // getDoctor(id){
  // this.departService.getDepartmentDoctors(id).subscribe(res=>{
  //   this.doctorList = res.Doctors;
  //   this.filteredList = res.Doctors;
  // })
  // }
  
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDoctor();
      
    }
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.doctorList.filter((x) =>
        x.DoctorName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id){
    this.doctorService.deleteDoctor(id).subscribe(res=>{
      this.userService.deleteUser(id).subscribe();
          //  this.filteredList = this.doctorList.filter(x=>x.DoctorId !=id)
          this.getDoctor();
    })
  }
  }
  