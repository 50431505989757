<div id="dashboard">
    <div class="content">
        <div class="sidebar mat-elevation">
           <div class="header">
            <!-- <img src="../../../assets/admin.png" class="image" alt=""> -->
  
            <h4 class="text-white ml-2">Department Dashboard</h4>
           </div>
           <div class="ItemS "  (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
            <mat-icon mat-list-icon>
              dashboard
              </mat-icon>
            <div class="mx-2"> Dashboard</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
           <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addDoctor/',this.id]"> Add Doctor</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
      
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addPatient/',this.id]"> Add Patient</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2" [routerLink]="['/addWard/',this.id]"> Add Ward</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2" [routerLink]="['/viewDoctor/',this.id]">View Doctor</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
           <div class="ItemS  " >
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2" [routerLink]="['/viewPatient/',this.id]">View Patient</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
          <div class="ItemS" >
            <mat-icon mat-list-icon>undo</mat-icon>
            <div class="mx-2" [routerLink]="['/hospitalDash/']"> Back</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
        
<!-- 
      <div class="ItemS  " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Add Doctor</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS  " (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Add Patient</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS  " (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Patient</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS  " (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Doctor</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


          <div class="ItemS" >
            <mat-icon mat-list-icon>undo</mat-icon>
            <div class="mx-2" [routerLink]="['/hospitalDash/']"> Back</div>
            <mat-icon>navigate_next</mat-icon>
          </div>
         -->
  
         
        
  
  
        </div>
      
          <div class="main">
      <div class="toolbar mat-elevation-z6" >
        <button type="button"(click)="logout()" class="btn float-right mx-3 my-3 logout " color="accent" mat-raised-button >
          <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
          <span>Logout</span>
        </button>
    
      </div>
  
      <!-- <div class="card" style="width: 100%;">
        <div class="card-body">
          <h2 class="card-title">{{dpt.DepartmentName}}</h2>
          
          <p class="card-text">{{dpt.Description}}</p>
         
        </div>
      </div> -->
          

      <div class="container mt-5">
        <span [ngSwitch]="showItem">
          <p *ngSwitchCase="'1'">
            <app-add-doctor></app-add-doctor>
          </p>
          <p *ngSwitchCase="'2'">
            <app-addpatient></app-addpatient>
          </p>
          <p *ngSwitchCase="'3'">
            <app-viewpatient></app-viewpatient>
          </p>
          <p *ngSwitchCase="'4'">
            <app-view-doctor></app-view-doctor>
          </p>
          <p *ngSwitchCase="'5'">
            <app-viewpatient></app-viewpatient>
          </p>

          <p *ngSwitchDefault>
            <app-viewpatient></app-viewpatient>
          </p>
        </span>
      </div>

          </div>
    


      </div>
  </div>
