import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Hospital } from 'src/app/Models/hospital.model';
import { OfficePolicyService } from 'src/app/services/office-policy.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-add-office-policy-consent',
  templateUrl: './add-office-policy-consent.component.html',
  styleUrls: ['./add-office-policy-consent.component.css'],
})
export class AddOfficePolicyConsentComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  parent: string;
  whohadproblems: string[] = ['Father', 'Mother'];
  id = 0;
  isSuccess = false;
  Frm: FormGroup;
  hospital: Hospital;
  constructor(
    private fb: UntypedFormBuilder,
    private officePolicyService: OfficePolicyService,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.Frm = this.fb.group({
      PatientId: this.id,
      AuthorisedSignatoryName: ['', Validators.required],
      DateOfSignature: [''],
      IslawyerrepresentingyouForThisMedicalCondition: [false],
      // Signature: ['', Validators.required],
    });
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }

  get IslawyerrepresentingyouForThisMedicalCondition() {
    return this.Frm.get('IslawyerrepresentingyouForThisMedicalCondition');
  }
  get AuthorisedSignatoryName() {
    return this.Frm.get('AuthorisedSignatoryName');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }

  formSubmit() {
    let body = {
      ...this.Frm.value,
      Signature: this.signatureImg,
    };
    this.officePolicyService.registerOfficePolicy(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
      this.submitAction.emit('');
    });
  }

  @ViewChild('signPad', { static: false })
  signPad!: ElementRef<HTMLCanvasElement>;
  @Output() signatureSaved = new EventEmitter();
  private signatureImg?: string;
  private sigPadElement: any;
  private context: any;
  private isDrawing!: boolean;

  public ngAfterViewInit(): void {
    this.sigPadElement = this.signPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  onMouseDown(e: any): void {
    // The mouse button is clicked, which means the start of drawing the signature
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any): void {
    // The mouse button is released, so this means the end of drawing the signature
    this.isDrawing = false;
  }

  onMouseMove(e: any): void {
    e.preventDefault();

    if (this.isDrawing) {
      // if we're not drawing we need to ignore the events
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  clearSignature(e): void {
    e.preventDefault;

    this.signatureImg = undefined;
    this.context.clearRect(
      0,
      0,
      this.sigPadElement.width,
      this.sigPadElement.height
    );
    this.context.beginPath();
  }

  saveSignature(e): void {
    e.preventDefault;
    this.signatureImg = this.sigPadElement.toDataURL('image/png');

    this.signatureSaved.emit(this.signatureImg);
    // console.log('bite', this.signatureImg);
  }

  private relativeCoords(event: any): { x: number; y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY,
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }
}
