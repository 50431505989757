
  
  
  <div class="mt-5">
    <button  mat-raised-button color="primary" 
      expand="block" 
     
      (click)="startScan()">
      Scan QR Code
    </button>
  </div>