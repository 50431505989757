import { Component, OnInit } from '@angular/core';
import { HospitalService } from '../../services/hospital.service';
import { Hospital } from '../../Models/hospital.model';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-view-hospital',
  templateUrl: './view-hospital.component.html',
  styleUrls: ['./view-hospital.component.css']
})
export class ViewHospitalComponent implements OnInit {
  helper = new JwtHelperService();
  showItem='';
  id=0;
  myToken=window.localStorage.getItem('token')
  hospitalList:Hospital[]=[]
  hospital:Hospital
  constructor(private hospitalService:HospitalService,private route:ActivatedRoute) { }

  ngOnInit(): void {

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id  = parseInt(decodedToken.nameid)

    // this.route.params.subscribe(res=>{
    //   this.id = res['id'];
    // })
// this. getHospitalById(this.id)
    this.getHospital()
  }


  getHospital(){
    this.hospitalService.getHospitals().subscribe(res=>{
           this.hospitalList =res;
    })
  }


  getHospitalById(id){
    this.hospitalService.getHospitalById(id).subscribe(res=>{
      this.hospital = res;
    })
  }
}
