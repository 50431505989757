<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            Nurse Routine Assessment

        </ng-template>
        <div class="conatiner mx-5 my-5 ">
            <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
                data-target=".bd-example-modal-lg"> Print </button>
            <div class="row">
                <div class="col-md-12" *ngFor="let item of nurseAssesmentList">

                    <mat-card class="mat-elevation-z8 mt-3 ">

                        <mat-card-content id="printable">
                            <h2 class="bgClr">Nurse Routine Assessment</h2>

                            <p class="float-right">Date : {{item.createdDate | date: 'medium' }}</p>

                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    Patient Name <p>{{item.PatientName}}</p>
                                </div>
                                <div class="col-md-6">
                                    Patient D.O.B <p>{{item.PatientDOB}}</p>

                                </div>

                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-3">
                                    Patient Height <p>{{item.PatientHeight}}</p>

                                </div>
                                <div class="col-md-3">
                                    Patient Height <p>{{item.PatientWeight}}</p>
                                </div>
                                <div class="col-md-3">
                                    Blood Pressure <p>{{item.BloodPressure}}</p>
                                </div>
                                <div class="col-md-3">
                                    Pulse <p>{{item.Pulse}}</p>

                                </div>

                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    Is Patient on Any Medication <p>{{item.IsPatientonAnyMedication}}</p>

                                </div>
                                <div class="col-md-6">
                                    Is Patient Under Any Medical Treatment <p>{{item.IsPatientUnderAnyMedicalTreatment}}
                                    </p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3">
                                    <h2>General</h2>
                                    <tr>
                                        <th> Recent Weight Changes : </th>

                                        <td>{{item.RecentWeightChanges ?'Yes':'No'}}</td>
                                    </tr>
                                    <tr>
                                        <th> Chills : </th>
                                        <td>{{item.Chills ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Fever : </th>
                                        <td>{{item.Fever ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Weakness : </th>
                                        <td>{{item.Weakness ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoGeneralProblems ?'No Other Problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherGeneralProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    Eyes
                                    <tr>
                                        <th> Vision Change : </th>
                                        <td>{{item.VisionChange ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Glassess/Contacts : </th>
                                        <td>{{item.GlassessOrContacts ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Catracts : </th>
                                        <td>{{item.Catracts ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Glaucoma : </th>
                                        <td>{{item.Glaucoma ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoEyeProblems ?'No Problemes':'Other Problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th>Other Problems : </th>
                                        <td>{{item.OtherEyeProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2>Ear,Nose,Throat</h2>
                                    <tr>
                                        <th> HearingLoss : </th>
                                        <td>{{item.HearingLoss ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> EarAche/Infection : </th>
                                        <td>{{item.EarAcheOrInfection ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Ringing In Ear : </th>
                                        <td>{{item.RingingInEar ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoEarProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherEarProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2> Cardio Vascular </h2>
                                    <tr>
                                        <th> Chest Pain : </th>
                                        <td>{{item.ChestPain ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Cyanosis : </th>
                                        <td>{{item.Cyanosis ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Edema : </th>
                                        <td>{{item.Edema ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Swelling In Legs : </th>
                                        <td>{{item.SwellingInLegs ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Palpitations : </th>
                                        <td>{{item.Palpitations ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoCadioVascularProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherCadioVascularProblems}}</td>

                                    </tr>
                                    <tr>
                                        <th> Rythm : </th>
                                        <td>{{item.Rythm}}</td>

                                    </tr>
                                    <tr>
                                        <th> Volume : </th>
                                        <td>{{item.Volume}}</td>

                                    </tr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <h2>Respiratory </h2>
                                    <tr>
                                        <th> Shortness Of Breath : </th>
                                        <td>{{item.ShortnessOfBreath ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Wheezing Or Asthma : </th>
                                        <td>{{item.WheezingOrAsthma ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Frequent Cough : </th>
                                        <td>{{item.FrequentCough ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Dyspnoea : </th>
                                        <td>{{item.Dyspnoea ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Gasping : </th>
                                        <td>{{item.Gasping ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Haemoptysis : </th>
                                        <td>{{item.Haemoptysis ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Expectorant : </th>
                                        <td>{{item.Expectorant ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Oxygen : </th>
                                        <td>{{item.Oxygen ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoRespiratoryProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherRespiratoryProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2>Gastrointestinal </h2>
                                    <tr>
                                        <th> Acid Reflex : </th>
                                        <td>{{item.AcidReflex ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Nausea Or Vomitimng : </th>
                                        <td>{{item.NauseaOrVomitimng ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Abdominal Pain : </th>
                                        <td>{{item.AbdominalPain ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Tenderness : </th>
                                        <td>{{item.Tenderness ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Constipation: </th>
                                        <td>{{item.Constipation ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Diarrhea : </th>
                                        <td>{{item.Diarrhea ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.OtherGastricProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherRespiratoryProblems}}</td>

                                    </tr>
                                    <tr>
                                        <th> Date Of Last Bowel Movement : </th>
                                        <td>{{item.DateOfLastBowelMovement}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2>Muskulatal </h2>
                                    <tr>
                                        <th> Muscle Aches : </th>
                                        <td>{{item.MuscleAches ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Swelling Of Joints : </th>
                                        <td>{{item.SwellingOfJoints ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Stiffness In Joints : </th>
                                        <td>{{item.StiffnessInJoints ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoMuskularProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherMuskularProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2>Skin </h2>
                                    <tr>
                                        <th> Rash : </th>
                                        <td>{{item.Rash ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Ulcers : </th>
                                        <td>{{item.Ulcers ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Abnormal Scars : </th>
                                        <td>{{item.AbnormalScars ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Dehydrated : </th>
                                        <td>{{item.Dehydrated ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Cyanotic : </th>
                                        <td>{{item.Cyanotic ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Pale : </th>
                                        <td>{{item.Pale ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Icterus : </th>
                                        <td>{{item.Icterus ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Cold : </th>
                                        <td>{{item.Cold ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Redness : </th>
                                        <td>{{item.Redness ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Lesson : </th>
                                        <td>{{item.Lesson ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Wound : </th>
                                        <td>{{item.Wound ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoSkinProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherSkinProblems}}</td>

                                    </tr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <h2>Neurological </h2>
                                    <tr>
                                        <th> Headaches : </th>
                                        <td>{{item.Headaches ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Dizziness : </th>
                                        <td>{{item.Dizziness ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Paralysis : </th>
                                        <td>{{item.Paralysis ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Oriented : </th>
                                        <td>{{item.Oriented ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Tremors : </th>
                                        <td>{{item.Tremors ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Unresponsive : </th>
                                        <td>{{item.Unresponsive ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Swallow : </th>
                                        <td>{{item.Swallow ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Speech : </th>
                                        <td>{{item.Speech ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Numbness , Tingling , Loss of Sensation : </th>
                                        <td>{{item.NumbnessTinglingLossofSensation ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoNeurologicalProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherNeurologicalProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2> Psychiatric</h2>
                                    <tr>
                                        <th> Depression : </th>
                                        <td>{{item.Depression ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Nervousness : </th>
                                        <td>{{item.Nervousness ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Anxiety : </th>
                                        <td>{{item.Anxiety ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Mood Swings : </th>
                                        <td>{{item.MoodSwings ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoPsychatricProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherPsychatricProblems}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2>Endocrine </h2>
                                    <tr>
                                        <th> Exessive Thirst Or Hunger : </th>
                                        <td>{{item.ExessiveThirstOrHunger ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Hot Cold Intolerance : </th>
                                        <td>{{item.HotColdIntolerance ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Hot Flashes : </th>
                                        <td>{{item.HotFlashes ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoEndocrine ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherEndocrine}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-3">
                                    <h2> Urinary Tract </h2>
                                    <tr>
                                        <th> Urenary Incontinence : </th>
                                        <td>{{item.UrenaryIncontinence ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Retention : </th>
                                        <td>{{item.Retention ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> Hematuria : </th>
                                        <td>{{item.Hematuria ?'Yes':'No'}}</td>

                                    </tr>


                                    <tr>
                                        <th> Urine Color : </th>
                                        <td>{{item.UrineColor}}</td>

                                    </tr>
                                    <tr>
                                        <th> Catheter Type : </th>
                                        <td>{{item.CatheterType}}</td>

                                    </tr>
                                    <tr>
                                        <th> Catheter Inserted On : </th>
                                        <td>{{item.CatheterInsertedOn}}</td>

                                    </tr>
                                    <tr>
                                        <th> None : </th>
                                        <td>{{item.NoUrenaryProblems ?'No problems':'Other problems'}}</td>

                                    </tr>
                                    <tr>
                                        <th> Other : </th>
                                        <td>{{item.OtherUrenaryProblems}}</td>

                                    </tr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <h2>gynecology </h2>
                                    <tr>
                                        <th> LMP : </th>
                                        <td>{{item.Lmp}}</td>

                                    </tr>
                                </div>
                                <div class="col-md-6">
                                    <h2>Fall Risk </h2>
                                    <tr>
                                        <th> Vulnerable : </th>
                                        <td>{{item.Vulnerable ?'Yes':'No'}}</td>

                                    </tr>
                                    <tr>
                                        <th> SideRailon : </th>
                                        <td>{{item.SideRailon ?'Yes':'No'}}</td>

                                    </tr>

                                    <tr>
                                        <th> AttendantInformed : </th>
                                        <td>{{item.AttendantInformed ?'Yes':'No'}}</td>

                                    </tr>
                                </div>

                            </div>

                        </mat-card-content>
                        <button class="float-right"
                            [routerLink]="['/editNurseDailyAssessment/',item.NurseDailyAssessmentId]" color="primary"
                            mat-mini-fab><mat-icon>edit</mat-icon> </button>
                        <button class="float-right mx-3" (click)="delete(item.NurseDailyAssessmentId)" color="accent"
                            mat-mini-fab><mat-icon>delete</mat-icon> </button>

                    </mat-card>
                </div>
            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>
            Nurse Routine Assessment
        </ng-template>
        <app-add-nurse-daily-assessment></app-add-nurse-daily-assessment>
    </mat-tab>
</mat-tab-group>

<!-- <mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                General
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Eyes
            </mat-panel-title>

        </mat-expansion-panel-header>


    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Ear,Nose,Throat
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Cardio Vascular
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Respiratory
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Gastrointestinal
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Muskulatal
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Skin
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Neurological
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>

    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Psychiatric
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Endocrine
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                gynecology
            </mat-panel-title>

        </mat-expansion-panel-header>


    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Urinary Tract
            </mat-panel-title>

        </mat-expansion-panel-header>

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Fall Risk
            </mat-panel-title>

        </mat-expansion-panel-header>



    </mat-expansion-panel>
    <hr>


</mat-accordion> -->